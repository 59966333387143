import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { Container, Col, Row, Form, Spinner, Button, InputGroup, Image, Modal, Card } from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { toast } from 'react-hot-toast';
import profile from '../assets/profieimg.png'
import { MdDelete } from "react-icons/md";
import { TextField, Chip, Stack } from "@mui/material";
import '../Styles/Homepage.css'
import Bottom from "./Home/bottom";
import { State, City } from "country-state-city";
import { Autocomplete } from "@mui/material";
import '../Styles/List.css'

const JobSeekerProfileView = () => {

  const [stateOptions, setStateOptions] = useState([]); // 🔹 State List
  const [cityOptions, setCityOptions] = useState([]);   // 🔹 City List

  const [currentPage, setCurrentPage] = useState(1);



  const fetchStates = (inputValue = "", countryCode = "IN") => {
    const states = State.getStatesOfCountry(countryCode);
    if (states) {
      const filteredStates = states
        .map((state) => state.name)
        .filter((state) => state.toLowerCase().includes(inputValue.toLowerCase()));

      setStateOptions(filteredStates);
    }
  };

  // Function to fetch cities dynamically
  const fetchCities = (stateName = "", countryCode = "IN") => {
    const stateObj = State.getStatesOfCountry(countryCode).find(
      (state) => state.name === stateName
    );
    if (stateObj) {
      const cities = City.getCitiesOfState(countryCode, stateObj.isoCode);
      if (cities) {
        const cityNames = cities.map((city) => city.name);
        setCityOptions(cityNames);
        // setNativeLocationOptions(cityNames); // Native locations same as city
      }
    }
  }

  useEffect(() => {
    fetchCities();
    fetchAllCities();
    fetchStates();
  }, []);

  const fetchAllCities = (countryCode = "IN") => {
    const states = State.getStatesOfCountry(countryCode);
    let allCities = [];

    states.forEach((state) => {
      const cities = City.getCitiesOfState(countryCode, state.isoCode);
      if (cities) {
        allCities = [...allCities, ...cities.map((city) => city.name)];
      }
    });

  };



  const [errors, setErrors] = useState({}); // To track validation errors

  const [, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [, setPreview] = useState(null);
  const [, setSavedFile] = useState(null); // 
  const [Resume, setResume] = useState(null)

  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem("savedFormData");
    return savedData
      ? JSON.parse(savedData)
      : {
        userName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        role: "",
        city: "",
        state: "",
        firstName: "",
        lastName: "",
        surName: "",
        designation: "",
        department: "",
        cinNumber: "",
        gstIn: "",
        panNumber: "",
        branch: "",
        landmark: "",
        pincode: "",
        communicationAdress: "",
        NativeLocation: "",
        NoticePeriod: "",
        LanguagesKnown: "",
        InterestedJobField: "",
        country: "",
        resume: "",
        Relocation: "",
        ReasonsforChange: "",
        image: "",
        otpCode: "",
        otpExpiryTime: "",
        emailAddress: "",
        address: "",
        aadharNo: "",
        voterId: "",
        drivingLicenceNumber: "",
        profileTitle: "",
        totalExperience: "",
        salary: "",
        Currentsalary: "",
        expectedSalary: "",
        miscDetails: "",
        skills: [], // Arrays should be initialized properly
        qualification: [],
        responsibilities: [],
        dob: "",
        Remarks: "",
        JobseekerFeedback: "",
        JobseekerFeedbackStatus: "",
        WRKBatchCODE: "",
      };
  });

  // 2️⃣ Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // 3️⃣ Save form data to localStorage and reload it
  const handleSave = async () => {
    const userId = sessionStorage.getItem("userId");

    try {
      // Save to localStorage
      localStorage.setItem("savedFormData", JSON.stringify(formData));

      // API payload
      const payload = { ...formData };

      // API call using PATCH
      await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: `${token}` },
      });

      // Fetch updated data
      fetchSavedData();

      // Show success message
      toast.success("Data saved successfully!");

    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data. Please try again.");
    }
  };

  // 4️⃣ Fetch saved data from localStorage
  const fetchSavedData = () => {
    const savedData = localStorage.getItem("savedFormData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // 5️⃣ Load saved data when the component mounts (useEffect)
  useEffect(() => {
    fetchSavedData();
  }, []);




  console.log("saved", formData)



  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [, setIsUploading] = useState(false);


  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  console.log(`Bearer ${token}`);



  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("userId");

      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data && response.data.data) {
        setUserDetails(response.data.data);
        setFormData(response.data.data);
        setResume(response.data.data.resume);
      } else {
        setError(response.data.message || "Failed to fetch profile.");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
      setError("An error occurred while fetching the profile.");
    } finally {
      setLoading(false);
    }
  }, [apiUrl, token]); // ✅ Dependency array to prevent unnecessary recreation


  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  console.log("formdata", formData);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the uploaded file is an image
      if (!file.type.startsWith("image/")) {
        toast.error("Only image files (JPG, PNG, GIF) are allowed!");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };


  const handleImageRemove = async (e) => {
    e?.stopPropagation(); // Prevent accidental file input triggers

    try {
      // Prepare the payload
      const payload = {
        ...formData,
        image: null
      };
      const userId = sessionStorage.getItem("userId");

      // Send PATCH request to update the profile
      const response = await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: ` ${token}` },
      });

      if (response.status === 200) {
        // Update local state to reflect the image removal
        setFormData((prevData) => ({ ...prevData, image: null }));

        // Refresh profile data
        await fetchProfile();
      }
    } catch (error) {
      console.error("Error removing image:", error.response?.data || error.message);
    }
  };




  const handleFileUpload = async () => {
    if (!file) return null;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${apiUrl}/shared/file-upload`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUploading(false);

      if (response.status === 200) {
        toast.success("File uploaded successfully.")
        return response.data.fileName;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      setIsUploading(false);
      toast.error("File upload failed.")

      return null;
    }
  };



  const handleDoneClick = async () => {
    // Check if required fields are filled
    const requiredFields = [
      "firstName", "lastName", "Currentsalary", "expectedSalary", "skills",
      "totalExperience", "phoneNumber", "city", "profileTitle", "companyName", "pincode",
      "qualification",
      "responsibilities", "qualification",
    ];

    const missingFields = requiredFields.filter(field => !formData[field]);



    // If there are missing fields or validation errors, show an error
    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following required fields: ${missingFields.join(",")}`,
        {
          style: {
            marginTop: '60px', // You can adjust this
            backgroundColor: 'red',
            color: 'white',
          }
        }
      );
      return;  // Prevent form submission if validation fails
    }

    // If everything is valid, proceed with further steps (e.g., file upload and API call)
    try {
      const uploadedFileName = await handleFileUpload(); // Assuming handleFileUpload() is defined
      const userId = sessionStorage.getItem("userId");
      setSavedFile(uploadedFileName); // Store the saved file name to show later

      // Payload to send with the API call
      const payload = {
        ...formData,
        resume: uploadedFileName,
        userId,
        // skills: Array.isArray(formData.skills) ? formData.skills : [],
        skills: formData.skills,
        WRKBatchCODE: formData.WRKBatchCODE,
        // responsibilities: Array.isArray(formData.responsibilities) ? formData.responsibilities : [],
        responsibilities: formData.responsibilities,
        qualification: Array.isArray(formData.qualification) ? formData.qualification : []
      };

      // Ensure payload is valid before making the request
      if (!payload || Object.keys(payload).length === 0) {
        throw new Error("Invalid payload.");
      }

      // API call to update user profile
      await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });

      // Show success modal after successful update
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    }
  };



  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
    }


  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleDeleteResume = () => {
    setResume(null);
    document.getElementById("resumeInput").value = ""; // Clear file input
  };


  const handleValidation = () => {
    let newErrors = {};

    // Personal Details Validation
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!/^\+91\d{10}$/.test(formData.phoneNumber))
      newErrors.phoneNumber = "Enter a valid 10-digit phone number (e.g., +91XXXXXXXXXX)";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!/^\d{6}$/.test(formData.pincode)) newErrors.pincode = "Enter a valid 6-digit pincode";

    // KYC Details Validation (Only if the field is filled)
    if (formData.aadharNo && !/^\d{12}$/.test(formData.aadharNo))
      newErrors.aadharNo = "Enter a valid 12-digit Aadhar Number";

    if (formData.panNumber && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panNumber))
      newErrors.panNumber = "Enter a valid PAN (e.g., ABCDE1234F)";

    if (formData.voterId && !/^[A-Z]{3}[0-9]{7}$/.test(formData.voterId))
      newErrors.voterId = "Enter a valid 10-character Voter ID";

    if (formData.drivingLicenceNumber && !/^[A-Z0-9]{10,20}$/.test(formData.drivingLicenceNumber))
      newErrors.drivingLicenceNumber = "Enter a valid Driving Licence Number (10-20 alphanumeric characters)";

    // Set errors in state
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };


  const professionalDetailsFields = [
    { key: "profileTitle", required: true },
    { key: "Currentsalary", required: true },
    { key: "expectedSalary", required: true },
    { key: "totalExperience", required: true },
    { key: "NativeLocation", required: false },
    { key: "LanguagesKnown", required: false },
    { key: "skills", required: true },
    { key: "responsibilities", required: true },
    { key: "miscDetails", required: false },
    { key: "Remarks", required: false },
    { key: "qualification", required: true },
    { key: "ReasonsforChange", required: false },
    { key: "companyName", required: true },
    { key: "InterestedJobField", required: false },
    { key: "Relocation", required: false },
    // { key: "JobseekerFeedback", required: false },
    { key: "NoticePeriod", required: false },
    // { key: "WRKBatchCODE", required: true },
  ];


  const getLabel = (key, isRequired = false) => {
    const labelMapping = {
      profileTitle: "JOB TITLE (Current)",
      Currentsalary: "CURRENT SALARY (Month)",
      expectedSalary: "EXPECTED SALARY (Month)",
      responsibilities: "JOB RESPONSIBILITIES",
      // WRKBatchCODE: "WRK-BATCH CODE",
      companyName: "COMPANY NAME",
      Relocation: "READY FOR RELOCATION (Y/N)",
      Addl: "DL DETAILS",
      Addl2: "ADDL DETAILS",
      ReasonsforChange: "REASONS FOR CHANGE",
      JobseekerFeedback: "JOBSEEKER STATUS",
      totalExperience: "TOTAL EXPERIENCE"

    };

    const label = labelMapping[key] || key.replace(/([A-Z])/g, " $1").toUpperCase();
    return (
      <>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </>
    );
  };


  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">

        <Row className="g-4">

          <Col className="side">
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-contents" >
            <div className="card border-0 rounded-0 " style={{ background: '#F5F6FA' }}>
              <div className="card-body">
                <div className="profile-details ">
                  <div className="text-center m" style={{ position: "relative" }}>
                    {currentPage === 1 && (<div
                      className="profile-image-wrapper "
                      onClick={handleImageClick}
                      style={{
                        position: "absolute",
                        top: "106px",
                        right: "20px",
                        zIndex: "1000",
                        cursor: "pointer",
                      }}
                    >
                      <Image
                        src={formData.image || profile}
                        alt="Profile Picture"
                        roundedCircle
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          border: "4px solid #E8EBF9",
                        }}
                      />
                      {/* X Button for Deleting Image */}
                      {formData.image && (
                        <h4
                          onClick={handleImageRemove}
                          style={{
                            position: 'absolute',
                            top: '80px'
                          }}
                          className="text-danger"
                        >
                          <MdDelete />
                        </h4>
                      )}

                      <input type="file" id="imageInput" style={{ display: "none" }} onChange={handleImageChange} />

                    </div>)}



                  </div>

                  {/* <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  /> */}
                  <h5 className=" ">Profile Details</h5>
                  {/* <Card className="mb-4"> */}
                  {/* <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                      Personal Details
                    </Card.Header> */}
                  {/* <Card.Body> */}
                  {currentPage === 1 ? (
                    <Card style={{ marginTop: "200px" }}>
                      <Card.Header style={{ backgroundColor: "rgb(47, 39, 97)", color: "white" }}>
                        Personal Details
                      </Card.Header>
                      <Card.Body>

                        <Row className="g-3">
                          {[
                            { key: "firstName", label: "First Name", required: true },
                            { key: "lastName", label: "Last Name", required: true },
                            { key: "phoneNumber", label: "Phone Number", required: true },
                            { key: "state", label: "State", required: true },
                            { key: "city", label: "City", required: true },
                            { key: "pincode", label: "Pincode", required: true },
                          ].map(({ key, label, required }) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>
                                  {label} {required && <span style={{ color: "red" }}>*</span>}
                                </InputGroup.Text>
                                {key === "state" || key === "city" ? (
                                  <Autocomplete

                                    options={key === "state" ? stateOptions : cityOptions}
                                    getOptionLabel={(option) => option}
                                    value={formData[key] || ""}
                                    onInputChange={(event, newInputValue) => {
                                      if (key === "state") fetchStates(newInputValue);
                                    }}
                                    onChange={(event, newValue) => {
                                      setFormData({ ...formData, [key]: newValue });
                                      if (key === "state") fetchCities(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={`Type ${label}...`}
                                        error={Boolean(errors[key])}
                                        helperText={errors[key]}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Form.Control
                                    type={key === "phoneNumber" ? "tel" : "text"}
                                    name={key}
                                    value={formData[key] || ""}
                                    onChange={handleChange}
                                    isInvalid={Boolean(errors[key])}
                                  />
                                )}
                                {errors[key] && (
                                  <Form.Control.Feedback type="invalid">{errors[key]}</Form.Control.Feedback>
                                )}
                              </InputGroup>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>

                      {/* KYC Details Section */}
                      <Card.Header style={{ backgroundColor: "rgb(47, 39, 97)", color: "white", marginTop: "10px" }}>
                        KYC Details
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-3">
                          {[
                            { key: "aadharNo", label: "Aadhar No", required: false },
                            { key: "panNumber", label: "PAN Number", required: false },
                            { key: "voterId", label: "Voter ID", required: false },
                            { key: "drivingLicenceNumber", label: "Driving Licence Number", required: false },
                          ].map(({ key, label, required }) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>{label}</InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name={key}
                                  value={formData[key] || ""}
                                  onChange={handleChange}
                                  isInvalid={Boolean(errors[key])}
                                />
                                {errors[key] && (
                                  <Form.Control.Feedback type="invalid">{errors[key]}</Form.Control.Feedback>
                                )}
                              </InputGroup>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>

                      {/* Next Button */}
                      <Card.Body className="profile-buttons">
                        <Button
                          variant="warning"
                          className="mt-3 me-2  profile-save"
                          onClick={handleSave}
                        >
                          Save
                        </Button>

                        <Button
                          variant="primary"
                          className="mt-3 profile-next"
                          onClick={() => {
                            if (handleValidation()) setCurrentPage(2);
                          }}
                        >
                          Next
                        </Button>


                      </Card.Body>
                    </Card>

                  ) : (
                    <Card>
                      <Card.Header style={{ backgroundColor: "rgb(47, 39, 97)", color: "white" }}>
                        Professional Details
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-3">
                          {professionalDetailsFields.map(({ key, required }) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>{getLabel(key, required)}</InputGroup.Text>

                                {key === "skills" || key === "responsibilities" ? (
                                  <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                                    <TextField
                                      style={{ position: "relative" }}
                                      label={key === "skills" ? "Enter your Skills" : "Enter your Responsibilities"}
                                      value={formData[`new${key.charAt(0).toUpperCase() + key.slice(1)}`] || ""}
                                      onChange={(e) => {
                                        const words = e.target.value.trim().split(/\s+/);
                                        if (words.length <= 20) {
                                          setFormData((prev) => ({
                                            ...prev,
                                            [`new${key.charAt(0).toUpperCase() + key.slice(1)}`]: e.target.value,
                                          }));
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          const newValue = formData[`new${key.charAt(0).toUpperCase() + key.slice(1)}`]?.trim();
                                          if (!newValue) return;

                                          const words = newValue.split(/\s+/);
                                          if (words.length > 20) {
                                            toast.error(`${key.charAt(0).toUpperCase() + key.slice(1)} cannot exceed 20 words.`);
                                            return;
                                          }

                                          setFormData((prev) => ({
                                            ...prev,
                                            [key]: [...(prev[key] || []), newValue],
                                            [`new${key.charAt(0).toUpperCase() + key.slice(1)}`]: "",
                                          }));
                                        }
                                      }}
                                      fullWidth
                                    />
                                    <Button
                                      style={{ position: "absolute", right: "10px", top: "30px" }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        const newValue = formData[`new${key.charAt(0).toUpperCase() + key.slice(1)}`]?.trim();
                                        if (!newValue) return;

                                        const words = newValue.split(/\s+/);
                                        if (words.length > 20) {
                                          toast.error(`${key.charAt(0).toUpperCase() + key.slice(1)} cannot exceed 20 words.`);
                                          return;
                                        }

                                        setFormData((prev) => ({
                                          ...prev,
                                          [key]: [...(prev[key] || []), newValue],
                                          [`new${key.charAt(0).toUpperCase() + key.slice(1)}`]: "",
                                        }));
                                      }}
                                    >
                                      +
                                    </Button>

                                    <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap", maxHeight: "100px", overflowY: "auto", padding: "8px", borderRadius: "5px" }}>
                                      {(formData[key] || []).map((item, index) => (
                                        <Chip
                                          key={index}
                                          label={item}
                                          onDelete={() =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              [key]: prev[key].filter((s) => s !== item),
                                            }))
                                          }
                                        />
                                      ))}
                                    </Stack>
                                  </Stack>
                                ) : key === "JobseekerFeedback" ? (
                                  <>
                                    <Form.Select
                                      name={key}
                                      value={formData[key] || ""}
                                      onChange={handleChange}
                                      required
                                      className="w-50"
                                    >
                                      <option value="">Select Feedback</option>
                                      <option value="Interested">Interested</option>
                                      <option value="Not Interested">Not Interested</option>
                                      <option value="Not Reachable">Not Reachable</option>
                                      <option value="Didn't answer the call">Didn't answer the call</option>
                                      <option value="Invalid No">Invalid No</option>
                                      <option value="Line Busy">Line Busy</option>
                                      <option value="Out of Service/Switch Off">Out of Service/Switch Off</option>
                                      <option value="No incoming calls">No incoming calls</option>
                                      <option value="Call forwarded">Call forwarded</option>
                                      <option value="Call Back">Call Back</option>
                                      <option value="Duplicate Profile">Duplicate Profile</option>
                                      <option value="Mismatch of Profile to JD">Mismatch of Profile to JD</option>
                                      <option value="Other">Other</option>
                                    </Form.Select>

                                    {formData[key] === "Interested" && (
                                      <Form.Group className="mt-3 w-75 mx-auto">
                                        <Form.Select
                                          name="JobseekerFeedbackStatus"
                                          value={formData.JobseekerFeedbackStatus || ""}
                                          onChange={handleChange}
                                          required
                                          className="form-select"
                                        >
                                          <option value="">Select Option</option>
                                          <option value="Screened by Call Center">Screened by Call Center</option>
                                          <option value="Interview Scheduled">Interview Scheduled</option>
                                          <option value="Interview Done">Interview Done</option>
                                          <option value="Shortlisted">Shortlisted</option>
                                          <option value="Selected">Selected</option>
                                          <option value="Job Offered">Job Offered</option>
                                        </Form.Select>
                                      </Form.Group>
                                    )}
                                  </>
                                ) :
                                  key === "ReasonsforChange" ? (
                                    <>
                                      <Form.Group style={{ width: '68%' }}>

                                        <Form.Select

                                          name={key}
                                          value={formData[key] || ""}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => ({
                                              ...prev,
                                              [key]: value, // Keep "Other" as value to prevent disappearing
                                              otherReason: value === "Other" ? "" : prev.otherReason, // Reset otherReason if not needed
                                            }));
                                          }}
                                          required
                                        >
                                          <option value="">Select Reason</option>
                                          <option value="personal">Personal</option>
                                          <option value="Family">Family</option>
                                          <option value="Salary">Salary</option>
                                          <option value="Job Satisfaction">Job Satisfaction</option>
                                          <option value="Location">Location</option>
                                          <option value="Travel Issue">Travel Issue</option>
                                          <option value="Marriage">Marriage</option>
                                          <option value="Company Culture">Company Culture</option>
                                          <option value="Other">Other</option>
                                        </Form.Select>


                                        {formData[key] === "Other" && (
                                          <Form.Control
                                            type="text"
                                            name="otherReason"
                                            value={formData.otherReason || ""}
                                            onChange={(e) => setFormData((prev) => ({ ...prev, otherReason: e.target.value }))}
                                            placeholder="Enter other reason"
                                            required
                                            style={{ marginTop: "10px" }}
                                          />
                                        )}
                                      </Form.Group>

                                    </>) :
                                    key === "Relocation" ? (
                                      <Form.Select
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </Form.Select>

                                    ) :
                                      ["miscDetails", "Remarks", "qualification"].includes(key) ? (
                                        // Render textarea for specified fields
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          name={key}
                                          value={formData[key] || ""}
                                          onChange={handleChange}
                                          required={required}
                                        />
                                      )
                                        : (
                                          <Form.Control type="text" name={key} value={formData[key] || ""} onChange={handleChange} required={required} />
                                        )}
                              </InputGroup>


                            </Col>
                          ))}

                          <Row 
                          style={{ display: 'flex',flexDirection:'column', justifyContent: 'space-between'}} >
                            <Form.Group controlId="resume" className="mb-4 w-50">
                              <Form.Label>Upload Resume</Form.Label>
                              <Form.Control
                                id="resumeInput"
                                type="file"
                                onChange={handleFileChange}
                                required
                              />
                            </Form.Group>

                            {Resume && (
                              <div className="saved-file d-flex align-items-center" style={{
                                background: '#E9ECEF', width: '350px',height:'40px', display: 'flex', alignItems: 'center', padding: '5px',
                                borderRadius: '10px', marginLeft: '10px', color: 'black', justifyContent: 'space-between'
                              }}>
                                <p className="mb-0">
                                  <strong>Resume:</strong> {Resume.split('_')[1]}
                                </p>
                                <Button
                                  style={{ width: '50px', background: "#E9ECEF", border: 'none' }}
                                  variant="danger"
                                  className="ms-2"
                                  size="sm"
                                  onClick={handleDeleteResume}
                                >
                                  ❌
                                </Button>
                              </div>
                            )}
                          </Row>
                        </Row>
                        <div className="profile-buttons ">
                          <Button variant="secondary" className="mt-3 me-2 profile-back" onClick={() => setCurrentPage(1)}>
                            Back
                          </Button>
                          <Button
                            variant="warning"
                            className="mt-3 me-2  profile-save"
                            onClick={handleSave}
                          >
                            Save
                          </Button>

                          <Button variant="success" className="mt-3 me-2 profile-submit" onClick={handleDoneClick}>
                            Submit
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>

                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully updated.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Col className="bottomNavigate">
        <Bottom />
      </Col>
    </div>
  );
};

export default JobSeekerProfileView;
