import React, { useState, useEffect, useRef } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import {
  Container,
  Nav,
  Button,
  Navbar,
  Modal,
  NavDropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import JobSeekerRegistration from "../components/JobseekerLogin";
import { useTranslation } from "react-i18next";
import { IoLogOutOutline } from "react-icons/io5";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { MdLeaderboard } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { FaMicroblog } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import { capitalize } from "@mui/material";
import '../Styles/EmployerDashboardH.css'

import { AiOutlineHome } from "react-icons/ai";
import { IoIosVideocam } from "react-icons/io";
import axios from "axios";
import EmployerLoginOtp from "./EmployerLoginotp";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import HomeIcon from "@mui/icons-material/Home";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

const EmployerDashboardHeader = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleDropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const handleContactUsDropdownToggle = () =>
    setIsContactUsDropdownOpen(!isContactUsDropdownOpen);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showJobseekerPopup, setShowJobseekerPopup] = useState(false);
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isContactUsDropdownOpen, setIsContactUsDropdownOpen] = useState(false);
  const userName = sessionStorage.getItem("userName");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");

  const languages = [
    { value: "en", label: "ENGLISH" },
    { value: "ta", label: "தமிழ்" },
    { value: "ma", label: "മലയാളം" },
    { value: "kn", label: "ಕನ್ನಡ" },
    { value: "hi", label: "हिंदी" },
    { value: "te", label: "తెలుగు" },
  ];
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDropdown, setOpenDropdown] = useState({});

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const handleDropdownClick = (menu) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };


  const getDashboardPath = () => {
    if (!isLoggedIn) return "/";
    return role === "admin" ? "/" : "/dashboard";

  };



  const handleHomeClick = () => {
    setOpenDrawer(false); // Close the drawer
    navigate(getDashboardPath()); // Navigate after closing
  };



  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const fetchSubscriptionStatus = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await axios.get(`${apiUrl}/user/get-subscription-status/${userId}`, {
        headers: { Authorization: `${token}` },
      });

      if (response.status === 200 && response.data.status) {
        setSubscriptionStatus(response.data.subscriptionStatus);
      }
    } catch (error) {
      console.error("Failed to fetch subscription status:", error);
    }
  };

  const handleToggle = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const newStatus = subscriptionStatus === "active" ? "inactive" : "active";

    // Optimistically update UI
    setSubscriptionStatus(newStatus);

    const payload = { userId, subscriptionStatus: newStatus };

    try {
      const token = sessionStorage.getItem("accessToken");
      await axios.post(`${apiUrl}/user/update-subscription-status`, payload, {
        headers: { Authorization: `${token}` },
      });
    } catch (error) {
      console.error("Failed to update subscription status:", error);
      // Revert if API fails
      setSubscriptionStatus(subscriptionStatus);
    } finally {
      setIsLoading(false);
    }
  };



  // ✅ Inline Styles
  const styles = {
    container: { display: "flex", alignItems: "center", gap: "10px", fontSize: "16px" },
    toggleSwitch: { position: "relative", display: "inline-block", width: "50px", height: "26px" },
    input: { opacity: 0, width: 0, height: 0 },
    slider: {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: subscriptionStatus === "active" ? "#4cd964" : "#ccc",
      borderRadius: "26px",
      transition: "0.4s",
    },
    sliderBefore: {
      content: '""',
      position: "absolute",
      height: "20px",
      width: "20px",
      left: subscriptionStatus === "active" ? "26px" : "3px",
      bottom: "3px",
      backgroundColor: "white",
      borderRadius: "50%",
      transition: "0.4s",
    },
  };


  const handleSelect = (value) => {
    handleChange({ target: { value } }); // Simulate select's onChange event
    setIsOpen(false);
  };

  const [isVideoDropdownOpen, setIsVideoDropdownOpen] = useState(false); // Manage dropdown state

  // Toggle the visibility of the Videos dropdown
  const handleVideoDropdownToggle = () => {
    setIsVideoDropdownOpen((prevState) => !prevState);
  };

  // Handle the video item selection and close the dropdown
  const handleVideoItemSelect = () => {
    setIsVideoDropdownOpen(false); // Close the dropdown
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const Language = localStorage.getItem("lang");

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contactdropdownRef = useRef(null);


  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contactdropdownRef.current &&
        !contactdropdownRef.current.contains(event.target)
      ) {
        setIsContactUsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
    // setSelectedLanguage("");

    // alert(e.target.value);
    // i18n.changeLanguage(lang); // Change the language in i18n
  };

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
      console.log("Token exists:", token);
    } else {
      setIsAuthenticated(false);
      console.log("Token does not exist");
    }
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const openJobseekerPopup = () => {
    setShowJobseekerPopup(true);


  };

  const closeJobseekerPopup = () => {
    setShowJobseekerPopup(false);

  };

  const openEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(true);
  };

  const closeEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
    setIsLoggedIn(false);
    setShowLogoutConfirmation(false);
    // props.handleLogout()
    window.location.href = "/";
  };


  const handleLogoutConfirmation = () => {
    setShowLogoutConfirmation(true);
    setOpenDrawer(false); // Close the drawer
  };

  const JobSeekerContactForm = () => {
    navigate("/contact", { state: { Roles: "jobseeker" } });
    setUserRoles("jobseeker");
  };

  const EmployerContactForm = () => {
    navigate("/contact", { state: { Roles: "employer" } });
    setUserRoles("employer");
  };


  const role = sessionStorage.getItem("role"); // Get role from sessionStorage


  const DrawerList = (
    <Box role="presentation"  >
      {/* Close Button at the Top */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }} style={{ background: '#F5F6FA' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Navbar.Brand as={Link} to={isLoggedIn ? "/dashboard" : "/"}>

            <img src={logo} loading="lazy" alt="" width={200} className="logo" />
          </Navbar.Brand>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Box>

      <List>
        {/* Home - Click to Navigate */}
        <ListItemButton onClick={handleHomeClick}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>

        {/* <List> */}
        {["Company"].map((text, index) => (
          <div key={text}>
            <ListItemButton onClick={() => handleDropdownClick(text)}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText primary={text} />
              {openDropdown[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/ledership")}>
                  <ListItemText primary="Ledership" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/aboutUs")}>
                  <ListItemText primary="About Us" />
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        ))}

        {["Resources"].map((text, index) => (
          <div key={text}>
            <ListItemButton onClick={() => handleDropdownClick(text)}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText primary={text} />
              {openDropdown[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/BlogPage")}>
                  <ListItemText primary="Blog" />
                </ListItemButton>
                {["Videos"].map((text, index) => (
                  <div key={text}>
                    <ListItemButton onClick={() => handleDropdownClick(text)}>
                      {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                      <ListItemText primary={text} />
                      {openDropdown[text] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    <Collapse in={openDropdown[text]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>

                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/VideoPage")}>
                          <ListItemText primary="Corporate" />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/employerVideos")}>
                          <ListItemText primary="Jobseeker" />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/jobseekerVideos")}>
                          <ListItemText primary="Employer" />
                        </ListItemButton>

                      </List>
                    </Collapse>
                  </div>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>

      {!isLoggedIn && (
        ["Commercial Plans"].map((text) => (
          <div key={text}>
            <ListItemButton onClick={() => handleDropdownClick(text)}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText primary={text} />
              {openDropdown[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/fulfillment-model")}>
                  <ListItemText primary="Job Fulfillment Model" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/subscription-model")}>
                  <ListItemText primary="Subscription Model" />
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        ))
      )}

      <div className="number mt-3">
        <div
          className="contact-card"

        >
          {/* Display the Phone Number */}
          <p
            className="contact-number"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
              marginBottom: "3px",
            }}
          >
            9606997985
          </p>

          {/* Links for WhatsApp and Phone Call */}
          <div
            className="contact-actions"
            style={{
              alignItems: "center",
              gap: "0px",
            }}
          >

            <a
              href="https://wa.me/9606997985"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsappLink btn-link"
              style={{
                fontSize: "10px",
                color: "green",
                textDecoration: "none",
                fontWeight: "500", // Added for better appearance
              }}
            >
              <WhatsAppIcon

                className="whatsappIcon"
              />

              WhatsApp/
            </a>
            <a
              href="tel:+919606997985"
              className="phoneLink btn-link"
              style={{
                fontSize: "10px",
                color: "blue",
                textDecoration: "none",
                fontWeight: "500", // Added for better appearance
              }}
            >
              <PhoneInTalkIcon
                className="phoneCallIcon"

              />
              Call
            </a>
          </div>
        </div>

        <div
          className="contact-card"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            padding: "15px",
          }}
        >
          {/* Display the Phone Number */}
          <p
            className="contact-number"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
              marginBottom: "3px",
            }}
          >
            9606997986
          </p>

          {/* Links for WhatsApp and Phone Call */}
          <div
            className="contact-actions"
            style={{
              alignItems: "center",
              gap: "0px",
            }}
          >
            <WhatsAppIcon

              className="whatsappIcon"
            />
            <a
              href="https://wa.me/9606997986"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsappLink btn-link"
              style={{
                fontSize: "10px",
                color: "green",
                textDecoration: "none",
                fontWeight: "500",
                maxWidth: "50px", // Added for better appearance
              }}
            >
              WhatsApp/
            </a>
            <a
              href="tel:+919606997986"
              className="phoneLink btn-link"
              style={{
                fontSize: "10px",
                color: "blue",
                textDecoration: "none",
                fontWeight: "500", // Added for better appearance
              }}
            >

              <PhoneInTalkIcon
                className="phoneCallIcon"

              />
              Call
            </a>
          </div>
        </div>
      </div>

      <div className="login-buttons" style={{ display: 'flex', flexWrap: 'wrap' }} >
        <button className="jobseekerbtn-container" ><a
          href="javascript:;"
          onClick={openJobseekerPopup}
          className="small-btn  btns jobseekerbtn"

        >
          {t("Jobseeker Login")}
        </a></button>
        <JobSeekerRegistration
          showJobseekerPopup={showJobseekerPopup}
          hideJobseekerPopup={closeJobseekerPopup}
        />
        <button className="employer-container"><a
          href="javascript:;"
          onClick={openEmployerLoginPopup}
          className="small-btn fill  mt-3 btns employerbtn"
        // style={{ width: '160px' }}
        >
          {t("Employer Login")}
        </a></button>

        <EmployerLoginOtp
          showJobseekerPopup={showEmployerLoginPopup}
          hideJobseekerPopup={closeEmployerLoginPopup}
        />
      </div>



    </Box>
  );


  return (
    <>
      <Navbar
        className={`custom-navbar ${isLoggedIn ? "shadow-navbar" : ""}`}
        sticky="top"
        expand="lg"
        bg="white"
        variant="light d-fixed"
        style={{ padding: '0px' }}

      >


        <Container fluid className="bg-white tog">
          <Navbar.Brand as={Link} to={isLoggedIn ? "/dashboard" : "/"}>
            <img src={logo} loading="lazy" alt="" width={200} className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarSupportedContent"
            className="ms-5 toggle  "
            onClick={toggleDrawer(true)}
          />
          <Drawer open={openDrawer} onClose={toggleDrawer(false)} className="newtoggle">
            {DrawerList}
          </Drawer>

          <Navbar.Collapse id="navbarSupportedContent">
            <Nav
              className="me-auto mb-2 mb-lg-0 drop"
            // style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <Nav.Link as={Link} to={getDashboardPath()} className="actives " style={{ fontSize: "16px", marginLeft: "0px", marginTop: '2px', textAlign: 'center' }}>
                <div className="home" style={{ color: '#000000A6' }}>
                  <AiOutlineHome style={{ marginRight: '3px', marginBottom: '3px', fontSize: '18px' }} />
                  {t('Home')}
                </div>
              </Nav.Link>

              <NavDropdown
                className="drop"
                title="Company"
                id="company-nav-dropdown"
                style={{ fontSize: "16px" }}

              >
                <NavDropdown.Item href="/ledership" className="navdrop" style={{ fontSize: "14px", color: "black", padding: '10px' }}>
                  <MdLeaderboard />  Leadership
                </NavDropdown.Item>
                <NavDropdown.Item href="/aboutUs" className="navdrop" style={{ fontSize: "14px", padding: '10px' }}>
                  <FiUser />   About Us
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="drop"
                title="Resources"
                id="resources-nav-dropdown"
                style={{ fontSize: "16px", padding: '0px' }}
              >
                {/* Blog Link */}
                <NavDropdown.Item
                  href="/BlogPage"
                  style={{ fontSize: "14px", padding: '10px' }}
                  className="navdrop"
                >
                  <FaMicroblog /> Blog
                </NavDropdown.Item>

                {/* FAQ Link */}
                <NavDropdown.Item
                  href="#Faq"
                  style={{ fontSize: "14px", padding: '10px' }}
                  className="navdrop"
                >
                  <FaQuestionCircle /> FAQ
                </NavDropdown.Item>

                {/* Videos Dropdown */}
                <NavDropdown
                  title="Videos"
                  id="video-dropdown"
                  style={{ fontSize: "14px", padding: '10px' }}
                  className="navdrop "
                  show={isVideoDropdownOpen} // Conditionally show the dropdown
                  onClick={handleVideoDropdownToggle} // Toggle the dropdown visibility on click
                >
                  <NavDropdown.Item
                    href="/VideoPage"
                    style={{ fontSize: "14px", padding: '10px' }}
                    className="navdrop"
                    onSelect={handleVideoItemSelect} // Close the dropdown when item is selected
                  >
                    <IoIosVideocam /> Corporate
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="/employerVideos"
                    style={{ fontSize: "14px", padding: '10px' }}
                    className="navdrop"
                    onSelect={handleVideoItemSelect} // Close the dropdown when item is selected
                  >
                    <IoIosVideocam /> Employer
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="/jobseekerVideos"
                    style={{ fontSize: "14px", padding: '10px' }}
                    className="navdrop"
                    onSelect={handleVideoItemSelect} // Close the dropdown when item is selected
                  >
                    <IoIosVideocam /> Jobseeker
                  </NavDropdown.Item>
                </NavDropdown>

              </NavDropdown>


              {!isLoggedIn && (
                <NavDropdown
                  className="drop bg"
                  title="Commercial Plans"
                  id="company-nav-dropdown"
                  style={{ fontSize: "16px", padding: '0px' }}

                >
                  <NavDropdown.Item href="/fulfillment-model" className="navdrop" style={{ fontSize: "14px", color: "black", padding: '10px' }}>
                    <MdLeaderboard />  Job Fulfillment Model
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/subscription-model" className="navdrop" style={{ fontSize: "14px", padding: '10px' }}>
                    <FiUser />   Subscription Model
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            </Nav>


          </Navbar.Collapse>


          <Navbar.Collapse className="justify-content-end sendbox">


            {role === "superadmin" && (
              <div style={styles.container} className="me-3">
                <span className="" style={{ color: '#2F2761' }}>{subscriptionStatus === "active" ? "Enabled" : "Disabled"}</span>
                <label style={styles.toggleSwitch}>
                  <input type="checkbox" checked={subscriptionStatus === "active"} onChange={handleToggle} disabled={isLoading} style={styles.input} />
                  <span style={styles.slider}>
                    <span style={styles.sliderBefore}></span>
                  </span>
                </label>
              </div>
            )}
            <Nav>
              {/* <Nav.Item className="custom-phone-wrapper"> */}

              <div className="number">
                <div
                  className="contact-card"

                >
                  {/* Display the Phone Number */}
                  <p
                    className="contact-number"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "black",
                      marginBottom: "3px",
                    }}
                  >
                    9606997985
                  </p>

                  {/* Links for WhatsApp and Phone Call */}
                  <div
                    className="contact-actions"
                    style={{
                      alignItems: "center",
                      gap: "0px",
                    }}
                  >

                    <a
                      href="https://wa.me/9606997985"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsappLink btn-link"
                      style={{
                        fontSize: "10px",
                        color: "green",
                        textDecoration: "none",
                        fontWeight: "500", // Added for better appearance
                      }}
                    >
                      <WhatsAppIcon

                        className="whatsappIcon"
                      />

                      WhatsApp/
                    </a>
                    <a
                      href="tel:+919606997985"
                      className="phoneLink btn-link"
                      style={{
                        fontSize: "10px",
                        color: "blue",
                        textDecoration: "none",
                        fontWeight: "500", // Added for better appearance
                      }}
                    >
                      <PhoneInTalkIcon
                        className="phoneCallIcon"

                      />
                      Call
                    </a>
                  </div>
                </div>

                <div
                  className="contact-card"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                  }}
                >
                  {/* Display the Phone Number */}
                  <p
                    className="contact-number"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "black",
                      marginBottom: "3px",
                    }}
                  >
                    9606997986
                  </p>

                  {/* Links for WhatsApp and Phone Call */}
                  <div
                    className="contact-actions"
                    style={{
                      alignItems: "center",
                      gap: "0px",
                    }}
                  >
                    <WhatsAppIcon

                      className="whatsappIcon"
                    />
                    <a
                      href="https://wa.me/9606997986"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsappLink btn-link"
                      style={{
                        fontSize: "10px",
                        color: "green",
                        textDecoration: "none",
                        fontWeight: "500",
                        maxWidth: "50px", // Added for better appearance
                      }}
                    >
                      WhatsApp/
                    </a>
                    <a
                      href="tel:+919606997986"
                      className="phoneLink btn-link"
                      style={{
                        fontSize: "10px",
                        color: "blue",
                        textDecoration: "none",
                        fontWeight: "500", // Added for better appearance
                      }}
                    >

                      <PhoneInTalkIcon
                        className="phoneCallIcon"

                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>


              {isLoggedIn ? (
                <>

                </>
              ) : (
                <>
                  <Nav.Item className="logins">
                    <div className="login-buttons" >
                      <button><a
                        href="javascript:;"
                        onClick={openJobseekerPopup}
                        className="small-btn me-3 mt-3 btns"
                      // style={{ width: '160px' }}
                      >
                        {t("Jobseeker Login")}
                      </a></button>
                      <JobSeekerRegistration
                        showJobseekerPopup={showJobseekerPopup}
                        hideJobseekerPopup={closeJobseekerPopup}
                      />
                      <button><a
                        href="javascript:;"
                        onClick={openEmployerLoginPopup}
                        className="small-btn fill me-3 mt-3 btns"
                      // style={{ width: '160px' }}
                      >
                        {t("Employer Login")}
                      </a></button>

                      <EmployerLoginOtp
                        showJobseekerPopup={showEmployerLoginPopup}
                        hideJobseekerPopup={closeEmployerLoginPopup}
                      />
                    </div>
                  </Nav.Item>
                </>
              )}

              {/* <div style={{display:'flex',background:'red'}}> */}

              <div className="" style={{ border: 'none', height: '60px', display: 'flex', background: '', justifyContent: 'space-between', alignItems: 'center', width: '90%' }}>
                {/* Dropdown Header */}
                <div
                  className="custom-dropdown-header mt-1 language"
                  onClick={() => setIsOpen(!isOpen)}
                  style={{}}
                >
                  <span className="dropdown-icon-globe">
                    <AiOutlineGlobal className="fs-1" />
                  </span>
                  <span className="dropdown-label ">
                    {languages.find((lang) => lang.value === Language)?.label || "Select Language"}
                  </span>
                  <span className={`dropdown-icon ${isOpen ? "open" : ""}`}>▼</span>
                </div>
                {isOpen && (
                  <div className="custom-dropdown-menu langu" style={{ width: isLoggedIn ? '200px' : '170px' }}>
                    {languages.map((lang) => (
                      <div
                        key={lang.value}
                        className={`custom-dropdown-item ${lang.value === Language ? "selected" : ""}`}
                        onClick={() => handleSelect(lang.value)}
                      >
                        {lang.label}
                      </div>

                    ))}
                  </div>
                )}

                {isLoggedIn ? (<div className="mobilelogout">
                  <a href="javascript:;"
                    onClick={handleLogoutConfirmation}
                    className="fs-6"
                    style={{ marginRight: '30px' }}
                  >
                    {t("Logout")}
                  </a></div>) : ''}
              </div>
              {/* </div> */}

              {!isLoggedIn ? (
                <></>
              ) : (
                <div className="name" style={{ position: 'relative', marginRight: "22px", width: '140px', marginTop: '5px' }}>

                  <div className='namedot'>
                    <h1 className=" fs-5" style={{ textTransform: 'uppercase' }}> {userName.slice(0, 2)} </h1>
                  </div>
                  <NavDropdown className="dropdowns namesfirst  fs-5 bg- "
                    title={(userName)}
                    id="company-nav-dropdown"
                    style={{ fontSize: "16px", marginLeft: "30px", marginTop: '10px' }}
                  >
                    <div className="navdrop-item">
                      <NavDropdown.Item href="/ledership" className="navdrop" style={{ fontSize: "14px", color: "black", padding: '10px' }}>
                        <MdLeaderboard /> <div className="drop-namedot" style={{ display: 'flex' }}> <div className='namedot'>
                          <h1 className="jobnames fs-6  text-primary" style={{ textTransform: 'uppercase' }}> {userName.slice(0, 2)} </h1>
                        </div>
                          <h5 style={{ marginLeft: '20px', marginTop: '-10px' }} className="fs-5"> {capitalize(userName)}</h5>
                        </div>
                      </NavDropdown.Item>

                      {role === "employer" || role === "employee" ? (
                        <>
                          <NavDropdown.Item href="/employerProfileView" className="navdrop" style={{ fontSize: "14px", padding: '10px', marginTop: '10px' }}>
                            <IoPersonCircleOutline className="fs-5" />   Profile
                          </NavDropdown.Item>
                        </>
                      ) : null}
                      {role === "jobseeker" ? (
                        <>
                          <NavDropdown.Item href="/jobseekerprofile" className="navdrop" style={{ fontSize: "14px", padding: '10px', marginTop: '10px' }}>
                            <IoPersonCircleOutline className="fs-5" />   Profile
                          </NavDropdown.Item>
                        </>
                      ) : null}


                      <NavDropdown.Item className="navdrop" style={{ fontSize: "14px", padding: '10px' }}>
                        <IoLogOutOutline className="fs-5" />

                        <a
                          href="javascript:;"
                          onClick={handleLogoutConfirmation}
                          className="fs-6"
                          style={{ marginRight: '-10px' }}
                        >
                          {t("Logout")}
                        </a>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                </div>
              )}


              {!isLoggedIn ? (
                <></>
              ) : (

                <div
                  className="dropdown"
                  style={{ position: "relative", marginTop: "6px" }}
                  ref={dropdownRef}
                >
                  <button
                    className="btn btn-link p-0 dropdown-toggle-btn"
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                    onClick={handleDropdownToggle}
                  >

                  </button>
                  <ul
                    className={`dropdown-menu dropdown-menu-end ${isDropdownOpen ? "show" : ""
                      }`}
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      position: "absolute",
                      top: "50px",
                      right: "0",
                      zIndex: "1000",
                    }}
                  >
                    {role === "employer" || role === "employee" ? (
                      <>
                        <li>
                          <a
                            href="/employerProfileView"
                            className="dropdown-item"
                          >
                            Profile
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard" className="dropdown-item">
                            Dashboard
                          </a>
                        </li>
                      </>
                    ) : null}
                    {role === "jobseeker" ? (
                      <>
                        <li>
                          <a href="/jobseekerprofile" className="dropdown-item">
                            Profile
                          </a>
                        </li>
                      </>
                    ) : null}

                    <li
                    >
                      <a
                        href="javascript:;"
                        onClick={handleLogoutConfirmation}
                        className="dropdown-item"
                      >
                        {t("Logout")}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={showLogoutConfirmation}
        onHide={() => setShowLogoutConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirm Logout")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to log out?")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowLogoutConfirmation(false)}
          >
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            {t("Logout")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployerDashboardHeader;








