import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Form,
  Spinner,
  Button,
  Image,
  Modal,
  Card,
} from "react-bootstrap";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import { toast } from "react-hot-toast";
import profile from "../assets/profieimg.png";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { City, State } from "country-state-city";


const EmployerProfileView = () => {
  const navigate = useNavigate();
  const Auth = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");

  const location = useLocation();
  const applicant = location.state;
  console.log("applicant", applicant)


  useEffect(() => {
    if (!Auth) {
      navigate("/");
    }
  }, [Auth, navigate]);

  const [, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    industryCategory: "",
    industryCategoryId: "",
    industrySubCategory: "",
    industrySubCategoryId: "",
    branch: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: [],
    qualification: [],
    responsibilities: [],
    dob: "",
    resume: "",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;


  //extra adding in state and city dropdown

  const [stateOptions, setStateOptions] = useState([]); // 🔹 State List
  const [cityOptions, setCityOptions] = useState([]);   // 🔹 City List


  const fetchStates = (inputValue = "", countryCode = "IN") => {
    const states = State.getStatesOfCountry(countryCode);
    if (states) {
      const filteredStates = states
        .map((state) => state.name)
        .filter((state) => state.toLowerCase().includes(inputValue.toLowerCase()));

      setStateOptions(filteredStates);
    }
  };

  // Function to fetch cities dynamically
  const fetchCities = (stateName = "", countryCode = "IN") => {
    const stateObj = State.getStatesOfCountry(countryCode).find(
      (state) => state.name === stateName
    );
    if (stateObj) {
      const cities = City.getCitiesOfState(countryCode, stateObj.isoCode);
      if (cities) {
        const cityNames = cities.map((city) => city.name);
        setCityOptions(cityNames);
        // setNativeLocationOptions(cityNames); // Native locations same as city
      }
    }
  }

  useEffect(() => {
    fetchCities();
    // fetchAllCities();
    fetchStates();
  }, []);





  const fetchProfile = async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("userId");

      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data && response.data.data) {
        setUserDetails(response.data.data);
        // setFormData(response.data.data);

        console.log("categ", response.data.data);
      } else {
        setError(response.data.message || "Failed to fetch profile.");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
      setError("An error occurred while fetching the profile.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {


    fetchProfile();
  }, [token, apiUrl]);


  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/category`, {
          headers: {
            Authorization: ` ${token}`,
          },
        });
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchAllSubCategories();
    fetchCategories();
  }, [apiUrl]);

  useEffect(() => {
    console.log("applicant-initial", applicant);

    if (applicant) {
      // win.clear();
      const formattedSubmissionDate = applicant?.submissionDate
        ? new Date(applicant?.submissionDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
        : '';
      setFormData({
        ...applicant,
        jobLocation: applicant?.location,
        submissionDate: formattedSubmissionDate,
        expiryDate: applicant?.expiryDate
          ? new Date(applicant?.expiryDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
          : '',
        qualifications: applicant?.qualificationRequirements,
        responsibilities: applicant?.responsibilitiesAndDuties,
        city: applicant?.city,
        // jobId: undefined,
      });
      console.log("applicant", applicant);

      console.log("form", formData);


    }
    else {    // Load form data from localStorage when the component mounts

      setFormData("");

    };
  }, []); // Empty dependency array, runs once when the component mounts


  const fetchAllSubCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sub-category`, {
        headers: { Authorization: ` ${token}` },
      });
      setSubcategories(response.data.data);
      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/sub-category/category/${categoryId}`,
        {
          headers: { Authorization: ` ${token}` },
        }
      );
      // setSubcategories(response.data.data)
      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleCategoryChange = async (e) => {
    const selectedCategoryId = e.target.value;

    // Find the selected category object based on the category_id
    const selectedCategory = categories.find(
      (category) => category.category_id === selectedCategoryId
    );

    // Update formData with selected category and reset subcategory
    setFormData((prev) => ({
      ...prev,
      // Set the full category object (industryCategory) with category_id and categoryName
      industryCategory: selectedCategory || {
        category_id: null,
        categoryName: "",
      },
      // Set just the category_id (industryCategoryId)
      industryCategoryId: selectedCategoryId
        ? selectedCategory.category_id
        : "",
      // Reset subcategory to an empty object (industrySubCategory) with subcategory_id and subcategoryName
      industrySubCategory: { subcategory_id: null, subcategoryName: "" },
      // Reset subcategory ID (industrySubCategoryId) to null
      industrySubCategoryId: null,
    }));

    // Fetch subcategories if a category is selected
    if (selectedCategoryId) {
      try {
        const fetchedSubcategories = await fetchSubcategories(
          selectedCategoryId
        );
        setSubcategories(fetchedSubcategories);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setSubcategories([]);
      }
    } else {
      setSubcategories([]); // Reset subcategories if no category is selected
    }
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategoryId = e.target.value;
    const selectedValue = e.target.value; // Declare selectedValue

    // Find the selected subcategory object based on subcategory_id
    const selectedSubcategory = subcategories.find(
      (subcategory) => subcategory.subcategory_id === selectedSubcategoryId
    );

    // Update the form data with the full subcategory object (subcategory_id and subcategoryName)
    setFormData((prev) => ({
      ...prev,
      industrySubCategory:
        selectedSubcategory || //   subcategory_id: selectedSubcategory.subcategory_id, // ? {

        { subcategory_id: null, subcategoryName: "" }, // If no subcategory selected, reset to empty object

      industrySubCategoryId: selectedSubcategoryId
        ? selectedSubcategory.subcategory_id
        : "",
    }));

    setFormData((prev) => ({
      ...prev,
      industrySubCategoryId: selectedSubcategory.subcategory_id ? selectedSubcategory.subcategory_id

        : { subcategory_id: null }, // If no subcategory selected, reset to empty object
    }));

    setFormData((prev) => ({
      ...prev,
      industrySubCategory: selectedSubcategory
        ? {
          subcategory_id: selectedSubcategory.subcategory_id,
          subcategoryName: selectedSubcategory.subcategoryName,
        }
        : { subcategory_id: null, subcategoryName: "" }, // If no subcategory selected, reset to empty object
      industrySubCategoryId: selectedSubcategory ? selectedSubcategory.subcategory_id : null, // Store only the subcategory ID
    }));
    localStorage.setItem('industrySubCategory', selectedValue);
    // Optionally store in localStorage (if needed)
    localStorage.setItem(
      "industrySubCategory",
      selectedSubcategory ? JSON.stringify(selectedSubcategory) : JSON.stringify({ subcategory_id: null, subcategoryName: "" })
    );
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPEG, PNG, JPG, WEBP) are allowed.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const win = window.localStorage;
  useEffect(() => {
    const cat_id = win.getItem("category_id");

    fetchSubcategories(cat_id);
  }, []);

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleImageRemove = async () => {
    setFormData((prevData) => ({ ...prevData, image: null })); // Update state

    try {
      const payload = {
        ...formData,
        image: null, // Explicitly set image to null
        industryCategory: formData.industryCategory?.categoryName,
        industryCategoryId: formData.industryCategory?.category_id,
        industrySubCategory: formData.industrySubCategory?.subcategoryName,
        industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      };

      console.log("Final Payload After Image Removal:", payload); // Debugging

      const response = await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: `${token}` },
      });

      toast.success("Profile image removed successfully.");
      fetchProfile(); // Refresh profile after removal
    } catch (error) {
      console.error("Error removing image:", error);
      toast.error("Failed to remove image.");
    }
  };


  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const mandatoryFields = [
    "userName",
    "email",
    "phoneNumber",
    "firstName",
    "lastName",
    "pincode",
    "city",
    "companyName"
  ];

  const fieldsToRender = [
    { label: "User Name", key: "userName", mandatory: true },
    { label: "First Name", key: "firstName", mandatory: true },
    { label: "Last Name", key: "lastName", mandatory: true },
    { label: "Mobile Number", key: "phoneNumber", mandatory: true },
    { label: "Email", key: "email", mandatory: true },
    // { label: "Role", key: "role" },
    { label: "Designation", key: "designation" },
    { label: "Department", key: "department" },
    { label: "Branch", key: "branch" },
    { label: "Company Name", key: "companyName", mandatory: true },
    { label: "CIN Number", key: "cinNumber" },
    { label: "GST In", key: "gstIn" },
    { label: "PAN Number", key: "panNumber" },

    { label: "Company Address", key: "communicationAdress" },
    { label: "State", key: "state" },
    { label: "City", key: "city", mandatory: true },
    { label: "Pincode", key: "pincode", mandatory: true },

  ];


  const handleSaveClick = async () => {
    const token = sessionStorage.getItem("token");

    if (!formData.industryCategory || !formData.industrySubCategory) {
      toast.error("Please select both an industry category and subcategory.");
      return;
    }

    // Prepare the payload
    const payload = {
      ...formData,
      industryCategory: formData.industryCategory?.categoryName,
      industryCategoryId: formData.industryCategory?.category_id,
      industrySubCategory: formData.industrySubCategory?.subcategoryName,
      industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      createdBy: userId, // Pass userId as createdBy
    };

    console.log("Final Payload:", payload);

    try {
      const response = await axios.post(`${apiUrl}/callcenter/createemployer`, payload, {
        headers: { Authorization: `${token}` },
      });

      toast.success("Employer created successfully!");
      navigate('applyJobAllJobSeeker')

      // ✅ Clear localStorage after saving
      localStorage.removeItem("industryCategory");
      localStorage.removeItem("industrySubCategory");
      localStorage.removeItem("industryCategoryId");
      localStorage.removeItem("industrySubCategoryId");

      console.log("API Response:", response.data);

      setShowSuccessModal(true); // Show success modal
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        toast.error(`Failed to create employer. ${error.response.data.message || error.response.statusText}`);
      } else {
        console.error("Error:", error);
        toast.error("Failed to create employer.");
      }
    }
  };

  const handleUpdateClick = async () => {
    const token = sessionStorage.getItem("token");

    if (!applicant || !applicant.userId) {
      toast.error("Employer ID is missing. Cannot update.");
      return;
    }

    // Prepare the payload
    const payload = {
      ...formData,
      industryCategory: formData.industryCategory?.categoryName,
      industryCategoryId: formData.industryCategory?.category_id,
      industrySubCategory: formData.industrySubCategory?.subcategoryName,
      industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
    };

    console.log("Final Update Payload:", payload);

    try {
      const response = await axios.patch(`${apiUrl}/callcenter/editemployer/${applicant.userId}`, payload, {
        headers: { Authorization: `${token}` },
      });

      toast.success("Employer updated successfully!");

      console.log("API Response:", response.data);
      navigate('/employerTable')
      setShowSuccessModal(true); // Show success modal
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        toast.error(`Failed to update employer. ${error.response.data.message || error.response.statusText}`);
      } else {
        console.error("Error:", error);
        toast.error("Failed to update employer.");
      }
    }
  };


  console.log("formData before sending:", formData);

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <Col className="">
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-content">
            <Card className="rounded-2 p-4">
              <Card.Body>
                <h3
                  className="text-center"
                  style={{
                    color: "white",
                    color: "#170F49",
                    marginRight: "80px",

                  }}
                >
                  Profile Details
                </h3>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <div className="text-center mb-4" style={{ position: 'relative' }}>
                  <div
                    className="profile-image-wrapper mb-3"
                    onClick={handleImageClick}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "80px",
                      marginBottom: "10px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={formData.image || profile}
                      alt="Profile Picture"
                      roundedCircle
                      className="shadow-lg"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        // background:'#000',
                        border: "4px solid #ECF6FF",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />

                  </div>
                  {formData.image && (
                    <h4
                      onClick={handleImageRemove}
                      style={{
                        position: "absolute",
                        top: "120px",
                        left: "53%",
                        color: "red",
                        cursor: "pointer",
                        transform: "translateX(-50%)",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <MdDelete />
                    </h4>
                  )}
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>


                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header
                        style={{
                          backgroundColor: "rgb(47 39 97)",
                          color: "white",
                          padding: "15px",
                        }}
                      >
                        Employer Details
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender
                            .slice(0, 9)
                            .map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label}{" "}
                                    {mandatory && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </Form.Label>

                                  {key === "department" ? (
                                    <Form.Select
                                      name={key}
                                      value={formData[key] || ""}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select Department</option>
                                      <option value="Administration">Administration</option>
                                      <option value="HR">HR</option>
                                      <option value="Recruitment">Recruitment</option>
                                      <option value="Production">Production</option>
                                      <option value="Finance">Finance</option>
                                      <option value="R&D">R&D</option>
                                      <option value="Sales">Sales</option>
                                      <option value="Marketting">Marketting</option>
                                      <option value="Facilities">Facilities</option>
                                      <option value="Security">Call Back</option>
                                      <option value="Other">Other</option>
                                    </Form.Select>
                                  ) : key === "branch" ? (
                                    <Form.Select
                                      name={key}
                                      value={formData[key] || ""}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select Branch</option>
                                      <option value="Head Office">Head Office</option>
                                      <option value="Branch Office">Branch Office</option>
                                      <option value="Factory">Factory</option>
                                      <option value="WareHouse">WareHouse</option>
                                      <option value="Satelite Office">Satelite Office</option>
                                      <option value="Other">Other</option>
                                    </Form.Select>
                                  ) : (
                                    <Form.Control
                                      type={
                                        key === "dob"
                                          ? "date"
                                          : key === "phoneNumber"
                                            ? "tel"
                                            : key === "email"
                                              ? "email"
                                              : "text"
                                      }
                                      name={key}
                                      value={formData[key]}
                                      onChange={(e) => {
                                        let value = e.target.value;

                                        // Phone Number Validation
                                        if (key === "phoneNumber") {
                                          value = value.replace(/\D/g, ""); // Remove non-numeric characters
                                          if (value.length > 10) return; // Prevent entering more than 10 digits
                                        }

                                        handleChange({ target: { name: key, value } }); // Update state
                                      }}
                                      required={mandatory}
                                      className="rounded-3"
                                      maxLength={key === "phoneNumber" ? 10 : undefined}
                                      pattern={
                                        key === "phoneNumber"
                                          ? "[0-9]{10}"
                                          : key === "email"
                                            ? "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
                                            : undefined
                                      }
                                      // inputMode={key === "phoneNumber" ? "numeric" : undefined}
                                      inputMode={key === "phoneNumber" && formData.phoneNumber ? "numeric" : "text"}
                                    />)}

                                  {/* Phone Number Error Message */}
                                  {key === "phoneNumber" &&
                                    formData?.phoneNumber?.length > 0 &&
                                    formData?.phoneNumber?.length < 10 && (
                                      <p style={{ color: "red", fontSize: "12px" }}>
                                        Phone number must be exactly 10 digits
                                      </p>
                                    )}

                                  {/* Email Validation Error */}
                                  {key === "email" &&
                                    formData.email &&
                                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                      formData.email
                                    ) && (
                                      <p style={{ color: "red", fontSize: "12px" }}>
                                        Enter a valid email address
                                      </p>
                                    )}
                                </Form.Group>
                              </Col>
                            ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header
                        style={{
                          backgroundColor: "rgb(47 39 97)",
                          color: "white",
                          padding: "15px",
                        }}
                      >
                        Company Details
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender
                            .slice(10)
                            .map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label}{" "}
                                    {mandatory && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </Form.Label>

                                  {key === "state" || key === "city" ? (
                                    <Autocomplete
                                      options={
                                        key === "state"
                                          ? stateOptions
                                          : key === "city"
                                            ? cityOptions
                                            : []
                                      }
                                      getOptionLabel={(option) => option}
                                      value={formData[key] || ""}
                                      onInputChange={(event, newInputValue) => {
                                        if (key === "state") {
                                          fetchStates(newInputValue); // State suggestions
                                        } else if (key === "city") {
                                          fetchCities(formData.state); // City suggestions based on state
                                        }
                                      }}
                                      onChange={(event, newValue) => {
                                        handleChange({
                                          target: { name: key, value: newValue },
                                        });
                                        if (key === "state") {
                                          fetchCities(newValue); // Fetch cities for selected state
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                          <input
                                            style={{ width: "100%" }}
                                            type="text"
                                            {...params.inputProps}
                                            className="form-control"
                                            placeholder={`Type ${key === "state" ? "State" : "City"
                                              } name...`}
                                          />
                                        </div>
                                      )}
                                      ListboxProps={{
                                        sx: {
                                          bgcolor: "white",
                                          borderRadius: "8px",
                                          "& li": {
                                            padding: "10px",
                                            width: "100%",
                                            "&:hover": { backgroundColor: "#f0f0f0" },
                                          },
                                        },
                                      }}
                                    />
                                  ) : label === "Company Address" ? (
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      name={key}
                                      value={formData[key]}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className="rounded-3"
                                      maxLength={350} //  this line to limit characters to 350
                                    />
                                  ) : (
                                    <Form.Control
                                      type={
                                        label === "Pincode" ? "number" : "text"
                                      }
                                      name={key}
                                      value={formData[key]}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className="rounded-3"
                                    />
                                  )}
                                </Form.Group>
                              </Col>
                            ))}

                          <Col>
                            <Form.Group>
                              <Form.Label>Industry Category</Form.Label>
                              <Form.Control
                                as="select"
                                name="industryCategory"
                                value={formData.industryCategoryId || ""} // This will show the saved value
                                onChange={handleCategoryChange}
                              >
                                <option value="">Select an Industry</option>
                                {categories.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category_id}
                                  >
                                    {category.categoryName}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label>Industry Sub Category</Form.Label>
                              <Form.Control
                                as="select"
                                name="industrySubCategory"
                                value={formData.industrySubCategoryId || ""}
                                onChange={handleSubcategoryChange}
                              >
                                <option value="">
                                  Select a Industry Subcategory
                                </option>
                                {subcategories.length > 0 ? (
                                  subcategories.map((subcategory) => (
                                    <option
                                      key={subcategory.subcategory_id}
                                      value={subcategory.subcategory_id}
                                    >
                                      {subcategory.subcategoryName}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">
                                    No subcategories available
                                  </option>
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button
                    onClick={() =>
                      applicant ? handleUpdateClick() : handleSaveClick()
                    }
                    className="default-btn px-5 py-2 "
                    disabled={mandatoryFields.some((field) => !formData[field])}
                    style={{ cursor: "pointer" }}
                  >
                    {applicant !== null && applicant !== undefined ? "Update" : "Save"}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {!applicant ? (<Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Created</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully Created!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>) : ''}


    </div>
  );
};

export default EmployerProfileView;
