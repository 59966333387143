// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 480px) {

    .jobseekerlogin{
        /* background: #000 !important; */
        width:300px !important;
        height:280px !important;
        padding: 15px !important;
    }

    .employerlogin{
        /* background: #000 !important; */
        width:300px !important;
        height:280px !important;
        padding: 15px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/jobseekerlogin.css"],"names":[],"mappings":";AACA;;IAEI;QACI,iCAAiC;QACjC,sBAAsB;QACtB,uBAAuB;QACvB,wBAAwB;IAC5B;;IAEA;QACI,iCAAiC;QACjC,sBAAsB;QACtB,uBAAuB;QACvB,wBAAwB;IAC5B;AACJ","sourcesContent":["\r\n@media (max-width: 480px) {\r\n\r\n    .jobseekerlogin{\r\n        /* background: #000 !important; */\r\n        width:300px !important;\r\n        height:280px !important;\r\n        padding: 15px !important;\r\n    }\r\n\r\n    .employerlogin{\r\n        /* background: #000 !important; */\r\n        width:300px !important;\r\n        height:280px !important;\r\n        padding: 15px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
