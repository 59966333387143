import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { FaBriefcase, FaClipboardList, FaUsers } from 'react-icons/fa';
import axios from 'axios';
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import EmployerDashboardSideBar from '../../components/EmployerDashboardSideBar';
import { Button, Typography, CircularProgress } from "@mui/material";

const Dashboard = () => {
  const [jobData, setJobData] = useState([]);
  const [, setError] = useState(null);
  const [, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Separate file states for job seekers & employers
  const [jobSeekerFile, setJobSeekerFile] = useState(null);
  const [employerFile, setEmployerFile] = useState(null);

  const token = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000"; // Ensure fallback


  // File handlers
  const handleJobSeekerFileChange = (event) => {
    setJobSeekerFile(event.target.files[0]);
  };

  const handleEmployerFileChange = (event) => {
    setEmployerFile(event.target.files[0]);
  };

  const [loadingJobseeker, setLoadingJobseeker] = useState(false);
  const [loadingEmployer, setLoadingEmployer] = useState(false);

  const handleUpload = async () => {
    if (!jobSeekerFile) {
      setMessage("Please select an Excel file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", jobSeekerFile);

    try {
      setLoadingJobseeker(true);
      setMessage("");

      const response = await axios.post(`${apiUrl}/shared/db-migrate`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMessage(response.data.message || "Migration successful!");
    } catch (error) {
      setMessage(error.response?.data?.message || "Migration failed.");
    } finally {
      setLoadingJobseeker(false);
    }
  };

  const handleEmployerUpload = async () => {
    if (!employerFile) {
      setMessage("Please select an Excel file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", employerFile);

    try {
      setLoadingEmployer(true);
      setMessage("");

      const response = await axios.post(`${apiUrl}/shared/db-migrate/employer`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setMessage(response.data.message || "Migration successful!");
    } catch (error) {
      setMessage(error.response?.data?.message || "Migration failed.");
    } finally {
      setLoadingEmployer(false);
    }
  };

  // Fetch job applicants
  useEffect(() => {
    const fetchApplicants = async () => {
      if (!userId || !apiUrl) return; // Prevent unnecessary API calls

      try {
        const response = await axios.get(`${apiUrl}/job/admin/${userId}`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        });

        setJobData(response.data.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [userId, apiUrl, token]);

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />

      <Container fluid className="content">
        <Row className='g-4'>
          <Col>
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-content">
            <Row className='g-4 pt-2'>
              <Col md={4} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga">Total Job Seekers</span>
                        <h3 className="fw-semibold lh-1 mb-0">{jobData.totalJobseekers}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-violet avatar-rounded">
                          <FaClipboardList size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga">Total Active Jobs</span>
                        <h3 className="fw-semibold lh-1 mb-0">{jobData.activeJobs}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-ora avatar-rounded">
                          <FaBriefcase size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga">Total Employers</span>
                        <h3 className="fw-semibold lh-1 mb-0">{jobData.totalEmployers}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-green avatar-rounded">
                          <FaUsers size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga">Total Enrollment</span>
                        <h3 className="fw-semibold lh-1 mb-0">{jobData.enrollment}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-green avatar-rounded">
                          <FaUsers size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}

            </Row>

            {(userRole === "admin" || userRole === "superadmin") && (
              <>
                {/* Job Seeker File Upload */}
                <h3 className='ms-2 mt-3'>Upload Jobseekers Excel File</h3>
                <Col style={{ display: 'flex', alignItems: 'center', width: '85%' }}>
                  <div style={{ textAlign: "center", padding: 20, width: '75%' }}>
                    <input type="file" accept=".xls,.xlsx" onChange={handleJobSeekerFileChange} />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleUpload}
                      disabled={loadingJobseeker}
                      style={{ background: '#2F2761' }}
                    >
                      {loadingJobseeker ? <CircularProgress size={24} /> : "Upload & Migrate"}
                    </Button>
                  </div>
                </Col>

                {/* Employer File Upload */}
                <h3 className='ms-2 mt-3'>Upload Employers Excel File</h3>
                <Col style={{ display: 'flex', alignItems: 'center', width: '85%' }}>
                  <div style={{ textAlign: "center", padding: 20, width: '75%' }}>
                    <input type="file" accept=".xls,.xlsx" onChange={handleEmployerFileChange} />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleEmployerUpload}
                      disabled={loadingEmployer}
                      style={{ background: '#2F2761' }}
                    >
                      {loadingEmployer ? <CircularProgress size={24} /> : "Upload & Migrate"}
                    </Button>
                  </div>
                </Col>
              </>
            )}


            {message && <Typography style={{ marginTop: 10, color: "green", marginLeft: '30px' }}>{message}</Typography>}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
