import React, { useState, useEffect } from "react";
import '../../index.css';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Spinner, } from 'react-bootstrap';
import axios from "axios";
import SubscriptionPacks from "./Subscription/EmployerSub";
// import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import toast from 'react-hot-toast';
import CloseIcon from "@mui/icons-material/Close";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { FaStreetView } from "react-icons/fa6";
import { CiImport } from "react-icons/ci";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, CircularProgress } from "@mui/material";
import EmployerLoginOtp from "../../components/EmployerLoginotp";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
// import CloseIcon from "@mui/icons-material/Close";
import { FaFileDownload } from "react-icons/fa";
import { FcUpload } from "react-icons/fc";

const Module2 = () => {
  const location = useLocation();
  const { Roles } = location.state || {};
  console.log(Roles);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [showSubscription, setShowSubscription] = useState(false); // New state
  //   const [open, setOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [credits, setCredits] = useState()
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null); // Default to first plan
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);


  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const response = await axios.get(`${apiUrl}/subscription`);
        console.log("Plan:", response.data);

        // Ensure correct data structure
        if (response.data.data && Array.isArray(response.data.data)) {
          setSubscriptionPlan(response.data.data.slice(0, 4));  // ✅ Update state
          setSelectedPlan(response.data.data[0] || null); // ✅ Set first plan if available
          // setSelectedPlan(response.data.data.slice(0, 2)); // ✅ Set first three plans if available
        } else {
          console.error("Invalid plan data format");
          setSubscriptionPlan([]);  // ✅ Ensure array
          setSelectedPlan(null);  // ✅ No selection if no data
        }
      } catch (error) {
        console.error("Error fetching plan:", error);
      }
    };

    fetchPlan();
  }, [apiUrl]);
  console.log("Subscription Plan:", subscriptionPlan);

  const [open, setOpen] = useState(false); // Modal visibility state
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
  });

  // Handle input changes
  //   const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  // Handle Subscribe button click


  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSubscribe = (plan) => {
    if (!isLoggedIn) {
      openEmployerLoginPopup(true);
      return;
    }

    setSelectedPlan(plan);
    setLoading(true); // Start loading spinner

    setTimeout(() => {
      setLoading(false); // Stop loading after 1 second

      if (formData.firstName && formData.email && formData.phoneNumber && formData.companyName) {
        // Proceed to payment after loading
        handlePayment(plan.price, userId, plan.planId);
      } else {
        setOpen(true); // Open popup if details are missing
      }
    }, 1000); // 1-second delay
  };


  const [errors, setErrors] = useState({
    phoneNumber: "",
  });


  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });

  //     // Validate phone number (must start with +91 and be followed by 10 digits)
  //     if (name === "phoneNumber") {
  //       if (!/^(\+91)[6-9]\d{9}$/.test(value)) {
  //         setErrors((prev) => ({
  //           ...prev,
  //           phoneNumber: "Phone number must start with +91 and have 10 digits",
  //         }));
  //       } else {
  //         setErrors((prev) => ({ ...prev, phoneNumber: "" }));
  //       }
  //     }
  //   };


  // const handleSubmit = async () => {

  //   try {
  //     if (!formData.firstName || !formData.email || !formData.phoneNumber || !formData.companyName) {
  //       toast.error("Please fill all fields correctly.");
  //       return;
  //     }

  //     // const token = sessionStorage.getItem("accessToken");
  //     // if (!token) {
  //     //   toast.error("User not authenticated.");
  //     //   return;
  //     // }

  //     setLoading(true);

  //     // Create User/Employer
  //     const userResponse = await axios.post(`${apiUrl}/user/employer/subscription`, formData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         // Authorization: `${token}`,
  //       },
  //     });
  //     // sessionStorage.setItem('accessToken', userResponse.data.data.accessToken);

  //     console.log("User Response:", userResponse);
  //     if (userResponse.status === 201) {
  //       const newUser = userResponse.data;
  //       toast.success("User created successfully!");

  //       if (!selectedPlan) {
  //         toast.error("Please select a plan before proceeding.");
  //         return;
  //       }

  //       // Open Razorpay payment gateway with selected plan
  //       handlePayment(selectedPlan.price, newUser.userId, selectedPlan.planId);
  //     } else {
  //       toast.error("Failed to create user/employer.");
  //     }
  //   } catch (error) {
  //     console.error("Error in creating user/employer:", error);
  //     toast.error(error.response?.data?.message || "An error occurred.");
  //   } finally {
  //     setOpen(false);
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      if (!formData.firstName || !formData.email || !formData.phoneNumber || !formData.companyName) {
        toast.error("Please fill all fields correctly.");
        return;
      }

      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        toast.error("User not authenticated.");
        return;
      }

      setLoading(true);

      // Update User/Employer details using PATCH with Authorization Header
      const userResponse = await axios.patch(`${apiUrl}/user/${userId}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      console.log("User Response:", userResponse);
      if (userResponse.status === 200) {
        const updatedUser = userResponse.data;
        toast.success("User details updated successfully!");

        if (!selectedPlan) {
          toast.error("Please select a plan before proceeding.");
          return;
        }

        // Proceed to payment with updated user details
        setTimeout(() => {
          setLoading(false);
          handlePayment(selectedPlan.price, updatedUser.userId, selectedPlan.planId);
        }, 1000); // Adding a 1-second delay for better user experience
      } else {
        toast.error("Failed to update user/employer.");
      }
    } catch (error) {
      console.error("Error updating user/employer:", error);
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };



  const handlePayment = async (amount, userId, planId) => {
    try {
      // const token = sessionStorage.getItem("accessToken");
      // Create Razorpay order using Axios

      const response = await axios.post(
        `${apiUrl}/razorpay/create-order`,
        { amount, currency: "INR" }, // Ensure you use INR or the required currency
        {
          headers: {
            "Content-Type": "application/json",
            // "Authorization": `${token}`, // Uncomment if needed
          },
        }
      );

      const order = response.data;

      if (!order.orderId) {
        throw new Error("Failed to create Razorpay order.");
      }

      // Initialize Razorpay payment widget
      const options = {
        key: 'rzp_live_q9CXwHp4B3rnrW',  // live id
        // key:'rzp_test_OEGbB0fk6OKxB5',  // test id
        amount: order.amount,
        currency: order.currency,
        name: "Your Company Name",
        description: `Subscription Plan: ${selectedPlan.planName}`,
        order_id: order.id,
        image: "https://yourwebsite.com/logo.png", // Replace with your logo
        handler: async (response) => {
          console.log("Payment Successful:", response);
          toast.success(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);

          // Call function to add user to subscription after payment
          await AddUserSubscription(response.razorpay_payment_id, userId, planId);
        },
        prefill: {
          name: formData?.firstName,
          email: formData.email,
          contact: formData.phoneNumber,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Payment error:", error);
      toast.error(error.response?.data?.message || "Payment failed. Please try again.");
    }
  };


  const AddUserSubscription = async (paymentId, userId, planId) => {
    try {
      const paymentData = {
        paymentId,
        userId,
        planId,
      };

      const response = await axios.post(
        `${apiUrl}/subscription/addusertosubscription`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Subscription added successfully!");
      } else {
        toast.error(response.data?.message || "Failed to add subscription!");
      }
    } catch (error) {
      console.error("Error in subscription:", error);
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };

  const openEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(true);
  };

  const closeEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(false);
  };

  // Fetch user data when dialog opens
  useEffect(() => {
    if (open && userId) {
      fetchUserDetails(userId);
    }
  }, [open, userId]);

  // const fetchUserDetails = async (userId) => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/user/${userId}`);
  //     if (response.status === 200) {
  //       const userData = response.data;
  //       setFormData({
  //         firstName: userData.firstName || "",
  //         companyName: userData.companyName || "",
  //         email: userData.email || "",
  //         phoneNumber: userData.phoneNumber || "",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user details:", error);
  //     toast.error("Failed to fetch user details.");
  //   }
  // };

  useEffect(() => {
    fetchUserDetails(userId);
  }, []);

  const fetchUserDetails = async (userId) => {
    try {
      const token = sessionStorage.getItem("accessToken"); // Retrieve the token

      if (!token) {
        // toast.error("Unauthorized: Please log in again.");
        return;
      }

      const response = await axios.get(`${apiUrl}/user/${userId}`, {
        headers: {
          "Authorization": `${token}`, // Add the token to the request
          "Content-Type": "application/json",
        },
      });

      if (response.status) {
        const userData = response.data.data;
        setFormData({
          firstName: userData.firstName || "",
          companyName: userData.companyName || "",
          email: userData.email || "",
          phoneNumber: userData.phoneNumber || "",
        });
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error(error.response?.data?.message || "Failed to fetch user details.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="dashboard">
      <Row>
        <EmployerDashboardHeader />
        {isLoggedIn && (<Col>
          <EmployerDashboardSideBar />
        </Col>)}
        <EmployerLoginOtp
          showJobseekerPopup={showEmployerLoginPopup}
          hideJobseekerPopup={closeEmployerLoginPopup}
        />
        <Col md={isLoggedIn ? 10 : 12}>
          <div style={{ display: "flex", flexDirection: "column", flexWrap: 'wrap', alignItems: "center",paddingBottom:'20px', background: '#F5F6FA',}}>
          <h3 className="TitleHead">
              {/* <span style={{ fontWeight: "bold" }}> </span>
              <span style={{ backgroundColor: "", padding: "3px", borderRadius: "3px" }}> */}
                Subscription Model ( To be Launched Soon )
              {/* </span> */}
            </h3>
            <div className="subscription-box" style={{background:'white'}}>
              <div style={{padding:'1rem'}}>
              <p style={{ fontSize: "16px", lineHeight: "1.6", fontWeight:'bold',marginTop:'2rem' }}>
                Subscription model leverages on <a href="#">Workaroo</a> Job Marketplace. Employers would get access to
                <a href="#"> Workaroo </a>
                resource database in terms of contacts and profiles and can start exploring them according to their
                <a href="#" > Job Descriptions(JD)</a> and needs.
              </p>
 
              <p  style={{ fontSize: "16px", lineHeight: "1.6",fontWeight:'bold' }}>
                This is suitable for the clients who have large or independent Recruitment groups and who have systems
                and bandwidth to go <span >through</span> the entire hiring process from sourcing to hiring.
              </p>
 
              <p  style={{ fontSize: "16px", lineHeight: "1.6",fontWeight:'bold' }}>
                There are 4 subscription models to cater to a wide range of needs of the clients, which vary in
                terms of the number of JDs they can post, the number of Jobseeker profiles, and the validity period.
              </p>
 
              <h5 style={{ textDecoration: "underline", fontWeight: "bold", color: "#2F2761", marginTop: "15px" }}>
                Deliverables :
              </h5>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.8",fontWeight:'bold' }}>
                <li className="mb-2">🔹 Unlock number of contacts & profiles as per subscription limit</li>
                <li className="mb-2">🔹 Post JDs as per subscription limit</li>
                <li className="mb-2">🔹 <strong>Validity:</strong> Number of months as per subscription limit</li>
                <li className="mb-2">🔹 Dedicated customer support</li>
              </ul>
              </div>
            </div>
 

            {/* Plans Container */}
            <div style={{ display: "flex", gap: "20px", flexWrap: 'wrap', position: "relative", paddingBottom: "30px",justifyContent:'center' }}>
              {subscriptionPlan.map((plan) => (
                <div
                  key={plan.planId} // Use planId instead of id
                  style={{
                    border: `2px solid ${selectedPlan?.planId === plan.planId ? "#2F2761" : "#ccc"}`,
                    padding: "20px",
                    borderRadius: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
                    width: "250px",
                    transition: "border 0.3s ease-in-out",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => setSelectedPlan(plan)}
                >
                  {/* Plan Tag */}
                  {/* <span style={{ backgroundColor: "#FFD700", fontSize: "12px", padding: "5px 10px", borderRadius: "10px" }}>
        {plan.planName}
      </span> */}
                  <h3 style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", textAlign: 'left', color: '#2F2761' }}>{plan.planName.charAt(0).toUpperCase() + plan.planName.slice(1)}</h3>
                  <p style={{ fontSize: "22px", fontWeight: "bold", margin: "5px 0", textAlign: 'left', marginBottom: '10px' }}>
                    {plan.price} <span style={{ fontSize: "12px", color: 'gray' }}></span>
                  </p>
                  {/* Subscribe Button with Conditional Styling */}
                  <button
                    style={{
                      backgroundColor: selectedPlan?.planId === plan.planId ? "#2F2761" : "#808080",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      width: "200px",
                      border: "none",
                      margin: "auto",
                      transition: "background 0.3s ease-in-out",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleSubscribe(plan)}
                    // {isLoggedIn ? disabled={loading} : disabled}
                    disabled={isLoggedIn ? loading : true}
                    // disabled
                  >
                    {loading && selectedPlan?.planId === plan.planId ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                </div>
              ))}

              {/* Moving Triangle */}
              <div
              className="triangle"
                style={{
                  position: "absolute",
                  bottom: "-20px", // Adjusted to properly align below the plan
                  left: `${selectedPlan
                    ? (subscriptionPlan.findIndex((p) => p.planId === selectedPlan.planId) * 270) + 110
                    : 110
                    }px`,
                  width: "0",
                  height: "0",
                  borderLeft: "25px solid transparent", // Increased size
                  borderRight: "25px solid transparent", // Increased size
                  borderTop: "30px solid #2F2761", // Bigger triangle & matches selection color
                  transition: "left 0.3s ease-in-out",
                }}
              />

            </div>


            {/* Selected Plan Details */}
            {selectedPlan && (
              <div style={{ marginTop: "30px", width: "80%", backgroundColor: "#fff", padding: "20px", boxShadow: "2px 2px 10px rgba(0,0,0,0.1)", borderRadius: "10px" }}
              className="">
                <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>Plan Details</h3>
                <div className="d-flex justify-content plan-box">
                  <ul style={{ marginTop: "15px", listStyle: "none", paddingLeft: "0", marginLeft: '50px' }}>
                    <li className="py-3"><RiCalendarScheduleLine className="fs-3 me-2" />Validity: {selectedPlan.validityInDays} Days</li>
                    <li className="py-3"><FcUpload className="fs-3 me-2" /> Job Credits: {selectedPlan.jobCredits}</li>
                    <li className="py-3"><FaStreetView className="fs-3 me-2" /> Database Credits: {selectedPlan.databaseCredits}</li>
                  </ul>

                  <ul style={{ marginTop: "15px", listStyle: "none", paddingLeft: "0", marginLeft: '50px' }}>
                    <li className="py-3"><FaFileDownload className="fs-4 me-2" /> Credits: {selectedPlan.download}</li>

                  </ul>

                </div>

              </div>
            )}
          </div>
        </Col>

        <div>
          {/* Subscription Popup */}
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
            <DialogTitle>
              Enter  Details
              <IconButton onClick={() => setOpen(false)} size="small" style={{ position: "absolute", top: "10px", right: "20px" }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <TextField fullWidth margin="dense" label="Name" name="firstName" value={formData.firstName} onChange={handleChange} required />
              <TextField fullWidth margin="dense" label="Company Name" name="companyName" value={formData.companyName} onChange={handleChange} required />
              <TextField fullWidth margin="dense" label="Email" name="email" value={formData.email} onChange={handleChange} required type="email" />

              {/* Phone Number Field with Validation */}
              <TextField
                fullWidth
                margin="dense"
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters

                  if (value.startsWith("91")) {
                    value = value.slice(2); // Remove 91 if user tries to type it manually
                  }

                  if (value.length > 10) {
                    value = value.slice(0, 10); // Limit to 10 digits
                  }

                  setFormData((prev) => ({
                    ...prev,
                    phoneNumber: `+91${value}`,
                  }));
                }}
                // InputProps={{
                //   startAdornment: <span style={{ marginRight: "5px" }}>+91</span>,
                // }}
                inputProps={{
                  maxLength: 13, // "91" (2) + 10 digits
                  pattern: "[0-9]{10}", // Ensure exactly 10 digits
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber || "Enter a valid 10-digit mobile number"}
                required
              />

            </DialogContent>

            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!!errors.phoneNumber || !formData.phoneNumber}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>

      </Row>
    </div>

  );
};

export default Module2;
