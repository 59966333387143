import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import EmployerDashboardSideBar from '../../components/EmployerDashboardSideBar';
// import EmployerDashboard from '../AllCandidates';
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import { Card, Col, Container, Form, InputGroup, Row, Stack } from 'react-bootstrap';
import { Chip } from '@mui/material';
import axios from "axios";
import moment from 'moment';

export const EmployerDetails = () => {
  const location = useLocation();
  const { id } = location.state || {}; // Destructure to get 'id' from location.state
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem('accessToken');

  const [applicantData, setApplicantData] = useState(null); // State to store applicant details
  const [loading, setLoading] = useState(true); // To track loading state
  const [error, setError] = useState(null); // To track error state
  const [plane, setPlane] = useState(null)

  console.log("id", id)
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          console.log("Fetching data for ID:", id);
          console.log("API URL:", `${apiUrl}/user/${id}`);
          console.log("Authorization Token:", token);

          const response = await fetch(`${apiUrl}/user/${id}`, {
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json',
            },
          });

          console.log("Response Status:", response.status);

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error fetching applicant data: ${errorData.message || 'Unknown error'}`);
          }

          const data = await response.json();
          console.log("Fetched data:", data.data);
          setApplicantData(data?.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setError(error.message);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id, apiUrl, token]); // Trigger fetch whenever 'id' or 'apiUrl' or 'token' changes

  useEffect(() => {
    if (id) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/subscription/getEmployerPlan/${id}`,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          console.log(response);
          if (response.status) {
            console.log("Response Status:", response.status);
            if (response.data && response.data.data) {
              const userData = response.data.data;
              setPlane(userData);
              console.log(userData);
            } else {
              console.error("Invalid data format in response.");
            }
          } else {
            console.error(`Unexpected status code: ${response.status}`);
          }
        } catch (err) {
          //  handleOpen()

          if (err.response) {
            const { status } = err.response;
            if (status === 400) {
              // toast.error("Bad Request: Please check the input data.");
              // alert(status?.data?.errorMsg)
              // toast.error(err?.response?.data?.errorMsg)
            } else if (status === 401) {
              //   toast.error(
              //     "Unauthorized: Please check your authentication token."
              //   );
            } else if (status === 404) {
              //   toast.error("Not Found: Unable to find the requested resource.");
            } else {
              //   toast.error(
              //     `Error ${status}:`,
              //     err.response.data.message || err.message
              //   );
              console.log('error in fetchddata', err);

            }
          } else {
            // toast.error("Network error or server not reachable:", err.message);
          }
        }
      };
      fetchProfile();

    }
  }, [id, apiUrl, token]);






  console.log("applicantData", applicantData)


  // Show loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show error if there was a problem fetching data
  if (error) {
    return <div>Error: {error}</div>;
  }

  const formattedApplicantData = {
    personalDetails: {
      userName: applicantData.userName,
      firstName: applicantData.firstName,
      lastName: applicantData.lastName,
      phoneNumber: applicantData.phoneNumber,
      email: applicantData.email,
      designation: applicantData.designation,
      department: applicantData.department,
      branch: applicantData.branch,
      companyName: applicantData.companyName,
    },
    kycDetails: {
      gstIn: applicantData.gstIn,
      panNumber: applicantData.panNumber,
      city: applicantData.city,
      state: applicantData.state,
      industryCategory: applicantData.industryCategory,
      industrySubCategory: applicantData.industrySubCategory,
      CompanyAddress: applicantData.communicationAdress

    },
    //   professionalDetails: {
    //     profileTitle: applicantData.profileTitle,
    //     totalExperience: applicantData.totalExperience,
    //     currentJob: applicantData.CurrentJob,
    //     currentSalary: applicantData.Currentsalary,
    //     expectedSalary: applicantData.expectedSalary,
    //     skills: applicantData.skills || [],
    //     responsibilities: applicantData.responsibilities || [],
    //     qualification: applicantData.qualification || [],
    //     NativeLocation:applicantData.NativeLocation,
    //     LanguagesKnown:applicantData.LanguagesKnown,
    //     NoticePeriod:applicantData.NoticePeriod,
    //     companyName: applicantData.companyName,
    //     Relocation:applicantData.Relocation,
    //     InterestedJobField:applicantData.InterestedJobField,
    //     Remarks:applicantData.Remarks,
    //     JobseekerFeedback:applicantData.JobseekerFeedback


    //   }
  }

  const formattedPlanEndDate = plane?.planEndDate
    ? moment(plane.planEndDate).format("DD-MM-YYYY")
    : '';

  const formattedPlanStartDate = plane?.planStartDate
    ? moment(plane.planEndDate).format("DD-MM-YYYY")
    : '';

  const EmployerPlaneData = {
    personalDetails: {
      planName: plane?.planName,
      price: plane?.price,
      planStartDate: formattedPlanStartDate,
      planEndDate: formattedPlanEndDate,
      validityInDays: plane?.validityInDays,
    }
  }


  return (
    <div>
      <EmployerDashboardHeader />
      <div style={{ display: 'flex' }}>
        <EmployerDashboardSideBar />
        {applicantData ? (
          <Container>
            <Row>
              <Col>
                <Card className="mb-4">
                  <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                    Employer Details
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3">
                      {Object.entries(formattedApplicantData.personalDetails || {}).map(([key, value]) => (
                        <Col md={6} key={key}>
                          <InputGroup>
                            <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
                            <Form.Control type="text" value={value} readOnly />
                          </InputGroup>
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-4">
                  <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                    Company Details
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3">
                    {Object.entries(formattedApplicantData.kycDetails || {}).map(([key, value]) => (
  <Col md={6} key={key}>
    <InputGroup>
      <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
      
      {key === "CompanyAddress" ? (
        <Form.Control as="textarea" value={value} readOnly rows={3} />
      ) : (
        <Form.Control type="text" value={value} readOnly />
      )}
      
    </InputGroup>
  </Col>
))}

                    </Row>
                  </Card.Body>
                </Card>
                {plane && (
                  <Card className="mb-4">
                    <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                      Plane Details
                    </Card.Header>
                    <Card.Body>
                      <Row className="g-3">
                        {Object.entries(EmployerPlaneData.personalDetails || {}).map(([key, value]) => (
                          <Col md={6} key={key}>
                            <InputGroup>
                              <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
                              <Form.Control type="text" value={value} readOnly />
                            </InputGroup>
                          </Col>
                        ))}
                      </Row>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        ) : (
          <p>No details available for this applicant.</p>
        )}
      </div>
    </div>
  );
};

