import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, InputGroup, Spinner, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../EmployerDashboard.css';
import axios from 'axios';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import { FaTrash } from 'react-icons/fa';
import ReactPaginate from "react-paginate";
import '../../Styles/jobseekerjoblist.css';
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import ConfirmationDialog from '../components/ConfirmationDialog.tsx';

const Enrollment = () => {
    const navigate = useNavigate();
    const [applicants, setApplicants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const [searchTermName, setSearchTermName] = useState("");
    const [searchTermCompany, setSearchTermCompany] = useState("");

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 7; // Number of rows per page

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleupdate = (applicant) => {
        navigate(`/enrollmentCreate`, { state: applicant }); // Navigate with userId
    };
    const handleCloseDialog = () => setDialogOpen(false);
    const [selectedUserId, setSelectedUserId] = useState(null); // Store selected userId
    useEffect(() => {


        fetchApplicants();
    }, []);

    const fetchApplicants = async () => {
        try {
            const token = sessionStorage.getItem('accessToken');
            if (!token) throw new Error('Authentication token is missing');

            const response = await axios.get(`${apiUrl}/user/roles/employer`, {
                headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' },
            });

            if (Array.isArray(response.data.data)) {


                const filteredData = response.data.data.filter(
                    (item) => item.subrole === "enrollment"
                  );
                  setApplicants(filteredData)
            } else {
                throw new Error('Expected data to be an array');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    // const handleConfirmAction = () => {
    //     handleDelete();
    //     console.log("Confirmed!");
    //     setDialogOpen(false);
    //   };


      
    const handleDelete = async (id) => {
        // const token = sessionStorage.getItem("accessToken"); // Retrieve token

        // if (!token) {
        //     toast.error("Authentication token is missing!");
        //     return;
        // }

        // try {
        //     const response = await axios.delete(`${apiUrl}/user/Enrollment/${id}`, {
        //         headers: {
        //             Authorization: `${token}`, // Send token in header
        //         },
        //     });

        //     if (response.status === 200) {
        //         toast.success("Enrollment successfully deleted!");
        //         // Optionally refresh the list after deletion
        //         fetchApplicants();
        //     }
        // } catch (error) {
        //     console.error("Error deleting enrollment:", error);
        //     toast.error(error.response?.data?.message || "Failed to delete enrollment.");
        // }
        setSelectedUserId(id); // Set selected user ID
        setDialogOpen(true); 
    };

    const handleConfirmAction = async () => {
        if (!selectedUserId) return;
    
        const token = sessionStorage.getItem("accessToken"); // Retrieve token
    
        if (!token) {
            toast.error("Authentication token is missing!");
            return;
        }
    
        try {
            const response = await axios.delete(`${apiUrl}/user/enrollment/${selectedUserId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Ensure correct token format
                },
            });
    
            if (response.status === 200) {
                toast.success("Enrollment successfully deleted!");
                fetchApplicants(); // Refresh the list
            }
        } catch (error) {
            console.error("Error deleting enrollment:", error);
            toast.error(error.response?.data?.message || "Failed to delete enrollment.");
        }
    
        setDialogOpen(false);
    };

    const handleViewClick = (applicant) => {
        navigate('/enrollmentDetails', { state: { id: applicant.userId } });
    };

    const handleDeleteClick = async (employer) => {
        const confirmed = window.confirm('Are you sure you want to delete this Employer?');
        if (!confirmed) return;

        try {
            const response = await fetch(`${apiUrl}/admin/employers/${employer.userId}`, { method: 'DELETE' });

            if (response.ok) {
                alert('Employer deleted successfully.');
                setApplicants(applicants.filter(app => app.userId !== employer.userId));
            } else {
                alert('Failed to delete the employer. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting employer:', error);
            alert('An error occurred while deleting the employer.');
        }
    };



    const filteredApplicants = applicants.filter((applicant) => {
        const name = (applicant.userName || "").toLowerCase();
        const company = (applicant.companyName || "").toLowerCase();

        return name.includes(searchTermName.toLowerCase()) && company.includes(searchTermCompany.toLowerCase());
    });

    const pageCount = Math.ceil(filteredApplicants.length / itemsPerPage);
    const currentApplicants = filteredApplicants.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handlePageChange = ({ selected }) => setCurrentPage(selected);

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Spinner animation="border" variant="primary" />
        </div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="dashboard">
            <EmployerDashboardHeader />
            <Container fluid className="content">
                <Row>
                    <EmployerDashboardSideBar />
                    <Col md={10} className="main-content">
                        <h5 className='mb-3 fw-bold p-4'>All Enrollment Employers</h5>

                        {/* Search Inputs */}
                        <div style={{ display: 'flex', gap: '50px', alignItems: 'center', background: 'white', padding: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Name</label> */}
                                <InputGroup>
                                    <InputGroup.Text className="filter-header-highlighter">Name</InputGroup.Text>
                                    <Form.Control
                                        style={{ width: '200px' }}
                                        type="text"
                                        placeholder="Search by Name"
                                        value={searchTermName}
                                        onChange={(e) => setSearchTermName(e.target.value)}
                                    />
                                </InputGroup>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Company</label> */}
                                <InputGroup>
                                    <InputGroup.Text className="filter-header-highlighter">Company</InputGroup.Text>
                                    <Form.Control
                                        style={{ width: '200px' }}
                                        type="text"
                                        placeholder="Search by Company"
                                        value={searchTermCompany}
                                        onChange={(e) => setSearchTermCompany(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                            {/* 
              <Button onClick={() => navigate("/employerCreateProfile")} className="Add-button">
                Add Employer
              </Button> */}
                        </div>

                        {/* Employers Table */}
                        <div className='card border-0 rounded-0'>
                            <Table hover className="table mt-3">
                                <thead className="theads">
                                    <tr className="tablehead">
                                        <th>Name</th>
                                        <th>Company Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentApplicants.map((applicant) => (
                                        <tr key={applicant.id} className="tbodys">
                                            <td>{applicant.firstName}</td>
                                            <td style={{ width: "300px" }}>{applicant.companyName || "-"}</td>
                                            <td>{applicant.phoneNumber}</td>
                                            <td style={{ width: "250px" }}>{applicant.email || "-"}</td>
                                            <td>
                                                <Button onClick={() => handleViewClick(applicant)} variant="info" className="me-2">
                                                    View
                                                </Button>
                                                {/* <Button
                                                    style={{
                                                        background: "none",
                                                        border: "none",
                                                        color: "red",
                                                        fontSize: "20px",
                                                        cursor: "pointer", // Ensure it looks clickable
                                                    }}
                                                    onClick={() => handleupdate(applicant)}
                                                >
                                                    <FaUserEdit />
                                                </Button> */}

                                                {/* <MdDelete
                                                    style={{ color: "red", cursor: "pointer", fontSize: "20px" }}
                                                    onClick={() => handleDelete(applicant.userId)}
                                                /> */}

                                                {/* <Button onClick={() => handleDeleteClick(applicant)} variant="danger">
                          <FaTrash size={16} />
                        </Button> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            
                                  <ConfirmationDialog
                                    open={dialogOpen}
                                    title="Delete Enrollment Employer?"
                                    message="Are you sure you want to delete this Enrollment Employer?"
                                    confirmText="Delete"
                                    cancelText="Cancel"
                                    onClose={handleCloseDialog}
                                    onConfirm={handleConfirmAction}
                                  />
                                  {/* <ConfirmationDialog */}
                                  

                            {/* Pagination Controls */}
                            <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination justify-content-center mt-3"}
                                previousLinkClassName={"page-link"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active p-1"}
                                disabledClassName={"disabled"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Enrollment;
