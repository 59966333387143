import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { Card, Form } from 'react-bootstrap'; // Add Card here
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../EmployerDashboard.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { FaEye } from "react-icons/fa";
import '../Styles/jobseekerjoblist.css'
import { FaRegEdit } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { Formik, Field } from 'formik';
import ConfirmationDialog from '../pages/components/ConfirmationDialog.tsx';

const CallcenterApplicant = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const employerid = sessionStorage.getItem("userId");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobTitle, setJobTitle] = useState(""); // State for job title
  const [jobLocation, setJobLocation] = useState(""); // State for job title
  const [jobSalary, setJobSalary] = useState(null); // State for job title
  // const [updateStatus, setUpdateStatus] = useState(null); // Track the status of the update

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    width: "500px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
    textAlign: "center",
  };

  const titleStyles = {
    marginBottom: "15px",
    fontSize: "22px",
    color: "#333",
  };

  const labelStyles = {
    display: "block",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "14px",
  };

  const inputStyles = {
    width: "100%",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const buttonContainer = {
    display: "flex",
    justifyContent: "space-between",
  };

  const saveButtonStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const closeButtonStyles = {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };


  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  const [filter, setFilter] = useState({
    name: '',
    location: '',
  });
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  useEffect(() => {
    setFilteredApplicants(applicants); // Initialize filteredApplicants with all applicants initially
  }, [applicants]);


  const applyFilters = () => {
    const filtered = applicants.filter((applicant) => {
      return (
        (filter.jobTitle ? (applicant.jobTitle && applicant.jobTitle.toLowerCase().includes(filter.jobTitle.toLowerCase())) : true) &&
        (filter.location ? (applicant.location && applicant.location.toLowerCase().includes(filter.location.toLowerCase())) : true)
      );
    });
    setFilteredApplicants(filtered); // Update filteredApplicants with filtered results
  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  // useEffect to show toast notifications based on updateStatus
  // useEffect(() => {
  //   if (updateStatus === 'success') {
  //     toast.success('Job updated successfully!'); // Show success toast
  //   } else if (updateStatus === 'error') {
  //     toast.error('Failed to update job'); // Show error toast
  //   }
  // }, [updateStatus]); // Dependency on updateStatus to trigger useEffect


  useEffect(() => {


    fetchApplicants();
  }, []);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Authentication token is missing');
      }

      // const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
      //   headers: {
      //     'Authorization': `${token}`,
      //     'Content-Type': 'application/json',
      //   },
      // });
      const response = await axios.get(`${apiUrl}/job/posted/${employerid}`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response?.data?.data)) {
        setApplicants(response?.data?.data);
      } else {
        throw new Error('Expected data to be an array');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleViewClick = async (applicant) => {
    navigate('/tableComponent', { state: { applicant } });
    // setSelectedApplicant(applicant);
    // try {
    //   const token = sessionStorage.getItem('accessToken');
    //   if (!token) {
    //     throw new Error('Authentication token is missing');
    //   }
    //   // 
    //   const response = await axios.get(`${apiUrl}/applications/job/${applicant?.jobId}`, {
    //     headers: {
    //       'Authorization': `${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   });

    //   if (Array.isArray(response?.data?.data)) {
    //     // setApplicants(response.data.data);
    //     setSelectedApplicant(response?.data?.data);
    //     // setShowDetailsModal(true);
    //     navigate('/List', { state: { applicant } });


    //     // setFilteredApplicants(response.data.data); 
    //   } else {
    //     // throw new Error('Expected data to be an array');
    //     navigate('/List', { state: { applicant } });
    //     toast.error('Expected data to be an array')
    //   }
    // } catch (err) {
    //   // setError(err.message);
    //   navigate('/List', { state: { applicant } });
    //   toast.error(err?.response?.data?.error)


    // } finally {
    //   setLoading(false);
    // }
  };

  const handleDeletePostedJob = (jobId) => {
    setSelectedJobId(jobId);
    handleOpenDialog()
  }

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('accessToken');

      if (!token) {
        toast.error("No access token found, please login again.");
        return;
      }

      // Log the id and token to ensure they're correct
      console.log("Deleting job with ID:", id);
      console.log("Authorization Token:", token);

      // Make the delete request
      const response = await axios.delete(`${apiUrl}/job/delete/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      console.log("response", response.status);

      // Check if the response is successful
      if (response.status === 200) {
        // Show success message with toast
        toast.success("Job has been deleted successfully.");
        fetchApplicants();
        // Optionally, update the state to remove the deleted item from the UI
        setFilteredApplicants((prevApplicants) =>
          prevApplicants.filter((applicant) => applicant?.applicationId !== id)
        );
      }
    } catch (error) {
      // Check if it's a 400 error and log the response for debugging
      if (error.response && error.response.status === 400) {
        console.log("Bad Request: ", error.response.data);
        toast.error(`Bad Request: ${error.response.data.message || 'Invalid request data'}`);
      } else {
        // Handle other types of errors
        const errorMessage = error.response?.data?.message || error.message || "Something went wrong, please try again.";
        toast.error(errorMessage);
      }
    }
    // handleOpenDialog()
    console.log("Deleted job id:", id);
  };
  const handleConfirmAction = () => {
    handleDelete(selectedJobId);
    console.log("Confirmed!");
    setDialogOpen(false);
  };


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }






  // Function to open the modal and set the selected jobId
  const handleUpdate = (applicant) => {
    // Any logic you want to run before navigation
    console.log('Updating applicant:', applicant);

    // Navigate to the /List page
    navigate(`/jobDescriptionForm`, { state: { job:applicant } });
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedJobId(null); // Reset the selected jobId
    setJobTitle(''); // Reset the job title
    setJobLocation('')
    setJobSalary(null)
  };

  // Function to handle saving the updated job title

  const handleSave = async () => {
    try {

      // Create an object with required fields
      const jobUpdateData = {
        jobTitle: jobTitle !== undefined || "" ? jobTitle : selectedJobId.jobTitle,
        location: jobLocation !== undefined || "" ? jobLocation : selectedJobId.jobLocation,
        salary: jobSalary !== undefined || "" ? parseInt(jobSalary, 10) : selectedJobId.salary,
      };

      // Remove undefined values dynamically
      const filteredJobData = Object.fromEntries(
        Object.entries(jobUpdateData).filter(([_, value]) => value !== undefined)
      );

      const response = await fetch(`${apiUrl}/job/update/${selectedJobId.jobId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filteredJobData),
      });

      // // const jobtitledata = jobTitle !== undefined??jobTitle 
      // // const joblocationdata = jobLocation !== undefined??jobLocation 
      // // const jobsalarydata = jobSalary !== undefined??jobSalary 
      // const response = await fetch(`${apiUrl}/job/update/${selectedJobId.jobId}`, {
      //   method: 'PATCH', // or 'PATCH' depending on your API
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ 

      //     "jobTitle":jobTitle??"",
      //     "location":jobLocation??"",
      //     "salary":jobSalary??""

      //    }),
      // });

      if (response.ok) {
        // toast.success('Job updated successfully!'); // Show success toast
        toast.success('Job updated successfully!');
        // setUpdateStatus('success');
        fetchApplicants();
        closeModal(); // Close the modal after successful update
      } else {
        toast.error('Failed to update job'); // Show error toast
        // setUpdateStatus('error');
      }
    } catch (error) {
      console.error('Error updating job:', error);
      toast.error('Error updating job'); // Show error toast
    }
  };

  // const handleFocus = (e) => {
  //   // When the input is clicked, clear its value
  //   e.target.value = '';
  // };

  const handleFocus = (InputValue) => {
    if (InputValue == "Title") {
      setJobTitle("");
    }
    if (InputValue == "Location") {
      setJobLocation("");
    }
    if (InputValue == "Salary") {
      setJobSalary(null)
    }
  };

   console.log("applicant",filteredApplicants)
   const userName=sessionStorage.getItem("userName") 

  return (
    <div className="dashboard">

      < EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="">
          <Col>
          <EmployerDashboardSideBar />

          </Col>
          <Col md={10} className="main-content">
            <h5 className='mb-4 fw-bold mt-4 px-3'>{userName} Posted Jobs</h5>

   <div >           
            <Card className="mb-4 shadow-sm posted-filter"  >
              <Card.Body >
                <Form>
                  <Row className="justify-content-between">
                    <Col sm={3}>
                      <Form.Group controlId="jobTitle">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="jobTitle"
                          value={filter?.jobTitle}
                          onChange={handleFilterChange}
                          placeholder="Job Title"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="location" >
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="text"
                          name="location"
                          value={filter?.location}
                          onChange={handleFilterChange}
                          placeholder="Location"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3} className="d-flex align-items-end" style={{ marginTop: "27px" }}>
                      <Button style={{ backgroundColor: " rgb(47 39 97)" }}
                        onClick={applyFilters}
                        className="btn w-100">
                         Filters
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <div className='card border-0 rounded-0'  >
              <div className='card-body table-responsive'>
                <Table hover className="table">
                  <thead>
                    <tr className="tablehead text-center">
                      <th style={{width:'200px'}}>Job Title</th>
                      <th>Start Date</th>
                      <th>Expiry Date</th>
                      <th>Experience</th>
                      <th>₹ Salary/Month</th>
                      <th style={{width:'200px'}}>Location</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredApplicants.length === 0 && !loading ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No matching jobs found
                        </td>
                      </tr>
                    ) : (
                      filteredApplicants.map((applicant) => (
                        <tr key={applicant?.jobId} className="tbodys text-center">
                          <td style={{width:'200px'}}>{applicant?.jobTitle}</td>
                          <td>{moment(applicant?.startDate).format('MMMM Do YYYY')}</td>
                          <td>{moment(applicant?.expiryDate).format('MMMM Do YYYY')}</td>
                          <td>
                            {applicant?.minExperience != null && applicant?.maxExperience != null
                              ? `${applicant?.minExperience} - ${applicant?.maxExperience}`
                              : (applicant?.totalExperience != null ? applicant?.totalExperience : "0")}
                          </td>
                          <td>₹ {applicant?.salary}</td>
                          <td style={{width:'200px'}}>{applicant?.location}</td>

                          <td>
                            <Button onClick={() =>  navigate('/applicantsTable', { state: { applicant} })} className="btn btn-info" style={{ background: '#2F2761det', border: 'none', color: 'white',width:'50%' }}>
                              {/* <FaEye style={{ color: '#170F49' }} /> */}view
                            </Button>
                            {/* <Link className='p-1'  onClick={() => navigate('/contact')}> */}
                              <MdModeEditOutline className='ms-1 me-1 fs-4 text-primary'  onClick={() =>handleUpdate(applicant)}  />
                            {/* </Link> */}
                            <Link className='' onClick={() => handleDeletePostedJob(applicant?.jobId)}>
                              <MdDelete className='fs-4  text-danger' />
                            </Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>

                {/* Modal for editing job */}
                {isModalOpen && (
                  <div style={overlayStyles}>
                    <div style={modalStyles}>
                      <h2 style={titleStyles}>Update the Job</h2>

                      <label style={labelStyles}>Job Title</label>
                      <input
                        type="text"
                        value={jobTitle || ""}
                        onChange={(e) => setJobTitle(e.target.value)}
                        onFocus={() => handleFocus("Title")}
                        style={inputStyles}
                        required
                      />

                      <label style={labelStyles}>Location</label>
                      <input
                        type="text"
                        value={jobLocation || ""}
                        onChange={(e) => setJobLocation(e.target.value)}
                        onFocus={() => handleFocus("Location")}
                        style={inputStyles}
                        required
                      />
                      <label style={labelStyles}>Salary</label>
                      <label style={labelStyles}></label>
                      <input
                        type="number"
                        value={jobSalary || null}
                        onChange={(e) => setJobSalary(e.target.value)}
                        onFocus={() => handleFocus("Salary")}
                        style={inputStyles}
                        required
                      />

                      <div style={buttonContainer}>
                        <button onClick={closeModal} style={closeButtonStyles}>
                          Close
                        </button>
                        <button onClick={handleSave} style={saveButtonStyles}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              </div>
              </div>
            </div>
          </Col>
        </Row>

      </Container>

      <ConfirmationDialog
        open={dialogOpen}
        title="Delete Posted Job?"
        message="Are you sure you want to delete this posted Job?"
        confirmText="Delete"
        cancelText="Cancel"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
      />
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Applicant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedApplicant && (
                        <div>
                          <p><strong>Name:</strong> {selectedApplicant.firstName} {selectedApplicant.lastName}</p>
                          <p><strong>Email:</strong> {selectedApplicant.emailAddress}</p>
                          <p><strong>Phone:</strong> {selectedApplicant.phoneNumber}</p>
                          <p><strong>Address:</strong> {selectedApplicant.address}</p>
                          <p><strong>Location:</strong> {selectedApplicant.location}</p>
            
                          <p><strong>Experience:</strong> {selectedApplicant.experience}</p>
                          <p><strong>Status:</strong> {selectedApplicant.status}</p>
                        </div>
                      )}
          {/* {selectedApplicant && (
                       <div>
                         {Object.entries(selectedApplicant)
                           .filter(([key]) => ['firstName', 'lastName', 'emailAddress', 'phoneNumber'].includes(key))
                           .map(([key, value]) => {
                             let label = key === 'firstName' || key === 'lastName' ? 'Name' : key === 'emailAddress' ? 'Email' : 'Phone';
                             return (
                               <p key={key}>
                                 <strong>{label}:</strong> {key === 'firstName' ? `${value} ${selectedApplicant.lastName}` : value}
                               </p>
                             );
                           })}
                       </div>
                     )} */}

          {selectedApplicant?.map((app, index) => (

            <p key={index}>
              <strong>Application ID:</strong> {app?.applicationId} - {app?.jobSeeker?.userName}
            </p>
          ))}




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CallcenterApplicant;
