import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import '../Styles/jobseekerlogin.css'

const EmployerLoginOtp = ({ showJobseekerPopup, hideJobseekerPopup }) => {
  const { t, i18n } = useTranslation();
  const [userName, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleSendOtp = async () => {
    let hasError = false;
    // Validate Username
    if (!userName) {
      setUsernameError("Username is required.");
      hasError = true;
    } else {
      setUsernameError("");
    }
    // Validate Phone Number
    if (!phoneNumber) {
      setPhoneError("Phone number is required.");
      hasError = true;
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneError("Phone number must be exactly 10 digits.");
      hasError = true;
    } else {
      setPhoneError("");
    }
    if (hasError) return;
    setSuccessMessage("Sending OTP...");
    try {

      // twilio otp send
      const response = await axios.post(`${apiUrl}/user/employer/send-otp`, {
        userName: userName,
        phoneNumber: `+91${phoneNumber}`,
      });

      console.log("sendotp:", response);

      if (response.status == 201) {
        setSuccessMessage("OTP sent successfully!");
        setShowOtpModal(true);

      } else {
        setPhoneError(response.data.message || "Failed to send OTP.");
        setSuccessMessage("");
      }
    } catch (error) {
      setPhoneError("Error while sending OTP. Please try again.");
      setSuccessMessage("");
      toast.error(error.response?.data.message);
    }
  };
  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("Please enter the OTP.");
      return;
    } else {
      setOtpError("");
    }

    try {
      const response = await axios.post(`${apiUrl}/user/emp/verify-otp`, {
        phoneNumber: `+91${phoneNumber}`,
        otp: otp,
      });
      console.log("verifyotp:", response);

      if (response && response.data) {
        navigate("/dashboard");
        // alert("mess")

        const { accessToken, data } = response.data;

        if (accessToken) {
          // Save access token and user details to session storage
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("role", data.role);
          sessionStorage.setItem("userId", data.userId);
          sessionStorage.setItem("userName", data.userName);
          console.log(data.username)
          console.log("Access token saved:", accessToken);
          toast.success("Login successful");

          // Close OTP modal and reload the page
          setShowOtpModal(false);
          hideJobseekerPopup();
          window.location.reload();
        } else {
          console.error("Couldn't find access token");
          setOtpError("Unexpected error. Please try again.");
        }
      }
      else {
        setOtpError("Invalid OTP. Please try again.");
        setOtpError("");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      const errorMessage =
        error.response?.data?.message || "Error verifying OTP. Please try again.";
      setOtpError(errorMessage);
      toast.error(errorMessage);
      setOtpError("");
    }
  };

  const closeOtpModel = () => {
    setShowOtpModal(false)
  }


  const speak = (message) => {
    const utterance = new SpeechSynthesisUtterance(message);
    speechSynthesis.speak(utterance);
  };
  const handleFocus = (field) => {
    if (field === "username") {
      speak("Enter your username");
    } else if (field === "phonenumber") {
      speak("Enter your phonenumber");
    } else if (field === "otp") {
      speak("Enter your otp")
    }
  };
  return (
    <>
      {showJobseekerPopup && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
          onClick={hideJobseekerPopup}
        >
          <div
            className="card shadow p-4 position-relative employerlogin"
            style={{
              width: "400px",
              borderRadius: "25px",
              background: "linear-gradient(to bottom, #ffffff, #f0f8ff)",
              border: "1px solid #d1d9e5",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 m-3"
              aria-label="Close"
              onClick={hideJobseekerPopup}
            ></button>
            <h4 className="fw-bold text-center" style={{ color: "#000000" }}>
              {t('Employer Login')}
            </h4>
            <br />
            <form>
              <div className="form-group mb-3">
                <input
                  type="text"
                  placeholder={t('Username')}
                  value={userName}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                  style={{
                    borderRadius: "15px",
                    borderColor: usernameError ? "red" : "#d1d3e2",
                  }}
                  onFocus={() => handleFocus("username")}
                  required
                />
                {usernameError && (
                  <p className="text-danger small mt-1">{usernameError}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <input
                  type="tel"
                  placeholder={t("Phone Number")}
                  value={phoneNumber}
                  onChange={(e) =>
                    setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))
                  }
                  className="form-control"
                  style={{
                    borderRadius: "15px",
                    borderColor: phoneError ? "red" : "#d1d3e2",
                  }}
                  onFocus={() => handleFocus('phonenumber')}
                  required
                />
                {phoneError && (
                  <p className="text-danger small mt-1">{phoneError}</p>
                )}
              </div>
              <button
                type="button"
                onClick={handleSendOtp}
                className="default-btn w-100"
              >
                {t('Send OTP')}
              </button>
              {successMessage && (
                <p className="text-success small mt-2">{successMessage}</p>
              )}
            </form>
          </div>
        </div>
      )}
      {showOtpModal && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1051,
          }}
        >
          <div
            className="card p-4"
            style={{
              width: "350px",
              borderRadius: "20px",
              background: "linear-gradient(to bottom, #ffffff, #f0f8ff)",
            }}
          >
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 m-3"
              aria-label="Close"
              onClick={closeOtpModel}
            ></button>
            <h5 className="text-center mb-3" style={{ color: "#000000" }}>
              {t('Verify OTP')}
            </h5>
            <div className="form-group mb-3">
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                style={{
                  borderRadius: "15px",
                  borderColor: otpError ? "red" : "#d1d3e2",
                }}
                onFocus={() => handleFocus("otp")}
              />
              {otpError && (
                <p className="text-danger small mt-1">{otpError}</p>
              )}
            </div>
            <button
              type="button"
              onClick={handleVerifyOtp}
              className="default-btn w-100 mb-4"
            >
              {t('Verify OTP')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default EmployerLoginOtp;  