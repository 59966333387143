// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    padding:0px;
    margin: 0px;
}
.reports{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.reports Button{
    width: 200px !important;
    background-color: #2F2761;
    border: none;
}
.reports Button:hover{
    background-color:white;
    color: #2F2761;
    font-weight: bold;
    border: 1px solid #2F2761;
}
.reports-dash{
    background-color: #44398d;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    /* width:350px; */
    padding: 0px !important;
}
.reports-box{
    height:80vh;
    padding-right: 0px !important;
    /* width:85%; */
    background-color: #F5F6FA;
}`, "",{"version":3,"sources":["webpack://./src/Styles/reports.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,cAAc;IACd,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,+EAA+E;IAC/E,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,6BAA6B;IAC7B,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["*{\r\n    padding:0px;\r\n    margin: 0px;\r\n}\r\n.reports{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 50px;\r\n}\r\n.reports Button{\r\n    width: 200px !important;\r\n    background-color: #2F2761;\r\n    border: none;\r\n}\r\n.reports Button:hover{\r\n    background-color:white;\r\n    color: #2F2761;\r\n    font-weight: bold;\r\n    border: 1px solid #2F2761;\r\n}\r\n.reports-dash{\r\n    background-color: #44398d;\r\n    color: white;\r\n    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;\r\n    /* width:350px; */\r\n    padding: 0px !important;\r\n}\r\n.reports-box{\r\n    height:80vh;\r\n    padding-right: 0px !important;\r\n    /* width:85%; */\r\n    background-color: #F5F6FA;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
