import React, { useState, useEffect } from "react";
import '../index.css';
import { useNavigate } from "react-router-dom";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";

import { useLocation } from "react-router-dom";


const Contact = () => {
  const location = useLocation();
  const { Roles } = location.state || {};
  console.log(Roles);


  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  // const [isJobseekerForm, setIsJobseekerForm] = useState(true); // State to toggle forms
  const [isJobseekerForm, setIsJobseekerForm] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12;
  const role = sessionStorage.getItem('role');

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
      setSidebar(true)
    }
 
  }, []);

  useEffect(() => {
    if (role == 'employer') {
      setIsJobseekerForm(false)

    }
    if (role == 'jobseeker') {
      setIsJobseekerForm(true); // Toggle between forms
   
    }
  }, [role])



  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">

          <Col>
          {isLoggedIn ? <EmployerDashboardSideBar /> :
            <></>
          }
          </Col>
          
          <Col md={colWidth} className="main-content">
            <section className="slider-sec">
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-12 text-center">
                    <h1 style={{ color: '#170F49' }}>Bridging the gap in blue-collar jobs.</h1>
                   
                  </div>
                </div>
                <div className="row g-4 mt-3">
                  <div className="col-lg-8 offset-lg-2">
                    
                    <form className=" p-4 shadow bg-white" style={{ borderRadius: '10px' }}  >
                      <h3 className="text-center">
                        {isJobseekerForm ? "Jobseeker Contact Form" : "Employer Contact Form"}
                      </h3>
                      <div className="row g-4 mt-4">
                        <div className="col-md-6">
                          <label className="form-label ms-1">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" required />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">
                            Sur name
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input type="email" className="form-control" required />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            maxLength="10"
                            pattern="^[6-9][0-9]{9}$"  // Ensures the number starts with 6, 7, 8, or 9 and is 10 digits long.
                            title="Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits long."
                            onInput={(e) => {
                              // Optional: If you want to validate as the user types
                              if (e.target.value.length > 10) {
                                e.target.setCustomValidity("Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits.");
                              } else {
                                e.target.setCustomValidity("");
                              }
                            }}
                          />
                          <div className="invalid-feedback">
                            Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits long.
                          </div>
                        </div>

                        {isJobseekerForm ? (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">
                                Skill <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" required />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Experience <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" required />
                            </div>
                            <div class="col-md-12">
                              <label for="jobSelect" class="form-label">What type of work/job do you need? <span className="text-danger">*</span> </label>
                              <div id="jobSelect">
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="plumber" value="plumber" />
                                  <label class="form-check-label ms-2" for="plumber">
                                    Plumber
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="electrician" value="electrician" />
                                  <label class="form-check-label ms-2" for="electrician">
                                    Electrician
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="driver" value="driver" />
                                  <label class="form-check-label ms-2" for="driver">
                                    Driver
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="mechanical" value="mechanical" />
                                  <label class="form-check-label ms-2" for="mechanical">
                                    Mechanic
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="welding" value="welding" />
                                  <label class="form-check-label ms-2" for="welding">
                                    Welder
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="other" value="other" />
                                  <label class="form-check-label ms-2" for="other">
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">
                                Company Name <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" required />
                            </div>

                            <div class="col-md-12">
                              <label for="jobSelect" class="form-label">What type of worker/job seeker  do you need? <span className="text-danger">*</span></label>
                              <div id="jobSelect">
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="plumber" value="plumber" />
                                  <label class="form-check-label ms-2" for="plumber">
                                    Plumber
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="electrician" value="electrician" />
                                  <label class="form-check-label ms-2" for="electrician">
                                    Electrician
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="driver" value="driver" />
                                  <label class="form-check-label ms-2" for="driver">
                                    Driver
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="mechanical" value="mechanical" />
                                  <label class="form-check-label ms-2" for="mechanical">
                                    Mechanic
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="welding" value="welding" />
                                  <label class="form-check-label ms-2" for="welding">
                                    Welder
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <input class="form-check-input" type="radio" name="job" id="other" value="other" />
                                  <label class="form-check-label ms-2" for="other">
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}


                        <div className="col-md-12">
                          <label className="form-label ms-1">Message </label>
                          <textarea
                            placeholder="Write your message..."
                            rows="4"
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="col-md-12 send-msg" >
                          <button type="submit" className="default-btn">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      {/* <Footer/> */}
    </div>
  );
};

export default Contact;


