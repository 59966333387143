import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select,FormControl,InputLabel } from "@mui/material";
import axios from 'axios';
import Swal from "sweetalert2"; 

const statusOptions = ["APPLIED", "INTERVIEW_SCHEDULED", "SHORTLISTED", "REJECTED", "HIRED"];

const ApplyJobPopup = ({ open, onClose, onUpdate, currentStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const[postedJobs, setPostedJobs] =useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
    const [applicants, setApplicants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  const employerid = sessionStorage.getItem("userId");
  const [selectedJobTitle, setSelectedJobTitle] = useState("");

console.log("selected",selectedStatus)

  useEffect(() => {


    fetchApplicants();
  }, []);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Authentication token is missing');
      }

      // const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
      //   headers: {
      //     'Authorization': `${token}`,
      //     'Content-Type': 'application/json',
      //   },
      // });
      const response = await axios.get(`${apiUrl}/job/posted/${employerid}`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response?.data?.data)) {
        setPostedJobs(response?.data?.data);
      } else {
        throw new Error('Expected data to be an array');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    // setSelectedStatus(event.target.value);
    const selectedJobId = event.target.value;
    setSelectedStatus(selectedJobId);
    // Find the corresponding job title
    const jobTitle = postedJobs.find((job) => job.jobId === selectedJobId)?.jobTitle || "";
    setSelectedJobTitle(jobTitle);
  };

  // const handleUpdate = () => {
  //   onUpdate(selectedStatus);
  //   onClose(); // Close popup after updating
  // };

  const handleUpdate = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to apply  for "${selectedJobTitle}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, apply it!",
      didOpen: () => {
        document.querySelector(".swal2-popup").style.zIndex = "9999"; // Set z-index inline
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onUpdate(selectedStatus);
        onClose(); // Close popup after updating
        // Swal.fire("Updated!", "Your changes have been applied.", "success");
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} style={{zIndex:"999"}}>
      <DialogTitle style={{backgroundColor:"#2F2761",color:"white",}}>Apply for posted Job</DialogTitle>
      <DialogContent className="mt-4">
      <FormControl fullWidth>
 
    <InputLabel id="select-placeholder-label" style={{ lineHeight: '60px', marginLeft:'-10px', fontSize: '17px' }}>
      Select a Job
    </InputLabel>
  
  <Select
    labelId="select-placeholder-label"
    label="Select a Job"
    value={selectedStatus}
    onChange={handleChange}
    fullWidth
    displayEmpty
    renderValue={(selected) => selected ? postedJobs.find(job => job.jobId === selected)?.jobTitle : "Select a Job"}
  >
    {postedJobs?.map((status) => (
      <MenuItem key={status.jobId} value={status.jobId}>
        {status.jobTitle}
      </MenuItem>
    ))}
  </Select>
</FormControl>

  {/* <FormControl fullWidth>
  <InputLabel id="select-placeholder-label" style={{lineHeight:'100px',fontSize:'20px'}}>Select a Job</InputLabel>
  <Select
    labelId="select-placeholder-label"
    value={selectedStatus}
    onChange={handleChange}
    displayEmpty
    fullWidth
  >a
    {postedJobs?.map((status) => (
      <MenuItem key={status.jobId} value={status.jobId}>
        {status.jobTitle}
      </MenuItem>
    ))}
  </Select>
</FormControl> */}

</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyJobPopup;
