import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { Container, Row, Col, Form, Card, Toast, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Links, Typography } from "@mui/material";

function ApplyJob() {


  // Get the previous job details page from session storage
  const previousJobDetails = sessionStorage.getItem("previousJobDetails") || "/Joblist";

  const uselocation = useLocation();
  const updatingJob = uselocation.state;
  console.log("updatingJob", updatingJob?.jobId)

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  // const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [Resume, setResume] = useState(null)

  const [formData, setFormData] = useState({
    jobTitle: "",
    experience: "",
    currentCTC: "",
    skills: '',
    // responsibilities: [],
    expectCTC: "",
    coverLetter: "",
    fileName: "",
  });


  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("accessToken");
  const selectedJobId = sessionStorage.getItem("JobId");
  const employerId = sessionStorage.getItem("Postedby");
  const userId = sessionStorage.getItem("userId");
  const jobTitleFromSession = sessionStorage.getItem("JobTitle");

  const apiUrl = process.env.REACT_APP_BASE_URL;

  console.log("jobtitle", jobTitleFromSession)

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  useEffect(() => {

  }, [updatingJob])


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "skills") {
      const updatedValue = value.split(/,\s*/).map(item => item);


      // This ensures the user can type commas and see the input as it is
      setFormData({ ...formData, [name]: updatedValue });
    }
    else if (["currentCTC", "expectCTC"].includes(name)) {
      // Allow only numeric input and prevent values above 100000
      let numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

      if (numericValue !== "" && Number(numericValue) > 100000) {
        // numericValue = "100000"; // Set max limit
        return;
      }

      setFormData({ ...formData, [name]: numericValue });
    }
    else {
      setFormData({ ...formData, [name]: value });
    }
  };




  sessionStorage.setItem('updatingJob', JSON.stringify(updatingJob));



  useEffect(() => {
    if (updatingJob !== null) {
      // Check if updatingJob and its nested properties are valid
      const job = updatingJob?.jobId?.job || {};

      setFormData({
        jobTitle: job?.jobTitle || '',
        experience: updatingJob?.jobId?.experience || '',
        skills: updatingJob?.jobId?.skills || '',
        responsibilities: '', // Set as empty string, can be updated if necessary
        currentCTC: updatingJob?.jobId?.currentCTC || '',
        expectCTC: updatingJob?.jobId?.expectCTC || '',
        coverLetter: updatingJob?.jobId?.coverletter || '',
        fileName: updatingJob?.jobId?.resume || "",
      });
    } else {
      fetchProfile();
    }

    // Optionally log formData to check how it's being set
    console.log('Form Data:', formData);

  }, [updatingJob]);

  console.log("resume", Resume)

  const fetchProfile = async () => {
    try {
      const id = sessionStorage.getItem("userId");
      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      console.log("jobseekeruser", response.data.data);

      if (response.data && response.data.data) {
        const userData = response.data.data;
        setResume(response.data.data?.resume)

        setFormData({
          jobTitle: jobTitleFromSession || '',
          // experience: userData.totalExperience || "",
          skills: userData.skills || '',
          responsibilities: "",
          currentCTC: userData.Currentsalary || '',
          expectCTC: userData.expectedSalary || "",
          coverLetter: userData.coverletter || "",
          fileName: userData.resume || "",
        });

        setFile(response.data.data?.resume)
        setPreview(response.data.data?.resume)
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
    }
  };

  console.log("filename", preview)

  const handleFileUpload = async () => {
    if (!file) return null;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${apiUrl}/shared/file-upload`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUploading(false);

      if (response.status === 200) {
        return response.data.fileName;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      setIsUploading(false);
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "File upload failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return null;
    }
  };

  const handleUpdate = async () => {
    try {
      const uploadedFileName = await handleFileUpload();
      if (!formData.jobTitle || !formData.experience || !formData.skills.length || !formData.currentCTC || !formData.expectCTC) {
        setToastMessage('Please fill all the required fields!');
        // setShowToast(true);
        toast.error("Please fill all the required fields!")
        console.log("if con")
      }
      else {



        const dataToPost = {
          ...formData,
          // fileName: uploadedFileName,
          jobId: selectedJobId,
          jobSeekerId: userId,
          employerId: employerId,
          jobTitle: formData.jobTitle,
          // responsibilities: formData.responsibilities,
        };
        console.log("Data being sent to the API:", dataToPost);

        const response = await axios.post(`${apiUrl}/applications/apply`, dataToPost, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          },
        });


        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Your application has been submitted successfully.",
            icon: "success",
            confirmButtonText: "OK",
            timer: 2000,
          });

          setTimeout(() => {
            navigate("/JobList");
          }, 2000);
        } else {
          throw new Error("Failed to submit application.");
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: err.response?.data?.message || "User alredy exists",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

  };


  const handleDeleteResume = () => {
    setResume(null);
    // document.getElementById("resumeInput").value = ""; // Clear file input
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">

          <Col>
            <EmployerDashboardSideBar />
          </Col>
          <Col md={10} className="main-content">
            <Row>
              {/* Breadcrumbs */}
              <div role="presentation" className="ms-2 pt-3" >
                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                  <Links underline="hover" color="inherit" onClick={() => navigate("/Joblist")} style={{ cursor: "pointer" }}>
                    All Jobs
                  </Links>

                  <Links
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(previousJobDetails)} // Navigate to previous Job Details page
                    style={{ cursor: "pointer" }}
                  >
                    Job Details
                  </Links>

                  <Typography sx={{ color: "#2F2761", fontWeight: 'bold' }}>Apply for Job</Typography>
                </Breadcrumbs>
              </div>

              <Col md={12}>
                <h5 className="mb-3 mt-3 ms-3 fw-bold">Apply for Job</h5>
                <Card className="p-4 rounded-3" >
                  <Row>
                    <Col md={6}>

                      <Form.Group controlId="jobTitle" className="mb-4">
                        <Form.Label>Job Title <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="jobTitle"
                          placeholder="Job Title"
                          value={formData.jobTitle}
                          onChange={handleInputChange}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group controlId="experience" className="mb-4">
                        <Form.Label>
                          Experience <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="experience"
                          value={formData.experience}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value="">Select Experience</option>
                          <option value="Fresher">Fresher</option>
                          <option value="0-2">0-2 years</option>
                          <option value="3-5">3-5 years</option>
                          <option value="6-8">6-8 years</option>
                          <option value="9-10">9-10 years</option>
                          <option value="11-15">11-15 years</option>
                          <option value="16-20">16-20 years</option>
                          <option value="20+">20+ years</option>
                          <option value="Others">Others</option>
                        </Form.Control>
                      </Form.Group>


                      <Form.Group controlId="skills" className="mb-4">
                        <Form.Label >
                          Skills<span className="text-danger" >*</span> <span className="" style={{ fontSize: '13px', color: 'gray' }}>(ex: plumbing,welding)</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="skills"
                          rows={4}
                          placeholder="Enter skills separated by commas"
                          //  value={ formData.skills.join(", ") }
                          value={Array.isArray(formData.skills) ? formData.skills.join(",") : formData.skills}
                          // value={formData.skills} // Update to directly show the value in the textarea
                          onChange={handleInputChange} // Standard change handler to update formData
                        />
                      </Form.Group>


                      <Form.Group controlId="currentCTC" className="mb-4">
                        <Form.Label>Current Salary (Month) <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="text" // Keep text but allow only numbers
                          name="currentCTC"
                          placeholder="Current CTC"
                          value={formData.currentCTC}

                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="expectCTC" className="mb-4">
                        <Form.Label>Expected Salary (Month) <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="expectCTC"
                          placeholder="Expected CTC"
                          value={formData.expectCTC}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group controlId="coverLetter" className="mb-4">
                        <Form.Label>Cover Letter</Form.Label>
                        <Form.Control

                          type="file"
                          name="coverLetter"
                          rows={6}
                          placeholder="Write your cover letter here..."
                          value={formData.coverLetter}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="resume" className="mb-4">
                        <Form.Label>Upload Resume</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleFileChange}
                        />


                        {Resume && (
                          <div className="saved-file d-flex align-items-center mt-3" style={{
                            background: '#E9ECEF', width: '350px', display: 'flex', alignItems: 'center', padding: '5px',
                            borderRadius: '10px', marginLeft: '10px', color: 'black', justifyContent: 'space-between'
                          }}>
                            <p className="mb-0">
                              <strong>Resume:</strong> {Resume.split('_')[1]}
                            </p>
                            <Button
                              style={{ width: '50px', background: "#E9ECEF", border: 'none' }}
                              variant="danger"
                              className="ms-2"
                              size="sm"
                              onClick={handleDeleteResume}
                            >
                              ❌
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>



                  <Link
                    variant="primary"
                    size="md"
                    className="default-btn default-sub-btn btn-md submitapp"
                    onClick={handleUpdate}
                    disabled={isUploading}
                  >
                    {isUploading ? "Uploading..." : "Submit Application"}
                  </Link>




                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ApplyJob;
