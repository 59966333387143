import React, { useState, useEffect } from "react";
import '../../index.css';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import axios from "axios";
import SubscriptionPacks from "./Subscription/EmployerSub";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import toast from 'react-hot-toast';
import CloseIcon from "@mui/icons-material/Close";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";

const EmployerSub = () => {
  const location = useLocation();
  const { Roles } = location.state || {};
  console.log(Roles);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [showSubscription, setShowSubscription] = useState(false); // New state
  const [open, setOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [credits, setCredits] = useState()
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  // const [selectedPlan, setSelectedPlan] = useState(null);
  // const plans = [
  //   { id: 1, name: "Relax", price: "₹2,999", tag: "ON CALL ASSISTANCE", details: ["45 Days Plan Validity", "Best Rental Consultation"] },
  //   { id: 2, name: "Super Relax", price: "₹5,499", tag: "MOST POPULAR", details: ["Top Slot Listing", "Property Promotion"] },
  //   { id: 3, name: "MoneyBack", price: "₹6,499", tag: "100% GUARANTEE", details: ["Guaranteed Tenants", "Privacy of Your Phone"] },
  //   { id: 4, name: "Super MoneyBack", price: "₹9,999", tag: "FASTEST CLOSURE", details: ["Personal Field Assistant", "Showing Property On Your Behalf"] },
  // ];

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state for API call
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
  });

  const handleOpen = () => {
    // if (subscriptionStatus === 'active') {
    setOpen(true);
    // }
  };

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle input change
  const handleInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      console.log("Sending request with:", formData);

      let updatedPhoneNumber = formData.phoneNumber;
      if (!updatedPhoneNumber.startsWith("+91")) {
        updatedPhoneNumber = "+91" + updatedPhoneNumber;
      }

      const updatedFormData = {
        ...formData,
        phoneNumber: updatedPhoneNumber,
        subrole:'enrolment'
      };

      const response = await axios.post(`${apiUrl}/user/employer/subscription`, updatedFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response:", response);

      if (response.status) {
        // alert("Form submitted successfully!");
        setFormData({
          firstName: "",
          surName: "",
          email: "",
          phoneNumber: "",
          companyName: "",
          message: "",
        });
        // Store userId in sessionStorage
        sessionStorage.setItem("userId", response?.data?.data?.userId);
        setOpen(true)

        setShowSubscription(true); // Show Subscription component after success
      } else {
        alert(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      console.log("Request failed:", error);
      // alert("Failed to send data. Please try again.");
      toast.error(error.response?.data.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Page load aana udane top la scroll aagum
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <Col md={12} className="main-content">
            <section className="slider-sec mt-0">
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-12 text-center enrollplan">
                    {/* <h1 style={{ color: '#170F49' }}>Bridging the gap in blue-collar jobs.</h1> */}
                    <h1 style={{ color: '#170F49' }}>Platform Enrolment Plan</h1>
                  </div>
                </div>
                <div className="row g-4 mt-3">
                  <div className="col-lg-8 offset-lg-2">
                    <form className="p-4 shadow bg-white" style={{ borderRadius: '10px' }} onSubmit={handleSubmit}>
                      <h3 className="text-center">Platform Enrolment Form</h3>
                      <div className="row g-4 mt-4">
                        <div className="col-md-6">
                          <label className="form-label ms-1">Name <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">Surname</label>
                          <input
                            type="text"
                            className="form-control"
                            name="surName"
                            value={formData.surName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">Email <span className="text-danger">*</span></label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label ms-1">Phone <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            required
                            maxLength="10"
                            pattern="^[6-9][0-9]{9}$"
                            title="Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits long."
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Company Name <span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        <div className="col-md-12 text-center">
                          <button type="submit" className="default-btn pay" disabled={isLoading} >
                            {isLoading ? <Spinner animation="border" size="sm" /> : "Enroll & Pay"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>

      {/* {subscriptionStatus? (
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" style={{ borderRadius: "10px" }}>
            <DialogContent style={{ backgroundColor: "#f5f6fa" }}>
              <IconButton onClick={handleClose} size="small"
                style={{
                  position: "absolute",
                  width: "40px",
                  top: "10px",
                  right: "20px",
                  zIndex: 10,
                }}>
                <CloseIcon />
              </IconButton>
              <SubscriptionPacks />
            </DialogContent>
          </Dialog>
        ) : ''} */}


      <div>
        {/* Subscription Popup */}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogContent style={{ backgroundColor: "#f5f6fa" }}>
            <IconButton onClick={handleClose} size="small"
              style={{
                position: "absolute",
                width: "40px",
                top: "10px",
                right: "20px",
                zIndex: 10,
              }}>
              <CloseIcon />
            </IconButton>
            <SubscriptionPacks />
          </DialogContent>
        </Dialog>
      </div>

    </div>
  );
};

export default EmployerSub;

