import React, { useState, useEffect } from "react";
import { FaStar, FaSuitcase, FaRupeeSign, FaMapMarkerAlt, FaBook } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import nojobs from '../../assets/unemployment.png'
const JobSearch = () => {
  const location = useLocation();  // useLocation hook to access query params
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { locations } = location.state || {};  // Extract location and skills from state
  const { filters } = location.state || {};
  console.log("Locations:", locations, "filters:", filters);

  const apiUrl = process.env.REACT_APP_BASE_URL;  // Ensure this environment variable is set

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      setError(null);  // Reset error state before fetching

      try {
        // Send 'filter' and 'location' as query parameters to match the backend
        const response = await axios.get(`${apiUrl}/job/joblist/filter`, {
          params: { filter: filters, location: locations },  // Correctly map 'skills' to 'filter' and 'locations' to 'location'
        });
        setJobs(response.data.data);  // Store jobs data in state
      } catch (err) {
        console.error("Error fetching jobs:", err);
        setError("Error fetching job listings.");
      } finally {
        setLoading(false);  // Set loading to false once the request is complete
      }
    };

    if (filters || locations) {
      fetchJobs();  // Only fetch if both skills and locations are defined
    }
  }, [filters, locations, apiUrl]);  // Effect runs when skills or locations change

  if (loading) {
    return <div>Loading...</div>;  // Show loading text while waiting for data
  }

  if (error) {
    return <div>{error}</div>;  // Show error if there's an issue with the request
  }

  return (
    <div className="job-listings">
      <EmployerDashboardHeader />
      {jobs.length > 0 ? (
        jobs.map((job) => (
          <div key={job.jobId} className="col-lg-3">
            <div className="list-block" style={{ margin: "25px 25px", width: "95vw" }}>
              <div className="list-title">
                <h5 className="fw-bold mb-2">{job.jobTitle}</h5>
                <p className="m-0 f-14 d-flex">
                  {job.companyName || "Company Name"}{" "}
                  <span className="ms-3 me-1 rev-icon">
                    <FaStar />
                  </span>
                  <span className="f-13">1020 Reviews</span>
                </p>
              </div>
              <div className="border-bottom my-2"></div>
              <p className="list-desc mb-1">
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaSuitcase />
                  <span>
                    {/* {job.experience} */}
                    {job.minExperience && job.maxExperience
                      ? `${job.minExperience}-${job.maxExperience} years`
                      : job.experience || "-"}

                  </span>
                </span>
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaRupeeSign />
                  <span>{job.salary}</span>
                </span>
                <span className="f-14 d-flex align-items-center gap-2">
                  <FaMapMarkerAlt />
                  <span>{job.location}</span>
                </span>
                <span className="f-14 d-flex gap-2 mt-3">
                  <FaBook />
                  <span>{job.responsibilitiesAndDuties || "No further details available"}</span>
                </span>
              </p>
            </div>
          </div>
        ))
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          width: "100%",
          maxWidth: "500px",
          margin: "20px auto",
          marginTop: '50px',
          marginBottom: '50px',
        }}>
          <img
            src={nojobs}
            alt="No Jobs"
            style={{ width: "120px", marginBottom: "10px", opacity: 0.8 }}
          />
          <h3 style={{ color: "#555", marginBottom: "10px" }}>
            No job listings available
          </h3>
          <p style={{ color: "#777", fontSize: "14px" }}>
            Stay tuned! New opportunities will be posted soon.
          </p>

        </div>

      )}
    </div>
  );
};

export default JobSearch;
