import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-hot-toast";
import "../../Styles/TableStyles.css";
import { useLocation } from "react-router-dom";
import StatusPopup from "./StatusPopup";
import { Container, Col, Row } from "react-bootstrap";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
// import { getMessaging, getToken } from "firebase/messaging";
// import { toast } from "react-toastify";
// import { requestPermission } from "../../notification/firebase-config.js"; // Helper function to send notifications
import { Box, TextField } from "@mui/material";

const statusColors = {
  APPLIED: "primary",
  INTERVIEW_SCHEDULED: "secondary",
  SHORTLISTED: "warning",
  REJECTED: "error",
  HIRED: "success",
};

export default function ApplicantsTable() {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const location = useLocation();
  const applicantFromState = location.state?.applicant;
  const employerId = sessionStorage.getItem("userId");
  const userId = sessionStorage.getItem("userId");
  const [selectedApplicants, setSelectedApplicants] = useState([]);


  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    experience: "",
    skills: "",
    company: "",
    NativeLocation: "",
  });

  // Function to filter rows based on user input
  const filteredRows = rows.filter((row) =>
    Object.keys(filters).every((key) =>
      filters[key] ? String(row[key]).toLowerCase().includes(filters[key].toLowerCase()) : true
    )
  );

  console.log("app:", applicantFromState);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleUpdateStatus = (newStatus) => {
    console.log("Updating status to:", newStatus);
    console.log("selecteddtata00",selectedRows);
    console.log("all000",rows);

    // setRows((prevRows) =>
    //   prevRows.map((row) =>
    //     selectedRows.includes(row.id) ? { ...row, status: newStatus, jobSeekerId: row.id, fcmToken:row.fcmToken } : row
    //   )
    // );
    const updatedSelectedRows = selectedRows.map((selectedRowId) => {
      const matchingRow = rows.find((row) => row.id === selectedRowId); // Use 'rows' instead of 'prevRows' if it's a state variable
      return {
        id: selectedRowId,
        fcmToken: matchingRow?.fcmToken || "", // Assign fcmToken if found, else empty string
      };
    });
    
    setRows((prevRows) =>
      prevRows.map((row) =>
        selectedRows.includes(row.id)
          ? {
              ...row,
              status: newStatus,
              jobSeekerId: row.id,
              fcmToken: updatedSelectedRows.find((item) => item.id === row.id)?.fcmToken || row.fcmToken || "",
            }
          : row
      )
    );
    
    // Pass `updatedSelectedRows` to `ApplyJob`
    ApplyJob(newStatus, updatedSelectedRows);
    
    console.log("selecteddtata",selectedRows);
    console.log("all",rows);
    
    setSelectedRows([]); // Clear selection after update
  };

  useEffect(() => {
    fetchApplicants();
  }, [apiUrl, applicantFromState]);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      if (applicantFromState) {
        const response = await axios.get(
          `${apiUrl}/applications/job/${applicantFromState?.jobId}`,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data?.data && Array.isArray(response.data.data)) {
          const transformedData = response.data.data.map((applicant) => ({
            id: applicant.jobSeeker.userId,
            firstName: applicant.jobSeeker.firstName,
            lastName: applicant.jobSeeker.lastName,
            skills: applicant.jobSeeker.skills.join(", "),
            phone: applicant.jobSeeker.phoneNumber,
            experience: applicant.jobSeeker.totalExperience || "N/A",
            expectedSalary:applicant.jobSeeker.expectedSalary != null
              ? `₹${applicant.jobSeeker.expectedSalary}`
              : "N/A",
            email: applicant.jobSeeker.email || "N/A",
            status: applicant.status,
            fcmToken:applicant.jobSeeker.fcmToken || "",
         
          }));

          setRows(transformedData);
        } else {
          toast.error("Expected data to be an array");
        }
      } else {
        toast.error("Applicant data not available");
      }
    } catch (err) {
      toast.error(err?.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };

  console.log("row",rows);
  
//   useEffect(() => {
//     fetchApplicants();
//   }, [apiUrl, applicantFromState]);

//   const fetchApplicants = async () => {
//     try {
//       const token = sessionStorage.getItem("accessToken");
//       if (!token) {
//         throw new Error("Authentication token is missing");
//       }

//       if (applicantFromState) {
//         const response = await axios.get(
//           `${apiUrl}/applications/job/${applicantFromState?.jobId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response?.data?.data && Array.isArray(response.data.data)) {
//           setRows(
//             response.data.data.map((applicant) => ({
//               id: applicant.jobSeeker.userId,
//               firstName: applicant.jobSeeker.firstName,
//               lastName: applicant.jobSeeker.lastName,
//               skills: applicant.jobSeeker.skills.join(", "),
//               phone: applicant.jobSeeker.phoneNumber,
//               experience: applicant.jobSeeker.totalExperience,
//               expectedSalary: `₹${applicant.jobSeeker.expectedSalary}`,
//               email: applicant.jobSeeker.email,
//               status: applicant.status,
//             }))
//           );
//         } else {
//           toast.error("Expected data to be an array");
//         }
//       } else {
//         toast.error("Applicant data not available");
//       }
//     } catch (err) {
//       toast.error(err?.response?.data?.error || err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

  const toggleRowSelection = (applicantData, id, applicantStatus) => {
    setSelectedApplicants((prev) => {
      const isSelected = prev.some((applicant) => applicant.jobSeekerId === id);
      return isSelected
        ? prev.filter((applicant) => applicant.jobSeekerId !== id)
        : [
            ...prev,
            {
              jobSeekerId: id,
              status: applicantStatus || "APPLIED",
              jobId: applicantData.jobSeeker.jobId || "",
              employerId: applicantData.jobSeeker.postedById || "",
              experience: applicantData.jobSeeker.experience || "",
              coverletter: applicantData.jobSeeker.coverletter || "",
              skills: applicantData.jobSeeker.skills || [],
              responsibility: applicantData.jobSeeker.responsibilities || [],
              resume: applicantData.jobSeeker.resume || "",
              currentCTC: applicantData.jobSeeker.salary || "N/A",
              expectCTC: applicantData.jobSeeker.expectedSalary || "N/A",
              email: applicantData.jobSeeker.email || "",
              fcmToken:applicantData.fcmToken || ""
            },
          ];
    });
  };

  console.log("applicantFromState",applicantFromState);
  
  const ApplyJob = async (ApplicantStatus,SelectedData) => {
    setLoading(true);
    const selectedApplicants = SelectedData;
    setSelectedApplicants(SelectedData)
    try {
      if (selectedApplicants.length === 0) {
        toast.error("Please select at least one applicant.");
        return;
      }
console.log("slected",selectedApplicants);

      const dataToPost = {
        jobId: applicantFromState.jobId,
        employerId: applicantFromState.postedById,
        createdById:userId,
        jobSeekerApplications: selectedApplicants.map((applicant) => ({
          // jobSeekerId: applicant.jobSeekerId,
          jobSeekerId: applicant.id,
          status: ApplicantStatus,
          // jobId: applicant.jobId || "",
          employerId: employerId || "",
          fcmToken:applicant.fcmToken || ""
          // experience: applicant.experience || "",
          // coverletter: applicant.coverletter || "",
          // skills: applicant.skills || [],
          // responsibility: applicant.responsibilities || [],
          // resume: applicant.resume || "",
          // currentCTC: applicant.salary || "",
          // expectCTC: applicant.expectedSalary || "",
          // email: applicant.email || "",
        })),
      };
      console.log("Payload Sent:", JSON.stringify(dataToPost, null, 2));
      const token = sessionStorage.getItem("accessToken");
      const response = await axios.patch(
        `${apiUrl}/applications/applymultiple/status`,
        dataToPost,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );


      if (response.data.status) {
        fetchApplicants();
        toast.success(
          response?.data?.message ?? "Applicants status changed successfully."
        );
        setPopupOpen(false);
      } else {
        toast.error(
          response?.data?.message ?? "Failed to change applicants status."
        );
      }
      setLoading(false);
    }catch (error) {
      console.error("Error Response:", error?.response?.data);
      toast.error(error?.response?.data?.message || "Error applying for jobs");
      setLoading(false);
    }
  };


// const ApplyJob = async (ApplicantStatus, SelectedData) => {
//   setLoading(true);
//   const selectedApplicants = SelectedData;
//   setSelectedApplicants(SelectedData);

//   try {
//     if (selectedApplicants.length === 0) {
//       toast.error("Please select at least one applicant.");
//       return;
//     }

//     console.log("Selected Applicants:", selectedApplicants);

//     // Prepare the data to send for job application status update
//     const dataToPost = {
//       jobId: applicantFromState.jobId,
//       employerId: applicantFromState.postedById,
//       createdById: userId,
//       jobSeekerApplications: selectedApplicants.map((applicant) => ({
//         jobSeekerId: applicant.id,
//         status: ApplicantStatus,
//         employerId: employerId || "",
//       })),
//     };

//     console.log("Payload Sent:", JSON.stringify(dataToPost, null, 2));

//     const token = sessionStorage.getItem("accessToken");

//     // Update applicant status via PATCH request
//     const response = await axios.patch(
//       `${apiUrl}/applications/applymultiple/status`,
//       dataToPost,
//       {
//         headers: {
//           Authorization: ` ${token}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     if (response.data.status) {
//       // **Fix: Ensure only one notification request is sent**
//       const uniqueFcmTokens = [
//         ...new Set(selectedApplicants.map((applicant) => applicant.fcmToken).filter(Boolean))
//       ]; 

//       if (uniqueFcmTokens.length > 0) {
//         const notificationData = {
//           fcmTokens: uniqueFcmTokens, // Send only unique tokens
//           title: `Job Application Status Updated`,
//           body: `Your application status has been changed to ${ApplicantStatus}.`,
//         };

//         // Send a single notification request
//         const notificationResponse = await axios.post(
//           `${apiUrl}/notifications/send-multiple`,
//           notificationData,
//           {
//             headers: {
//               Authorization: ` ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (notificationResponse.data.status) {
//           console.log('Notifications sent successfully!');
//         } else {
//           console.error('Error sending notifications:', notificationResponse.data.message);
//         }
//       }

//       // Refresh applicant data and show success message
//       fetchApplicants();
//       toast.success(response?.data?.message ?? "Applicants' status changed successfully.");
//       setPopupOpen(false);
//     } else {
//       toast.error(response?.data?.message ?? "Failed to change applicants' status.");
//     }
    
//     setLoading(false);
//   } catch (error) {
//     console.error("Error Response:", error?.response?.data);
//     toast.error(error?.response?.data?.message || "Error applying for jobs");
//     setLoading(false);
//   }
// };


  const handleSelectionChange = (selectionModel) => {
    
    setSelectedRows(selectionModel);
    console.log("selectionModel",selectionModel)
  };

  const updateStatus = () => {
    if (selectedRows.length === 0) {
      toast.error("No rows selected!");
      return;
    }
    handleOpenPopup();
  };

 
  

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "skills", headerName: "Skills", flex: 1 },
    { field: "phone", headerName: "Mobilee", flex: 1 },
    { field: "experience", headerName: "Experience", flex: 1 },
    { field: "expectedSalary", headerName: "Expected Salary", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          className={`status-badge ${statusColors[params.value] || "default"}`}
        >
          {params.value}
        </span>
      ),
    },
  ];

  return (
    <div className="" style={{ height: "auto", width: "100%" }}>
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row>
          <Col>
          <EmployerDashboardSideBar />
          </Col>
          <Col
            md={10}
            className="main-content mt-1"
            style={{ overflow: "visible !important" }}
          >
            {loading ? (
              <div className="loading-container">
                <CircularProgress />
                <p>Loading applicants...</p>
              </div>
            ) : (
              <>
                <h4 className="m-2 bold pt-3"><span style={{color:'#170F49',fontSize:'28px'}}>{applicantFromState?.jobTitle}</span>  Applicant Table</h4>

                <h3></h3>
                <div className="action-buttons">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={updateStatus}
                    disabled={!selectedRows.length}
                  >
                    Action
                  </Button>
                </div>

                {/* <DataGrid
                  rows={rows}
                  columns={columns}
                  style={{height:"auto",margin:"10px"}}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleSelectionChange}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{ toolbar: { showQuickFilter: true , printOptions: { disableToolbarButton: true }, 
                  csvOptions: { disableToolbarButton: true } } }}
                  disableColumnMenu={false}
                  disableColumnFilter={false}
                  disableColumnSelector={false}
                  componentsProps={{
                    columnMenu: {
                      disablePortal: false,
                    },
                  }}
                  pagination
                  pageSizeOptions={[10, 25, 50, 100]} // Page size options
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 }, // Set default page size to 25
                    },
                  }}
                  sx={{
                    "& .MuiDataGrid-footerContainer": {
                      justifyContent: "center", // Align pagination to the center
                    },
                  }}
                /> */}

<Box style={{ height: "auto", margin: "10px", background: "white" }}>
      {/* Filter Inputs */}
      <div className="px-3 pt-3 fs-4">Filters</div>
      <Box display="flex" gap={1} padding={1}>
        {["firstName", "lastName", "experience", "skills"].map((field) => (
          <TextField
            key={field}
            label={columns.find((col) => col.field === field)?.headerName}
            variant="outlined"
            size="small"
            onChange={(e) => setFilters({ ...filters, [field]: e.target.value })}
            sx={{ flex: 1 }}
          />
        ))}
      </Box>

      {/* DataGrid with Filters Applied */}
      <DataGrid
        rows={filteredRows} // Apply filtered rows
        columns={columns}
        style={{ height: "auto", margin: "10px" }}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        disableColumnMenu={false}
        disableColumnFilter={false}
        disableColumnSelector={false}
        componentsProps={{
          columnMenu: { disablePortal: false },
        }}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        sx={{
          "& .MuiDataGrid-footerContainer": {
            justifyContent: "center",
          },
        }}
      />
    </Box>

                {popupOpen && (
                  <StatusPopup
                    open={popupOpen}
                    onClose={handleClosePopup}
                    onUpdate={handleUpdateStatus}
                    currentStatus={rows.find(
                      (row) => row.id === selectedRows[0]
                    )?.status}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
