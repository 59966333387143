import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Card } from 'react-bootstrap';
import { FaBriefcase, FaClipboardList, FaUsers, FaChartLine } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import Bottom from './Home/bottom';
import '../Styles/Homepage.css'

const JobseekerDashboard = () => {
  const [, setJobData] = useState([]);
  const [, setLoading] = useState(true);
  const [, setError] = useState(null);
  const employerid = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicationjoblistData, setApplicationjoblistData] = useState([])
  const [joblistPostedData, setjoblistPostedData] = useState([])

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/job/posted/${employerid}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setJobData(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [apiUrl, employerid]);

  useEffect(() => {
    const applicationlist = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }
        const response = await axios.get(`${apiUrl}/applications/jobseekerDashboard/${employerid}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });


        console.log("res", response.data.data);



        setApplicationjoblistData(response.data.data);
        setjoblistPostedData(response.data.data.jobListings);
        console.log("bzjb", applicationjoblistData)
        console.log("data", joblistPostedData)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    applicationlist();
  }, [employerid, apiUrl, applicationjoblistData, joblistPostedData]);




  const statusColors = {
    APPLIED: "primary",
    INTERVIEW_SCHEDULED: "secondary",
    SHORTLISTED: "warning",
    REJECTED: "danger",
    HIRED: "success",
  };

  return (
    <div className="dashboard">

      < EmployerDashboardHeader />



      <Container fluid className="content">
        <Row className='g-4'>

          <Col className='side'>
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="dash">
            <Row className='g-4 mt-2'>
              <Col md={3} sm={6}>
                <Card className="custom-card ">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga"> Applied Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{applicationjoblistData.totalApplications}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-violet avatar-rounded"><FaClipboardList size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Shortlisted </span>
                        <h3 class="fw-semibold lh-1 mb-0">{applicationjoblistData.shortlistedJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-ora avatar-rounded"><FaBriefcase size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Interview Scheduled</span>
                        <h3 class="fw-semibold lh-1 mb-0">{applicationjoblistData.interviewSchedule}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-green avatar-rounded"><FaUsers size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Selected & Offered</span>
                        <h3 class="fw-semibold lh-1 mb-0">{applicationjoblistData.hiredJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-blue avatar-rounded"><FaChartLine size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h5 className='mb-3 fw-bold mx-4'>AppliedJob Listing</h5>
                <div className='card border-0 rounded-0'>
                  <div className='card-body table-responsive'>
                    <Table hover className="table" >
                      <thead>
                        <tr className="tablehead text-center">
                          <th>Job Title</th>
                          <th>Company Name</th>
                          <th>₹ Salary/Month</th>
                          <th>Status</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {joblistPostedData.map((job) => (
                          <tr key={job.id} className="tbodys text-center">
                            <td>{job.jobTitle}</td>
                            <td>{job.industry}</td>
                            <td>₹ {job.salary}</td>
                            {/* <td>{job.applications}</td> */}
                            <td>

                              <span
                                className={`badge bg-${statusColors[job.applicationStatus] || job.applicationStatus ||
                                  "primary"
                                  }`}
                              >
                                {job.applicationStatus ?? "APPLIED"}
                              </span>
                            </td>


                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Col className="bottomNavigate">
        <Bottom />
      </Col>
    </div>
  );
};

export default JobseekerDashboard;
