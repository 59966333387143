import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation to get passed state
import { toast } from "react-hot-toast";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";

import { Container, Col, Row, Table, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import "../Styles/List.css";


const statusColors = {
  APPLIED: "secondary",
  INTERVIEW_SCHEDULED: "warning",
  SHORTLISTED: "success",
  REJECTED: "danger",
  HIRED: "primary",
};

const List = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [applicants, setApplicants] = useState([]); // Store applicants here
  const [selectedApplicants, setSelectedApplicants] = useState([]); // Store selected applicants with their status
  const location = useLocation(); // Get the location object, which contains state
  const applicantFromState = location.state?.applicant; // Extract the applicant data from state
  const employerId = sessionStorage.getItem("userId");

  useEffect(() => {
    fetchApplicants();
  }, [apiUrl, applicantFromState]);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      if (applicantFromState) {
        const response = await axios.get(
          `${apiUrl}/applications/job/${applicantFromState?.jobId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data?.data && Array.isArray(response.data.data)) {
          setApplicants(response.data.data); // Set the applicants' data
        } else {
          toast.error("Expected data to be an array");
        }
      } else {
        toast.error("Applicant data not available");
      }
    } catch (err) {
      // toast.error(err?.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };

  // Toggle applicant selection with status
  const toggleRowSelection = (applicantData, id, applicantStatus) => {
    console.log("appicatsData", applicantData);

    setSelectedApplicants((prev) => {
      const isSelected = prev.some((applicant) => applicant.jobSeekerId === id);

      if (isSelected) {
        // If selected, remove them from the list
        return prev.filter((applicant) => applicant.jobSeekerId !== id);
      } else {
        // If not selected, add them with status
        return [
          ...prev,
          {
            jobSeekerId: id,
            status: applicantStatus || "APPLIED", // Default to "APPLIED" status
            jobId: applicantData.jobSeeker.jobId || "",
            employerId: applicantData.jobSeeker.postedById || "",
            experience: applicantData.jobSeeker.experience || "",
            coverletter: applicantData.jobSeeker.coverletter || "",
            skills: applicantData.jobSeeker.skills || [],
            responsibility: applicantData.jobSeeker.responsibilities || [],
            resume: applicantData.jobSeeker.resume || "",
            currentCTC: applicantData.jobSeeker.salary || "",
            expectCTC: applicantData.jobSeeker.expectedSalary || "",
            email: applicantData.jobSeeker.email || "",
          },
        ];
      }
    });
  };

  // Apply selected applicants
  const ApplyJob = async (ApplicantStatus) => {
    setLoading(true);
    try {
      // If no applicants are selected, show an alert
      if (selectedApplicants.length === 0) {
        toast.error("Please select at least one applicant.");
        return;
      }

      const dataToPost = {
        jobId: applicantFromState.jobId,
        employerId: applicantFromState.postedById,
        jobSeekerApplications: selectedApplicants.map((applicant) => ({
          jobSeekerId: applicant.jobSeekerId,
          status: ApplicantStatus, // Include the status of each selected applicant
          jobId: applicant.jobId || "",
          employerId: employerId || "",
          experience: applicant.experience || "",
          coverletter: applicant.coverletter || "",
          skills: applicant.skills || [],
          responsibility: applicant.responsibilities || [],
          resume: applicant.resume || "",
          currentCTC: applicant.salary || "",
          expectCTC: applicant.expectedSalary || "",
          email: applicant.email || "",
        })),
      };

      // Send the data to the backend
      const token = sessionStorage.getItem("accessToken");
      const response = await axios.post(
        `${apiUrl}/applications/applymultiple/status`,
        dataToPost,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.status) {
        fetchApplicants();
        toast.success(
          response?.data?.message ?? "Applicants status changed successfully."
        );
      } else {
        toast.error(
          response?.data?.message ?? "Failed to change applicants status."
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error applying for jobs");
      setLoading(false);
    }
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="">
          <EmployerDashboardSideBar />
          <Col md={10} className="main-content">
            <div className="card border-0 rounded-0">
              <div className="card-body table-responsive">
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <div>
                    <div className="apply-status-btn">

                    
                    <Button
                        className="btn btn-secondary"
                        onClick={() => {
                          setSelectedApplicants((prev) =>
                            prev.map((applicant) => ({
                              ...applicant,
                              status: "APPLIED",
                            }))
                          );
                          ApplyJob("APPLIED");
                        }}
                        disabled={selectedApplicants.length === 0}
                      >
                        Applied
                      </Button>
                      <Button
                        className="btn btn-warning"
                        style={{color:"white"}}
                        onClick={() => {
                          setSelectedApplicants((prev) =>
                            prev.map((applicant) => ({
                              ...applicant,
                              status: "INTERVIEW_SCHEDULED",
                            }))
                          );
                          ApplyJob("INTERVIEW_SCHEDULED");
                        }}
                        disabled={selectedApplicants.length === 0}
                      >
                        Under Review
                      </Button>
                      <Button
                        className="btn btn-success"
                        onClick={() => {
                          setSelectedApplicants((prev) =>
                            prev.map((applicant) => ({
                              ...applicant,
                              status: "SHORTLISTED",
                            }))
                          );
                          ApplyJob("SHORTLISTED");
                        }}
                        disabled={selectedApplicants.length === 0}
                      >
                        Shortlisted
                      </Button>

                      

                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          setSelectedApplicants((prev) =>
                            prev.map((applicant) => ({
                              ...applicant,
                              status: "HIRED",
                            }))
                          );
                          ApplyJob("HIRED");
                        }}
                        disabled={selectedApplicants.length === 0}
                      >
                        Selected & Offered
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          setSelectedApplicants((prev) =>
                            prev.map((applicant) => ({
                              ...applicant,
                              status: "REJECTED",
                            }))
                          );
                          ApplyJob("REJECTED");
                        }}
                        disabled={selectedApplicants.length === 0}
                      >
                        Rejected
                      </Button>

                      
                    </div>
                    <Table hover className="table">
                      <thead>
                        <tr className="tablehead text-center">
                          <th>Select</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Skills</th>
                          <th>Phone Number</th>
                          <th>Experience</th>
                          <th>Expected Salary</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicants.length === 0 ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              <strong>No applicants found</strong>
                            </td>
                          </tr>
                        ) : (
                          applicants.map((applicant) => (
                            <tr
                              key={applicant.jobid}
                              style={{ textAlign: "center" }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedApplicants.some(
                                    (item) =>
                                      item.jobSeekerId ===
                                      applicant.jobSeeker.userId
                                  )}
                                  onChange={() =>
                                    toggleRowSelection(
                                      applicant,
                                      applicant.jobSeeker.userId,
                                      applicant.status
                                    )
                                  }
                                />
                              </td>
                              <td>{applicant.jobSeeker?.firstName}</td>
                              <td>{applicant.jobSeeker?.lastName}</td>
                              <td>{applicant.jobSeeker?.skills.join(", ")}</td>
                              <td>{applicant.jobSeeker?.phoneNumber}</td>
                              <td>{applicant.experience}</td>
                              <td>₹ {applicant.jobSeeker.expectedSalary}</td>
                              <td>{applicant.jobSeeker.email}</td>
                              {/* <td>{applicant?.status}</td> */}
                              <td>
                                <span
                                  className={`badge bg-${
                                    statusColors[applicant?.status] ||
                                    "secondary"
                                  }`}
                                >
                                  {applicant?.status?.replace("_", " ")}
                                </span>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                    
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default List;
