import { Col, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'

import '../Styles/EmployerDashboardSidebar.css'
import home from '../assets/sidebaricons/home.avif'
import postedjobs from '../assets/sidebaricons/postedjobs.jpg'
import post1jobs from '../assets/sidebaricons/post1jobs.avif'
import findjobs from '../assets/sidebaricons/findjob.avif'
import alljobs from '../assets/sidebaricons/alljobs.avif'
import applicants from '../assets/sidebaricons/crowd-icon.png'
import subscribe from '../assets/renewal.png'
import enrollment from '../assets/computer-worker.png';

import { useState } from 'react';

const EmployerDashboardSideBar = () => {
  const userRole = sessionStorage.getItem('role');
  const navigate = useNavigate()
  const [activeLink, setActiveLink] = useState(''); // Track the active link


  console.log(activeLink)
  const renderSidebarContent = () => {
    switch (userRole) {
      case "employer":
        return (
          <Nav className="flex-column">
            <div className="sidebar-item sidebar-itemss">
              <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>

                <li style={{ marginBottom: '15px', alignItems: 'center', marginBottom: '10px' }}>
                  <NavLink
                    to="/"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker`}
                  >
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginLeft: '10px' }} />

                    <p>Home</p>
                  </NavLink>
                </li>

                <li style={{ marginBottom: '15px', alignItems: 'center', marginBottom: '10px' }}>
                  <NavLink
                    to="/dashboard"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker`}
                  >
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginLeft: '10px' }} />

                    <p>Dashboard</p>
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/employerProfileView" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Profile
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/jobDescriptionForm" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={post1jobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Post Job
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: '', marginBottom: '15px' }}>
                  <NavLink
                    to="/applicants"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker `}

                  >
                    <img src={postedjobs} className="rotating-icon" height="25px" alt="Posted Jobs" style={{ marginLeft: '10px' }} />
                    Posted Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobs
                  </NavLink>
                </li>

                {/* <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/AllCandidates" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobseekers
                  </NavLink>
                </li> */}

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/allJobSeekerTable" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobseekers
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/appliedApplicants" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={applicants} className='rotating-icon' height="15px" alt="Home" style={{ marginRight: '10px', marginLeft: '15px' }} />
                    All Applicants
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/subscription-model" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={subscribe} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Subscription
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/contact" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Contact
                  </NavLink>
                </li>


              </ul>

            </div>

          </Nav>
          // </Col>
        );
      case "superadmin":
        return (

         

          <Nav className="flex-column">
            <div className="sidebar-item sidebar-itemss">
              <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>

                <li style={{ marginBottom: '15px', alignItems: 'center', marginBottom: '10px' }}>
                  <NavLink
                    to="/admin-dashboard"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker`}
                  >
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginLeft: '10px' }} />

                    <p>Dashboard</p>
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/employerTable" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Employer
                  </NavLink>
                </li>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/enrollment" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={enrollment} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Enrollments
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/applyJobAllJobSeeker" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Jobseeker
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: '', marginBottom: '15px' }}>
                  <NavLink
                    to="/Joblist"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker `}>
                    <img src={postedjobs} className="rotating-icon" height="25px" alt="Posted Jobs" style={{ marginLeft: '10px' }} />
                    All Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/jobDescriptionForm" className="nav text-decoration-none jobtypes-side namesjobseeker" activeClassName="active-link">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Post job
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: '', marginBottom: '15px' }}>
                  <NavLink
                    to="/applicants"
                    className={`nav text-decoration-none jobtypes-side namesjobseeker `}>
                    <img src={postedjobs} className="rotating-icon" height="25px" alt="Posted Jobs" style={{ marginLeft: '10px' }} />
                    Posted jobs
                  </NavLink>
                </li>
              </ul>

            </div>

          </Nav>

          

        );
      case "admin":
        return (
          

          <Nav>
            <div className="sidebar-item sidebar-itemss">
              <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/" className="nav text-decoration-none jobtypes-side namesjobseeker ">
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Home
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/admin-dashboard" className="nav text-decoration-none jobtypes-side namesjobseeker ">
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Dashboard
                  </NavLink>
                </li>

               
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/employerTable" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Employer
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/CallcenterJobDescriptionForm" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Post Job
                  </NavLink>
                </li>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/callcenterApplicant" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Posted Job
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/appliedApplicants" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Applicants
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/applyJobAllJobSeeker" onClick={() => handleNavClick} className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobseeker
                  </NavLink>
                </li>

               

              </ul>



            </div>

          </Nav>
       


        );
      case "callcenter":
        return (
          
          <Nav>
            <div className="sidebar-item sidebar-itemss">
              <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/admin-dashboard" className="nav text-decoration-none jobtypes-side namesjobseeker ">
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Dashboard
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/employerTable" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Employer
                  </NavLink>
                </li>


                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/CallcenterJobDescriptionForm" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={post1jobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Post job
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/callcenterApplicant" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Posted job
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/applyJobAllJobSeeker" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobseeker
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/appliedApplicants" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={applicants} className='rotating-icon' height="15px" alt="Home" style={{ marginRight: '10px', marginLeft: '15px' }} />
                    All Applicants
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/reports" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Reports
                  </NavLink>
                </li>
              </ul>
            </div>

          </Nav>
        );
      default:
        return (

          <Nav>
            <div className="sidebar-item sidebar-itemss">
              <ul style={{ display: 'flex', flexDirection: 'column', padding: 0, listStyleType: 'none', margin: 0 }}>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/" className="nav text-decoration-none jobtypes-side namesjobseeker ">
                    <img src={home} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Home
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/jobseekerprofile" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Profile
                  </NavLink>
                </li>


                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/appliedjob" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={alljobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Applied Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/Joblist" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={findjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    All Jobs
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/jobseekerDashboard" className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Dashboard
                  </NavLink>
                </li>

                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <NavLink to="/contact" onClick={() => handleNavClick()} className="nav text-decoration-none jobtypes-side namesjobseeker">
                    <img src={postedjobs} className='rotating-icon' height="25px" alt="Home" style={{ marginRight: '10px' }} />
                    Contact
                  </NavLink>
                </li>
              </ul>

            </div>

          </Nav>
        );
    }
  };


  const handleNavClick = () => {
    navigate("/contact", { state: { Roles: userRole } });
  }

  return (
    <>

      <Col md={2} className="sidebar">
        
        {renderSidebarContent()}
      </Col>

          </>
  );
};

export default EmployerDashboardSideBar;
