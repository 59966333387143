import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../EmployerDashboard.css';
import axios from 'axios';
import { Dialog, DialogContent, IconButton } from "@mui/material";
import SubscriptionPacks from '../../pages/Employee/Subscription/SubscriptionPacks';
import CloseIcon from "@mui/icons-material/Close";
import EmployerDashboardSideBar from '../../components/EmployerDashboardSideBar';


const AllAdminList = () => {
  
  const [, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/user/roles/admin`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });
        //  console.log(response.data.data);

        if (Array.isArray(response?.data?.data)) {
          setApplicants(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [apiUrl]);


  const handleViewClick = (applicant) => {
    handleOpen()
    // {
    //   !open ?
    //   <></> :
    //   setShowDetailsModal(true);
    // }

    if (open) {
      setShowDetailsModal(true);
    }
    setSelectedApplicant(applicant);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <Row>
        <Col><EmployerDashboardSideBar />
        </Col>
        <Col md={10}>
          <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Admin Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedApplicant && (
                <div>
                  <p><strong>Name:</strong> {selectedApplicant.firstName} {selectedApplicant.lastName}</p>
                  <p><strong>Email:</strong> {selectedApplicant.emailAddress}</p>
                  <p><strong>Phone:</strong> {selectedApplicant.phoneNumber}</p>
                  <p><strong>Address:</strong> {selectedApplicant.address}</p>
                  <p><strong>Location:</strong> {selectedApplicant.location}</p>

                  <p><strong>Experience:</strong> {selectedApplicant.experience}</p>
                  <p><strong>Status:</strong> {selectedApplicant.status}</p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>


          {/* testing for subscription todo */}
          {/* <div> */}
          {/* Button to Open Modal */}
          {/* <Button variant="contained" color="primary" onClick={handleOpen}>
                               Subscription
                             </Button> */}

          {/* Dialog to Display MyComponent */}
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" style={{ borderRadius: "10px" }}>
            {/* <DialogTitle style={{backgroundColor:"white"}}>
                                 <span style={{ display: "flex", justifyContent: "space-between",fontSize:"20px", alignItems: "center" }}>
                                   <span>Subscription Modal</span>
                              
                                   <IconButton onClick={handleClose} size="small">
                                     <CloseIcon />
                                   </IconButton>
                                 </span>
                               </DialogTitle> */}
            <DialogContent style={{ backgroundColor: "#f5f6fa" }}>
              <IconButton onClick={handleClose} size="small"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 10,
                }}>
                <CloseIcon />
              </IconButton>
              <SubscriptionPacks />
            </DialogContent>
            {/* <DialogActions>
                                 <Button onClick={handleClose} color="secondary">
                                   Close
                                 </Button>
                               </DialogActions> */}
          </Dialog>
          {/* </div> */}

          <Container fluid className="content">
            <Row className="mt-4">
              <Col md={12}>
                <h5 className='mb-3 fw-bold'>All Admins</h5>
                <div className='card border-0 rounded-0'>
                  <div className='card-body table-responsive'>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Location</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicants.map((applicant) => (
                          <tr key={applicant.id} className="table-row">
                            <td>{applicant.userName} {applicant.lastName}</td>
                            <td>{applicant.email}</td>
                            <td>{applicant.phoneNumber}</td>
                            <td>{applicant.city}</td>

                            <td>

                              <Link onClick={() => handleViewClick(applicant)} className="btn btn-info btn-sm">
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
        </Col>
      </Row>
    </div>


  );
};

export default AllAdminList;