// import React, { useState } from "react";
// import { Form, Row, Col, Button, Alert } from "react-bootstrap";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { apiCall } from "../utils/api";
// import AdminDashboard from '.././components/AdminDashboard';
// import { Container} from 'react-bootstrap';
// import Header from '.././pages/admin/Header';

// const JobSeekerForm = () => {
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     firstName: "",
//     sureName: "",
//     mobileNumber: "",
//     emailAddress: "",
//     address: "",
//     city: "",
//     state: "",
//     pinCode: "",
//     aadharNo: "",
//     panNo: "",
//     drivingLicenceNumber: "",
//     voterId: "",
//     profileTitle: "",
//     totalExperience: "",
//     expectedSalary: "",
//     skills: [],
//     responsibilities: [],
//     qualification: [],
//     salary: "",
//     miscDetails: "",
//   });

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();

//   const inputStyle = {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "8px",
//     border: "1px solid #ddd",
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     if (step === 1) {
//       if (!formData.firstName.trim()) newErrors.firstName = "First Name is required";
//       if (!formData.sureName.trim()) newErrors.sureName = "Sure Name is required";
//       if (!formData.mobileNumber.trim()) newErrors.mobileNumber = "Mobile Number is required";
//       if (!formData.emailAddress.trim()) newErrors.emailAddress = "Email Address is required";
//       if (!formData.address.trim()) newErrors.address = "Address is required";
//       if (!formData.city.trim()) newErrors.city = "City is required";
//       if (!formData.state.trim()) newErrors.state = "State is required";
//       if (!formData.pinCode.trim()) newErrors.pinCode = "Pin Code is required";
//     }

//     if (step === 3) {
//       if (!formData.profileTitle.trim()) newErrors.profileTitle = "Profile Title is required";
//       if (!formData.totalExperience.trim()) newErrors.totalExperience = "Total Experience is required";
//       if (!formData.expectedSalary.trim()) newErrors.expectedSalary = "Expected Salary is required";
//       if (!formData.skills.trim()) newErrors.skills = "Skills are required";
//       if (!formData.responsibilities.trim()) newErrors.responsibilities = "Responsibilities are required";
//       if (!formData.qualification.trim()) newErrors.qualification = "Qualification is required";
//       if (!formData.salary.trim()) newErrors.salary = "Current Salary is required";
//       if (!formData.miscDetails.trim()) newErrors.miscDetails = "Miscellaneous details are required";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleNext = () => {
//     if (validateForm()) {
//       setErrors({});
//       setStep((prevStep) => prevStep + 1);
//     }
//   };

//   const handlePrevious = () => {
//     setErrors({});
//     setStep((prevStep) => prevStep - 1);
//   };


// const id = sessionStorage.getItem("userId");
// const role = sessionStorage.getItem("role")

// const userId = id;
// const payload = {
//   userId,
//   role,
//   ...formData,
// };
//   const handleSubmit = async () => {
//     if (validateForm()) {
//       // try {
//       //   const response = await axios.post(`https://localhost:3000/user/jobseekerProfile/${id}`, payload, {
//       //     headers: {
//       //       "Content-Type": "application/json",
//       //     },
//       //   });
//       //   setSuccessMessage("Form submitted successfully!");
//       //   setErrorMessage("");
//       //   console.log("responjee:", response);
//       // } catch (error) {
//       //   setErrorMessage("Error submitting form. Please try again.");
//       //   setSuccessMessage("");
//       //   console.error("Error:", error);
//       // }
//       try {
//         const response = await apiCall(`user/jobseekerProfile/${id}`, "PATCH", payload);
//         if (response?.data) {
//           // navigate("/employerProfileView");
//         }
//       } catch (error) {
//         console.error("Failed to create User profile:", error);
//       }
//     };
//     }

//   return (

//     <div className="dashboard">
//     <Header />
//   {/* < EmployerDashboardHeader /> */}
//   {/* <Header /> */}

//   <Container fluid className="content">
//     <Row className='g-4'>
//       <AdminDashboard />
//     <div
//       style={{
//         maxWidth: "800px",
//         margin: "50px auto",
//         padding: "20px",
//         borderRadius: "12px",
//         boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
//         backgroundColor: "#fff",
//       }}
//     >
//       <Form>
//         <h3
//           style={{
//             textAlign: "center",
//             color: "#007bff",
//             marginBottom: "30px",
//           }}
//         >
//           Step {step}:{" "}
//           {step === 1
//             ? "Personal Details"
//             : step === 2
//             ? "KYC Details"
//             : "Professional Details"}
//         </h3>

//         {successMessage && <Alert variant="success">{successMessage}</Alert>}
//         {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

//         {/* Step Content */}
//         {step === 1 && (
//           <>
//           <Row>
//             <Col md={6}>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>First Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="firstName"
//                   value={formData.firstName}
//                   onChange={handleChange}
//                   placeholder="Enter first name"
//                   style={inputStyle}
//                 />
//                 {errors.firstName && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.firstName}
//                   </Alert>
//                 )}
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>Sure Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="sureName"
//                   value={formData.sureName}
//                   onChange={handleChange}
//                   placeholder="Enter sure name"
//                   style={inputStyle}
//                 />
//                 {errors.sureName && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.sureName}
//                   </Alert>
//                 )}
//               </Form.Group>
//             </Col>
//           </Row>
//           <Form.Group style={{ marginBottom: "20px" }}>
//             <Form.Label>Mobile Number</Form.Label>
//             <Form.Control
//               type="text"
//               name="mobileNumber"
//               value={formData.mobileNumber}
//               onChange={handleChange}
//               placeholder="Enter mobile number"
//               style={inputStyle}
//             />
//             {errors.mobileNumber && (
//               <Alert
//                 variant="danger"
//                 style={{
//                   color: "red",
//                   backgroundColor: "transparent",
//                   borderColor: "transparent",
//                   border: "0",
//                   padding: "0",
//                   paddingLeft: "15px",
//                 }}
//               >
//                 {errors.mobileNumber}
//               </Alert>
//             )}
//           </Form.Group>
//           <Form.Group style={{ marginBottom: "20px" }}>
//             <Form.Label>Email Address</Form.Label>
//             <Form.Control
//               type="email"
//               name="emailAddress"
//               value={formData.emailAddress}
//               onChange={handleChange}
//               placeholder="Enter email address"
//               style={inputStyle}
//             />
//             {errors.emailAddress && (
//               <Alert
//                 variant="danger"
//                 style={{
//                   color: "red",
//                   backgroundColor: "transparent",
//                   borderColor: "transparent",
//                   border: "0",
//                   padding: "0",
//                   paddingLeft: "15px",
//                 }}
//               >
//                 {errors.emailAddress}
//               </Alert>
//             )}
//           </Form.Group>
//           <Form.Group style={{ marginBottom: "20px" }}>
//             <Form.Label>Address</Form.Label>
//             <Form.Control
//               type="text"
//               name="address"
//               value={formData.address}
//               onChange={handleChange}
//               placeholder="Enter address"
//               style={inputStyle}
//             />
//             {errors.address && (
//               <Alert
//                 variant="danger"
//                 style={{
//                   color: "red",
//                   backgroundColor: "transparent",
//                   borderColor: "transparent",
//                   border: "0",
//                   padding: "0",
//                   paddingLeft: "15px",
//                 }}
//               >
//                 {errors.address}
//               </Alert>
//             )}
//           </Form.Group>
//           <Row>
//             <Col md={4}>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>City</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="city"
//                   value={formData.city}
//                   onChange={handleChange}
//                   placeholder="Enter city"
//                   style={inputStyle}
//                 />
//                 {errors.city && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.city}
//                   </Alert>
//                 )}
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>State</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="state"
//                   value={formData.state}
//                   onChange={handleChange}
//                   placeholder="Enter state"
//                   style={inputStyle}
//                 />
//                 {errors.state && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.state}
//                   </Alert>
//                 )}
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>Pin Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="pinCode"
//                   value={formData.pinCode}
//                   onChange={handleChange}
//                   placeholder="Enter pin code"
//                   style={inputStyle}
//                 />
//                 {errors.pinCode && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.pinCode}
//                   </Alert>
//                 )}
//               </Form.Group>
//             </Col>
//           </Row>
//         </>

//         )}

//         {step === 2 && (
//             <>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Aadhar Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="aadharNo"
//                 value={formData.aadharNo}
//                 onChange={handleChange}
//                 placeholder="Enter Aadhar number"
//                 style={inputStyle}
//               />

//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>PAN Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="panNo"
//                 value={formData.panNo}
//                 onChange={handleChange}
//                 placeholder="Enter PAN number"
//                 style={inputStyle}
//               />

//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Driving Licence Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="drivingLicenceNumber"
//                 value={formData.drivingLicenceNumber}
//                 onChange={handleChange}
//                 placeholder="Enter driving licence number"
//                 style={inputStyle}
//               />

//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Voter ID</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="voterId"
//                 value={formData.voterId}
//                 onChange={handleChange}
//                 placeholder="Enter voter ID"
//                 style={inputStyle}
//               />
//             </Form.Group>

//           </>
//         )}

//         {step === 3 && (
//           <>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Profile Title</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="profileTitle"
//                 value={formData.profileTitle}
//                 onChange={handleChange}
//                 placeholder="Enter profile title"
//                 style={inputStyle}
//               />
//               {errors.profileTitle && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.profileTitle}
//                 </Alert>
//               )}
//             </Form.Group>
//             <Form.Group style={{ marginBottom: "0px" }}>
//               <Form.Label>Total Experience</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="totalExperience"
//                 value={formData.totalExperience}
//                 onChange={handleChange}
//                 placeholder="Enter total experience"
//                 style={inputStyle}
//               />
//               {errors.totalExperience && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.totalExperience}
//                 </Alert>
//               )}
//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Expected Salary</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="expectedSalary"
//                 value={formData.expectedSalary}
//                 onChange={handleChange}
//                 placeholder="Enter expected salary"
//                 style={inputStyle}
//               />
//               {errors.expectedSalary && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.expectedSalary}
//                 </Alert>
//               )}
//             </Form.Group>
//               <Form.Group style={{ marginBottom: "20px" }}>
//                 <Form.Label>Skills</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="skills"
//                   value={formData.skills}
//                   onChange={handleChange}
//                   placeholder="Enter skills (comma separated)"
//                   style={inputStyle}
//                 />
//                 {errors.skills && (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       color: "red",
//                       backgroundColor: "transparent",
//                       borderColor: "transparent",
//                       border: "0",
//                       padding: "0",
//                       paddingLeft: "15px",
//                     }}
//                   >
//                     {errors.skills}
//                   </Alert>
//                 )}
//               </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Responsibilities</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="responsibilities"
//                 value={formData.responsibilities}
//                 onChange={handleChange}
//                 placeholder="Enter responsibilities (comma separated)"
//                 style={inputStyle}
//               />
//               {errors.responsibilities && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.responsibilities}
//                 </Alert>
//               )}
//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Qualification</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="qualification"
//                 value={formData.qualification}
//                 onChange={handleChange}
//                 placeholder="Enter qualification (comma separated)"
//                 style={inputStyle}
//               />
//               {errors.qualification && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.qualification}
//                 </Alert>
//               )}
//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Current Salary</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="salary"
//                 value={formData.salary}
//                 onChange={handleChange}
//                 placeholder="Enter current salary"
//                 style={inputStyle}
//               />
//               {errors.salary && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.salary}
//                 </Alert>
//               )}
//             </Form.Group>
//             <Form.Group style={{ marginBottom: "20px" }}>
//               <Form.Label>Misc Details</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="miscDetails"
//                 value={formData.miscDetails}
//                 onChange={handleChange}
//                 placeholder="Enter miscellaneous details"
//                 style={inputStyle}
//               />
//               {errors.miscDetails && (
//                 <Alert
//                   variant="danger"
//                   style={{
//                     color: "red",
//                     backgroundColor: "transparent",
//                     borderColor: "transparent",
//                     border: "0",
//                     padding: "0",
//                     paddingLeft: "15px",
//                   }}
//                 >
//                   {errors.miscDetails}
//                 </Alert>
//               )}
//             </Form.Group>

//           </>
//         )}

//         <div style={{ textAlign: "center", marginTop: "30px" }}>
//           {step > 1 && (
//             <Button variant="secondary" onClick={handlePrevious} style={{ marginRight: "10px" }}>
//               Previous
//             </Button>
//           )}
//           {step < 3 ? (
//             <Button variant="primary" onClick={handleNext}>
//               Next
//             </Button>
//           ) : (
//             <Button variant="success" onClick={handleSubmit}>
//               Submit
//             </Button>
//           )}
//         </div>
//       </Form>
//     </div>
//     </Row>
//       </Container>
//     </div>
//   );
// };

// export default JobSeekerForm;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Form, Spinner, Button, InputGroup, Image, Modal, Card, Alert } from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { toast } from 'react-hot-toast';
import profile from '../assets/profieimg.png'
import { MdDelete } from "react-icons/md";
import { create } from "@mui/material/styles/createTransitions";
import { useLocation } from "react-router-dom";
import { TextField, Chip, Stack } from "@mui/material";

const JobSeekerProfileView = () => {

  const navigate = useNavigate();
  // const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({}); // To track validation errors

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fileName, setFileName] = useState('');
  const [savedFile, setSavedFile] = useState(null); // 
  const [Resume, setResume] = useState(null)
  const location = useLocation();
  const jobseeker = location.state?.id?.id || null; // Retrieve jobseekerId safely
  console.log("Received Jobseeker ID:", jobseeker);

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    // industryCategory: "",
    // industrySubCategory: "",
    branch: "",

    NativeLocation: "",
    NoticePeriod: "",
    LanguagesKnown: "",
    InterestedJobField: "",
    Remarks: "",
    Addl: "",
    Addl2: "",
    JobseekerFeedback: "",
    Currentsalary: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    resume: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pincode: "",
    aadharNo: "",
    panNumber: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: "" || [],
    qualification: "" || [],
    responsibilities: "" || [],
    dob: "",
    WRKBatchCODE: "",
    CSE: ""
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);


  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  //     useEffect(() => {
  //       console.log("applicant-initial",jobseeker);

  //       if (jobseeker) {
  //         // win.clear();
  //         // const formattedSubmissionDate = jobseeker?.submissionDate
  //         //   ? new Date(applicant?.submissionDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
  //         //   : '';
  //         setFormData({
  //           ...jobseeker,
  //           jobLocation: jobseeker?.location,
  //           // submissionDate: formattedSubmissionDate,
  //           // expiryDate: applicant?.expiryDate
  //           //   ? new Date(applicant?.expiryDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
  //           //   : '',
  //           // qualifications: applicant?.qualificationRequirements,
  //           // responsibilities: applicant?.responsibilitiesAndDuties,
  //           city:jobseeker?.city,
  //           skills:jobseeker?.skills,
  //           // jobId: undefined,
  //         });
  //         console.log("applicant",jobseeker);

  // console.log("form",formData);


  //       }
  //       // else {    // Load form data from localStorage when the component mounts
  //       //   const savedFormData = {
  //       //     jobTitle: win.getItem('jobTitle') || '',
  //       //     totalExperience: win.getItem('totalExperience') || null,
  //       //     jobLocation: win.getItem('jobLocation') || '',
  //       //     salary: win.getItem('salary') || '',
  //       //     companyName: win.getItem('companyName') || '',
  //       //     category_id: win.getItem('category_id') || '',
  //       //     industryCategory: win.getItem('industryCategory') || '',
  //       //     subcategory_id: win.getItem('subcategory_id') || '674af135e938a95a587969e8',
  //       //     jobSubCategory_id: win.getItem('jobSubCategory_id') || '679e532394c108bb56f28704',
  //       //     industryId: win.getItem('industryId') || '674b02b57a85d68f70dce160',
  //       //     qualifications: win.getItem('qualifications') || '',
  //       //     responsibilities: JSON.parse(win.getItem('responsibilities')) || [],
  //       //     skills: JSON.parse(win.getItem('skills')) || [],
  //       //     hoursPerWeek: win.getItem('hoursPerWeek') || '',
  //       //     jobPosition: win.getItem('jobPosition') || '',
  //       //     miscDetails: win.getItem('miscDetails') || '',
  //       //     termsAndConditions: win.getItem('termsAndConditions') || '',
  //       //     specialRequirements: win.getItem('specialRequirements') || '',
  //       //     noOfPosition: win.getItem('noOfPosition') || 1,
  //       //     submissionDate: win.getItem('submissionDate') || '',
  //       //     expiryDate: win.getItem('expiryDate') || '',
  //       //     benefitsDetails: win.getItem('benefitsDetails') || '',
  //       //     shift: win.getItem('shift') || '',
  //       //     workWeek: win.getItem('workWeek') || 'y',
  //       //   }
  //       //   setFormData("");

  //       // };
  //     }, []); // Empty dependency array, runs once when the component mounts


  useEffect(() => {
    // if (!jobseeker) {
    //   console.error("Jobseeker ID is missing!");
    //   return;
    // }



    fetchJobseekerProfile();
  }, []);

  console.log("formdata", formData)

  const fetchJobseekerProfile = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user/${jobseeker}`, {
        headers: { Authorization: `${token}` },
      });

      const jobseekers = response.data.data; // Extract the jobseeker data
      console.log("Fetched Jobseeker Profile:", jobseekers);

      setFormData({
        ...jobseekers,
        jobLocation: jobseekers?.location || "",
        city: jobseekers?.city || "",
        skills: jobseekers?.skills || [],
      });
      console.log("jobseeker", jobseekers)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobseeker profile:", error);
    }
  };

  console.log("jobseeker", formData)


  useEffect(() => {

    fetchProfile();
  }, [token, apiUrl]);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("userId");

      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data && response.data.data) {
        setUserDetails(response.data.data);
        // setFormData(response.data.data);
        setResume(response.data.data.resume)
      } else {
        setError(response.data.message || "Failed to fetch profile.");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
      setError("An error occurred while fetching the profile.");
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedValue = value;

      if (['qualification'].includes(name)) {
        // Split by comma, trim spaces, and ensure proper formatting
        updatedValue = value.split(/,\s*/).map(item => item);
      }
      else if (name === 'aadharNo') {
        // Allow only numbers, max 12 characters
        updatedValue = value.replace(/\D/g, '').slice(0, 12);
      }
      else if (name === 'panNumber') {
        // Convert to uppercase, remove invalid characters, and limit to 10 characters
        updatedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 10);
      }
      else if (name === 'voterId') {
        // Ensure first two characters are letters, rest are numbers (e.g., AB12345678)
        updatedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 10);
      }
      else if (name === 'drivingLicenceNumber') {
        // Format similar to voter ID (state code + numbers)
        updatedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 16);
      }
      else if (['Currentsalary', 'expectedSalary', 'totalExperience'].includes(name)) {
        // Allow only numbers and limit to 100000
        updatedValue = value.replace(/\D/g, '');
        if (Number(updatedValue) > 100000) updatedValue = '100000'; // Limit max value
      }

      return { ...prevData, [name]: updatedValue };
    });

    // Aadhaar validation when 12 digits are entered
    if (name === 'aadharNo' && value.length === 12) {
      validateAadhar(value);
    }


    if (name === "panNumber" && value === 10) {
      // updatedValue = updatedValue.slice(0, 10); // Limit to 10 characters
      validatePAN(value)
    }

    if (name === "voterId" && value === 10) {
      // updatedValue = updatedValue.slice(0, 10); // Limit to 10 characters
      validatePAN(value)
    }

    // if (name === "drivingLicenceNumber" && value === 16) {
    //   // updatedValue = updatedValue.slice(0, 10); // Limit to 10 characters
    //   validateDrivingLicence(value)
    // }

  };


  // const handleBlur = (e) => {
  //   const { name, value } = e.target;
  //   // Validate Aadhaar number when user leaves the field (on blur)
  //   if (name === 'aadharNo') {
  //     validateAadhar(value);
  //   }
  // };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "aadharNo") {
      validateAadhar(value);
    }
    if (name === "panNumber") {
      validatePAN(value);
    }

    if (name === "voterId") {
      validateVoterId(value);
    }

    //    if (name === "drivingLicenceNumber") {
    //     validateDrivingLicence(value);
    //  }
    // if (name === "voterId") {
    //   errorMessage = validateVoterId(value);
    // }
    // if (name === "drivingLicenceNumber") {
    //   errorMessage = validateDrivingLicence(value);
    // setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));

  }

  // Set errors for the specific field
  // };

  // const validateDrivingLicence = (drivingLicenceNumber) => {
  //   // Regex: State code (2 letters) + 2 digits + 2 alphanumeric + 6 digits
  //   const dlRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{2}[0-9]{6}$/;

  //   // If invalid, set the error
  //   if (!dlRegex.test(drivingLicenceNumber)) {
  //     setErrors({
  //       ...errors,
  //       drivingLicenceNumber: "Invalid DL format (e.g., KA01AB123456).",
  //     });
  //   } else {
  //     setErrors({
  //       ...errors,
  //       drivingLicenceNumber: "", // Clear error if valid
  //     });
  //   }
  // };


  const validatePAN = (panNumber) => {
    // Regex to match PAN format: 5 uppercase letters, 4 digits, 1 uppercase letter
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    // If the value does not match PAN format, set an error
    if (!panRegex.test(panNumber)) {
      setErrors({
        ...errors,
        panNumber: "PAN number must be in the format ABCDE1234F.",
      });
    } else {
      setErrors({
        ...errors,
        panNumber: "", // Clear error when valid
      });
    }
  };

  const validateVoterId = (voterId) => {
    // Regex: First 2 characters must be uppercase letters, followed by 8 digits
    const voterIdRegex = /^[A-Z]{2}[0-9]{8}$/;

    // If invalid, set the error
    if (!voterIdRegex.test(voterId)) {
      setErrors({
        ...errors,
        voterId: "Invalid Voter ID format (e.g., AB12345678).",
      });
    } else {
      setErrors({
        ...errors,
        voterId: "", // Clear error if valid
      });
    }
  };



  const validateAadhar = (aadharNo) => {
    // Regex to match exactly 12 digits
    const aadharRegex = /^\d{12}$/;

    // If the value is not exactly 12 digits, set error
    if (!aadharRegex.test(aadharNo)) {
      setErrors({
        ...errors,
        aadharNo: 'Aadhaar number must be exactly 12 digits.',
      });
    } else {
      setErrors({
        ...errors,
        aadharNo: '', // Clear error when valid
      });
    }
  };
  // const validateAadhar = (aadharNo) => {
  // const aadharPattern = /^[2-9]{1}[0-9]{11}$/; // Validate 12 digit Aadhaar number (first digit cannot be 0 or 1)
  // if (aadharNo && !aadharPattern.test(aadharNo)) {
  //   setErrors({
  //     ...errors,
  //     aadharNo: 'Invalid Aadhaar number. It should be 12 digits.',
  //   });
  // } else {
  //   setErrors({
  //     ...errors,
  //     aadharNo: '', // Clear error when valid
  //   });
  // }

  //   };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the uploaded file is an image
      if (!file.type.startsWith("image/")) {
        toast.error("Only image files (JPG, PNG, GIF) are allowed!");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };


  const handleImageRemove = async (e) => {
    e?.stopPropagation(); // Prevent accidental file input triggers

    try {
      // const token = sessionStorage.getItem("accessToken"); // Get auth token

      // Prepare the payload
      const payload = {
        ...formData,
        image: null
      };
      const userId = sessionStorage.getItem("userId");

      // Send PATCH request to update the profile
      const response = await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: ` ${token}` },
      });

      if (response.status === 200) {
        // Update local state to reflect the image removal
        setFormData((prevData) => ({ ...prevData, image: null }));

        // Refresh profile data
        await fetchProfile();
      }
    } catch (error) {
      console.error("Error removing image:", error.response?.data || error.message);
    }
  };



  const handleEditClick = () => {
    setShowSuccessModal(true);
  };

  const handleFileUpload = async () => {
    if (!file) return null;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(`${apiUrl}/shared/file-upload`, formData, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUploading(false);

      if (response.status === 200) {
        toast.success("File uploaded successfully.")
        return response.data.fileName;
      } else {
        throw new Error(response.data.message || "File upload failed");
      }
    } catch (error) {
      setIsUploading(false);
      toast.error("File upload failed.")
      // Swal.fire({
      //   title: "Error!",
      //   text: error.response?.data?.message || "File upload failed.",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
      return null;
    }
  };



  // const handleDoneClick = async () => {
  //   // Check if required fields are filled
  //   const requiredFields = [
  //     "firstName", "lastName", "salary", "expectedSalary", "skills",
  //     "address", "totalExperience", "phoneNumber", "city", "profileTitle",
  //     "responsibilities", "qualification", "email"
  //   ];

  //   const missingFields = requiredFields.filter(field => !formData[field]);


  //   // if (!formData.phoneNumber || !/^\d{10}$/.test(formData.phoneNumber)) {
  //   //   missingFields.push("Valid Phone Number (10 digits)");
  //   // }

  //   // // Validation logic
  //   // if (!formData.phoneNumber || !/^\d{13}$/.test(formData.phoneNumber)) {
  //   //   missingFields.push("Valid Phone Number (10 digits required)");
  //   // }



  //   if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
  //     missingFields.push("Valid Email Address");
  //   }

  //   // If there are missing fields or validation errors, show an error
  //   if (missingFields.length > 0) {
  //     toast.error(
  //       `Please fill in the following required fields: ${missingFields.join(",")}`,
  //       {
  //         style: {
  //           marginTop: '60px', // You can adjust this
  //           backgroundColor: 'red',
  //           color: 'white',
  //         }
  //       }
  //     );
  //     return;  // Prevent form submission if validation fails
  //   }

  //   // If everything is valid, proceed with further steps (e.g., file upload and API call)
  //   try {
  //     const uploadedFileName = await handleFileUpload(); // Assuming handleFileUpload() is defined
  //     const userId = sessionStorage.getItem("userId");
  //     setSavedFile(uploadedFileName); // Store the saved file name to show later

  //     // Payload to send with the API call
  //     const payload = {
  //       ...formData,
  //       resume: uploadedFileName,
  //       userId,
  //       skills: Array.isArray(formData.skills) ? formData.skills : [],
  //       responsibilities: Array.isArray(formData.responsibilities) ? formData.responsibilities : [],
  //       qualification: Array.isArray(formData.qualification) ? formData.qualification : []
  //     };

  //     // Ensure payload is valid before making the request
  //     if (!payload || Object.keys(payload).length === 0) {
  //       throw new Error("Invalid payload.");
  //     }

  //     // API call to update user profile
  //     await axios.patch(`${apiUrl}/user/${userId}`, payload, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     });

  //     // Show success modal after successful update
  //     setShowSuccessModal(true);
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //     toast.error("Failed to update profile.");
  //   }
  // };

  const handleDoneClick = async () => {
    const requiredFields = [
      "firstName", "lastName", "Currentsalary", "expectedSalary", "skills",
      "totalExperience", "phoneNumber", "city", "profileTitle", "companyName", "pincode",
      "qualification", "JobseekerFeedback", "WRKBatchCODE", "CSE",
      "responsibilities", "qualification", "email", "WRKBatchCODE",
    ];

    const missingFields = requiredFields.filter(field => !formData[field]);

    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      missingFields.push("Valid Email Address");
    }

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following required fields: ${missingFields.join(", ")}`,
        {
          style: {
            marginTop: "60px",
            backgroundColor: "red",
            color: "white",
          },
        }
      );
      return;
    }

    try {
      const uploadedFileName = await handleFileUpload(); // Upload file first
      const userId = sessionStorage.getItem("userId");

      const payload = {
        ...formData,
        resume: uploadedFileName,
        userId,
        // skills: Array.isArray(formData.skills) ? formData.skills : [],
        skills: formData.skills || [],
        responsibilities: Array.isArray(formData.responsibilities) ? formData.responsibilities : [],
        qualification: Array.isArray(formData.qualification) ? formData.qualification : [],
        city: formData.city,
        pincode: formData.pincode,
        CSE: formData.firstName,
        createdBy: formData.firstName,
      };

      // 🔥 API call to create Jobseeker
      const response = await axios.post(`${apiUrl}/callcenter/createjobseeker`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data?.message === "Jobseeker created successfully") {
        setShowSuccessModal(true);
      } else {
        throw new Error(response.data?.message || "Unknown error occurred.");
      }
    } catch (error) {
      console.error("Error creating jobseeker:", error);
      toast.error("Failed to create jobseeker.");
    }
  };

  const handleJobseekerUpdate = async () => {
    if (!jobseeker) {
      toast.error("Jobseeker ID is missing.");
      return;
    }

    try {
      const userId = sessionStorage.getItem("userId");
      const token = sessionStorage.getItem("token");

      const payload = {
        ...formData,
        userId,
        // skills: Array.isArray(formData.skills) ? formData.skills : [],
        skills: formData.skills || [],
        responsibilities: Array.isArray(formData.responsibilities) ? formData.responsibilities : [],
        qualification: Array.isArray(formData.qualification) ? formData.qualification : [],
        WRKBatchCODE: formData.WRKBatchCODE,
        updatedBy: userId,
      };

      console.log("Updating Jobseeker with payload:", payload);

      // 🔥 API call to update Jobseeker
      const response = await axios.patch(`${apiUrl}/callcenter/updatejobseeker/${jobseeker}`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data?.message === "Jobseeker updated successfully") {
        toast.success("Jobseeker profile updated!");

        // navigate('/applyJobAllJobSeeker')
        setShowSuccessModal(true);
      } else {
        throw new Error(response.data?.message || "Unknown error occurred.");
      }
    } catch (error) {
      console.error("Error updating jobseeker:", error);
      toast.error("Failed to update jobseeker.");
    }
  };


  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
    }


  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const personalDetailsFields = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
    "address",
    // "dob",
    "city",
    "NativeLocation",
    "LanguagesKnown",

    "state",
    "pincode",
    // "country",
    // "profileTitle",
  ];

  const kycFields = [
    "aadharNo",
    "panNumber",
    "voterId",
    "drivingLicenceNumber",
    // "address",
    // "pinCode",
  ];

  const professionalDetailsFields = [
    "profileTitle",
    "totalExperience",
    // "companyName",

    // "salary",
    "expectedSalary",
    "Currentsalary",

    "NoticePeriod",
    "Relocation",
    "InterestedJobField",
    "ReasonsforChange",

    "companyName",
    "qualification",
    "Addl",
    "Addl2",

    // "languageKnown",
    "skills",
    "responsibilities",
    "miscDetails",
    "Remarks",
    // "CSE",
    "JobseekerFeedback",
    "WRKBatchCODE",
    "CSE"

    // "role",
    // "designation",
    // "department",
    // "salary",
    // "expectedSalary",
    // "coverLetter"
  ];

  const handleDeleteResume = () => {
    setResume(null);
    document.getElementById("resumeInput").value = ""; // Clear file input
  };

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">

        <Row className="g-4">

          <Col>
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-content">
            <div className="card border-0 rounded-0">
              <div className="card-body">
                <div className="profile-details">
                  <div className="text-center mb-4" style={{ position: "relative" }}>
                    <div
                      className="profile-image-wrapper"
                      onClick={handleImageClick}
                      style={{
                        position: "absolute",
                        top: "106px",
                        right: "20px",
                        zIndex: "1000",
                        cursor: "pointer",
                      }}
                    >
                      <Image
                        src={formData.image || profile}
                        alt="Profile Picture"
                        roundedCircle
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          border: "4px solid #E8EBF9",
                        }}
                      />
                      {/* X Button for Deleting Image */}
                      {formData.image && (
                        <h4
                          onClick={handleImageRemove}
                          style={{
                            position: 'absolute',
                            top: '80px'
                          }}
                          className="text-danger"
                        >
                          <MdDelete />
                        </h4>
                      )}

                      <input type="file" id="imageInput" style={{ display: "none" }} onChange={handleImageChange} />

                    </div>



                  </div>

                  {/* <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  /> */}
                  <h5 className="mb-4">Profile Details</h5>
                  <Card className="mb-4">
                    <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                      Personal Details
                    </Card.Header>
                    <Card.Body>
                      <Row className="g-3" style={{ marginTop: '120px' }}>
                        {personalDetailsFields.map((key) => (
                          <Col md={6} key={key}>
                            <InputGroup>
                              <InputGroup.Text>
                                {["firstName", "lastName", "phoneNumber", "city", "pincode"].includes(key) ? (
                                  <>
                                    {key.replace(/([A-Z])/g, " $1").toUpperCase()} <span style={{ color: "red" }}>*</span>
                                  </>
                                ) : (
                                  key.replace(/([A-Z])/g, " $1").toUpperCase()
                                )}
                              </InputGroup.Text>

                              {key === "address" ? (
                                // Address as a textarea
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name={key}
                                  value={formData[key] || ""}
                                  onChange={handleChange}
                                // required
                                />
                              ) : (
                                <Form.Control
                                  type={key === "dob" ? "date" : key === "phoneNumber" ? "tel" : key === "email" ? "email" : "text"}
                                  name={key}
                                  value={formData[key] || ""}
                                  onChange={(e) => {
                                    let value = e.target.value;

                                    // ✅ Phone Number Handling
                                    if (key === "phoneNumber") {
                                      value = value.replace(/\D/g, ""); // Remove non-numeric characters

                                      // Ensure the number starts with "91" (India)
                                      if (!value.startsWith("91")) {
                                        value = "91" + value;
                                      }

                                      // Limit to 12 digits (91XXXXXXXXXX)
                                      if (value.length > 12) {
                                        value = value.slice(0, 12);
                                      }

                                      // Add "+" at the beginning before updating the state
                                      value = "+" + value;
                                    }

                                    // ✅ Update State
                                    handleChange({ target: { name: key, value } });
                                  }}
                                  required={["firstName", "lastName", "phoneNumber", "city"].includes(key)}
                                  maxLength={key === "phoneNumber" ? 13 : undefined} // "+91XXXXXXXXXX" = 13 chars
                                  pattern={
                                    key === "phoneNumber"
                                      ? "\\+91\\d{10}" // Ensures format "+91XXXXXXXXXX"
                                      : key === "email"
                                        ? "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
                                        : undefined
                                  }
                                  inputMode={key === "phoneNumber" ? "numeric" : undefined}
                                />

                              )}
                            </InputGroup>

                            {/* Validation Messages */}
                            {key === "phoneNumber" &&
                              formData.phoneNumber &&
                              formData.phoneNumber.length < 13 && (
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  Phone number must be exactly 10 digits
                                </p>
                              )}


                            {/* ✅ Validation Messages */}
                            {key === "phoneNumber" && formData.phoneNumber && (
                              <>
                                {/* ❌ Show error if number is not 10 digits after +91 */}
                                {!/^\+91\d{10}$/.test(formData.phoneNumber) && (
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    Enter a valid 10-digit phone number (e.g., +91XXXXXXXXXX)
                                  </p>
                                )}

                                {/* ❌ Show error if +91 is missing */}
                                {!formData.phoneNumber.startsWith("+91") && (
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    Phone number must start with "+91"
                                  </p>
                                )}
                              </>
                            )}

                            {/* {key === "phoneNumber" && formData.phoneNumber && (
                              <>
                                ❌ Show error if number is not exactly 10 digits
                                {!/^\d{10}$/.test(formData.phoneNumber) && (
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    Enter a valid 10-digit phone number (e.g., 9876543210)
                                  </p>
                                )}
                              </>
                            )} */}



                            {key === "email" &&
                              formData.email &&
                              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email) && (
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  Enter a valid email address
                                </p>
                              )}
                          </Col>
                        ))}

                      </Row>
                    </Card.Body>
                  </Card>


                  <Card className="mb-4">
                    <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>KYC Details</Card.Header>
                    <Card.Body>
                      <Row className="g-3">
                        {kycFields.map((key) => (
                          <Col md={6} key={key}>
                            <InputGroup>
                              <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
                              <Form.Control
                                type="text" // Keep as text for better control
                                name={key}
                                value={formData[key] || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={key === 'aadharNo' ? 12 : key === 'panNumber' ? 10 : key === 'voterId' ? 10 : key === 'drivingLicenceNumber' ? 16 : undefined}
                                isInvalid={errors[key]}
                                onInput={(e) => {
                                  let value = e.target.value;

                                  if (key === 'aadharNo') {
                                    // Aadhaar: Only numbers (0-9), max 12 digits
                                    e.target.value = value.replace(/[^0-9]/g, '');
                                  }
                                  else if (key === 'panNumber') {
                                    // Ensure the PAN format: 5 letters, 4 numbers, 1 letter
                                    e.target.value = value
                                      .toUpperCase() // Convert to uppercase
                                      .replace(/[^A-Z0-9]/g, '') // Remove invalid characters
                                      .slice(0, 10); // Limit to 10 characters
                                  }
                                  else if (key === 'voterId') {
                                    // Voter ID: First 2 characters must be letters, rest must be numbers (total 10)
                                    if (value.length <= 2) {
                                      e.target.value = value.replace(/[^A-Za-z]/g, ''); // First 2 characters only letters
                                    } else {
                                      e.target.value = value.slice(0, 2) + value.slice(2).replace(/[^0-9]/g, ''); // Rest must be numbers
                                    }
                                  }
                                  else if (key === 'drivingLicenceNumber') {
                                    // Voter ID: First 2 characters must be letters, rest must be numbers (total 10)
                                    if (value.length <= 2) {
                                      e.target.value = value.replace(/[^A-Za-z]/g, ''); // First 2 characters only letters
                                    } else {
                                      e.target.value = value.slice(0, 2) + value.slice(2).replace(/[^0-9]/g, ''); // Rest must be numbers
                                    }
                                  }
                                }}
                              />
                              {errors[key] && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[key]}
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                          </Col>
                        ))}



                        {/* {errors.aadharNo && (
        <Alert variant="danger">
          {errors.aadharNo}
        </Alert>
      )} */}
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4">
                    <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>Professional Details</Card.Header>
                    <Card.Body>
                      <Row className="g-3">
                        {professionalDetailsFields.map((key) => (
                          <Col md={6} key={key}>
                            <InputGroup>
                              {/* <InputGroup.Text>
                                                      {["Currentsalary", "expectedSalary", "skills", "totalExperience", "profileTitle", "responsibilities", "qualification", "WRKBatchCODE"].includes(key) ? (
                                                        <>
                                                          {key.replace(/([A-Z])/g, " $1").toUpperCase()} <span style={{ color: "red" }}>*</span>
                                                        </>
                                                      ) : (
                                                        key.replace(/([A-Z])/g, " $1").toUpperCase()
                                                      )}
                                                    </InputGroup.Text> */}

                              <InputGroup.Text>
                                {["Currentsalary", "expectedSalary", "skills", "totalExperience", "profileTitle", "responsibilities", "qualification", "WRKBatchCODE", "companyName", "JobseekerFeedback", "CSE"].includes(key) ? (
                                  <>
                                    {key === "profileTitle" ? "JOB TITLE (Current) "
                                      : key === "Currentsalary" ? "CURRENT SALARY (Month) "
                                        : key === "expectedSalary" ? "EXPECTED SALARY (Month) "
                                          : key === "responsibilities" ? "JOB RESPONSIBILITIES "
                                            : key === "WRKBatchCODE" ? "WRK-BATCH CODE"
                                              : key === "companyName" ? "COMPANY NAME"
                                                : key === "JobseekerFeedback" ? "JOBSEEKER STATUS "
                                                  : key === "CSE" ? "CSE "
                                                    : key.replace(/([A-Z])/g, " $1").toUpperCase()}
                                    <span style={{ color: "red" }}>*</span>
                                  </>
                                ) : (
                                  key === "Relocation" ? "READY FOR RELOCATION (Y/N)"
                                    : key === "Addl" ? "DL DETAILS"
                                      : key === "Addl2" ? "ADDL DETAILS"
                                        : key === "ReasonsforChange" ? "REASONS FOR CHANGE"

                                          : key.replace(/([A-Z])/g, " $1").toUpperCase()
                                )}
                              </InputGroup.Text>


                              {/* Skills Section */}
                              {key === "skills" && (

                                <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                                  <TextField
                                    style={{ position: "relative" }}
                                    label="Enter your Skills"
                                    value={formData.newSkill || ""}
                                    onChange={(e) => {
                                      const words = e.target.value.trim().split(/\s+/);
                                      if (words.length <= 20) {
                                        setFormData((prev) => ({
                                          ...prev,
                                          newSkill: e.target.value, // Temporary input for skills
                                        }));
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        const newSkill = formData.newSkill?.trim();
                                        if (!newSkill) return;

                                        const words = newSkill.split(/\s+/);
                                        if (words.length > 20) {
                                          toast.error("Skill cannot exceed 20 words.");
                                          return;
                                        }

                                        setFormData((prev) => ({
                                          ...prev,
                                          skills: [...(prev.skills || []), newSkill], // Add skill
                                          newSkill: "", // Clear input
                                        }));
                                      }
                                    }}
                                    fullWidth
                                  />

                                  <Button
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "30px",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      const newSkill = formData.newSkill?.trim();
                                      if (!newSkill) return;

                                      const words = newSkill.split(/\s+/);
                                      if (words.length > 20) {
                                        toast.error("Skill cannot exceed 20 words.");
                                        return;
                                      }

                                      setFormData((prev) => ({
                                        ...prev,
                                        skills: [...(prev.skills || []), newSkill], // Add skill
                                        newSkill: "", // Clear input
                                      }));
                                    }}
                                  >
                                    +
                                  </Button>

                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                      flexWrap: "wrap",
                                      maxHeight: "100px",  // Set a max height
                                      overflowY: "auto",   // Enable vertical scrolling
                                      // border: "1px solid #ccc", // Optional: Add a border for better visibility
                                      padding: "8px",
                                      borderRadius: "5px"
                                    }}
                                  >
                                    {(formData.skills || []).map((skill, index) => (
                                      <Chip
                                        key={index}
                                        label={skill}
                                        onDelete={() =>
                                          setFormData((prev) => ({
                                            ...prev,
                                            skills: prev.skills.filter((s) => s !== skill), // Remove skill
                                          }))
                                        }
                                      />
                                    ))}
                                  </Stack>

                                </Stack>

                              )}

                              {/* Responsibilities Section */}
                              {key === "responsibilities" && (


                                <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                                  <TextField
                                    style={{ position: "relative" }}
                                    label="Enter your Responsibilities"
                                    value={formData.newResponsibility || ""}
                                    onChange={(e) => {
                                      const words = e.target.value.trim().split(/\s+/);
                                      if (words.length <= 20) {
                                        setFormData((prev) => ({
                                          ...prev,
                                          newResponsibility: e.target.value, // Temporary input for responsibilities
                                        }));
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        const newResponsibility = formData.newResponsibility?.trim();
                                        if (!newResponsibility) return;

                                        const words = newResponsibility.split(/\s+/);
                                        if (words.length > 20) {
                                          toast.error("Responsibility cannot exceed 20 words.");
                                          return;
                                        }

                                        setFormData((prev) => ({
                                          ...prev,
                                          responsibilities: [...(prev.responsibilities || []), newResponsibility], // Add responsibility
                                          newResponsibility: "", // Clear input
                                        }));
                                      }
                                    }}
                                    fullWidth
                                  />

                                  <Button
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "30px",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      const newResponsibility = formData.newResponsibility?.trim();
                                      if (!newResponsibility) return;

                                      const words = newResponsibility.split(/\s+/);
                                      if (words.length > 20) {
                                        toast.error("Responsibility cannot exceed 20 words.");
                                        return;
                                      }

                                      setFormData((prev) => ({
                                        ...prev,
                                        responsibilities: [...(prev.responsibilities || []), newResponsibility], // Add responsibility
                                        newResponsibility: "", // Clear input
                                      }));
                                    }}
                                  >
                                    +
                                  </Button>

                                  <Stack direction="row" spacing={1} sx={{
                                    flexWrap: "wrap",
                                    maxHeight: "100px",  // Set a max height
                                    overflowY: "auto",   // Enable vertical scrolling
                                    // border: "1px solid #ccc", // Optional: Add a border for better visibility
                                    padding: "8px",
                                    borderRadius: "5px"
                                  }}>
                                    {(formData.responsibilities || []).map((resp, index) => (
                                      <Chip
                                        key={index}
                                        label={resp}
                                        onDelete={() =>
                                          setFormData((prev) => ({
                                            ...prev,
                                            responsibilities: prev.responsibilities.filter((r) => r !== resp), // Remove responsibility
                                          }))
                                        }
                                      />
                                    ))}
                                  </Stack>
                                </Stack>


                              )}





                              {key === "JobseekerFeedback" ? (
                                <>
                                  <Form.Select
                                    name={key}
                                    value={formData[key] || ""}
                                    onChange={handleChange}
                                    required
                                    style={{ width: '50%' }}
                                  >
                                    <option value="">Select Feedback</option>
                                    <option value="Interested">Interested</option>
                                    <option value="Not Interested">Not Interested</option>
                                    <option value="Not Reachable">Not Reachable</option>
                                    <option value="Didn't answer the call">Didn't answer the call</option>
                                    <option value="Invalid No">Invalid No</option>
                                    <option value="Line Busy">Line Busy</option>
                                    <option value="Out of Service/Switch Off">
                                      Out of Service/Switch Off
                                    </option>
                                    <option value="No incoming calls">No incoming calls</option>
                                    <option value="Call forwarded">Call forwarded</option>
                                    <option value="Call Back">Call Back</option>
                                    <option value="Dublicate Profile">Dublicate Profile</option>
                                    <option value="Mismatch of Profile to JD">
                                      Mismatch of Profile to JD
                                    </option>
                                    <option value="Other">Other</option>
                                  </Form.Select>

                                  {/* Show extra input if Interested */}
                                  {formData[key] === "Interested" && (
                                    <Form.Group className="mt-3" style={{ width: '70%', marginLeft: '12rem', borderRadius: 'none' }}>
                                      <Form.Select
                                        name="JobseekerFeedbackStatus"
                                        value={formData.JobseekerFeedbackStatus || ""}
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select Option</option>
                                        <option value="Screened by Call Center">Screened by Call Center</option>
                                        <option value="Interview Scheduled">Interview Scheduled</option>
                                        <option value="Interview Done">Interview Done</option>
                                        <option value="Shortlisted">Shortlisted</option>
                                        <option value="Selected">Selected</option>
                                        <option value="Job Offered">Job Offered</option>
                                      </Form.Select>
                                    </Form.Group>
                                  )}
                                </>
                              ) : ["skills", "responsibilities", "ReasonsforChange", "Relocation"].includes(key) ? (
                                // Avoid rendering input for skills and responsibilities
                                null
                              ) : (
                                <Form.Control
                                  as={["miscDetails", "Remarks", "companyName", "qualification", "Addl", "Addl2"].includes(key) ? "textarea" : "input"}
                                  rows={["miscDetails", "Remarks"].includes(key) ? 3 : undefined}
                                  name={key}
                                  value={formData[key] || ""}
                                  onChange={(e) => {
                                    if (key === "WRKBatchCODE" && e.target.value.length > 12) {
                                      toast.error("WRKBatchCODE must be 12 characters only.");
                                      return;
                                    }
                                    handleChange(e);
                                  }}
                                  required={["miscDetails", "Remarks", "WRKBatchCODE"].includes(key)}
                                />
                              )}

                              {key === "ReasonsforChange" && (
                                <>
                                  <Form.Group style={{ width: '69%' }}>
                                    {/* <Form.Label>Reason</Form.Label> */}
                                    <Form.Select

                                      name={key}
                                      value={formData[key] || ""}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFormData((prev) => ({
                                          ...prev,
                                          [key]: value, // Keep "Other" as value to prevent disappearing
                                          otherReason: value === "Other" ? "" : prev.otherReason, // Reset otherReason if not needed
                                        }));
                                      }}
                                      required
                                    >
                                      <option value="">Select Reason</option>
                                      <option value="personal">Personal</option>
                                      <option value="Family">Family</option>
                                      <option value="Salary">Salary</option>
                                      <option value="Job Satisfaction">Job Satisfaction</option>
                                      <option value="Location">Location</option>
                                      <option value="Travel Issue">Travel Issue</option>
                                      <option value="Marriage">Marriage</option>
                                      <option value="Company Culture">Company Culture</option>
                                      <option value="Other">Other</option>
                                    </Form.Select>

                                    {/* Show input box when "Other" is selected */}
                                    {formData[key] === "Other" && (
                                      <Form.Control
                                        type="text"
                                        name="otherReason"
                                        value={formData.otherReason || ""}
                                        onChange={(e) => setFormData((prev) => ({ ...prev, otherReason: e.target.value }))}
                                        placeholder="Enter other reason"
                                        required
                                        style={{ marginTop: "10px" }}
                                      />
                                    )}
                                  </Form.Group>

                                </>)}

                              {key === "Relocation" && (
                                <Form.Select
                                  name={key}
                                  value={formData[key] || ""}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select Option</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Form.Select>

                              )}


                            </InputGroup>
                          </Col>
                        ))}
                      </Row>

                      <Row>
                        <Form.Group controlId="resume" className="mb-4">
                          <Form.Label>Upload Resume</Form.Label>
                          <Form.Control
                            id="resumeInput"
                            type="file"
                            onChange={handleFileChange}
                            required
                          />
                        </Form.Group>

                        {Resume && (
                          <div className="saved-file d-flex align-items-center" style={{
                            background: '#E9ECEF', width: '350px', display: 'flex', alignItems: 'center', padding: '5px',
                            borderRadius: '10px', marginLeft: '10px', color: 'black', justifyContent: 'space-between'
                          }}>
                            <p className="mb-0">
                              <strong>Resume:</strong> {Resume.split('_')[1]}
                            </p>
                            <Button
                              style={{ width: '50px', background: "#E9ECEF", border: 'none' }}
                              variant="danger"
                              className="ms-2"
                              size="sm"
                              onClick={handleDeleteResume}
                            >
                              ❌
                            </Button>
                          </div>
                        )}
                      </Row>
                    </Card.Body>

                  </Card>

                  <div className="d-flex justify-content-between mt-3">
                    <Button className="default-btn"
                      onClick={!jobseeker ? handleDoneClick : handleJobseekerUpdate}>
                      {jobseeker ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* { !jobseeker &&(  */}
      <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully Created.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      {/* // )} */}
    </div>
  );
};

export default JobSeekerProfileView;

