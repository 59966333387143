
import React, { useState ,useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Breadcrumbs from "./components/Breadcrumbs";
import ProtectedRoute from "./routes/ProductedRoutes";
// import { useNavigate } from "react-router-dom";
import { publicRoutes } from "../src/routes/PublicRoutes";
import { adminRoutes } from "../src/routes/AdminRoutes";
import { employerRoutes } from "../src/routes/EmployerRoutes";
import { jobSeekerRoutes } from "../src/routes/JobSeekersRoutes";
import { superAdminRoutes } from "../src/routes/SuperAdminRoutes";
import { callCenterRoutes } from "../src/routes/CallCenterRoutes";
import EmployerDashboardHeader from "./components/EmployerDashboardHeader"
// import Navbar from "./components/Navbar"; // Import Navbar
import LoginPage from "./pages/LoginPage"; // Import LoginPage
import { Toaster } from "react-hot-toast";
import EmployerLogin from "./components/EmployerLogin";
import LoginModal from "./components/Login";
import './i18n';
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
// import ManualChatbot from "./pages/Chatbot/manualChatbot";
import ScrollToTop from "./pages/ScrollToTop";

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { requestPermission  } from "./notification/firebase-config";


const App = () => {
  // const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(""); // "admin", "employer", or "jobseeker"
  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const [value, setValue] = React.useState(0);
  
    // useEffect(() => {
    //   requestPermission();

    // }, []);

    // useEffect(() => {
    //   requestPermission();
  
    //   let isSubscribed = true;
  
    //   // onMessageListener()
    //   //   .then((payload) => {
    //   //     if (isSubscribed) {
    //   //       console.log("🚀 Notification received:", payload);
    //   //     }
    //   //   })
    //   //   .catch((err) => console.log("⚠️ Notification listener error:", err));
  
    //   return () => {
    //     isSubscribed = false; // Cleanup to prevent multiple triggers
    //   };
    // }, []);

  const handleLogin = (userRole) => {
    setIsAuthenticated(true);
    setRole(userRole);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole("");
    alert("Logged out successfully.");
  };


  const showEmployerLoginPopupHandler = () => {
    setShowEmployerLoginPopup(true);
  };

  const closeEmployerLoginPopupHandler = () => {
    setShowEmployerLoginPopup(false);
  };

  const renderRoutes = (routes, allowedRoles) =>
    routes.map(({ path, element }, index) => (
      <Route
        key={index}
        path={path}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            role={role}
            allowedRoles={allowedRoles}
          >
            {element}
          </ProtectedRoute>
        }
      />
    ));

  return (
    <Router>
      {isAuthenticated && <EmployerDashboardHeader handleLogout={handleLogout} />}
      {/* <EmployerDashboardHeader handleLogout={handleLogout} /> */}
      <Breadcrumbs />
      <Toaster />

      {/* <div className="App">
      <h1>Web Push Notifications</h1>
      <button onClick={requestPermission}>Enable Notifications</button>
    </div> */}
      {/* <Breadcrumbs />
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
        /> */}
        <ScrollToTop />
      <Routes>
        <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
        <Route
          path="/employer-login"
          element={
            <EmployerLogin
            onLogin={handleLogin}
              showEmployerLoginPopup={showEmployerLoginPopup}
              closeEmployerLoginPopup={closeEmployerLoginPopupHandler}
            />
          }
        />
        {/* <Route
          path="/employer-login"
          element={
            <LoginModal
              onLogin={handleLogin}
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
            />
          }
        /> */}
        <Route
          path="/employer-login"
          element={

         <LoginModal
        onLogin={handleLogin} 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)} 
      />}
      />
        
        {/* <Route path="/login" element={<EmployerLogin handleLogin={handleLogin} />} /> */}

        {/* Public Routes */}
        {publicRoutes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}

        {/* Role-Based Routes */}
        {renderRoutes(adminRoutes, ["admin"])}
        {renderRoutes(employerRoutes, ["employer"])}
        {renderRoutes(jobSeekerRoutes, ["jobseeker"])}
        {renderRoutes(superAdminRoutes, ["superadmin"])}
        {renderRoutes(callCenterRoutes, ["callcenter"])}
      </Routes>
      {/* <ManualChatbot/> */}

          {/* <Box sx={{ width: "100%", position: "fixed", bottom: 0, left: 0, bgcolor: "background.paper", zIndex: 1000 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);

          // Navigation logic
          if (newValue === 0) navigate("/alljobseekertable"); // Redirects to AllJobSeekerTable
        }}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation>
    </Box> */}

      <Footer/>

      {/* <Box sx={{ width: 500 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation>
    </Box> */}

    </Router>
  );
};

export default App;

