import HomePage from "../pages/HomePage";
import AboutUs from "../pages/Home/AboutUs";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
import EmployerDashboard from "../pages/EmployerDashboad";
import JobseekerProfileForm from "../pages/JobseekerCreateProfile";
import JobDetailPage from "../pages/JobDetailPage";
import Jobstatus from "../components/jobstatus";
import Reviews from "../components/Reviews";
import ResumeDetails from "../components/Resumedetails";
import Contact from "../components/Contact";
import JobDescription from "../pages/JobDescription";
import EmployerProfileView from "../pages/EmployerProfileView";
import JobListPage from "../pages/JobseekerJobList";
import ResumeBuilder from "../pages/ResumeBuilder";
import CreateProfile from "../pages/EmployerCreateProfile";
import ApplicantList from "../pages/AllCandidates";
import JobSeekerProfileView from "../pages/JobseekerProfileView"
import ApplyJob from "../components/ApplyJob";
import Applicant from "../pages/Applicant";
import AppliedJob from "../pages/AppliedJob";
import Dashboard from "../pages/admin/dashboard";
import EmployerTable from "../pages/Employee/EmployerTable";
import JobSeekerTable from "../pages/Employee/JobSeekerTable";
import ApplicantsListPage from "../pages/Call_center/ApplicantsListPage";
import SubscriptionPacks from "../pages/Employee/Subscription/SubscriptionPacks";
import ApplyJobAllJobSeeker from "../pages/Call_center/ApplyJobAllJobSeeker";
import AllAdminList from "../pages/admin/AllAdminList";
import AllCallCenterList from "../pages/admin/AllCallCenterList";

import JobDescriptionForm from "../pages/Postjob";
import PrivacyPolicy from "../pages/Company/PrivacyAndPolicy";
import TermsAndConditions from "../pages/Company/TermsAndConditions";
import JobSearch from "../pages/JobSeeker/JobSearch";
import Leadership from "../pages/Home/Leadership";
import NewBlog from "../pages/Home/NewBlog";
import BlogPage from "../pages/Home/Blogs";
import Faq from "../pages/Home/Faq";
import VideoPage from "../pages/Home/video";
import List from "../pages/List"
import ApplicantShortList from "../pages/applicantShortList";
import JobseekerDashboard from "../pages/jobseekerDashboard";
import AppliedApplicants from "../pages/Employee/AppliedApplicants";
import ApplicantsTable from "../pages/Employee/ApplicantsTable"
import AllJobSeekerTable from "../pages/Employee/AllJobseekerTable"
import EmployerVideo from "../pages/Home/employerVideo";
import JobseekerVideo from "../pages/Home/jobseekerVideos";
import { JobseekerDetails } from "../pages/JobseekerDetails";
import { AppliedJobDetails } from "../pages/AppliedJobDetails";
import { EmployerDetails } from "../pages/Employee/EmployerDetails";
import CallcenterJobDescriptionForm from "../pages/Call_center/callcenterpostjob";
import CallcenterApplicant from "../pages/Call_center/callcenterapplicants";
import EmployerSub from "../pages/Employee/employersubcription";
import ChooseModule from "../pages/Employee/choosemodule";
import Module2 from "../pages/Employee/module1";
import Enrollment from "../pages/Employee/enrollment";
import { EnrollmentDetails } from "../pages/Employee/enrollmentDetails";
import EnrollmentCreate from "../pages/Employee/enrollmentCreate";
import { Reports } from "../pages/Call_center/reports";

export const publicRoutes = [
  { path: "/", element: <HomePage /> },
  { path: "/aboutUs", element: <AboutUs /> },
  { path: "/admin/login", element: <LoginPage /> },
  { path: "/contact", element: <Contact /> },
  { path: "*", element: <NotFound /> },
  { path: "/joblist", element: <JobListPage /> },
  { path: "/viewdetails/:id", element: <JobDetailPage /> },
  { path: "/jobstatus", element: <Jobstatus /> },
  { path: "/resumedetails", element: <ResumeDetails /> },
  { path: "/jobseeker/apply", element: <ApplyJob /> },
  { path: "/reviews", element: <Reviews /> },
  { path: "/contact", element: <Contact /> },
  { path: "/jobdescription/:id", element: <JobDescription /> },
  { path: "/employerCreateProfile", element: <CreateProfile /> },
  { path: "/employerProfileView", element: <EmployerProfileView /> },
  { path: "/dashboard", element: <EmployerDashboard /> },
  { path: "/jobseekerDashboard", element: <JobseekerDashboard/>},
  { path: "/jobseekerCreateProfile", element: <JobseekerProfileForm /> },
  { path: "/JobList", element: <JobListPage /> },
  { path: "/resumebuilder", element: <ResumeBuilder /> },
  { path: "/allCandidates", element: <ApplicantList /> },
  { path: "/jobseekerprofile", element: <JobSeekerProfileView /> },
  { path: "/applicants", element: <Applicant /> },
  { path: "/appliedjob", element: <AppliedJob /> },
  { path: "/admin-dashboard", element: <Dashboard /> },
  { path: "/employerTable", element: <EmployerTable /> },
  { path: "/jobSeekerTable", element: <JobSeekerTable /> },
  { path: "/appliedJobList", element: <ApplicantsListPage /> },
  { path: "/subscriptionPacks", element: <SubscriptionPacks /> },
  { path: "/applyJobAllJobSeeker", element: <ApplyJobAllJobSeeker /> },
  { path: "/allAdminList", element: <AllAdminList /> },
  { path: "/allCallCenterList", element: <AllCallCenterList /> },
  { path: "/jobDescriptionForm", element: < JobDescriptionForm/> },
  { path: "/privacyAndPolicy", element: < PrivacyPolicy/> },
  { path: "/termsAndConditions", element: < TermsAndConditions/> },
  {path:"/jobSearch", element:<JobSearch />},
  {path:"/ledership", element:<Leadership />},
  {path:"/newBlog", element:<NewBlog/>},
  {path:"/blogPage", element:<BlogPage/>},
  {path:"/Faq", element:<Faq/>},
  {path:"/VideoPage", element:<VideoPage />},
  {path:'/List',element:<List />},
  {path:'/applicantshortlist',element:<ApplicantShortList/>},
  {path:'/appliedApplicants',element:<AppliedApplicants/>},
  {path:'/applicantsTable',element:<ApplicantsTable/>},
  {path:'/allJobSeekerTable',element:<AllJobSeekerTable/>},
  {path:'/employerVideos',element:<EmployerVideo/>},
  {path:'/jobseekerVideos', element:<JobseekerVideo/>},
  {path:'/jobseekerDetails',element:<JobseekerDetails/>},
  {path:'/appliedjobDetails',element:<AppliedJobDetails/>},
  {path:'/employerDetails', element:<EmployerDetails/>},
  {path:'/CallcenterJobDescriptionForm' , element:<CallcenterJobDescriptionForm/>},
  {path:'/callcenterApplicant', element:<CallcenterApplicant/>},
  {path:'/platform-enrolment-plan', element:<EmployerSub/>},
  {path:'/fulfillment-model', element:<ChooseModule/>},
  {path:'/subscription-model', element:<Module2/>},
  {path:"/enrollment", element:<Enrollment/>},
  {path:"/enrollmentDetails",element:<EnrollmentDetails/>},
  {path:"/enrollmentCreate",element:<EnrollmentCreate/>},
  {path:"/reports",element:<Reports/>}

];
