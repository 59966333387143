import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { Row, Col, Button, Spinner, Card } from "react-bootstrap";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"; // Import separately!
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import axios from "axios";
import { FaBriefcase, FaClipboardList, FaUsers } from 'react-icons/fa';
import '../../Styles/reports.css'
import { Modal } from "react-bootstrap";

export const Reports = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [loadingEmployer, setLoadingEmployer] = useState(false);
  const [loadingJobseeker, setLoadingJobseeker] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [Jobs, setJobs] = useState();
  const [jobData, setJobData] = useState([]);
  const [error, setError] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [AppliedStatus, setAppliedStatus] = useState([])
  const [jobSeekerData, setJobSeekerData] = useState([]); // Store jobseeker details
  const [loadingallJobseeker, setLoadingallJobseeker] = useState(false);
  const [appliedJobSeekers, setAppliedJobSeekers] = useState([]);
  const [shortlistedJobSeekers, setShortlistedJobSeekers] = useState([]);
  const [interviewScheduledJobSeekers, setInterviewScheduledJobSeekers] = useState([]);
  const [hiredJobSeekers, setHiredJobSeekers] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("PDF");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);

  // const fetchDataAndGeneratePDF = async (role) => {
  //   if (role === "employer") setLoadingEmployer(true);
  //   if (role === "jobseeker") setLoadingallJobseeker(true);

  //   try {
  //     const response = await axios.get(`${apiUrl}/user/roles/${role}`);
  //     console.log(`${role} Data:`, response.data);
  //     generatealljobseekerPDF(response.data.data, role);
  //   } catch (error) {
  //     console.error(`Error fetching ${role} data:`, error);
  //   } finally {
  //     if (role === "employer") setLoadingEmployer(false);
  //     if (role === "jobseeker") setLoadingallJobseeker(false);
  //   }
  // };





  // const generatePDF = (data, role) => {
  //   if (!data || data.length === 0) {
  //     console.error(`No data available for ${role}`);
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   doc.text(`${role.charAt(0).toUpperCase() + role.slice(1)} Report`, 14, 10);

  //   const columns = role === "employer"
  //     ? ["ID", "Name", "Email", "Company", "Phone"]
  //     : ["ID", "Name", "Email", "Skills", "Phone", "Experience"]; // Adjust Jobseeker columns

  //   const rows = data.map((item, index) =>
  //     role === "employer"
  //       ? [
  //         index + 1,
  //         item.firstName + " " + item.lastName || "N/A",
  //         item.email || "N/A",
  //         item.company || "N/A",
  //         item.phone || item.phoneNumber || "N/A",
  //       ]
  //       : [
  //         index + 1,
  //         item.firstName + " " + item.lastName || "N/A",
  //         item.email || "N/A",
  //         item.skills || "N/A",
  //         item.phone || item.phoneNumber || "N/A",
  //         item.experience || "N/A",
  //       ]
  //   );

  //   autoTable(doc, { head: [columns], body: rows, startY: 20 });

  //   doc.save(`${role}_Report.pdf`);
  // };

  // const generatePDF = (data, role) => {
  //   if (!data || data.length === 0) {
  //     console.error(`No data available for ${role}`);
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   doc.text(`${role.charAt(0).toUpperCase() + role.slice(1)} Report`, 14, 10);

  //   const columns = role === "employer"
  //     ? ["ID", "Name", "Email", "Company", "Phone"]
  //     : ["ID", "Name", "Email", "Skills", "Phone", "Experience"];

  //   const rows = data.map((item, index) => [
  //     index + 1,
  //     item.firstName + " " + (item.lastName || ""),
  //     item.email || "N/A",
  //     item.skills || item.company || "N/A",
  //     item.phone || item.phoneNumber || "N/A",
  //     role !== "employer" ? (item.experience || "N/A") : "", // Only for jobseekers
  //   ]);

  //   autoTable(doc, {
  //     head: [columns],
  //     body: rows,
  //     startY: 20,
  //     theme: "grid",
  //     styles: { fontSize: 7, cellPadding: 1 },
  //     columnStyles: {
  //       0: { cellWidth: 10 }, // ID
  //       1: { cellWidth: 40 }, // Name
  //       2: { cellWidth: 30 }, // Email
  //       3: { cellWidth: 50 }, // Skills/Company
  //       4: { cellWidth: 25 }, // Phone
  //       5: { cellWidth: 20 }, // Experience (Jobseeker only)
  //     },
  //   });

  //   doc.save(`${role}_Report.pdf`);
  // };


  const fetchDataAndGenerateReport = async (role, format) => {
    if (role === "employer") setLoadingEmployer(true);
    if (role === "jobseeker") setLoadingJobseeker(true);

    try {
      const response = await axios.get(`${apiUrl}/user/roles/${role}`);
      console.log(`${role} Data:`, response.data);

      if (format === "PDF") {
        exportToPDF(response.data.data, role);
      } else {
        exportToExcel(response.data.data, role);
      }
    } catch (error) {
      console.error(`Error fetching ${role} data:`, error);
    } finally {
      setSelectedRole(null);
      if (role === "employer") setLoadingEmployer(false);
      if (role === "jobseeker") setLoadingJobseeker(false);
    }
  };

  // const exportToPDF = (data, role) => {
  //   if (!data || data.length === 0) {
  //     console.error(`No data available for ${role}`);
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   let y = 15;

  //   doc.setFontSize(16);
  //   doc.setTextColor(40, 40, 40);
  //   doc.text(`${role.charAt(0).toUpperCase() + role.slice(1)} Report`, 14, y);
  //   y += 10;

  //   data.forEach((item, index) => {
  //     doc.setFontSize(12);
  //     doc.setTextColor(0, 102, 204); // Blue color for section titles
  //     doc.text(`Record ${index + 1}`, 14, y);
  //     y += 7;

  //     doc.setFontSize(10);
  //     doc.setTextColor(0, 0, 0);
  //     doc.text(`Name: ${item.firstName} ${item.lastName || ""}`, 14, y);
  //     y += 6;
  //     doc.text(`Email: ${item.email || "N/A"}`, 14, y);
  //     y += 6;

  //     if (role === "employer") {
  //       doc.text(`Company: ${item.company || "N/A"}`, 14, y);
  //       y += 6;
  //     } else {
  //       doc.text(`Skills: ${item.skills ? item.skills.join(", ") : "N/A"}`, 14, y);
  //       y += 6;
  //       doc.text(`Experience: ${item.experience || "N/A"}`, 14, y);
  //       y += 6;
  //     }

  //     doc.text(`Phone: ${item.phone || item.phoneNumber || "N/A"}`, 14, y);
  //     y += 10;

  //     if (y > 280) {
  //       doc.addPage();
  //       y = 15;
  //     }
  //   });

  //   doc.save(`${role}_Report.pdf`);
  // };


  const exportToPDF = (data, role) => { 
    if (!data || data.length === 0) {
        console.error(`No data available for ${role}`);
        return;
    }

    const doc = new jsPDF();
    let y = 15;

    doc.setFontSize(16);
    doc.setTextColor(40, 40, 40);
    doc.text(`${role.charAt(0).toUpperCase() + role.slice(1)} Report`, 14, y);
    y += 10;

    data.forEach((item, index) => {
        doc.setFontSize(12);
        doc.setTextColor(0, 102, 204); // Blue color for section titles
        doc.text(`Record ${index + 1}`, 14, y);
        y += 7;

        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);

        // Define fields to include
        const fields = [
            { label: "First Name", value: item.firstName || "N/A" },
            { label: "Last Name", value: item.lastName || "N/A" },
            { label: "Email", value: item.email || "N/A" },
            { label: "Phone", value: item.phone || item.phoneNumber || "N/A" },
            { label: "Current Salary", value: item.CurrentSalary || item.salary || "N/A" },
            { label: "Qualification", value: item.Qualification || item.qualification || "N/A" },
            { label: "Expected Salary", value: item.ExpectedSalary || "N/A" },
            { label: "Ready for Relocation", value: item.Relocation || "N/A" },
            { label: "Current Location", value: item.city || "N/A" },
            { label: "Job Title", value: item.profileTitle || "N/A" },
            { label: "Interested Job Field", value: item.InterestedJobField || "N/A" },
            { label: "Languages Known", value: item.LanguagesKnown || "N/A" },
            { label: "Job Responsibilities", value: item.responsibilities || "N/A" },
            { label: "Notice Period", value: item.NoticePeriod || "N/A" },
            { label: "DL Details", value: item.Addl || "N/A" },
            { label: "Additional Details", value: item.Addl2 || "N/A" },
            { label: "Jobseeker Status", value: item.JobseekerFeedback || "N/A" },
            { label: "CSE", value: item.CSE || "N/A" }
        ];

        // Add fields to PDF
        fields.forEach(field => {
            doc.text(`${field.label}: ${field.value}`, 14, y);
            y += 8;

            // Check if we need to add a new page
            if (y > 270) {
                doc.addPage();
                y = 15;
            }
        });

        // Handle remarks (multi-line text)
        if (item.Remarks) {
            doc.text("Remarks:", 14, y);
            y += 6;
            const remarksLines = doc.splitTextToSize(item.Remarks, 180);
            remarksLines.forEach(line => {
                doc.text(line, 18, y);
                y += 6;

                if (y > 270) {
                    doc.addPage();
                    y = 15;
                }
            });
        }

        // Handle skills (multi-line text)
        if (item.skills) {
            doc.text("Skills:", 14, y);
            y += 6;
            const skillsLines = doc.splitTextToSize(item.skills, 180);
            skillsLines.forEach(line => {
                doc.text(line, 18, y);
                y += 6;

                if (y > 270) {
                    doc.addPage();
                    y = 15;
                }
            });
        }

        y += 10; // Space between records

        // Final check for page break before next record
        if (y > 270) {
            doc.addPage();
            y = 15;
        }
    });

    doc.save(`${role}_Report.pdf`);
};


  

  const exportToExcel = (data, role) => {
    console.log("excel", data)
    if (!data || data.length === 0) {
      console.error(`No data available for ${role}`);
      return;
    }

    const formattedData = data.map(item => ({
      FirstName: `${item.firstName} `,
      LastName: `${item.lastName} `,
      Email: item.email || "N/A",
      Phone: item.phone || item.phoneNumber || "N/A",
      Current_Salary: item.CurrentSalary || item.salary || "N/A",
      Qualification: item.Qualification || item.qualification || "N/A",
      Expected_Salary: item.ExpectedSalary || item.ExpectedSalary || "N/A",
      Ready_for_relocation: item.Relocation  || "N/A",
      Current_Location: item.city  || "N/A",
      Job_Title: item.profileTitle  || "N/A",
      Interested_Job_Field: item.InterestedJobField  || "N/A",
      Languages_Known: item.LanguagesKnown  || "N/A",
      Job_Responsibilities: item.responsibilities  || "N/A",
      Notice_Period: item.NoticePeriod  || "N/A",
      DL_Details: item.Addl  || "N/A",
      ADDL_Details: item.Addl2    || "N/A",
      Remarks: item.Remarks    || "N/A",
      Jobseeker_Status: item.JobseekerFeedback  || "N/A",
      CSE: item.CSE    || "N/A",

      ...(role === "employer"
        ? { Company: item?.companyName || "N/A" }
        : { Skills: item.skills ? item.skills.join(", ") : "N/A", Experience: item.experience || "N/A" }
      ),
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${role} Report`);

    XLSX.writeFile(wb, `${role}_Report.xlsx`);
  };


  const handleDownloadClick = (status) => {
    setSelectedStatus(status);
    setShowModal(true);
  };

  const handleDownload = () => {
    setShowModal(false);
    if (selectedFormat === "PDF") {
      generatePDF(getJobSeekerData(selectedStatus), selectedStatus);
    } else {
      generateExcel(getJobSeekerData(selectedStatus), selectedStatus);
    }
  };

  const getJobSeekerData = (status) => {
    switch (status) {
      case "Applied":
        return appliedJobSeekers;
      case "Shortlisted":
        return shortlistedJobSeekers;
      case "InterviewScheduled":
        return interviewScheduledJobSeekers;
      case "Hired":
        return hiredJobSeekers;
      default:
        return [];
    }
  };

  // const generatePDF = (jobSeekerData, status) => {
  //   if (jobSeekerData.length === 0) {
  //     console.error(`No data available for ${status} report.`);
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   let y = 20;

  //   doc.setFontSize(18);
  //   doc.setTextColor(0, 51, 102);
  //   doc.text(`${status} Jobseeker Report`, 60, y);
  //   y += 10;

  //   jobSeekerData.forEach((jobSeeker, index) => {
  //     doc.setFontSize(14);
  //     doc.setTextColor(255, 69, 0);
  //     doc.text(`Jobseeker ${index + 1}`, 14, y);
  //     y += 7;

  //     doc.setTextColor(0, 0, 0);
  //     doc.setFontSize(11);

  //     doc.setFont("helvetica", "bold");
  //     doc.text("Name:", 14, y);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(`${jobSeeker.data.firstName} ${jobSeeker.data.lastName || ""}`, 50, y);
  //     y += 6;

  //     doc.setFont("helvetica", "bold");
  //     doc.text("Email:", 14, y);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(jobSeeker.data.email || "N/A", 50, y);
  //     y += 6;

  //     doc.setFont("helvetica", "bold");
  //     doc.text("Phone:", 14, y);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(jobSeeker.data.phone || jobSeeker.data.phoneNumber || "N/A", 50, y);
  //     y += 6;

  //     doc.setFont("helvetica", "bold");
  //     doc.text("Skills:", 14, y);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(jobSeeker.data.skills ? jobSeeker.data.skills.join(", ") : "N/A", 50, y);
  //     y += 6;

  //     doc.setFont("helvetica", "bold");
  //     doc.text("Experience:", 14, y);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(jobSeeker.data.experience || "N/A", 50, y);
  //     y += 10;
      

  //     doc.setDrawColor(100, 100, 100);
  //     doc.line(14, y, 190, y);
  //     y += 10;

  //     if (y > 270) {
  //       doc.addPage();
  //       y = 20;
  //     }
  //   });

  //   doc.save(`${status}_Jobseeker_Report.pdf`);
  // };

  const generatePDF = (jobSeekerData, status) => {
    if (jobSeekerData.length === 0) {
      console.error(`No data available for ${status} report.`);
      return;
    }
  
    const doc = new jsPDF();
    let y = 20;
  
    doc.setFontSize(18);
    doc.setTextColor(0, 51, 102);
    doc.text(`${status} Jobseeker Report`, 60, y);
    y += 15; // Increased space
  
    jobSeekerData.forEach((jobSeeker, index) => {
      doc.setFontSize(14);
      doc.setTextColor(255, 69, 0);
      doc.text(`Jobseeker ${index + 1}`, 14, y);
      y += 10; // Increased spacing
  
      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
  
      const fields = [
        { label: "Name", value: `${jobSeeker.data.firstName} ${jobSeeker.data.lastName || ""}` },
        { label: "Email", value: jobSeeker.data.email || "N/A" },
        { label: "Phone", value: jobSeeker.data.phone || jobSeeker.data.phoneNumber || "N/A" },
        { label: "Current Salary", value: jobSeeker.data.CurrentSalary || jobSeeker.data.salary || "N/A" },
        { label: "Qualification", value: jobSeeker.data.Qualification || jobSeeker.data.qualification || "N/A" },
        { label: "Expected Salary", value: jobSeeker.data.ExpectedSalary || "N/A" },
        { label: "Ready for Relocation", value: jobSeeker.data.Relocation || "N/A" },
        { label: "Current Location", value: jobSeeker.data.city || "N/A" },
        { label: "Job Title", value: jobSeeker.data.profileTitle || "N/A" },
        { label: "Interested Job Field", value: jobSeeker.data.InterestedJobField || "N/A" },
        { label: "Languages Known", value: jobSeeker.data.LanguagesKnown || "N/A" },
        { label: "Job Responsibilities", value: jobSeeker.data.responsibilities || "N/A" },
        { label: "Notice Period", value: jobSeeker.data.NoticePeriod || "N/A" },
        { label: "DL Details", value: jobSeeker.data.Addl || "N/A" },
        { label: "Additional Details", value: jobSeeker.data.Addl2 || "N/A" },
        { label: "Jobseeker Status", value: jobSeeker.data.JobseekerFeedback || "N/A" },
        { label: "CSE", value: jobSeeker.data.CSE || "N/A" },
        { label: "Skills", value: jobSeeker.data.skills ? jobSeeker.data.skills.join(", ") : "N/A" },
        { label: "Experience", value: jobSeeker.data.experience || "N/A" }
      ];
  
      fields.forEach(field => {
        // doc.setFont("helvetica", "bold");
        doc.text(`${field.label}:`, 14, y);
        // doc.setFont("helvetica", "normal");
  
        const textLines = doc.splitTextToSize(field.value, 150);
        textLines.forEach(line => {
          doc.text(line, 60, y); // Adjusted alignment
          y += 8; // Increased spacing
        });
  
        if (y > 270) {
          doc.addPage();
          y = 20;
        }
      });
  
      if (jobSeeker.data.Remarks) {
        // doc.setFont("helvetica", "bold");
        doc.text("Remarks:", 14, y);
        // doc.setFont("helvetica", "normal");
  
        const remarksLines = doc.splitTextToSize(jobSeeker.data.Remarks, 150);
        remarksLines.forEach(line => {
          doc.text(line, 60, y);
          y += 8;
        });
      }

      // Handle skills (Multiline)
      if (jobSeeker.data.skills) {
        // doc.setFont("helvetica", "bold");
        doc.text("Skills:", 14, y);
        // doc.setFont("helvetica", "normal");
  
        const skillsLines = doc.splitTextToSize(jobSeeker.data.skills, 150);
        skillsLines.forEach(line => {
          doc.text(line, 50, y);
          y += 6;
        });
      }
  
      y += 12; // Extra spacing
  
      doc.setDrawColor(100, 100, 100);
      doc.line(14, y, 190, y);
      y += 12;
  
      if (y > 270) {
        doc.addPage();
        y = 20;
      }
    });
  
    doc.save(`${status}_Jobseeker_Report.pdf`);
  };
  
  


  const generateExcel = (jobSeekerData, status) => {
    if (jobSeekerData.length === 0) {
      console.error(`No data available for ${status} report.`);
      return;
    }

    // const worksheet = XLSX.utils.json_to_sheet(
    //   jobSeekerData.map(jobSeeker => ({
    //     Name: `${jobSeeker.data.firstName} ${jobSeeker.data.lastName || ""}`,
    //     Email: jobSeeker.data.email || "N/A",
    //     Phone: jobSeeker.data.phone || jobSeeker.data.phoneNumber || "N/A",
    //     Skills: jobSeeker.data.skills ? jobSeeker.data.skills.join(", ") : "N/A",
    //     Experience: jobSeeker.data.experience || "N/A",
    //     Salary: jobSeeker.data.salary || "N/A",
    //   }))
    // );

    const worksheet = XLSX.utils.json_to_sheet(
      jobSeekerData.map(jobSeeker => ({
        First_Name: jobSeeker.data.firstName || "",
        Last: jobSeeker.data.lastName || "",
        Email: jobSeeker.data.email || "N/A",
        Phone: jobSeeker.data.phone || jobSeeker.data.phoneNumber || "N/A",
        Current_Salary: jobSeeker.data.CurrentSalary || jobSeeker.data.salary || "N/A",
        Qualification: jobSeeker.data.Qualification || jobSeeker.data.qualification || "N/A",
        Expected_Salary: jobSeeker.data.ExpectedSalary || "N/A",
        Ready_for_Relocation: jobSeeker.data.Relocation || "N/A",
        Current_Location: jobSeeker.data.city || "N/A",
        Job_Title: jobSeeker.data.profileTitle || "N/A",
        Interested_Job_Field: jobSeeker.data.InterestedJobField || "N/A",
        Languages_Known: jobSeeker.data.LanguagesKnown || "N/A",
        Job_Responsibilities: jobSeeker.data.responsibilities || "N/A",
        Notice_Period: jobSeeker.data.NoticePeriod || "N/A",
        DL_Details: jobSeeker.data.Addl || "N/A",
        Additional_Details: jobSeeker.data.Addl2 || "N/A",
        Jobseeker_Status: jobSeeker.data.JobseekerFeedback || "N/A",
        CSE: jobSeeker.data.CSE || "N/A",
        Skills: jobSeeker.data.skills ? jobSeeker.data.skills.join(", ") : "N/A",
        Experience: jobSeeker.data.experience || "N/A",
        Remarks: jobSeeker.data.Remarks || "N/A",
      }))
    );
    

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Jobseekers");

    XLSX.writeFile(workbook, `${status}_Jobseeker_Report.xlsx`);
  };


  // const generateAppliedPDF = () => generatePDF(appliedJobSeekers, "Applied");
  // const generateShortlistedPDF = () => generatePDF(shortlistedJobSeekers, "SHORTLISTED");
  // const generateInterviewScheduledPDF = () => generatePDF(interviewScheduledJobSeekers, "INTERVIEW_SCHEDULED");
  // const generateHiredPDF = () => generatePDF(hiredJobSeekers, "HIRED");

  const generatealljobseekerPDF = (data, role) => {
    if (!data || data.length === 0) {
      console.error(`No data available for ${role}`);
      return;
    }

    const doc = new jsPDF();
    let y = 15;

    doc.setFontSize(16);
    doc.text(`${role.charAt(0).toUpperCase() + role.slice(1)} Report`, 14, y);
    y += 10;

    data.forEach((item, index) => {
      doc.setFontSize(12);
      doc.text(`Record ${index + 1}`, 14, y);
      y += 7;

      doc.setFontSize(10);
      doc.text(`Name: ${item.firstName} ${item.lastName || ""}`, 14, y);
      y += 6;
      doc.text(`Email: ${item.email || "N/A"}`, 14, y);
      y += 6;

      if (role === "employer") {
        doc.text(`Company: ${item.company || "N/A"}`, 14, y);
        y += 6;
      } else {
        doc.text(`Skills: ${item.skills || "N/A"}`, 14, y);
        y += 6;
        doc.text(`Experience: ${item.experience || "N/A"}`, 14, y);
        y += 6;
      }

      doc.text(`Phone: ${item.phone || item.phoneNumber || "N/A"}`, 14, y);
      y += 10;

      if (y > 280) { // Avoid page overflow
        doc.addPage();
        y = 15;
      }
    });

    doc.save(`${role}_Report.pdf`);
  };



  useEffect(() => {
    fetchApplicationsAndJobSeekerData();
  }, []);



  const fetchApplicationsAndJobSeekerData = async () => {
    setLoadingJobseeker(true);
    try {
      // const token = localStorage.getItem("token");
      if (!token) {
        console.error("Error: Authentication token is missing");
        setLoadingJobseeker(false);
        return;
      }

      // Fetch applications
      const response = await axios.get(`${apiUrl}/applications`, {
        headers: { Authorization: `${token}` },
      });

      let applications = response.data?.data?.data;
      console.log("appp", applications);

      if (!Array.isArray(applications)) {
        console.error("Error: applications is not an array", applications);
        setLoadingJobseeker(false);
        return;
      }

      // Filter applications by status
      const appliedApplications = applications.filter(app => app.status === "APPLIED");
      const shortlistedApplications = applications.filter(app => app.status === "SHORTLISTED");
      const interviewScheduledApplications = applications.filter(app => app.status === "INTERVIEWSCHEDULED");
      const hiredApplications = applications.filter(app => app.status === "HIRED");

      // Extract job seeker IDs
      const fetchJobSeekerData = async (jobSeekerIds) => {
        return await Promise.all(
          jobSeekerIds.map(async (id) => {
            try {
              const jobSeekerResponse = await axios.get(`${apiUrl}/user/${id}`, {
                headers: { Authorization: `${token}` },
              });
              console.log("kdmm", jobSeekerResponse.data)
              return jobSeekerResponse.data;
            } catch (error) {
              console.error(`Error fetching jobseeker data for ID: ${id}`, error);
              return null;
            }
          })
        );
      };

      // Fetch job seeker details for each category
      setAppliedJobSeekers((await fetchJobSeekerData(appliedApplications.map(app => app.jobSeekerId))).filter(js => js !== null));
      setShortlistedJobSeekers((await fetchJobSeekerData(shortlistedApplications.map(app => app.jobSeekerId))).filter(js => js !== null));
      setInterviewScheduledJobSeekers((await fetchJobSeekerData(interviewScheduledApplications.map(app => app.jobSeekerId))).filter(js => js !== null));
      setHiredJobSeekers((await fetchJobSeekerData(hiredApplications.map(app => app.jobSeekerId))).filter(js => js !== null));

    } catch (error) {
      console.error("Error fetching applications:", error);
    }
    setLoadingJobseeker(false);
  };



  const fetchJobs = async () => {
    setLoadingJobs(true);
    try {
      const response = await axios.get(`${apiUrl}/job`); // Replace with your actual API endpoint
      console.log("Jobs Data:", response.data);
      setJobs(response.data.data); // Assuming the response has a `data` field

      generateJobPDF(response.data.data); // Call function to generate PDF
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoadingJobs(false);
    }
  };

  const generateJobPDF = (data) => {
    if (!data || data.length === 0) {
      console.error("No job data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    doc.text("Job Report", 14, 10); // Title

    // Define table columns
    const columns = ["Job ID", "Title", "Company", "Location", "Salary"];
    const rows = data.map((job, index) => [
      job.id || index + 1,
      job.jobTitle || "N/A",
      job?.postedBy?.companyName || "N/A",
      job.location || "N/A",
      job.salary || "N/A",
    ]);

    // ✅ Use autoTable to generate the PDF table
    autoTable(doc, {
      head: [columns],
      body: rows,
      startY: 20,
    });

    doc.save("Job_Report.pdf");
  };
  useEffect(() => {
    const fetchApplicants = async () => {
      if (!userId || !apiUrl) return; // Prevent unnecessary API calls

      try {
        const response = await axios.get(`${apiUrl}/job/admin/${userId}`, {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        });

        setJobData(response.data.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [userId, apiUrl]);



  return (
    <div>
      <EmployerDashboardHeader />
      <div>
        <Row>
          <Col>
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="reports-box">
            <Row className='g- pt-2'>
              <Col md={4} sm={6} className="mt-2">
                <Card className="custom-card reports-dash">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga text-white">Total Job Seekers</span>
                        <h3 className="fw-semibold lh-1 mb-0 text-white">{jobData.totalJobseekers}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-violet avatar-rounded">
                          <FaClipboardList size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} sm={6} className="mt-2">
                <Card className="custom-card  reports-dash">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga text-white">Total Active Jobs</span>
                        <h3 className="fw-semibold lh-1 mb-0 text-white">{jobData.activeJobs}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-ora avatar-rounded">
                          <FaBriefcase size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} sm={6} className="mt-2">
                <Card className="custom-card  reports-dash">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span className="d-block mb-3 fw-medium text-ga text-white">Total Employers</span>
                        <h3 className="fw-semibold lh-1 mb-0 text-white">{jobData.totalEmployers}</h3>
                      </div>
                      <div className="text-end">
                        <span className="avatar avatar-md bg-green avatar-rounded">
                          <FaUsers size={18} />
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>


              <Col md={10} className="reports mt-5">


                <Button className="w-50" onClick={() => setSelectedRole("employer")} disabled={loadingEmployer}>
                  {loadingEmployer ? <Spinner animation="border" size="sm" /> : "Download Employer Data"}
                </Button>

                <Button className="w-50 mt-2" onClick={() => setSelectedRole("jobseeker")} disabled={loadingJobseeker}>
                  {loadingJobseeker ? <Spinner animation="border" size="sm" /> : "Download Jobseeker Data"}
                </Button>
                <Button className="w-50 mt-2" onClick={() => fetchJobs()} disabled={loadingJobseeker}>
                  {loadingJobs ? <Spinner animation="border" size="sm" /> : "Download JD's Data"}
                </Button>
                <Button className="w-50 mt-2" onClick={() => handleDownloadClick("Applied")} disabled={loadingJobseeker}>
                  {loadingJobseeker ? <Spinner animation="border" size="sm" /> : "Download Applied Status"}
                </Button>

                <Button className="w-50 mt-2" onClick={() => handleDownloadClick("Shortlisted")} disabled={loadingJobseeker}>
                  {loadingJobseeker ? <Spinner animation="border" size="sm" /> : "Download Shortlisted Status"}
                </Button>

                <Button className="w-50 mt-2" onClick={() => handleDownloadClick("InterviewScheduled")} disabled={loadingJobseeker}>
                  {loadingJobseeker ? <Spinner animation="border" size="sm" /> : "Download Interview Scheduled Status"}
                </Button>

                <Button className="w-50 mt-2" onClick={() => handleDownloadClick("Hired")} disabled={loadingJobseeker}>
                  {loadingJobseeker ? <Spinner animation="border" size="sm" /> : "Download Hired Status"}
                </Button>




              </Col>



            </Row>



          </Col>


        </Row>

        {/* Modal for selecting format */}
        <Modal show={!!selectedRole} onHide={() => setSelectedRole(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Download {selectedRole} Report</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Select format:</p>
            <select className="form-select" value={selectedFormat} onChange={(e) => setSelectedFormat(e.target.value)}>
              <option value="PDF">PDF</option>
              <option value="Excel">Excel</option>
            </select>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setSelectedRole(null)}>Cancel</Button>
            <Button variant="primary" onClick={() => fetchDataAndGenerateReport(selectedRole, selectedFormat)}>Download</Button>
          </Modal.Footer>
        </Modal>



        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Download {selectedStatus} Report</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Select format:</p>
            <select className="form-select" value={selectedFormat} onChange={(e) => setSelectedFormat(e.target.value)}>
              <option value="PDF">PDF</option>
              <option value="Excel">Excel</option>
            </select>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
            <Button variant="primary" onClick={handleDownload}>Download</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
};
