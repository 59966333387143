// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list {
  list-style: none;
  padding: 0;
}

.custom-list li {
  position: relative;
  padding-left: 25px;
}
ul li{
  font-size: 16px;
}
.custom-list li::before {
  content: "✔"; /* Tick mark */
  position: absolute;
  left: 0;
  color: green;
  font-weight: bold;
}

.innerul {
  list-style: none;
  padding-left: 25px;
  margin-left:-350px;

}

.innerul li::before {
  content: "●"; /* Dot bullet */
  position: absolute;
  left: 10px;
  color: black;
  font-weight: bold;
}

@media (max-width: 480px) {
  .custom-list li::before {
  }
  .custom-list li::before {
    content: "✔"; /* Tick mark */
    position: absolute;
    left: 0;
    color: green;
    font-weight: bold;
    font-size: 15px;
  }
  .container{
    overflow: auto;
  }

  .innerul li::before {
    font-size: 20px;
  }
  ul li {
    font-size: 11px;
  }
  .enroll{
    font-size: 12px;
  }
  .enroll span{
    font-size: 15px !important;
  }
  .enroll a{
    font-size: 15px !important;
  }
  .enroll{
    width:100% !important;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/Styles/choosemodels.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,YAAY,EAAE,cAAc;EAC5B,kBAAkB;EAClB,OAAO;EACP,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;;AAEpB;;AAEA;EACE,YAAY,EAAE,eAAe;EAC7B,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;EACA;EACA;IACE,YAAY,EAAE,cAAc;IAC5B,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;EACA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,qBAAqB;EACvB;;AAEF","sourcesContent":[".custom-list {\r\n  list-style: none;\r\n  padding: 0;\r\n}\r\n\r\n.custom-list li {\r\n  position: relative;\r\n  padding-left: 25px;\r\n}\r\nul li{\r\n  font-size: 16px;\r\n}\r\n.custom-list li::before {\r\n  content: \"✔\"; /* Tick mark */\r\n  position: absolute;\r\n  left: 0;\r\n  color: green;\r\n  font-weight: bold;\r\n}\r\n\r\n.innerul {\r\n  list-style: none;\r\n  padding-left: 25px;\r\n  margin-left:-350px;\r\n\r\n}\r\n\r\n.innerul li::before {\r\n  content: \"●\"; /* Dot bullet */\r\n  position: absolute;\r\n  left: 10px;\r\n  color: black;\r\n  font-weight: bold;\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .custom-list li::before {\r\n  }\r\n  .custom-list li::before {\r\n    content: \"✔\"; /* Tick mark */\r\n    position: absolute;\r\n    left: 0;\r\n    color: green;\r\n    font-weight: bold;\r\n    font-size: 15px;\r\n  }\r\n  .container{\r\n    overflow: auto;\r\n  }\r\n\r\n  .innerul li::before {\r\n    font-size: 20px;\r\n  }\r\n  ul li {\r\n    font-size: 11px;\r\n  }\r\n  .enroll{\r\n    font-size: 12px;\r\n  }\r\n  .enroll span{\r\n    font-size: 15px !important;\r\n  }\r\n  .enroll a{\r\n    font-size: 15px !important;\r\n  }\r\n  .enroll{\r\n    width:100% !important;\r\n  }\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
