import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Badge, Form, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaBriefcase, FaClipboardList, FaUsers, FaChartLine } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import Bottom from './Home/bottom';
import '../Styles/Homepage.css'


const EmployerDashboard = () => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("accessToken");
  const employerid = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [joblistData, setjoblistData] = useState([])
  const [joblistPostedData, setjoblistPostedData] = useState([])

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/job/posted/${employerid}`, {
        // const response = await axios.get(`${apiUrl}/job/employer/postedById`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setJobData(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);

  useEffect(() => {
    const joblist = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        // const response = await axios.get(`${apiUrl}/job/employer/${employerid}`, {
        const response = await axios.get(`${apiUrl}/job/employerDashboard/${employerid}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        console.log(response.data.data)
        console.log("res", response.data.data);

        // if (Array.isArray(response.data.data)) {

        setjoblistData(response.data.data);
        setjoblistPostedData(response.data.data.jobListings);
        console.log("bzjb", joblistData)
        console.log("data", joblistPostedData)

        // } else {
        //   throw new Error('Expected data to be an array');
        // }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    joblist();
  }, [employerid]);





  const getJobStats = () => {
    const activeJobs = jobData.filter(job => job.status === 'Active').length;
    const inactiveJobs = jobData.filter(job => job.status === 'Inactive').length
    const totalJobs = jobData.length;
    console.log(totalJobs)
    const totalApplications = jobData.reduce((acc, job) => acc + job.applications, 0);
    console.log("totalJobs", totalJobs)

    return { activeJobs, inactiveJobs, totalJobs, totalApplications };
  };

  const handleStatusChange = (id) => {
    setJobData(prevJobs =>
      prevJobs.map(job =>
        job.id === id ? { ...job, status: job.status === 'Active' ? 'Inactive' : 'Active' } : job
      )
    );
  };

  const stats = getJobStats();
const userName=sessionStorage.getItem("userName") 

  return (
    <div className="dashboard">

      < EmployerDashboardHeader />

      <Container fluid className="content">

        <Row className='g-4'>
          <Col className='side'>
          <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="employerdashboard" style={{background:'#F5F6FA'}}>
          <h3 className='py-2 px-2'>{userName}</h3>

            <Row className='g-4 mt-2'>
              <Col md={3} sm={6}>
                <Card className="custom-card dash-box">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Total Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{joblistData.totalJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-violet avatar-rounded"><FaClipboardList size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card dash-box">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Active Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{joblistData.activeJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-ora avatar-rounded"><FaBriefcase size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card dash-box">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Inactive Jobs</span>
                        <h3 class="fw-semibold lh-1 mb-0">{joblistData.inactiveJobs}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-green avatar-rounded"><FaUsers size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={6}>
                <Card className="custom-card dash-box">
                  <Card.Body>
                    <div className="d-flex align-items-start justify-content-between flex-wrap">
                      <div>
                        <span class="d-block mb-3 fw-medium text-ga">Total Applications</span>
                        <h3 class="fw-semibold lh-1 mb-0">{joblistData.totalApplications}</h3>
                      </div>
                      <div class="text-end">
                        <div>
                          <span class="avatar avatar-md bg-blue avatar-rounded"><FaChartLine size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h5 className='mb-3 fw-bold mx-4'>Job Listing</h5>
                <div className='card border-0 rounded-0'>
                  <div className='card-body table-responsive'>
                    <Table hover className="table" >
                      <thead>
                        <tr className="tablehead text-center">
                          <th>Job Title</th>
                          <th>Industry</th>
                          <th>₹ Salary/Month</th>
                          {/* <th>Applications</th> */}
                          <th>Status</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {joblistPostedData.map((job) => (
                          <tr key={job.id}  className="tbodys text-center">
                            <td>{job.jobTitle}</td>
                            <td>{job.jobType}</td>
                            <td>₹ {job.salary}</td>
                            {/* <td>{job.applications}</td> */}
                            <td>
                              <Badge
                                bg={job.status === "Expired" ? 'danger' : 'success'}
                              >
                                {job.status === "Expired" ? 'Inactive' : 'Active'}
                              </Badge>
                            </td>
                            {/* <td>
                              <Form.Check
                                type="switch"
                                id={`edit-switch-${job.id}`}
                                checked={job.status === 'Active'}
                                onChange={() => handleStatusChange(job.id)}
                                style={{ color: "#014C9" }}
                                disabled={job.status === 'Active'}  // Disable the switch if deleted
                              />
                            </td> */}

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
     <Col className="bottomNavigate">
     <Bottom/>
     </Col> 
    </div>
  );
};

export default EmployerDashboard;
