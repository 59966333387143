import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@mui/material";

const statusOptions = ["APPLIED", "INTERVIEW_SCHEDULED", "SHORTLISTED", "REJECTED", "HIRED"];

const StatusPopup = ({ open, onClose, onUpdate, currentStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(selectedStatus);
    onClose(); // Close popup after updating
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{backgroundColor:"#2F2761",color:"white"}}>Change Applicant Status</DialogTitle>
      <DialogContent className="mt-4">
        <Select value={selectedStatus} onChange={handleChange} fullWidth>
             {/* <MenuItem value="" disabled>
               Select a status
             </MenuItem> */}
          {statusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusPopup;
