// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutul{
    list-style-type: none;
    margin-top: 50px !important;
    font-size: 16px !important;
    margin-bottom: 5px;
  }
  .aboutul li{
    color: #555;
    margin-bottom: 7px;
  }
  .btn-confirm{
    width: 150px;
  }
  .about{
    margin-top: 50px !important;
    font-size: 14px !important;
  }
  .abouthead{
    margin-top: 0px !important;
  }
 .aboutimg{
  margin-top: 200px !important;
 }
 .aboutvideo{
  margin-top:100px !important
 }`, "",{"version":3,"sources":["webpack://./src/Styles/Aboutus.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,0BAA0B;IAC1B,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,YAAY;EACd;EACA;IACE,2BAA2B;IAC3B,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;CACD;EACC,4BAA4B;CAC7B;CACA;EACC;CACD","sourcesContent":[".aboutul{\r\n    list-style-type: none;\r\n    margin-top: 50px !important;\r\n    font-size: 16px !important;\r\n    margin-bottom: 5px;\r\n  }\r\n  .aboutul li{\r\n    color: #555;\r\n    margin-bottom: 7px;\r\n  }\r\n  .btn-confirm{\r\n    width: 150px;\r\n  }\r\n  .about{\r\n    margin-top: 50px !important;\r\n    font-size: 14px !important;\r\n  }\r\n  .abouthead{\r\n    margin-top: 0px !important;\r\n  }\r\n .aboutimg{\r\n  margin-top: 200px !important;\r\n }\r\n .aboutvideo{\r\n  margin-top:100px !important\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
