import { useLocation, useNavigate } from 'react-router-dom';
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import EmployerDashboardSideBar from '../components/EmployerDashboardSideBar';
import { Container, Col, Row, Table, Form, Spinner, InputGroup, Button } from 'react-bootstrap';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Links, Typography } from "@mui/material";

export const AppliedJobDetails = () => {
  const location = useLocation();
  const { job } = location.state || {}; // Access the job data passed via navigate
  const navigate = useNavigate();
  return (
    <div>
      <EmployerDashboardHeader />
      <div >
        <Row>
          <Col >
            <EmployerDashboardSideBar />
          </Col>


          <Col md={10} style={{ width: '', background: '#F5F6FA' }}>
            {/* Breadcrumbs */}
            <div role="presentation" className="ms-2 py-3" >
              <Breadcrumbs maxItems={2} aria-label="breadcrumb" className='breadcrum'>


                <Links
                  underline="hover"
                  color="inherit"
                  onClick={() => navigate('/appliedjob')} // Navigate to previous Job Details page
                  style={{ cursor: "pointer" }}
                >
                  Applied Jobs
                </Links>

                <Typography sx={{ color: "#2F2761", fontWeight: 'bold' }}> Applied Job Details</Typography>
              </Breadcrumbs>
            </div>
            <Row>
              <Col style={{ padding: '0px' ,}}>
                <div style={{ padding: '10px', width: '100%', }}>
                  {job ? (
                    <div >
                      <h1 className='p-3' style={{ background: '#170F49', color: 'white', width: '100%' }}>{job?.job?.jobTitle} Details</h1>

                      <div style={{ marginTop: '20px', padding: '10px', background: 'white' }}>
                        <p>Job Title: {job?.job?.jobTitle}</p>
                        <p>Location: {job?.job?.location}</p>
                        <p>Job Description: {job?.description}</p>

                        {/* Responsibilities and Duties as a list */}
                        <div>
                          <p>Responsibilities and Duties:</p>
                          {job?.job?.responsibilitiesAndDuties && job?.job?.responsibilitiesAndDuties.length > 0 ? (
                            <ul style={{ marginTop: '-30px', marginBottom: '40px' }}>
                              {job?.job?.responsibilitiesAndDuties.map((responsibility, index) => (
                                <li key={index}>{responsibility}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>No responsibilities listed.</p>
                          )}
                        </div>

                        {/* Skills as a list */}
                        <div>
                          <p>Skills:</p>
                          {job?.job?.skills && job?.job?.skills.length > 0 ? (
                            <ul style={{ marginTop: '-30px', marginBottom: '40px' }}>
                              {job?.job?.skills.map((skill, index) => (
                                <li key={index}>{skill}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>No skills listed.</p>
                          )}
                        </div>

                        <p>Experience: {job?.job?.experience}</p>
                        <p>ExpectCTC: {job?.expectCTC}</p>
                        <p>CurrentCTC: {job?.currentCTC}</p>
                        <p>Qualification: {job?.job?.qualificationRequirements}</p>
                      </div>
                    </div>
                  ) : (
                    <p>No job details available.</p>
                  )}

                </div>
              </Col></Row>
          </Col>
        </Row>
      </div>

    </div>
  );
};
