import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import { Modal, Button } from 'react-bootstrap';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Pagination } from "swiper/modules";
import cus from '../assets/Frame.png';
import area from '../assets/Frame2.png';
import tech from '../assets/fram1.png';
import defaultprofile from '../assets/defaultprofile.webp';
import c5 from '../assets/logos/images.jpg';
import t1 from '../assets/testimonials/t1.jpg';
import t2 from '../assets/testimonials/images2.jpg';
import t3 from '../assets/testimonials/images3.jpg';
import t4 from '../assets/testimonials/images9.jpg'
import t5 from '../assets/testimonials/images10.jpg';
import t6 from '../assets/testimonials/images6.jpg';
import t7 from '../assets/testimonials/images8.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';
import { FaSuitcase } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import axios from 'axios';

import '../Styles/Homepage.css'

// import Notification from "./JobSeeker/Notification";
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";

import blogimg1 from "../../src/assets/images.jpeg"
import blogimg2 from "../../src/assets/imaes2.jpeg"
import blogimg3 from "../../src/assets/imaes3.png"
import { FaArrowRight } from "react-icons/fa";
import EmployerLogin from "../components/EmployerLogin";
import { ChevronLeft, ChevronRight } from "lucide-react";

import aboutVideoEnglish from "../assets/Videos/Workaroo_Intro_ENG.mp4"
import aboutVideoHindi from "../assets/Videos/Workaroo_Intro_HINDI.mp4"
import toast from "react-hot-toast";

import Bottom from "./Home/bottom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft } from "react-icons/fa";
import { City } from "country-state-city";

const HomePage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [, setIsAuthenticated] = useState(false);
  const [, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [showEmployerLoginPopup, setShowEmployerLoginPopup] = useState(false);
  const [, setNotification] = useState(null);
  const [, setJobListings] = useState([]);
  const [, setIsNotificationVisible] = useState(true); // State to control visibility


  const [, setNewFetchData] = useState()
  const [filter, setFilter] = useState(""); //already
  const [location, setLocation] = useState(''); //already
  const [jobSearchList, setJobSearchList] = useState([]); // 🔥 Job title, skills, company name
  const [locationSearchList, setLocationSearchList] = useState([]); // 🔥 Locations
  const [filteredJobResults, setFilteredJobResults] = useState([]);
  const [filteredLocationResults, setFilteredLocationResults] = useState([]);
  const scrollRef = useRef(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredJobResults([]); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownReflocation = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownReflocation.current && !dropdownReflocation.current.contains(event.target)) {
        setFilteredLocationResults([]); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 350; // Adjust scroll distance
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 350; // Adjust scroll distance
    }
  };


  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`);
        if (response.status === 200) {
          setNewFetchData(response.data.data);

          // 🔥 Extract job title, skills, company name separately
          const jobData = response.data.data.flatMap((job) => [
            job.jobTitle,
            // job.postedBy?.companyName,
          ]).filter(Boolean);

          // 🔥 Extract locations separately and split into individual locations
          const locationData = response.data.data.flatMap((job) =>
            job.location ? job.location.split(",").map((loc) => loc.trim()) : []
          ).filter(Boolean);

          // Remove duplicates
          setJobSearchList([...new Set(jobData)]);
          setLocationSearchList([...new Set(locationData)]);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };

    fetchJobs();
  }, [apiUrl]);


  useEffect(() => {
    if (filter) {
      const results = jobSearchList
        .filter((item) =>
          item.toLowerCase().includes(filter.toLowerCase())
        )
        .map((job) => job.charAt(0).toUpperCase() + job.slice(1)); // Capitalizing first letter

      setFilteredJobResults(results);
    } else {
      setFilteredJobResults([]);
    }
  }, [filter, jobSearchList]);



  useEffect(() => {
    if (location) {
      const results = locationSearchList
        .filter((item) =>
          item.toLowerCase().includes(location.toLowerCase())
        )
        .map((loc) => loc.charAt(0).toUpperCase() + loc.slice(1)); // Capitalizing first letter

      setFilteredLocationResults(results);
    } else {
      setFilteredLocationResults([]);
    }
  }, [location, locationSearchList]);


  console.log('filteredJobResults', filteredJobResults);
  console.log('filteredLocationResults', filteredLocationResults);
  console.log('filter', filter);
  console.log('location', location);



  const closeEmployerLoginPopup = () => {
    setShowEmployerLoginPopup(false);
  };




  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.swiper.slideNext();
  };

  const swiperRef = useRef(null);
  const [, setCategories] = useState([]);
  const [jobs, setJob] = useState([]);
  const [companies, setCompny] = useState([]);
  const colWidth = isLoggedIn ? 10 : 12;



  const [show, setShow] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);


  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');

    if (token) {
      setIsLoggedIn(true);
      // setSidebar(true)
    }

  }, []);

  const blogs = [
    {
      title: " Workaroo.ai | Revolutionizing Blue-Collar Recruitment with AI",
      description1: " ➤ Workaroo.ai is transforming the blue-collar job market by addressing inefficiencies in hiring, skill development, and language barriers through innovative AI-powered solutions.Our platform bridges the gap between employers and workers with features like precise AI-driven job matching, multilingual interfaces, and real-time skill enhancement",
      description2: " ➤ Employers benefit from reduced recruitment time and costs, while workers gain access to better opportunities and holistic support, including training, financial aid, and healthcare options. By leveraging AI for data-driven decisions, Workaroo ensures long-term sustainability and growth for both employers and workers.",
      description3: " ➤ The blue-collar sector is riddled with inefficiencies: Employers struggle to find skilled workers, positions remain unfilled, leading to operational disruptions and productivity losses.Workers face difficulties finding suitable jobs: Despite having the necessary skills, workers often remain unemployed or underemployed due to mismatched opportunities.Attrition and turnover rates are alarmingly high in this sector, which increases recruitment costs for employers. Skill gaps are prevalent, creating barriers to finding and retaining the right talent. Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot.",
      description4: " ➤ Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot. Our AI engine automates the process of matching workers to jobs based on a deep analysis of skills, experience, and job requirements.",
      description5: " ➤ By leveraging machine learning algorithms, we go beyond keyword-based searches, analyzing skill sets, experience level, and geographical preferences. This reduces time-to-hire for employers and increases job satisfaction for workers. Workaroo’s AI assesses candidates using AI-powered video interviews and audio profiles to offer richer insights. Multilingual AI interfaces allow workers to interact with the platform in their native languages. AI also recommends upskilling programs tailored to industry needs. By providing real-time recommendations, holistic worker support, and intelligent recruitment workflows, Workaroo ensures a happier, more stable workforce. Employers reduce costs and workers gain better opportunities, revolutionizing the blue-collar job market.",
      description6: " ➤ Creative Use of AI for Interviews and Candidate Insights: Workaroo’s AI doesn’t just match candidates—it assesses them. We use AI powered video interviews and audio profiles to offer a richer understanding of each worker. This is especially important in the blue-collar space, where traditional resumes often do not fully capture a worker’s potential. By analyzing: Voice and language proficiency, Facial expressions and body language during interviews, Technical knowledge through targeted interview questions, our AI provides employers with deeper insights into a worker's capabilities beyond what a typical resume would convey.",
      description7: " ➤  Breaking Language Barriers with Multilingual AI: Language is a major barrier in the blue-collar hiring process. Many workers are not comfortable with English centric job portals. Workaroo’s multilingual AI interface addresses this problem by allowing workers to interact with the platform in their native languages. This includes innovative ideas like: Audio and video resumes in multiple languages,Audio and video JDs(Job Descriptions) in multiple languages Multilingual job descriptions and communication, making the process more inclusive and effective. This feature significantly increases the pool of available talent for employers, giving them access to workers who were previously underserved by traditional platforms. ",
      description8: " ➤ AI Powered Skill Development and Upskilling: Workaroo goes beyond placement; we empower workers with personalized skill development. AI analyzes market trends and worker profiles to recommend specific upskilling programs tailored to both current job demands and future industry needs. This creates a sustainable talent pipeline, enabling workers to stay competitive and employers to benefit from a continually improving workforce. Workers are groomed before deployment to make sure they understand the company and environment, work procedures and expectations. This helps cementing their relationship with companies and increases the stickiness.  Workers receive real time recommendations for certifications, vocational training, and soft skill enhancement. ",
      description9: "  ➤ AI for Data Driven Decision Making: The intelligence layer of Workaroo’s platform uses AI to continuously analyze data from job placements, industry trends, and user interactions. This allows both employers and the platform itself to: Predict hiring trends and proactively address skill gaps. Optimize job postings based on historical success metrics. Improve employer decision making through real time insights into the workforce, reducing attrition by placing the right candidates in the right roles. This data driven approach helps employers stay ahead of market changes and ensures that workers are always aligned with the most relevant job opportunities. ",
      description10: "Creative AI Solutions That Set Workaroo Apart: ",
      description11: " ➤  Holistic Worker Support: Unlike traditional platforms, Workaroo doesn’t just stop at recruitment. Through AI powered solutions, we provide access to broader services such as training/skill upgradation enablement, accommodation assistance, financial aid, and healthcare options, addressing the broader needs of the blue-collar workforce. This ensures a happier, more stable workforce for employers. ",
      description12: " ➤ Focus on Skills Over Resumes: Our platform prioritizes skills assessment, ensuring that workers without formal education or detailed resumes are evaluated based on their hands on expertise. This levels the playing field for blue-collar workers. ",
      description13: " ➤ Intelligent Automation of Recruitment Workflow: From job description creation to resume filtering, Workaroo uses AI to automate repetitive tasks, freeing up HR teams to focus on strategic decisions. This drastically reduces recruitment costs for employers. ",
      description14: " ➤ Measurable Impact and Results: ",
      description15: " ➤ Workaroo’s AIpowered platform has already made significant strides: Placement success rate: Over 70% of job vacancies posted on the platform are filled with qualified candidates within two weeks. Reduction in recruitment time: Employers using Workaroo have reduced their recruitment time by over 40%, thanks to automated processes and precise matching. Upskilling initiatives: Workers on the platform receive continuous skill development recommendations, contributing to long term employability and lower turnover. ",
      description16: "Our Mission: ",
      description17: " ➤ Workaroo is not just another recruitment platform—it’s a comprehensive ecosystem built on the backbone of AI. We are empowering workers, solving industry challenges, and shaping the future of blue-collar recruitment. By investing in technology that fosters inclusivity, efficiency, and growth, we are revolutionizing the blue-collar job market, one placement at a time. #AIRecruitment #BlueCollarJobs #AIInnovation #WorkforceSolutions #TalentMatching #SkillDevelopment ",
      discription25: "",
      link: "/blogs/resume-tips",
      image: blogimg1
    },
    {
      title: "Bridging the Digital Divide: Making Tech-Driven Job Platforms More Accessible to India’s Blue-Collar Workforce ",
      description1: " ➤ In the small town of Nagpur, Ramesh, an experienced welder, wakes up every day hoping for work. His skills are sharp, but the local job market is unpredictable, and he relies heavily on contractors who offer inconsistent pay and limited opportunities.",
      description2: " ➤ If you look at the other side of the spectrum, across the country, companies in cities like Pune or Chennai are struggling to find skilled workers like him, despite the rapid rise of tech-driven job platforms.",
      description3: " ➤ Why the disconnect? For millions of blue-collar workers like Ramesh, these platforms are difficult to access due to barriers such as language, digital literacy, and access to technology.",
      description4: " ➤ This situation is not unique to Ramesh. India, with its massive 400m+ blue-collar workforce, is grappling with how to bring these workers into the fold of the digital economy.",
      description5: " ➤ While job platforms have revolutionized hiring for the white-collar workforce, particularly for IT services, they must adapt to meet the needs of workers on the ground. Let’s explore the challenges and the solutions that can help bridge this gap.",
      description6: " ➤ In a country as linguistically diverse as India, one of the first obstacles workers encounter on digital job platforms is language. The majority of these platforms operate in English which immediately alienates workers from states like UP, MP, Tamil Nadu, West Bengal, or Maharashtra, where local languages dominate. A daily wage worker from Kanpur who speaks only Hindi, may struggle to understand job descriptions or fill out application forms on an English-language platform.",
      description7: " ➤ Digital Literacy While smartphone penetration is growing across India, many workers are still not familiar with using technology beyond making phone calls or sending WhatsApp messages. Take Rekha, a domestic worker in Bengaluru. She has a smartphone but uses it mainly to communicate with family. When her friend suggested she look for jobs online, Rekha was unsure how to navigate the job portals or upload her information. Many blue-collar workers like Rekha lack the digital literacy needed to fully participate in the online job market. They are overwhelmed by the complexity of job applications, login processes, and resume creation, leading to frustration and disengagement. ",
      description8: " ➤ Access to Technology Though India is fast becoming a digital-first nation, access to technology remains a challenge for many in rural and semi-urban areas. Even those who own smartphones might struggle with unstable internet connections or expensive data plans. For example, in villages across Uttar Pradesh, workers may have to walk miles just to find reliable internet to apply for jobs online. While technology has improved a lot over last 2 decades still there are islands of poor connectivity. We had stories during COVID that students in some villages have to sit on the house roof to get the access to internet as connectivity was poor. The lack of affordable and accessible technology becomes a significant hurdle for workers to find better job opportunities. ",
      description9: "Solutions to Bridge the Gap ",
      description10: "1. Multilingual Platforms One of the most effective ways to make job platforms accessible is by providing interfaces in regional languages. In India, where over 22 official languages are spoken, job portals must be tailored to include languages such as Tamil, Telugu, Marathi, Bengali, and Kannada. Offering multilingual support can significantly ease the burden on workers. Consider the success of some e-commerce platforms that have integrated regional language options. When workers from rural Karnataka can search for jobs in Kannada or a laborer in Bengal can navigate a portal in Bengali, they are more likely to use these platforms confidently. ",
      description11: "2. Simple, User-Friendly Design   Platforms must be designed with the user in mind—especially those who are not tech-savvy. Instead of long forms or complex resume builders, platforms could offer features like audio and video profiles. A plumber from Bihar, for instance, could simply record a short video explaining his work experience rather than struggling to type out a resume. This not only makes the platform easier to use but allows employers to assess a worker’s skills more directly. In today’s world of AI, the video or audio profiles can be translated to text resumes.   Simplified navigation, easy-to-understand icons, and the use of everyday language rather than corporate jargon can also make these platforms more accessible. A construction worker should be able to apply for jobs without needing extensive technical knowledge. ",
      description12: "3. Mobile-First Approach In India, the mobile phone is the primary device for accessing the internet, especially in rural areas. Job platforms should be fully optimized for mobile use, ensuring they work efficiently even on low-cost smartphones. They should load quickly, use minimal data, and be accessible in areas with weak internet connections. Additionally, SMS-based job alerts can help bridge the technology gap. Workers can receive job notifications via SMS and respond with a simple message to apply. For Ramesh, the welder from Nagpur, receiving a text message about a job in Hyderabad that matches his skills could be the nudge he needs to access better-paying work. ",
      description13: "4. Digital Literacy and Training Programs While simplifying platforms is essential, so too is improving digital literacy. Workers need to be equipped with the basic skills to navigate online platforms confidently. Local governments, NGOs, and private companies can collaborate to set up digital literacy programs in communities. These programs could teach workers how to search for jobs online, create profiles, and apply for positions. There are already promising examples of this approach. In Kerala, the government has rolled out digital literacy programs that have helped thousands of citizens, including blue-collar workers, use technology to improve their employment prospects. Short 2/3 minute “how to do” kind of videos can go great extent to educate the users. There can be online distance learning programs in local languages which will be very productive.  ",
      description14: "5. Hybrid Models: Combining Offline and Online Efforts Not all workers are ready for a fully digital experience. This is where offline support can make a difference. By setting up local support centers or kiosks, workers can receive assistance in registering for platforms, building profiles, and applying for jobs. These centers(or Agents) could be run by local agencies or even mobile shops, painter or hardware shops providing a crucial bridge between workers and digital job portals. These agents can play a role in attracting, enabling, educating, grooming the workers.  A real-world example of this model in action comes from Andhra Pradesh, where local employment agencies assist workers in signing up for job portals. Workers who are unfamiliar with online systems are guided through the process and receive updates on job opportunities via SMS. ",
      description15: "A Win-Win for Workers and Employers ",
      description16: " ➤ The benefits of addressing these challenges are enormous—not just for workers, but for employers too. Indian companies often struggle to find skilled blue-collar workers. By making digital platforms more inclusive, they can tap into a vast, underserved talent pool. For example, a manufacturing company in Pune might find it easier to recruit electricians and welders from nearby villages through a platform that supports local languages and mobile-friendly applications. The recruitment process also becomes more streamlined. Features such as video resumes allow employers to assess candidates more thoroughly, reducing mismatches in hiring. The process becomes faster, more efficient, and ultimately more reliable for both workers and businesses. ",
      description17: " Conclusion ",
      description18: " ➤ As India races towards becoming a digital economy, we cannot afford to leave millions of blue-collar workers behind. By addressing language barriers, improving digital literacy, and ensuring broader access to technology, we can bridge the digital divide and open doors for workers like Ramesh and Rekha. This transition will not only improve the livelihoods of blue-collar workers across the country but also help businesses find the skilled workforce they need. In a country where opportunity and talent abound, it’s time to ensure that technology works for everyone. #DigitalDivide #InclusiveDigital #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #Multilingual  4th Aug 2024  Ravi Annadurai https://www.linkedin.com/in/arc1507/ ",
      discription25: "",
      link: "/blogs/interview-tips",
      image: blogimg2,
    },
    {
      title: "Upskilling the Blue-Collar Workforce: Industry’s Urgent and Critical need for Skill Development ",
      description1: " ➤ In the heart of India’s industrial belt, stories like that of Mohit, a seasoned welder from Ludhiana, are becoming increasingly common. Despite decades of experience, Mohit now finds himself at a crossroads. The machines in his factory have become more advanced, and the younger, tech-savvy workers are increasingly taking over tasks that once defined his career.",
      description2: " ➤ Mohit knows he needs new skills, but where can he learn them? And how will he afford it?",
      description3: " ➤ This scenario highlights the reality faced by millions of India’s blue-collar workers.",
      description4: " ➤ As technological advancements transform industries, the skills gap among these workers presents a critical challenge, threatening livelihoods, business profitability, and the overall health of the nation's economy.",
      description5: " ➤ The struggle to stay relevant in the changing job market is not just a personal issue for Mohit but a larger problem affecting millions of skilled workers across the country.",
      description6: " ➤ The Blue-Collar Skills Crisis in India ",
      description7: " ➤ India is home to one of the largest blue-collar workforces in the world. Estimates suggest that blue-collar workers account for nearly 90% of the country’s workforce. These workers are the backbone of industries such as construction, automotive manufacturing, logistics, and retail. Yet, according to reports, over 60% of India’s blue-collar workers lack formal training, with many relying solely on practical, hands-on experience. ",
      description8: " 1. Skill Gaps and Obsolete Knowledge Blue-collar workers often acquire their skills through informal channels, such as on-the-job learning or apprenticeships. While this form of learning has sufficed for years, it no longer matches the demands of today’s digital and automated industrial landscape. Take the manufacturing sector, for example. The transition to automated processes, including robotics and machine learning systems, has created a high demand for workers who understand and can operate advanced technologies. In contrast, a majority of the existing workforce is still equipped with outdated skills that were relevant decades ago. A welder might have mastered manual welding techniques but has no experience operating CNC (Computer Numerical Control) machines. Similarly, factory workers trained to operate mechanical tools are struggling to adapt to digitized production lines where everything is automated and controlled by software. ",
      description9: "2. Impact on Livelihoods of Workers The most immediate impact of this skills gap is on the workers themselves. The lack of relevant skills reduces their employability, making it difficult for them to secure stable, high-paying jobs. As industries automate and modernize, those without the necessary skills are either forced into low-paying, menial jobs or, worse, pushed out of the workforce entirely. Workers like Mohit, despite their years of experience, risk becoming irrelevant if they cannot upskill and adapt. The economic consequences of this are severe. For millions of workers, this skill gap translates to lower wages, job insecurity, and reduced opportunities for career growth. A factory worker who once earned a respectable wage may find his earnings dropping as companies increasingly value new skills over old experience. The cumulative effect is devastating for households, leading to a cycle of poverty and reduced social mobility. ",
      description10: "3. Adverse Effects on Businesses and Industry Health For businesses, the skill gap creates major operational challenges. Indian industries are heavily dependent on blue-collar labor, and the inability to find workers with the necessary skills leads to reduced productivity, higher operational costs, and longer production cycles. For example, a manufacturing company that has invested in automated machinery may find its operations stalled because it cannot find workers who can operate the new equipment efficiently. Furthermore, the lack of skilled workers reduces the competitiveness of entire industries. When companies are unable to keep up with technological advancements due to skill shortages, they lose out on contracts, miss deadlines, and face increased downtime.  Recently during my conversation with one Industrialist in Bangalore in the manufacturing space , he said that “the business can grow 70-80% year given the business inflow”. However he is able to grow the business only 25% due to lack of quality  manpower. That is an alarming extent of skills shortage problem. This in turn, affects profitability and limits the potential for growth. India’s automotive and manufacturing sectors, which are integral to its economy, are particularly vulnerable to these challenges. ",
      description11: " ➤  The Way Forward: Solutions to the Skills Problem ",
      description12: " ➤ To address the blue-collar skills crisis, a multi-pronged approach is necessary—one that involves not only workers but also businesses, educational institutions, NGOs and the government. Indian Govt has launched several initiatives and agencies to address the skill development namely National Skill development corporation, Skill India Digital and Pradhan Mantri Kaushal Vikas Yojana(launched in 2015) to encourage and promote skill development in the country. Towards finding innovative and deeper solutions to skill development, we have explored the following ideas. These solutions highlight how technology, innovative learning methods, and collaboration between stakeholders can help bridge the skills gap. ",
      description13: "1. Online Learning Platforms and E-Learning Tools In a country as vast as India, traditional vocational training programs are often limited by geography, accessibility, and cost. Online learning platforms are changing this. By offering courses that workers can access from their smartphones, these platforms are removing barriers to learning. Platforms such as Skill India, Coursera, and Udemy are providing affordable and relevant courses in fields like machine operation, plumbing, and automotive repair, making skill development more accessible to blue-collar workers across India. For example, a logistics worker in Uttar Pradesh who wants to learn about supply chain management can now do so through his phone, at his own pace. E-learning allows workers to upskill without having to leave their jobs, giving them the flexibility to learn new skills while still earning an income. ",
      description14: "2. Flexible Learning and Continuous Development A rigid curriculum no longer works in a rapidly changing world. Learning must be continuous and flexible, allowing workers to acquire new skills as industries evolve. For example, a CNC machine operator who has already completed basic training may need to return for refresher courses or learn additional skills as technology advances.  Flexible curriculums, where workers HOP in and HOP out of courses/learning modules based on their needs, will ensure that the workforce remains adaptable and ready for future changes. These learning pathways could be supported by micro-credentials and certificates, which workers can collect as they progress through different stages of learning. ",
      description15: "3. Industry’s Aggressive Role in Upskilling Industries cannot wait for workers to catch up with their technological advancements. Businesses must take an aggressive role in upskilling their employees. Many Indian companies are already partnering with training platforms to offer in-house training programs that prepare workers for the specific technologies and systems used in their operations. For example, large automotive manufacturers are providing training on how to operate and maintain robotic equipment, while logistics companies are teaching their workers how to use warehouse management software. This model not only fills immediate skill gaps but also invests in the long-term development of the workforce, creating a more capable and adaptable pool of workers. Training programs by Industries can help them to fulfil their requirements as well as improve their branding in terms of workers trained/certified by Company’s Academy.  Infact companies which hire blue collar workforce can take a cue from IT industry which spent lot of time and effort in training the white collar resources which ultimately created huge base of IT resources. Today it is helping newer Tech industries like GCC(Global Capability centers) and new age startups.  ",
      description16: "4. AI-Driven Skill Assessments One of the most exciting developments in the upskilling of blue-collar workers is the use of AI-driven skill assessments. AI can analyze a worker’s current skill set, identify gaps, and recommend personalized learning paths based on the individual’s experience and aspirations. For instance, a technician with basic electrical skills can take an AI-driven test that evaluates his readiness to handle more advanced electrical systems and suggests specific courses to close those gaps. For businesses, AI tools can also be used to assess their workforce’s strengths and weaknesses, enabling employers to provide targeted training programs. This ensures that workers are trained efficiently, saving companies time and resources while upskilling their workforce in areas critical to operations. ",
      description17: "5. Innovative Skill Development funding: Skill Development Vouchers  Skill development vouchers are an innovative approach to financing vocational training and skill development programs, aimed at increasing access to quality education and empowering individuals to choose the training programs best suited to their needs. These vouchers can be seen as a market-driven mechanism to enhance skills, often funded by the government or other agencies. Vouchers as Financial Aid: Individuals receive vouchers that cover part or all of the cost of vocational training or skill development courses. These vouchers are typically redeemable at approved training institutions. Choice and Flexibility: Vouchers give individuals the flexibility to choose from a variety of training providers, encouraging competition among institutions to offer high-quality training programs. Targeted Groups: These vouchers are often aimed at economically disadvantaged individuals or those in need of specific skill development, such as blue-collar workers, youth, or workers in the informal sector.",
      description18: " ➤ Conclusion: A Collective Responsibility ",
      description19: " ➤ The skill crisis in India’s blue-collar workforce is a multi-faceted problem that affects the livelihoods of millions of workers, threatens the profitability of businesses, and impacts the health of industries. However, the solution is not beyond reach. By leveraging technology, embracing AI-driven initiatives, reforming vocational training, and encouraging industries to play an active role in skill development, India can build a workforce that is future-ready. For workers like Mohit and the millions of others who rely on their skills for a living, upskilling is not just an option—it’s a necessity. With the right tools and support, India’s blue-collar workforce can adapt to the changing demands of the 21st century and continue to power the country’s economic growth. #IndustryDrivenTrainings #LifeLongSkillDevelopment #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #VocationalTrainings 1st Sep 2024  Ravi Annadurai ",
      discription25: "",
      link: "/blogs/remote-jobs-2025",
      image: blogimg3,
    },

  ];


  const handleShow = (blog) => {
    setCurrentBlog(blog);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentBlog(null);
    console.log('currentBlog', currentBlog);

  };

  const handleSearch = async () => {
    // if (!filter || !location) {
    //   // You might want to allow searching even if one of the parameters is missing
    //   alert("Please enter both skills and location.");
    //   return;
    // }
    if (filter || location) {
      navigate("/JobSearch", { state: { filters: filter, locations: location } });
      setLoading(true);

      try {
        const response = await axios.get(`${apiUrl}/job/joblist/filter`, {
          params: { filter, location },
        });

        setResults(response.data.data);

      } catch (error) {
        console.error("Error fetching results:", error.message || error);
        alert("Error fetching results. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
  }

  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    fetchJobApplicationStatus(); // Call once when component is mounted

    // No need for interval or repeated polling
  }, []); // Only fetch when userId changes


  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsAuthenticated(true);
      console.log('Token exists:', token);
    } else {
      setIsAuthenticated(false);
      console.log('Token does not exist');
    }
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/category`);
        setCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [apiUrl]);


  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(`${apiUrl}/company/topcompany`);
        setCompny(response.data.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCompany();
  }, [apiUrl]);


  const handleImageError = () => setImageError(true);
  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job/topjobs`);
        setJob(response.data.data);
        console.log("response:", response.data.data)
      } catch (error) {
        console.error('Error fetching fetchJob:', error);
      }
    };

    fetchJob();
  }, [apiUrl]);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const cards = [
    {
      id: 1,
      title: "Delivery Boys",
      icon: "🛵",
      name: 'Santanu Chatterjee',
      location: "	Durgapur",
      skills: ['Delivery', 'Time Management', 'Customer Service'], // Skills as an array
      totalExperience: '2 years',
    },
    {
      id: 2,
      title: "Taxi Drivers",
      icon: "🚖",
      name: 'Ahmad Khan',
      location: "	Hyderabad",
      skills: ['Safe Driving', 'Time Management', 'Customer Service', 'Navigation Skills', 'Defensive Driving', 'Problem Solving', 'Vehicle Maintenance', 'Multitasking', 'Knowledge of Local Area'],
      totalExperience: '5 years',
    },

    {
      id: 3,
      title: "Safety Officer",
      icon: "🚧",
      name: 'Raj P',
      location: "	Thane",
      skills: ["Accident Investigation",
        "Safety Audits",
        "First Aid",
        "Safety Training",
        "Workplace Inspections",
        "Fire Safety",],
      totalExperience: '4 years',
    },
    {
      id: 4,
      title: "Elevator Technician",
      icon: "🛠️",
      name: 'Akbar Bin',
      skills: [
        "Elevator Installation",
        "Maintenance and Repair",
        "Electrical Systems",
        "Mechanical Systems",
        "Troubleshooting",
        "Blueprint Reading",
        "Safety Standards",
        "Hydraulic Systems",
      ],
      location: "	Hyderabad",
      totalExperience: '7 years',
    },

    {
      id: 5,
      title: "Welder",
      icon: "🔧",
      name: 'Ramkumar',
      skills: [
        "Arc Welding",
        "MIG Welding",
        "TIG Welding",
        "SMAW (Stick Welding)",
        "Blueprint Reading",
        "Metal Fabrication",
        "Welding Safety",
        "Cutting and Grinding",

      ],
      location: "	Chennai",
      totalExperience: '5 years',
    },
  ];




  const testimonials = [
    {
      name: "Vish Suri",
      image: t1, //defaultprofile,  
      role: "Employer",
      text: "It is indeed a new approach to Human Resources. Mr Ravi, Pradeep and team have hands on experience and are skilled in providing the Right candidate for the Job :) ",
      stars: 5,
    },
    {
      name: "Abdul Raffik",
      image: t2, //defaultprofile, 
      role: "Jobseeker",
      text: "A good feel I never expect the right placement and hike. Really myself provided an secure job in an well grown organization. thanks to (workaroo ,vanisree mam)",
      stars: 4,
    },
    {
      name: "Shanmuganathan S",
      image: t3, //defaultprofile, 
      role: "Jobseeker",
      text: "Their response was good and throughout the process they keep on supporting and the one who handled me she (vani sree)was very calm thanks ",
      stars: 5,
    },
    {
      name: "JANAKIRAMAN",
      image: t4, //defaultprofile,
      role: "Jobseeker",
      text: "Thank you for referring my profile to an reputated company. recently I joined in one of the most popular brand company. Later I'll share my work Nature and company details to this same site . thank you for chaithra mam. ",
      stars: 4,
    },
    {
      name: "Sibhi Arasu",
      image: t5, //defaultprofile,
      role: "Jobseeker",
      text: "Workaroo I had really good experience with them I haven’t met them personally but the employers are so good friendly & helping out to land into your dream jobs to greater extent especially thank you miss.Chaitra for your support & special care without any hesitation ",
      stars: 5,
    },
    {
      name: "NIRMAL RAJ S",
      image: t6, //defaultprofile,
      role: "Jobseeker",
      text: "Workaroo is a very good bluecaller platform. Especially sharing my experience with the company is a wonderful platform for unemployed people. Thank you soo much vanisree mam. She helped a lot to get this job. Once again thank you vanisree mam and workaroo team.",
      stars: 4,
    },
    {
      name: "JAGADISH MANI",
      image: t7,
      role: "Jobseeker",
      text: "After a gap I never expect the right placement and hike. Really myself provided an 💯 secure job in an well grown organization. Special thanks to Workaroo (VANISREE) ",
      stars: 5,
    },

    {
      name: "Resitha Pradeep",
      image: defaultprofile,
      role: "Employer",
      text: "Workaroo is a game-changer in blue-collar recruitment! With its AI-driven platform and vast database, it connects job seekers and employers seamlessly. The team's commitment to innovation and impact, along with their focus on sustainable development, sets them apart. Highly recommended for anyone looking to transform the hiring process. Kudos to the Workaroo team for making a real difference!",
      stars: 5,
    },
  ];





  const ViewAllJobs = () => {

    navigate('/joblist')
  }


  const [, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to fetch job application status
  const fetchJobApplicationStatus = async () => {
    const token = sessionStorage.getItem('accessToken'); // Retrieve token from sessionStorage

    if (!token) {
      setNotification({
        message: 'You are not authenticated. Please log in.',
      });
      return;
    }

    try {
      setLoading(true);

      const response = await axios.get(`${apiUrl}/applications/applicationStatus/${userId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const data = response.data;

      if (data.status && data.data) {
        const jobListings = data.data;

        // Fetch the already notified job IDs from localStorage
        const notifiedJobs = JSON.parse(localStorage.getItem('notifiedJobs')) || [];
        // Fetch the last known status from localStorage
        const lastStatus = JSON.parse(localStorage.getItem('lastStatus')) || {};

        let delay = 0; // Variable to track the time delay between notifications

        jobListings.forEach((job) => {
          // Check if the job's status has changed and notify accordingly
          if (job.applicationStatus !== 'APPLIED' && job.applicationStatus !== lastStatus?.[job.jobId]) {
            // Use setTimeout to delay the notification
            setTimeout(() => {
              // Show notification if status has changed
              setNotification({
                message: `Your are  ${job.applicationStatus} for  ${job.jobTitle}. You have applied.`,
              });

              // Automatically hide notification container after 1 second
              setTimeout(() => {
                setIsNotificationVisible(false); // Hide the notification container
              }, 10000); // 1 second delay to hide the notification container

              // Add the job ID to the notifiedJobs array to avoid repeating notifications
              notifiedJobs.push(job.jobId);
              localStorage.setItem('notifiedJobs', JSON.stringify(notifiedJobs)); // Store notified jobs in localStorage

              // Update the last known status for this job
              lastStatus[job.jobId] = job.applicationStatus;
              localStorage.setItem('lastStatus', JSON.stringify(lastStatus)); // Store lastStatus in localStorage
            }, delay);

            delay += 3000; // Increment delay by 5000ms (5 seconds)
          }
        });

        // Update job listings after the fetch
        setJobListings(jobListings);
      } else {
        setNotification({
          message: 'No job listings found or an error occurred.',
        });
      }
    } catch (error) {
      console.error('Error fetching job listings:', error);
      setNotification({
        message: 'Error fetching job listings. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };


  console.log('currentBlog', currentBlog);

  const getDaysAgo = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysAgo === 0 ? "Today" : `${daysAgo} days ago`;
  };


  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <EmployerLogin
            showEmployerLoginPopup={showEmployerLoginPopup}
            closeEmployerLoginPopup={closeEmployerLoginPopup} />
          {isLoggedIn && (
            <>


              <Col className="side">
                <EmployerDashboardSideBar />
              </Col>
            </>
          )}

          <Col md={colWidth} className="homepage" style={{ width: isLoggedIn ? '85%' : '100%' }}>


            <section className="Homefirst">

              <div className="row homapgehead" style={{ height: '65vh', padding: '0px', margin: '0px' }}>
                <div className="col-12 d-flex justify-content-center align-items-center ">
                  <div className="text-center">

                    <h1 className="fs-1 fw-bold letsjob m t-2">Let’s find your future job!</h1>
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className="d-flex gap-2 flex-column flex-lg-row justify-content-center align-items-center mt-4 search-box bg-white srch">


                        <div ref={dropdownRef} style={{ position: "relative", width: "100%" }}>
                          <input
                            type="text"
                            placeholder="Select job title /skills  / industry"
                            className="form-control search-input"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            onFocus={() => setFilteredJobResults(jobSearchList)} // Show all results on focus
                            style={{ border: "1px solid rgb(214 217 231)" }}
                          />

                          {filteredJobResults.length > 0 && (
                            <ul className="dropdown-list" style={{ paddingLeft: "0px" }}>
                              {filteredJobResults.map((item, index) => (
                                <li
                                  key={index}
                                  style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px" }}
                                  onClick={() => {
                                    setFilter(item);
                                    setTimeout(() => setFilteredJobResults([]), 50);
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>


                        <div ref={dropdownReflocation} style={{ position: "relative", width: "100%" }}>
                          <input
                            type="text"
                            placeholder="Select location"
                            className="form-control search-input"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            onFocus={() => setFilteredLocationResults(locationSearchList)} // Show all locations on focus
                            style={{ border: "1px solid rgb(214 217 231)" }}
                          />

                          {filteredLocationResults.length > 0 && (
                            <ul className="dropdown-list" style={{ paddingLeft: "2px" }}>
                              {filteredLocationResults.map((item, index) => (
                                <li
                                  key={index}
                                  style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px" }}
                                  onClick={() => {
                                    setLocation(item);
                                    setTimeout(() => setFilteredLocationResults([]), 50);
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>


                        <button
                          style={{ background: '#170F49', color: 'white' }}
                          className="btn mt-2 mt-lg-0 srch-btn"
                          onClick={handleSearch}
                        >
                          {loading ? 'Searching...' : 'Search'}
                        </button>

                      </div>
                    </div>
                    {/* Stats Section */}
                    <div className="d-flex gap-4 gap-md-5 justify-content-center mt-4 sml">
                      <div className="text-center">
                        <img src={cus} loading="lazy" alt="" />
                        <h2 className="fw-900 mt-3 mb-2">100+</h2>
                        <h5>Satisfied customers</h5>
                      </div>

                      <div className="text-center mx-3 headloading">
                        <img style={{ filter: 'sepia(1) hue-rotate(120deg)' }} src={area} loading="lazy" alt="" />
                        <h2 className="fw-900 mt-3 mb-2">100+</h2>
                        <h5>Service areas</h5>
                      </div>

                      <div className="text-center">
                        <img style={{ filter: 'invert(1)' }} src={tech} loading="lazy" alt="" />
                        <h2 className="fw-900 mt-3 mb-2">500+</h2>
                        <h5>Experienced Technicians</h5>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Section (Optional Background or Image) */}
                <div
                  className="col-lg-9 col-md-12 d-none d-lg-block homebgset"
                  style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '550px',
                    position: 'relative',
                  }}
                >
                </div>
              </div>
            </section>
            <section className="list-sec bg-light discoverjobsection">
              <div className="container">
                <div className="text-center mb-lg-5">
                  <h2 className="mb-3 pb-3">Dear Jobseeker, Let us find a Ideal Jobs for you ! </h2>
                </div>

                <div className="row justify-content-center g-4">
                  {jobs.length > 0 ? (
                    jobs.map((job) => (

                      <div key={job.jobId} className="col-lg-3">
                        <div className="list-block dearjobseeker">

                          <Link to={`/viewdetails/${job.jobId}`}>

                            {!imageError ? (
                              <img
                                src='#'
                                alt="logo"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                }}
                                onError={handleImageError} // Set fallback on error
                              />
                            ) : (
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                <div
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    // border: '2px solid black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(47, 39, 97)',
                                  }}
                                >
                                  A
                                </div>
                                <p
                                  style={{
                                    marginLeft: 'auto',
                                    marginBottom: 0,
                                    fontSize: '10px',
                                    color: 'rgb(47, 39, 97)',
                                    backgroundColor: 'rgb(240, 238, 255)', // Light shade of rgb(47, 39, 97)
                                    borderRadius: '10px',
                                    padding: '1px 5px',
                                    display: 'inline-block',
                                    // border: '0.2px solid rgb(47, 39, 97)',
                                  }}
                                >
                                  {getDaysAgo(job.createdAt)}
                                </p>

                              </div>
                            )}
                            <div className="list-title">
                              <h5 className="fw-bold mb-2">{job.jobTitle} </h5>

                            </div>
                            <div>


                              <div className="fulltimes">
                                <p
                                  style={{
                                    marginLeft: '200px',
                                    marginBottom: 0,
                                    fontSize: '10px',
                                    color: 'rgb(47, 39, 97)',
                                    backgroundColor: 'rgb(240, 238, 255)',
                                    borderRadius: '10px',
                                    padding: '1px 5px',
                                    display: 'inline-block',
                                  }}
                                  className="fulltime"
                                >
                                  Full-time
                                </p>

                              </div>

                            </div>
                            <div className="border-bottom  my-2 fixedborder" ></div>

                            <span className="f-14 d-flex align-items-center gap-2 my-2">
                              <FaSuitcase />
                              <span>
                                {/* {job.experience} */}
                                {job.minExperience}-{job.maxExperience} years
                              </span>
                            </span>
                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{ fontSize: "12px" }} // Base font size for inner elements
                            >
                              {/* Left-end content */}
                              <div className="d-flex align-items-center gap-2">
                                <FaRupeeSign style={{ fontSize: "10px" }} /> {/* Reduce icon size */}
                                <div>
                                  {job.salary ? `${job.salary}/month` : "Not Disclosed"}
                                </div>
                              </div>

                              {/* Right-end content */}
                              <div className="d-flex align-items-center gap-2">
                                <FaMapMarkerAlt style={{ fontSize: "10px" }} /> {/* Reduce icon size */}
                                <div>{job.location}</div>

                              </div>

                            </div>

                          </Link>


                        </div>

                      </div>

                    ))
                  ) : (
                    <p style={{ textAlign: 'center' }}>No job listings available at the moment.</p>
                  )}
                </div>


                <div className="mt-5 text-center">
                  <button onClick={ViewAllJobs} className="default-btn border-btn mt-3 view-all">
                    View all jobs
                  </button>
                </div>
              </div>
            </section>



            <section className="list-sec pt-0 topcompanysection">
              <div className="container">
                <div className="text-center mb-lg-5">
                  <h2 className="fw-900 py-4">Top companies hiring now</h2>
                </div>
                <div className="row justify-content-center g-4">
                  {companies.map((company, index) => (
                    <div className="col-lg-3" key={company.companyId}>
                      <div className="list-block">
                        <div className="text-center">
                          <div className="logo-img mb-4">
                            <img
                              src={
                                company.image
                                  ? `data:image/png;base64,${company.image}`
                                  : c5
                              } // Replace with fallback logic
                              loading="lazy"
                              alt={`${company.name} Logo`}
                            />
                          </div>
                          <h5 className="fw-bold mb-3">
                            {company.name || "-"}
                          </h5>
                          <p className="m-0 f-13 d-flex justify-content-center text-muted">

                            4.1 <span className="mx-2 text-muted">|</span>
                            <span className="f-13">124 Reviews</span>
                          </p>
                          <div className="border-bottom my-3"></div>
                          <p className="f-14 lh-base px-4 mx-3">
                            {company.overview || "No overview available."}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-5 text-center">
                </div>
              </div>
            </section>


            <section className="testimonialssection">
              <div className="container my-5 cardcontainer">
                <h2>Testimonials</h2>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="col col-md-3 px-3">
                      <div className="card text-cente ">
                        <img
                          src={testimonial.image || defaultprofile}
                          alt={testimonial.name}
                          className="card-img-top rounded-circle mx-auto mt-3"
                          style={{ width: "80px", height: "80px", objectFit: "cover" }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{testimonial.name}</h5>
                          <h6 className="card-subtitle mb-2 text-muted">
                            {testimonial.role}
                          </h6>
                          <p className="card-text">{testimonial.text}</p>
                          {/* <div className="stars">
              {"★".repeat(testimonial.stars) + "☆".repeat(5 - testimonial.stars)}
            </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </section>


            <section className="py-5 dreamjobsection bg-">
              <h3 style={{ fontSize: "30px", fontWeight: "bold", textAlign: 'center' }}>
                Dear Employer, Let us find an Ideal candidate for you!
              </h3>
              <div className="dear-emp" style={{ width: "100%", }}>
                <div className="row align-items-center w-100">
                  <div className=" mt-3 p-0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "40px",
                        fontSize: "30px",
                        padding: '0px',

                      }}
                    >

                    </div>
                    <div className="slider" style={{ position: "relative", width: '88%' }}>
                      {/* Left Arrow */}
                      <button
                        onClick={handlePrev}
                        className="slider-arrow left"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "-70px",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "24px",
                          zIndex: 10,
                        }}
                      >
                        <ChevronLeft size={36} color="rgb(47, 39, 97)" />
                      </button>

                      <Swiper

                        ref={swiperRef}
                        modules={[Pagination]}
                        spaceBetween={200}
                        slidesPerView={5}
                        centeredSlides={true}
                        pagination={{ clickable: true }}
                        loop={true}
                        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          768: { slidesPerView: 2 },
                          1024: { slidesPerView: 5 },
                        }}
                      >
                        {cards.map((card, index) => (
                          <SwiperSlide key={card.id} style={{ width: '100%' }}>
                            <div
                              className="empl"
                              style={{
                                width: "270px",
                                height: "350px",
                                marginLeft: 'auto',
                                background: 'white',
                                backgroundColor: activeIndex === index ? "#ffffff" : "#f5f5f5",
                                border: activeIndex === index ? "1px solid rgb(47, 39, 97)" : "1px solid #ccc",
                                boxShadow:
                                  activeIndex === index
                                    ? "0 4px 15px rgba(0, 0, 0, 0.2)"
                                    : "0 2px 5px rgba(0, 0, 0, 0.1)",
                                transition: "all 0.3s ease",
                                cursor: "pointer",
                                padding: "20px",
                                borderRadius: "20px",
                              }}
                            >
                              <div style={{ fontSize: "2rem", color: "rgb(47, 39, 97)", fontWeight: "lighter" }}>
                                {card.icon}
                              </div>

                              <h5
                                className="card-title"
                                style={{ fontWeight: "bold", marginTop: "12px", fontSize: "18px" }}
                              >
                                {card.name}
                              </h5>

                              <h5
                                className="card-title"
                                style={{ fontWeight: "10px", marginTop: "12px", fontSize: "15px" }}
                              >
                                <span style={{ color: "gray" }}>{card.title}</span>
                              </h5>

                              <p
                                className="card-title"
                                style={{ fontWeight: "", marginTop: "10px", fontSize: "15px", color: "gray" }}
                              >
                                <span style={{ color: "black" }}>Experience: </span> {card.totalExperience}
                              </p>

                              <p
                                className="card-title"
                                style={{ fontWeight: "none", marginTop: "10px", fontSize: "15px", color: "gray" }}
                              >
                                <span style={{ color: "black" }}>Location:</span> {card.location}
                              </p>

                              <p
                                className="card-title"
                                style={{ fontWeight: "", marginTop: "10px", fontSize: "13px" }}
                              >
                                <span style={{ color: "black", fontSize: "15px" }}>Skills: </span>{" "}
                                {Array.isArray(card.skills) ? card.skills.join(", ") : card.skills}
                              </p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* Right Arrow */}
                      <button
                        onClick={handleNext}
                        className="slider-arrow right"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "40px",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "24px",
                          zIndex: 10,
                        }}
                      >
                        <ChevronRight size={36} color="rgb(47, 39, 97)" />
                      </button>
                    </div>

                    {/* Pagination Progress Bar */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        className="pagination-container text-center mt-4"
                        style={{
                          position: "relative",
                          width: "200px",
                          height: "10px",
                          background: "#ddd",
                          borderRadius: "50px",
                          cursor: "pointer",
                          marginTop: "30px",
                        }}
                      >
                        <div
                          className="progress-value"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "0",
                            height: "5px",
                            width: `${(activeIndex / (cards.length - 1)) * 100}%`,
                            background: "rgb(47, 39, 97)",
                            borderRadius: "50px",
                            transform: "translateY(-50%)",
                            boxShadow: "0 10px 40px -10px #fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section style={{ padding: '0px', background: '#F8F9FA' }}>
              {/* <div> */}
              <Container fluid className="content">
                <Row className="g-4">

                  <Col md={colWidth} className="video-btns"  >
                    <section
                      style={{
                        backgroundColor: "#f8f9fa",
                        padding: "40px 0 10px",
                        textAlign: "center",
                      }}
                    >
                      <Container>
                        <h1 style={{ fontWeight: "bold", marginBottom: '0px', color: '#170F49' }}> Corporate Videos </h1>

                      </Container>
                    </section>


                    <Container style={{ padding: "0px 0" }}>
                      <Row className="g-4" style={{ display: 'flex', alignItems: 'stretch' }}>
                        <Col xs={12} md={6} className="mb img-box">
                          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <h5 className="text-center py-3" style={{ color: '#170F49', fontSize: '15px' }} >Workaroo - Corporate Video (English)</h5>
                            <video className="video-src" width="85%" height="auto" controls>
                              <source src={aboutVideoEnglish} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </Col>

                        <Col xs={12} md={6} className="mb img-box">
                          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <h5 className="text-center py-3 " style={{ color: '#170F49', fontSize: '15px' }} >Workaroo - Corporate Video (Hindi)</h5>
                            <video className="video-src" width="85%" height="auto" controls>
                              <source src={aboutVideoHindi} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </Col>

                      </Row>

                      <div style={{ display: 'flex', justifyContent: 'center', gap: '50px', width: '100%', marginTop: '20px' }} className="videobtns">
                        <button style={{ width: '150px' }}><Link to={"/videoPage"}>Corporate Videos</Link></button>
                        <button style={{ width: '150px' }}><Link to={"/employerVideos"}>Employer Videos</Link></button>
                        <button style={{ width: '150px' }}><Link to={"/jobseekerVideos"}>Jobseeker Videos</Link></button>
                      </div>


                    </Container>
                  </Col>
                </Row>
              </Container>


              {/* </div> */}
            </section>


            <section className="blogepagesection mt-0">
              <div className="dashboard">
                <Container fluid className="content">
                  <Row className="g-4">
                    <Col md={colWidth} className="main-content">
                      <section
                        style={{
                          backgroundColor: "#f8f9fa",
                          padding: "40px 0 10px",
                          textAlign: "center",
                        }}
                      >
                        <Container>
                          <h1 style={{ fontWeight: "bold", marginBottom: '0px', color: '#170F49' }}> Blog</h1>

                        </Container>
                      </section>

                      {/* Blog Section */}



                      <Container style={{ padding: "30px 0", position: "relative" }}>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Button onClick={scrollLeft} variant="light" className="shadow-sm" style={{ borderRadius: "50%" }}>
                            <FaArrowLeft />
                          </Button>

                          <div ref={scrollRef} style={{ display: "flex", scrollBehavior: "smooth", gap: "20px", padding: "10px", scrollbarWidth: "none", msOverflowStyle: "none" }} className="blogsection">
                            {blogs.map((blog, index) => (
                              <Card key={index} className="blogcard" style={{ width: '23rem', minWidth: "300px", flex: "0 0 auto", border: "none", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
                                <Card.Img variant="top" src={blog.image} alt={blog.title} style={{ height: "200px", borderRadius: "10px 10px 0px 0px" }} />
                                <Card.Body style={{ padding: "15px" }}>
                                  <Card.Title className="cardtitle">{blog.title}</Card.Title>
                                  <Card.Text style={{ height: "120px", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", color: "#555" }}>
                                    {blog.description1.split(" ").slice(0, 18).join(" ")}

                                    {blog.description1.split(" ").length > 18 && <span style={{ color: "#333", fontWeight: "bold" }}>...</span>}
                                  </Card.Text>
                                </Card.Body>
                                <button
                                  className="readmore"
                                  onClick={() => handleShow(blog)}
                                  style={{
                                    position: "absolute",
                                    bottom: "20px",
                                    left: "10px",
                                    fontWeight: "bold",
                                    background: "none",
                                    border: "none",
                                    color: "#2F2761",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                  }}
                                >
                                  <FaArrowRight className="readarrow" /> Read More
                                </button>

                              </Card>
                            ))}
                          </div>

                          <Button onClick={scrollRight} variant="light" className="shadow-sm" style={{ borderRadius: "50%" }}>
                            <FaArrowRight />
                          </Button>
                        </div>
                      </Container>

                      {/* Modal Popup */}
                      {currentBlog && (
                        <Modal
                          className="blog-box"
                          show={show}
                          onHide={handleClose}
                          centered={true} // Vertically center the modal
                          size="xl" // Ensures initial large width
                          dialogClassName="custom-modal-dialog"
                        >
                          <Modal.Body
                            className="bloginner"
                            style={{
                              display: "flex",
                              borderRadius: "15px",
                              overflow: "hidden",
                              width: "90%", // Increased width to 90% of screen
                              height: "80vh", // Increased height to 95% of viewport height
                              margin: "auto", // Center the modal horizontally and vertically
                            }}
                          >
                            {/* Left-side Image */}
                            <div className="blog-inner" style={{ flex: "1 1 50%", maxHeight: "100%", overflow: "hidden" }}>
                              <img
                                src={currentBlog.image}
                                alt={currentBlog.title}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover", // Ensures the image scales proportionally
                                }}
                              />
                            </div>
                            {/* Right-side Content */}
                            <div className="blog-last"
                              style={{
                                flex: "1 1 50%",
                                padding: "20px",
                                overflowY: "auto", // Ensures scrollability for content
                              }}
                            >
                              <h2>{currentBlog.title}</h2>
                              <div style={{ color: '#555', fontSize: '14px', marginTop: '10px', fontWeight: '100px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                                <div style={{ fontWeight: '400px' }} className="blog-para">
                                  {currentBlog.description1}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description2}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description3}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description4}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description5}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description6}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description7}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description8}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description9}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description10}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description11}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description12}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description13}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description14}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description15}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description16}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description17}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description18}
                                  <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description19}

                                </div>
                              </div> <p style={{ fontSize: "16px" }}>{currentBlog.description}</p>
                              <div className='BlogDiv d-flex flex-row gap-2'>
                                {currentBlog.image.includes("improvement-summary-personal-development-workflow.a670643e265f49147d3c.jpg") && (
                                  <Button
                                    style={{ position: 'relative', top: '16rem!important' }}
                                    onClick={() => navigate('/employersub')}
                                  >
                                    Apply
                                  </Button>
                                )}
                                <Button

                                  variant="secondary"
                                  className=" blog-btn"
                                  onClick={handleClose}
                                  style={{
                                    borderRadius: "8px",
                                    padding: "10px 20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Close
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}

                    </Col>
                  </Row>
                </Container>
                {/* <Footer/> */}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Col className="bottomNavigate">
        <Bottom />
      </Col>

    </div>
  );
};

export default HomePage;