import EmployerDashboardHeader from "../components/EmployerDashboardHeader"
import { Table } from 'react-bootstrap';

const ApplicantShortList = () => {

    return (
        <div>
            <EmployerDashboardHeader />

            <Table hover className="table">
                <thead>
                    <tr className="tablehead text-center">
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>Skills</th>
                        <th>PhoneNumber</th>
                        <th>Experience</th>
                        <th>ExpectedSalary</th>
                        <th>Email</th>

                    </tr>
                </thead>

            </Table>
        </div>
    )
}

export default ApplicantShortList