import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-hot-toast";
import "../../Styles/TableStyles.css";
import { useLocation } from "react-router-dom";
import ApplyJobPopup from "../Employee/ApplyJobPopup";
import { Container, Col, Row, Table, Modal, Card, Form, Spinner } from 'react-bootstrap';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";
import { FaUserEdit } from "react-icons/fa";
import { TextField } from "@mui/material";

import Box from '@mui/material/Box';


export default function AllJobSeekerTable() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const location = useLocation();
  const [applied, setApplied] = useState([])
  const applicantFromState = location.state?.applicant;
  const employerId = sessionStorage.getItem("userId");
  const userId = sessionStorage.getItem("userId");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const token = sessionStorage.getItem('accessToken');

  const [planId, setPlanId] = useState(null);
  const [postedJobs, setPostedJobs] = useState([]);
  const [error, setError] = useState(null);
  const employerid = sessionStorage.getItem("userId");
  const hasShownToastRef = useRef(false);

  const [role, setRole] = useState(null);
  const [UserCounts, setUserCounts] = useState();
  const [limit, setlimit] = useState();
  const [downloadUsed, setDowloadUsed] = useState();
  const [employerPackageData, setEmployerPackageData] = useState([]);

  const [filters, setFilters] = useState({ firstName: "", lastName: "", experience: "" });

  const filteredRowss = rows.filter((row) =>
    Object.entries(filters).every(([key, value]) =>
      value ? String(row[key]).toLowerCase().includes(value.toLowerCase()) : true
    )
  );


  useEffect(() => {
    const storedRole = sessionStorage.getItem("role");
    setRole(storedRole);
  }, []);

  console.log("app", applicantFromState);


  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleUpdateStatus = (newStatus) => {
    console.log("Updating status to:", newStatus);

    // Get full details of selected users
    const selectedUserData = rows.filter((row) => selectedRows.includes(row.id));

    setRows((prevRows) =>
      prevRows.map((row) =>
        selectedRows.includes(row.id) ? { ...row, status: newStatus, jobSeekerId: row.id } : row
      )
    );

    console.log("Selected User Data:", selectedUserData); // Debugging

    // Pass full selected users' data instead of just IDs
    ApplyJob(newStatus, selectedUserData);

    console.log("All Rows:", rows); // Debugging

    setSelectedRows([]); // Clear selection after update
  };



  useEffect(() => {

    fetchApplicants();
  }, [apiUrl]);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });

      if (Array.isArray(response?.data?.data)) {
        console.log("live", response.data.data);

        const transformedData = response.data.data.map((applicant) => ({
          id: applicant.userId,  // Ensure each row has a unique id
          _id: applicant.jobseekerId,
          firstName: applicant.firstName || "N/A",
          lastName: applicant.lastName || "N/A",
          profileTitle: applicant.profileTitle || "N/A",
          skills: applicant.skills?.join(", ") || "N/A",
          phone: applicant.phoneNumber || "N/A",
          experience: applicant.experience || "N/A",
          company: applicant.companyName || "N/A",
          salary: applicant.CurrentSalary ? `₹${applicant.CurrentSalary}` : "N/A",
          email: applicant.email || "N/A",
          city: applicant.city || "N/A",
          NativeLocation: applicant.NativeLocation || "N/A",
          ReadyforRelocation: applicant.ReadyforRelocation || "N/A",
          CurrentJob: applicant.CurrentJob || "N/A",
          InterestedJobField: applicant.InterestedJobField || "N/A",
          Qualification: applicant.Qualification || "N/A",
          Languages: applicant.LanguagesKnown || "N/A",
          TaskResponsibiles: applicant.TaskResponsibiles || "N/A",
          NoticePeriod: applicant.NoticePeriod || "N/A",
          ExpectedSalary: applicant.ExpectedSalary || "N/A",
          Addl: applicant.Addl || "N/A",
          Addl2: applicant.Addl2 || "N/A",
          status: applicant.status || "APPLIED",
          JobseekerStatus: applicant.JobseekerFeedback || "N/A",
          CSE: applicant.CSE || "N/A",
          NoticePeriod: applicant.NoticePeriod || "N/A",
          WRKBatchCODE: applicant.WRKBatchCODE || "N/A",
          jobseekerId: applicant.jobseekerId || "N/A",
          Remarks: applicant.Remarks || "N/A",
        }));
        console.log("transformedData", transformedData);
        setRows(transformedData);
      } else {
        throw new Error("Expected data to be an array");
      }
    } catch (err) {
      toast.error(err?.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };



  const ApplyJob = async (ApplicantStatus, SelectedData) => {
    setLoading(true);
    const selectedApplicants = SelectedData;
    setSelectedApplicants(SelectedData);
    try {
      if (selectedApplicants.length === 0) {
        toast.error("Please select at least one applicant.");
        return;
      }
      console.log("SelectedData", SelectedData)
      console.log("Selected Applicants:", selectedApplicants);  // Debugging


      const dataToPost = {
        jobId: ApplicantStatus,
        employerId: employerId,
        createdById: userId,
        jobSeekerApplications: selectedApplicants.map((applicant) => ({
          jobSeekerId: applicant.id,
          jobId: ApplicantStatus || "",
          employerId: employerId || "",
          experience: applicant.experience || "",
          currentCTC: applicant.expectedSalary || "",
          expectCTC: applicant.expectedSalary || "",

        })),
      };


      const token = sessionStorage.getItem("accessToken");
      console.log("Token:", token);  // Debugging

      const response = await axios.post(
        `${apiUrl}/applications/applymultiple`,
        dataToPost,

        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      console.log("API Response:", response);  // Debugging

      if (response.data.status) {
        fetchApplicants();
        toast.success(response?.data?.message ?? "Applicants status changed successfully.");
        setPopupOpen(false);
      } else {
        toast.error(response?.data?.message ?? "Failed to change applicants status.");
      }
      setLoading(false);

    } catch (error) {
      console.error("Error details:", error);  // Detailed error logging
      toast.error(error?.response?.data?.message ?? "Error applying for jobs");
      setLoading(false);
    }
  };



  const handleViewClick = async (applicant) => {
    console.log(applicant);
    navigate('/jobseekerDetails', { state: { id: applicant?.row?.id } });
    console.log("app", applicant?.row?.id);

  };

  useEffect(() => {

    fetchjobs();
  }, []);

  const handleUpdateJobseeker = async (applicant) => {
    navigate('/jobseekerCreateProfile', { state: { id: applicant?.row } });
  };

  const fetchjobs = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      const response = await axios.get(`${apiUrl}/job/posted/${employerId}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });


      // Extract only job titles and update state
      if (response?.data?.data) {
        setPostedJobs(response.data.data.map(job => job.jobTitle));
      }
    } catch (error) {
      console.log("postedjoberror", error);
    }
  };
  console.log("postedjob", postedJobs);




  const handleSelectionChange = (selectionModel) => {

    setSelectedRows(selectionModel);
  };


  const updateStatus = () => {

    navigate("/Joblist", { state: { selectedIds: selectedRows } });

  };



  const columns = [
    // { field: "id", headerName: "ID", flex: 1  },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "profileTitle", headerName: "Profile Title", flex: 1 },
    { field: "experience", headerName: "Total Experience", flex: 1 },
    { field: "company", headerName: "Company", flex: 1 },
    { field: "salary", headerName: "₹ Salary/Month", flex: 1 },
    { field: "skills", headerName: "Skills", flex: 1 },
    { field: "NativeLocation", headerName: "NativeLocation", flex: 1 },
    { field: "ReadyforRelocation", headerName: "Relocation", flex: 1 },
    { field: "CurrentJob", headerName: "CurrentJob", flex: 1 },
    { field: "InterestedJobField", headerName: "InterestedJob", flex: 1 },
    { field: "Qualification", headerName: "Qualification", flex: 1 },
    { field: "Languages", headerName: "Languages", flex: 1 },
    { field: "TaskResponsibiles", headerName: "TaskResponsibiles", flex: 1 },
    { field: "NoticePeriod", headerName: "NoticePeriod", flex: 1 },
    { field: "ExpectedSalary", headerName: "ExpectedSalary", flex: 1 },
    { field: "Addl", headerName: "DL DETAILS", flex: 1 },
    { field: "Addl2", headerName: "ADDL DETAILS", flex: 1 },
    { field: "JobseekerStatus", headerName: "JobseekerStatus", flex: 1 },
    { field: "WRKBatchCODE", headerName: "WRK-Batch CODE", flex: 1 },
    { field: "Remarks", headerName: "Remarks", flex: 1 },

    ...(planId
      ? [{ field: "phone", headerName: "Mobile", flex: 1 }]
      : []), // ✅ Show "Phone" column only if planId exists
    { field: "city", headerName: "Location", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    // { field: "", headerName: "Action", flex: 1 },
    {
      field: "id",
      headerName: "Action",
      flex: 1,
      renderCell: (id) => (

        <div>        <Button style={{ width: '60px', height: '30px', background: '#2F2761', padding: '0px', margin: '0px', color: 'white', borderRadius: '10px' }}
          onClick={() => handleViewClick(id)}
        >
          view
        </Button>
          <FaUserEdit style={{ background: 'none', fontSize: '22px', marginLeft: '5px', color: 'red' }}
            onClick={() => handleUpdateJobseeker(id)} />
        </div>

      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    // Set only the first 10 columns to be visible by default
    const visibleColumns = columns.slice(0, 10).map(col => col.field).concat("id");;
    return columns.reduce((acc, col) => {
      acc[col.field] = visibleColumns.includes(col.field);
      return acc;
    }, {});
  });


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = sessionStorage.getItem("accessToken");

        // ✅ Fetch user profile
        const response = await axios.get(`${apiUrl}/user/${userId}`, {
          headers: { Authorization: `${token}` },
        });

        if (response.status === 200 && response.data) {
          // ✅ Store planId from response
          setPlanId(response.data.data.activePlanId);
          console.log("Fetched Plan ID:", response.data.data.activePlanId);
        } else {
          console.warn("Unexpected response:", response);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    if (userId) {
      fetchProfile();
    }
  }, [userId]);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      // setIsLoggedIn(true);
    }
    const fetchEmployerPlan = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/subscription/getEmployerPlan/${userId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log(response);
        if (response.status) {

          console.log("Response Status:", response.status);
          if (response.data && response.data.data) {
            const userData = response.data.data.planName;
            const usercount = response.data.data.databaseCreditsRemaining;
            setUserCounts(usercount)
            console.log("usercount", UserCounts)
            setEmployerPackageData(userData);
            setlimit(response.data.data.download)
            console.log("userData", userData);
          } else {
            console.error("Invalid data format in response.");
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (err) {
        //  handleOpen()

        if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            // toast.error("Bad Request: Please check the input data.");
            // alert(status?.data?.errorMsg)
            // toast.error(err?.response?.data?.errorMsg)
          } else if (status === 401) {
            toast.error(
              "Unauthorized: Please check your authentication token."
            );
          } else if (status === 404) {
            toast.error("Not Found: Unable to find the requested resource.");
          } else {
            toast.error(
              `Error ${status}:`,
              err.response.data.message || err.message
            );
          }
        } else {
          toast.error("Network error or server not reachable:", err.message);
        }
      }
    };

    fetchEmployerPlan();
  }, []);

  const handleDownloadCSV = async () => {
    if (!employerPackageData) {
      toast.error("No active package found.", { position: "top-center" });
      return;
    }

    // Validate selected rows
    if (selectedRows.length === 0) {
      toast.error("Please select at least one row to download.");
      return;
    }

    if (downloadUsed + selectedRows.length > limit) {
      toast.error(`You have reached the download limit.`);
      return;
    }

    if (selectedRows.length >= limit) {
      toast.error(`You can download up to ${limit} rows only.`);
      return;
    }

    // Debugging: Log the selected rows before filtering
    console.log("🔍 Selected Rows:", selectedRows);

    // Filter selected rows and extract required fields
    const selectedData = rows
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => ({
        FirstName: row.firstName || "N/A",
        LastName: row.lastName || "N/A",
        Email: row.email || "N/A",
        Mobile: row.phone || row.phoneNumber || "N/A",
        Qualification: row.Qualification || "N/A",
        Ready_for_Relocation: row.ReadyforRelocation || "N/A",
        Profile_Title: row.profileTitle || "N/A",
        Experience: row.experience || "N/A",
        Company: row.company || "N/A",
        City: row.city || "N/A",
        Current_Salary: row.salary || "N/A",
        // status: row.status || "N/A",
        Skills: row.skills || "N/A",
        NoticePeriod: row.NoticePeriod || "N/A",
        NativeLocation: row.NativeLocation || "N/A",
        Languages: row.Languages || "N/A",
        InterestedJobField: row.InterestedJobField || "N/A",
        Expected_Salary: row.ExpectedSalary || "N/A",
        CurrentJob: row.CurrentJob || "N/A",
        DL_DETAILS: row.Addl || "N/A",
        ADDL_DETAILS: row.Addl2 || "N/A",
        Task_Responsibiles: row.TaskResponsibiles || "N/A",
        Jobseeker_Status: row.JobseekerStatus || "N/A",
        CSE: row.CSE || "N/A",
        Notice_Period: row.NoticePeriod || "N/A",
        Remarks: row.Remarks || "N/A",
        WRKBatchCODE: row.WRKBatchCODE || "N/A",
        jobseekerId: row.jobseekerId || "N/A",
        // _id: row._id || "N/A"
      }));

    if (selectedData.length === 0) {
      toast.error("No valid data to download.");
      return;
    }

    const formatCSVValue = (value) => {
      if (!value) return '"N/A"'; // Ensure empty values are "N/A"
      const formattedValue = String(value).replace(/"/g, '""').replace(/\r?\n|\r/g, " "); // Escape quotes & remove newlines
      return `"${formattedValue}"`; // Wrap in double quotes
    };


    const csvHeader = Object.keys(selectedData[0]).join(",") + "\n";
    const csvContent = selectedData
      .map((row) => Object.values(row).map(formatCSVValue).join(","))
      .join("\n");

    // Debugging: Log final CSV content
    console.log("📄 CSV Content:\n", csvHeader + csvContent);

    // Create a Blob and initiate the file download
    const blob = new Blob([csvHeader + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", `selected_rows_${selectedRows.length}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);


  };


  return (
    <div className="" style={{ height: "auto", width: "100%" }}>
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row>
          <Col>
            <EmployerDashboardSideBar />
          </Col>

          <Col
            md={10}
            className=" mt-1"
            style={{ overflow: "visible !important", background: '#F5F6FA' }}
          >

            {loading ? (
              <div className="loading-container">
                <CircularProgress />
                <p>Loading jobseekers...</p>
              </div>
            ) : (
              <>
                <h4 className="m-3 bold">All Jobseekers</h4>

                <div className="action-buttons" >
                  <Button
                    onClick={handleDownloadCSV}
                    disabled={selectedRows.length === 0} // Disable if no selection or limit reached
                    variant="contained"
                    style={{
                      background: selectedRows.length === 0 ? "#808080" : "#2F2761",
                      color: "white",
                      marginLeft: '10px',
                      width: '150px',
                    }}
                    sx={{ marginBottom: 2 }}
                  >
                    Download Data
                  </Button>

                  <Button
                    style={{ width: '150px', height: '40px' }}
                    variant="contained"
                    color="success"
                    onClick={updateStatus}
                    disabled={!selectedRows.length}
                  >
                    Apply for Job
                  </Button>

                  {(role === 'callcenter' || role === 'admin') && (<Button
                    style={{ width: '150px', height: '40px', background: '#2F2761', color: 'white' }}
                    onClick={() => navigate("/jobseekerCreateProfile")}
                  >Add Jobseeker</Button>)}

                </div>


                <Box style={{ height: "auto", margin: "10px", background: "white" }}>
                  {/* Filter Inputs */}
                  <div className="px-3 pt-3 fs-4">
                    Filters
                  </div>
                  <Box display="flex" gap={1} padding={1}>

                    {["firstName", "lastName", "experience", "skills", "company", "NativeLocation", "WRKBatchCODE"].map((field) => (
                      <TextField
                        key={field}
                        label={columns.find((col) => col.field === field)?.headerName}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFilters({ ...filters, [field]: e.target.value })}
                        sx={{ flex: 1 }}
                      />
                    ))}
                  </Box>

                  <DataGrid
                    style={{ height: "auto", margin: "10px", background: "white" }}
                    rows={filteredRowss}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    onRowSelectionModelChange={handleSelectionChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    pagination
                    pageSizeOptions={[7, 25, 50, 100]}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 7, page: 0 },
                      },
                    }}
                    sx={{
                      "& .MuiDataGrid-footerContainer": {
                        justifyContent: "center",
                      },
                    }}
                  />
                </Box>

                {popupOpen && (
                  <ApplyJobPopup
                    open={popupOpen}
                    onClose={handleClosePopup}
                    onUpdate={handleUpdateStatus}
                    currentStatus={rows.find(
                      (row) => row.id === selectedRows[0]
                    )?.status}
                  />
                )}
              </>
            )}

            <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
              <Modal.Header style={{ backgroundColor: "#2F2761", color: "white" }} closeButton>
                <Modal.Title >Applicant Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>


                {rows ? (
                  <div>
                    <p><strong>First Name:</strong> {applied.firstName}</p>
                    <p><strong>Last Name:</strong>{applied.lastName}</p>
                    <p><strong>Company:</strong> {applied.companyName || []}</p>
                    <p><strong>Location:</strong> {applied.city}</p>
                    <p><strong>Experience:</strong> {applied.experience || "-"}</p>
                    <p><strong>Skills:</strong> {applied.skills || []}</p>
                    {planId ? (<p><strong>Mobile Number: </strong>{applied.phone}</p>) : ''}
                    <p><strong>Email: </strong>{applied.email}</p>
                    <p><strong>createdAt: </strong>{moment(applied?.createdAt).format('MMMM Do YYYY')}</p>
                    {/* <p><strong>updatedAt: </strong> {moment(applied?.updatedAt).format('MMMM Do YYYY')}</p> */}
                  </div>
                ) : (
                  <p>Loading applicant details...</p>
                )}


              </Modal.Body>
              <Modal.Footer>
                <Button color="warning" variant="contained" onClick={() => setShowDetailsModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
}




