import React, { useState, useEffect } from "react";

import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';

import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";

import  aboutVideoEnglish  from "../../assets/Videos/Workaroo_Intro_ENG.mp4"
import  aboutVideoHindi  from "../../assets/Videos/Workaroo_Intro_HINDI.mp4"
import  aboutVideoKannada  from "../../assets/Videos/Workaroo_Intro_KANNADA.mp4"
import  aboutVideoMalayalam  from "../../assets/Videos/Workaroo_Intro_MALAYALAM.mp4"
import  aboutVideoTamil  from "../../assets/Videos/Workaroo_Intro_TAMIL.mp4"
import  aboutVideotelugu  from "../../assets/Videos/Workaroo_Intro_TELUGU.mp4"
import  aboutEmployerEnglish  from "../../assets/Videos/Hello-Employers-From-Workaroo(ENGLISH).mp4"
import  aboutEmployerHindi  from "../../assets/Videos/Hello-Employers-From-Workaroo(HINDI).mp4"
import  aboutJobseekerEnglish  from "../../assets/Videos/Hello-Jobseekers-From-Workaroo(ENGLISH).mp4"
import  aboutJobseekerHindi  from "../../assets/Videos/Hello-Jobseekers-From-Workaroo(HINDI).mp4"
import  workaroojobseeker from "../../assets/Videos/Workaroo_Jobseeker_ENG.mp4" 
import  workaroojobseekerHindi from "../../assets/Videos/Workaroo_Jobseeker_HINDI.mp4"
import  workaroojobseekerKannada from "../../assets/Videos/Workaroo_Jobseeker_Kannada.mp4"
import  workaroojobseekerMalayalam from "../../assets/Videos/Workaroo_Jobseeker_Malayalam.mp4"
import  workaroojobseekerTamil from "../../assets/Videos/Workaroo_Jobseeker_Tamil.mp4"
import  workaroojobseekerTelugu from "../../assets/Videos/Workaroo_JobseekerTelugu.mp4"



import '../../Styles/videoPage.css'


const JobseekerVideo = () => {

  const [show, setShow] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12;

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
      // setSidebar(true)
    }

  }, []);

     useEffect(() => {
        window.scrollTo(0, 0); // Page load aana udane top la scroll aagum
      }, []);
  

  return (
    <>
      <div className="dashboard">
        <EmployerDashboardHeader/>
        <Container fluid className="content">
          <Row className="g-4">
            {isLoggedIn ? <Col>
            <EmployerDashboardSideBar />
            </Col> :
              <></>
            }
            <Col md={colWidth} className="main-content">
              <section
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "40px 0 10px",
                  textAlign: "center",
                }}
              >
                <Container>
                  <h1 style={{ fontWeight: "bold" ,marginBottom:'0px',color:'#170F49'}}> Jobseeker Videos</h1>
                  
                </Container>
              </section>

              {/* Blog Section */}
              <Container style={{ padding: "30px 0" }}>
              <Row className="g-4" style={{ display: 'flex', alignItems: 'stretch' }}>
        

  
  </Row>


<Row>
<h3 className="mt-3 mb-2" style={{ fontWeight: "bold" ,marginBottom:'0px',color:'#170F49', textAlign: "center",}}>  </h3>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (English)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={aboutJobseekerEnglish} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>
            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (English)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={aboutJobseekerHindi} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (English)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseeker} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (Hindi)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseekerHindi} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (Kannada)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseekerKannada} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (Malayalam)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseekerMalayalam} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (Tamil)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseekerTamil} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>

            <Col xs={12} md={6} className="mb-4 img-box">
                <div>
                  <h5 className="text-center py-3" style={{color:'#170F49',fontSize:'15px'}} >Workaroo - Hello Jobseeker Video (Telugu)</h5>
                <video className="video-src-portait" width="100%" height="50%" style={{ maxHeight: '550px', borderRadius: '10px' }} controls>
                    <source src={workaroojobseekerTelugu} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
            </Col>


  
  </Row>


</Container> 
            </Col>
          </Row>
        </Container>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default JobseekerVideo;


