import React, { useState, useEffect } from "react";
import '../../index.css';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import "../../Styles/choosemodels.css";
import axios from "axios";
import SubscriptionPacks from "./Subscription/EmployerSub";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import toast from 'react-hot-toast';
import CloseIcon from "@mui/icons-material/Close";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import enroll from "../../assets/contract.png";
import Hiring from "../../assets/new-hire.png";
// import subImg from "../../assets/SubIcon.png"
 
const ChooseModule = () => {
  const location = useLocation();
  const { Roles } = location.state || {};
  console.log(Roles);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [showSubscription, setShowSubscription] = useState(false); // New state
  const [open, setOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [credits, setCredits] = useState()
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
 
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
 
 
  return (
    <div className="bg" style={{ height: "auto", }}>
      <EmployerDashboardHeader />
      <h3 className="TitleHead "style={{marginTop:'1.5rem'}} >
          <strong>Job Fulfillment Model</strong>
        </h3>
      <div className="container mt-4" >
      {/* <h3 className="mb-4 text-center " style={{ color: "#2F2761",fontSize: "2rem",fontWeight: "bold",letterSpacing: "1px",marginBottom:'1rem' }}> */}
     
            <div style={{widows:'100%',padding:'1rem',borderBottom:'1.5px solid black'}}>
          <p className="mb-4" style={{fontSize:'1.2rem',marginTop:'2rem'}}>
            The <strong>Job Fulfillment Model</strong> is designed to help employers hire skilled candidates
            quickly and efficiently without overburdening their internal recruitment teams. Workaroo takes
            full ownership of the recruitment process—understanding the Job Description (JD), sourcing and
            screening candidates, evaluating fitment, and presenting a curated list of top-quality candidates
            to employers for hiring decisions.
          </p>
          <p style={{fontSize:'1.2rem',marginTop:'2rem'}}>
            This model streamlines hiring by reducing the need for employers to manually sift through large
            volumes of applications while ensuring high-quality talent acquisition.
          </p>
        </div>
       
 
        <p  style={{fontFamily:'Roboto',fontWeight:'550',fontSize:'1.4rem',marginTop:'1rem'}}>The Model Includes Two Key Charges :</p>
 
        {/* Platform Enrollment Charges */}
        <div className="FirstDiv mt-4 ">
          <h4
            className="highlight p-3 text-white text-start enroll"
            style={{
              width:'80%',
              fontWeight: "bold",
              marginBottom: "20px",
              background: "linear-gradient(90deg, #2F2761, #4B3F92)",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
            }}
          >
            1. Platform Enrollment Charges ➜ One-Time Fee:
            <span className="balance-text" style={{ color: "rgb(255 225 119)", fontSize: "1.7rem", marginLeft: "5px" }}>₹ 2,500</span>
 
            <a
              href="/platform-enrolment-plan"
              className="ms-3 fs-5"
              style={{
                color: "white",
                textDecoration: "none",
                padding: "5px 15px",
                borderRadius: "5px",
                background: "rgba(255, 255, 255, 0.2)",
                marginLeft: "10px",
                transition: "background 0.3s"
              }}
              onMouseOver={(e) => (e.target.style.background = "#10B981", e.target.style.color = "white")}
              onMouseOut={(e) => (e.target.style.background = "rgba(255, 255, 255, 0.2)", e.target.style.color = "white")}
            >
              Enroll & Pay
            </a>
            {/* <img style={{height:'2.2rem'}} src={subImg} alt="SubIcon"/> */}
 
          </h4>
 
          <p className="mt-4">
            This charge covers the initial setup and preparatory work required to launch the recruitment
            process, ensuring that the right candidates are sourced effectively.
          </p>
          <ul className="custom-list">
            <li className="mb-2"><strong>Account Setup & JD Documentation –</strong> We create a detailed job description (JD) based on your hiring needs.</li>
            <li className="mb-2"><strong>Video JD Creation & Posting –</strong> A dynamic job video is created for enhanced candidate engagement.</li>
            <li className="mb-2"><strong>Targeted Job Promotions –</strong> Job listings are marketed through:
              <ul className="py-2  innerul">
                <li className="mb-2 mt-2">Social Media & Print Media Campaigns</li>
                <li className="mb-3">Workaroo Website & Mobile Apps</li>
              </ul>
            </li>
            <li className="mb-2"><strong>Comprehensive Candidate Database Access –</strong> Employers can access a pool of pre-vetted, skilled candidates.</li>
            <li className="mb-2"><strong>Value Addition –</strong> The enrollment ensures a structured, AI-enabled hiring process, reducing time-to-hire and optimizing candidate reach.</li>
            <li className="mb-2"><strong>Technology Platform Access –</strong> Employers gain access to Workaroo' AI-driven recruitment system.</li>
            <li className="mb-2"><strong>Dedicated Customer Support –</strong> Assistance throughout the job fulfillment process.</li>
          </ul>
 
        </div>
 
        {/* Hiring Charges */}
        <div className=" FirstDiv mt-4" >
          <h4
            className="highlight text p-3 enroll"
            style={{
              width:'80%',
              fontWeight: "bold",
              marginBottom: "20px",
              background: "linear-gradient(90deg, #2F2761, #4B3F92)",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              color: 'white'
 
            }}
          >
 
            2. Hiring Charges ➜ One-Time Fee : &nbsp;
            <span style={{ color: "rgb(255 225 119)", fontWeight: "bold", fontSize: "1.2rem" }}>
                X% of Annual CTC
            </span> per Candidate on Fulfillment
            <span style={{ fontSize: "0.9rem", color: "white" }}> (Contact Sales Team) </span>
          </h4>
 
          <p className="mt-4">
            Once the enrollment is complete, Workaroo' AI-powered recruitment & human screening processes
            are leveraged to source and match the right candidates for your role.
          </p>
          <ul className="custom-list">
            <li className="mb-2"><strong>Dedicated Recruitment Specialist –</strong> A recruitment expert manages the entire hiring cycle.</li>
            <li className="mb-2"><strong>End-to-End Candidate Screening & Evaluation –</strong> Includes skill assessments, background checks, and JD fitment review.</li>
            <li className="mb-2"><strong>Candidate Interviewing & Shortlisting –</strong> Only pre-screened candidates are shared, reducing employer effort.</li>
            <li className="mb-2"><strong>AI-Moderated Candidate Matching –</strong> AI-based screening ensures the best-fit talent.</li>
            <li className="mb-2"><strong>Essential Background Checks –</strong> Work history, references, and identity verification.</li>
            <li className="mb-2"><strong>Value Addition –</strong> The AI-driven, curated approach helps cut down hiring time, ensures quality hiring, and minimizes dropouts.</li>
            <li className="mb-2"><strong>Ongoing Communication & Hiring Assistance –</strong> Continuous support throughout the recruitment process.</li>
            <li className="mb-2"><strong>Onboarding Support –</strong> Assistance with documentation.</li>
          </ul>
 
        </div>
      </div>
      {/* <EmployerDashboardHeader />
      <div style={{ padding: "30px", background: "#F5F6FA" }} className="choosemain">
        <h2 className="text-center">Commercial Plans</h2>
 
        <div className="container">
          <div className="row justify-content-center mt-5">
            Enrollment Box
            <div className="col-md-5 col-sm-12 d-flex justify-content-center">
              <a href="/employersub" className="text-decoration-none">
                <div className="enrollment-box text-center">
                  <div className="image-box">
                    <img src={enroll} className="img-fluid" alt="Enrollment" />
                  </div>
                  <h3 className="fs-4"> Platform Enrolment <br /> Charges</h3>
                </div>
              </a>
            </div>
 
            Hiring Box
            <div className="col-md-5 col-sm-12 d-flex justify-content-center">
              <a href="/modules" className="text-decoration-none">
              <a href="#" className="text-decoration-none">
                <div className="enrollment-box text-center">
                  <div className="image-box">
                    <img src={Hiring} className="img-fluid" alt="Hiring" />
                  </div>
                  <h3 className="fs-4">Hiring <br /> Charges</h3>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
 
    </div>
 
  );
};
 
export default ChooseModule;
 
 