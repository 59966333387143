import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import l1 from '../../assets/leaders/leader1.jpg';
import l2 from '../../assets/leaders/leader2.jpg';
import l3 from '../../assets/leaders/leader3.jpg';
import l4 from '../../assets/leaders/leader4.jpg';
import l5 from '../../assets/leaders/leader5.jpg';
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";

import { useLocation } from "react-router-dom";


const leaders = [
  {
    name: "Ravichandran Annadurai",
    title: "CEO & Co-Founder",
    description:
      "Ravi is an IT Services Leader with 3+ decades of Global experience.  As a Business and Delivery Leader with Wipro and Infosys Technologies, he has strategized, incubated and managed business practices. Ravi has founded Co-Living marketplace startup and has mentored businesses",
    img: l5,
    img_width: '150px',
    img_height: 'auto',
    linkedin: "https://www.linkedin.com/in/arc1507/",
  },
  {
    name: "Pradeep CP",
    title: "COO & Co-Founder",
    description:
      "Pradeep is a seasoned sales and marketing professional with two decades of corporate experience specializing in building and expanding business. As a Director at Syosys Infotech Pvt Ltd, he has groomed IT products business. Pradeep is a Business consultant who brings strategic insights and leadership to the table and also Author with thought leadership.",
    img: l4,
    img_width: 'auto',
    img_height: '169px',
    linkedin: "https://www.linkedin.com/in/pradeep-cp/",
  },
];

const Leadership = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12;

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const styles = {
    
    section: {
      padding: '5px 0px',
      backgroundColor: 'transparent',
      backgroundImage: 'linear-gradient(230deg, #AFC6E6 0%, #E9E6EF 86%)',
    },
    name: {
      fontWeight: '600',
      fontSize: '20px',
    },
    title: {
      fontWeight: '600',
      fontSize: '14px',
      color: "black",
    },
    leaderCard: {
      margin: '25px auto', // Centered the cards
    },
    linkedinButton: {
      backgroundColor: " rgb(47 39 97)",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    card: {
      width: "400px",
      maxHeight: "500px",
      border: "none",
      borderRadius: "15px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: "20px",
      cursor: "pointer",
    },
    profileImageContainer: {
      marginTop: "-70px",
      position: "relative",
    },
    
    profileImage: {
      width: "150px",
      height: "150px",
      borderRadius: "20%",
      border: "3px solid #fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    },
    cardTitle: {
      marginTop: "10px",
      fontWeight: "bold",
      color: "#333",
    },
    textMuted: {
      color: "#777",
    },
    statsContainer: {
      display: "flex",
      justifyContent: "space-around",
      margin: "20px 0",
      
    },
    stat: {
      textAlign: "center",
    },
    statValue: {
      fontWeight: "bold",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
    followButton: {
      backgroundColor: "#ff5858",
      border: "none",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
    },
    followButtonHover: {
      backgroundColor: "#f857a6",
    },
    messageButton: {
      border: "1px solid #ff5858",
      color: "#ff5858",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
    },
    messageButtonHover: {
      backgroundColor: "#ff5858",
      color: "#fff",
    },
  };

  return (
    <div className="dashboard">
  <EmployerDashboardHeader />
  <Container fluid className="content">
    <Row className="g-4 justify-content-center"> {/* Added justify-content-center to Row */}
      {isLoggedIn ? <EmployerDashboardSideBar /> : <></>}
      <Col md={colWidth} className="main-content">
        <Box sx={{ p: 4,  }}>

        <div className="text-center mb-4">
          <h2 style={{ fontWeight: "bold", fontSize: "2rem",marginBottom: "6rem" }}>Our Leaderships</h2>
        </div>

          <div className="d-flex justify-content-center  lead " style={{flexWrap:'wrap',gap:'80px'}}> {/* Use flexbox to center the content */}
            {leaders.map((leader, index) => (
              <div style={styles.card} onClick={() => setShowDetails(!showDetails)} key={index}>
                <div className="profileImageContainer" style={styles.profileImageContainer}>
                  <img
                    src={leader.img}
                    alt="Profile"
                    style={styles.profileImage}
                  />
                </div>
                <h5 style={styles.cardTitle}>{leader.name}</h5>
                <p style={styles.textMuted}>{leader.title}</p>

                  <>
                    <div style={styles.statsContainer}>
                      <p style={{fontSize: "1rem"}}>{leader.description}</p>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <a
                        href={leader.linkedin}
                        className="btn"
                        target="_blank"
                        rel="noopener noreferrer" // Security best practice
                        style={styles.linkedinButton}
                      >
                        Linkedin
                      </a>
                    </div>
                  </>
             
              </div>
            ))}
          </div>
        </Box>
      </Col>
    </Row>
  </Container>
</div>

  );
};

export default Leadership;
