import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../EmployerDashboard.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { MdDelete } from "react-icons/md";
import toast from 'react-hot-toast';
// import '../Styles/model.css'
import Swal from 'sweetalert2'; // Import Swal
import { MdModeEditOutline } from "react-icons/md";
import moment from 'moment';

const AppliedJob = () => {

  const navigate = useNavigate();
  const employerid = sessionStorage.getItem("userId");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedJobDetails, setSelectedJobDetails] = useState(null);
  const userId = sessionStorage.getItem("userId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobSalary, setJobSalary] = useState(null); // State for job title
  const [jobLocation, setJobLocation] = useState(null); // State for job location
  const [jobTitle, setJobTitle] = useState(null); // State for job title
  const [selectedJobId, setSelectedJobId] = useState(null); // State for selected job id
  const [applied, setApplied] = useState([])
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const token = sessionStorage.getItem('accessToken');



  // Open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleJobNameClick = (jobDetails) => {
    setSelectedJobDetails(jobDetails);
    setShowModal(true);
  };

  const [filter, setFilter] = useState({
    jobTitle: '',
    location: '',
    status: '',
    minSalary: '',
    maxSalary: '',
  });

  // const handleClose = () => setShowModal(false);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {

    fetchApplicants();
  }, [apiUrl]);

  const fetchApplicants = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Authentication token is missing');
      }

      const response = await axios.get(`${apiUrl}/job/applications/${userId}`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response.data.data)) {
        setApplicants(response.data.data);
        setFilteredApplicants(response.data.data);
      } else {
        throw new Error('Expected data to be an array');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  // Optionally, useEffect to log changes in `applied` after state updates
  useEffect(() => {
    console.log("Updated Applied Data:", applied);
  }, [applied]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    let filtered = applicants;

    if (filter.jobTitle) {
      filtered = filtered.filter(applicant =>
        applicant?.job?.jobTitle && applicant?.job?.jobTitle.toLowerCase().includes(filter.jobTitle.toLowerCase())
      );
    }
    if (filter.location) {
      filtered = filtered.filter(applicant =>
        applicant?.job?.location && applicant?.job?.location.toLowerCase().includes(filter.location.toLowerCase())
      );
    }
    if (filter.status) {
      filtered = filtered.filter(applicant =>
        applicant?.status && applicant?.status.toLowerCase() === filter.status.toLowerCase()
      );
    }

    setFilteredApplicants(filtered);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('accessToken');

      if (!token) {
        toast.error("No access token found, please login again.");
        return;
      }

      // Use SweetAlert to show confirmation
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to undo this action!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn-confirm',
          cancelButton: 'btn-cancel',
          actions: 'btn-actions' // This will be used to align the buttons inline
        }
      });

      console.log("result", result)
      // Check if the user clicked 'Yes'
      if (result.isConfirmed) {
        // If confirmed, proceed with deletion
        const response = await axios.delete(`${apiUrl}/applications/delete/${id}`, {
          headers: {
            'Authorization': `${token}`,
          },
        });
        console.log("res", response)

        if (response.status === 200) {
          toast.success("Job has been deleted successfully.");
          // Update state to remove deleted item from the list
          setFilteredApplicants((prevApplicants) =>
            prevApplicants.filter((applicant) => applicant?.applicationId !== id)
          );
        }
      } else if (result.isDismissed) {
        // If the user clicked 'Cancel', do nothing
        console.log("Delete action was canceled.");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "Something went wrong, please try again.";
      toast.error(errorMessage);
    }
  };

  const handleUpdate = (jobId) => {
    // console.log("jobId", jobId.job.jobId)
    // setSelectedJobId(jobId.job.jobId);
    //  // Store the selected jobId
    // setJobTitle(jobId.job.jobTitle); // Set the current job title to the input field
    // setJobLocation(jobId.job.location)
    // setJobSalary(jobId.job.salary)
    // setIsModalOpen(true); // Open the modal
    // console.log(selectedJobId.)
    navigate('/jobseeker/apply', { state: { jobId } });
  };

  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    width: "500px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
    textAlign: "center",
  };

  const titleStyles = {
    marginBottom: "15px",
    fontSize: "22px",
    color: "#333",
  };

  const labelStyles = {
    display: "block",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "14px",
  };

  const inputStyles = {
    width: "100%",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const buttonContainer = {
    display: "flex",
    justifyContent: "space-between",
  };

  const saveButtonStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const closeButtonStyles = {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };

  const handleFocus = (InputValue) => {
    if (InputValue === "Title") {
      setJobTitle("");
    }
    if (InputValue === "Location") {
      setJobLocation("");
    }
    if (InputValue === "Salary") {
      setJobSalary(null)
    }
  };

  const handleSave = async () => {
    try {

      // Create an object with required fields
      const jobUpdateData = {
        jobTitle: jobTitle !== undefined || "" ? jobTitle : selectedJobId.jobTitle,
        location: jobLocation !== undefined || "" ? jobLocation : selectedJobId.jobLocation,
        salary: jobSalary !== undefined || "" ? parseInt(jobSalary, 10) : selectedJobId.salary,
      };

      // Remove undefined values dynamically
      const filteredJobData = Object.fromEntries(
        Object.entries(jobUpdateData).filter(([_, value]) => value !== undefined)
      );

      const response = await fetch(`${apiUrl}/job/update/${selectedJobId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filteredJobData),
      });

      // // const jobtitledata = jobTitle !== undefined??jobTitle 
      // // const joblocationdata = jobLocation !== undefined??jobLocation 
      // // const jobsalarydata = jobSalary !== undefined??jobSalary 
      // const response = await fetch(`${apiUrl}/job/update/${selectedJobId.jobId}`, {
      //   method: 'PATCH', // or 'PATCH' depending on your API
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ 

      //     "jobTitle":jobTitle??"",
      //     "location":jobLocation??"",
      //     "salary":jobSalary??""

      //    }),
      // });

      if (response.ok) {
        // toast.success('Job updated successfully!'); // Show success toast
        toast.success('Job updated successfully!');
        fetchApplicants();

        // setUpdateStatus('success');
        // fetchPostedJob(); // Fetch the updated job
        closeModal(); // Close the modal after successful update
      } else {
        toast.error('Failed to update job'); // Show error toast
        // setUpdateStatus('error');
      }
    } catch (error) {
      console.error('Error updating job:', error);
      toast.error('Error updating job'); // Show error toast
    }
  };

  // const fetchPostedJob = async () => {
  //   try {
  //     const token = sessionStorage.getItem('accessToken');
  //     if (!token) {
  //       throw new Error('Authentication token is missing');
  //     }

  //     // const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
  //     //   headers: {
  //     //     'Authorization': `${token}`,
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     // });
  //     const response = await axios.get(`${apiUrl}/job/posted/${jobId}`, {
  //       headers: {
  //         'Authorization': `${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     if (Array.isArray(response?.data?.data)) {
  //       setApplicants(response?.data?.data);
  //     } else {
  //       throw new Error('Expected data to be an array');
  //     }
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleViewClick = async (applicant) => {
    setShowDetailsModal(true);

    try {
      // Fetch job details based on the applicant's jobId
      const response = await axios.get(`${apiUrl}/job/getjob/${applicant.jobId}`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Make sure token is prefixed with 'Bearer' if required
          'Content-Type': 'application/json',
        },
      });

      // Log the response for debugging
      console.log("Fetched job details:", response.data.data);

      // Set the fetched job details to the state
      setApplied(response.data.data);
    } catch (error) {
      console.error("Error fetching applicant details:", error);
    }
  };



  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">

          <Col>
            <EmployerDashboardSideBar />
          </Col>
          <Col md={10} sm={12} className="main-content">
            <h5 className="mb-4 fw-bold py-2 ms-2">Applied Jobs</h5>

            <div >
              <Card className="mb-4 shadow-sm" >
                <Card.Body>
                  <Form>
                    <Row className="justify-content-between">
                      <Col sm={3}>
                        <Form.Group controlId="jobTitle">
                          <Form.Label>Job Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="jobTitle"
                            value={filter?.jobTitle}
                            onChange={handleFilterChange}
                            placeholder="Job Title"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="location" >
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type="text"
                            name="location"
                            value={filter?.location}
                            onChange={handleFilterChange}
                            placeholder="Location"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="status" >
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            value={filter?.status}
                            onChange={handleFilterChange}
                          >
                            <option value="">All</option>
                            <option value="applied">Applied</option>
                            <option value="shortlist">Shortlisted</option>
                            <option value="scheduled">schudule</option>
                            <option value="selected">Selected</option>
                            <option value="offer">Offered</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="d-flex align-items-end" style={{ marginTop: "27px" }}>
                        <Button style={{ backgroundColor: " rgb(47 39 97)" }} onClick={applyFilters} className="btn w-100">
                          Filters
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              <Card className="shadow-sm" >
                <Card.Body>
                  <div className="table-responsive">
                    <Table hover className="table " style={{ marginTop: '30px' }}>
                      <thead className="theads">
                        <tr className="tablehead text-center">
                          <th>Job Title</th>
                          <th>Location</th>
                          <th>₹ Salary/Month</th>
                          <th>Applied Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredApplicants
                          .filter((applicant) => applicant?.job) // Filter out applicants with null job
                          .map((applicant) => (
                            <tr key={applicant?.applicationId} className="tbodys" style={{ textAlign: 'center' }}>
                              <td>
                                <span style={{ cursor: 'pointer' }} onClick={() => handleJobNameClick(applicant?.job)}>
                                  {applicant?.job?.jobTitle}
                                </span>
                              </td>
                              <td>{applicant?.job?.location}</td>
                              <td>{applicant?.job?.salary}</td>
                              <td>{new Date(applicant?.createdAt).toLocaleDateString()}</td>
                              <td>
                                {(applicant?.status || 'Applied').charAt(0).toUpperCase() +
                                  (applicant?.status || 'Applied').slice(1).toLowerCase()}
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    navigate('/appliedjobDetails', { state: { job: applicant } });
                                  }}
                                  style={{ width: '80px', height: '30px', background: '#2F2761' }}
                                >
                                  View
                                </Button>
                                <MdDelete
                                  className={`fs-4 ms-2 ${applicant?.status !== 'APPLIED' ? 'text-muted' : 'text-danger'}`}
                                  onClick={() => applicant?.status === 'APPLIED' && handleDelete(applicant?.applicationId)}
                                  disabled={applicant?.status !== 'APPLIED'}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>

                    </Table>

                    {/* {isModalOpen && (
                    <div style={overlayStyles}>
                      <div style={modalStyles}>
                        <h2 style={titleStyles}>Update the Job</h2>

                        <label style={labelStyles}>Job Title</label>
                        <input
                          type="text"
                          value={jobTitle || ""}
                          onChange={(e) => setJobTitle(e.target.value)}
                          onFocus={() => handleFocus("Title")}
                          style={inputStyles}
                          required
                        />

                        <label style={labelStyles}>Location</label>
                        <input
                          type="text"
                          value={jobLocation || ""}
                          onChange={(e) => setJobLocation(e.target.value)}
                          onFocus={() => handleFocus("Location")}
                          style={inputStyles}
                          required
                        />
                        <label style={labelStyles}>Salary</label>
                        <label style={labelStyles}></label>
                        <input
                          type="number"
                          value={jobSalary || null}
                          onChange={(e) => setJobSalary(e.target.value)}
                          onFocus={() => handleFocus("Salary")}
                          style={inputStyles}
                          required
                        />

                        <div style={buttonContainer}>
                          <button onClick={closeModal} style={closeButtonStyles}>
                            Close
                          </button>
                          <button onClick={handleSave} style={saveButtonStyles}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )} */}

                    {/* Modal Component */}
                    {isModalOpen && (

                      <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="Confirmation Modal"
                        className="modal-content"
                        overlayClassName="modal-overlay"
                      >
                        <button className="close" onClick={closeModal}>&times;</button>
                        <h2>Are you sure you want to delete?</h2>
                        <div className="modal-buttons">
                          <button className="cancel-btn" onClick={closeModal}>Cancel</button>
                          <button className="ok-btn" onClick={handleDelete}>OK</button>
                        </div>
                      </Modal>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Job Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {applied ? (
            <div>
              <p><strong>JobTitle:</strong> {applied.jobTitle}</p>
              <p><strong>Location:</strong> {applied.location}</p>
              <p><strong>Experience:</strong> {applied.experience}</p>
              <p><strong>Salary:</strong> {applied.salary}</p>
              {/* <p><strong>Skills:</strong> {applied.skills}</p> */}
              <p><strong>CreatedAt: </strong>{moment(applied?.createdAt).format('MMMM Do YYYY')}</p>
              <p><strong>ExpiryDate: </strong> {moment(applied?.expiryDate).format('MMMM Do YYYY')}</p>
            </div>
          ) : (
            <p>Loading applicant details...</p>
          )}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Job Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedJobDetails ? (
            <>
              <p><strong>Job Name:</strong> {selectedJobDetails?.jobTitle}</p>
              <p><strong>Location:</strong> {selectedJobDetails?.location}</p>
              <p><strong>Description:</strong> {selectedJobDetails?.responsibilitiesAndDuties}</p>
              <p><strong>Posted Date:</strong> {new Date(selectedJobDetails?.startDate).toLocaleDateString()}</p>
              <p><strong>Expiry Date:</strong> {new Date(selectedJobDetails?.expiryDate).toLocaleDateString()}</p>
              <p><strong>₹ Salary/Month:</strong> {selectedJobDetails?.salary}</p>
              <p><strong>Total Experience:</strong> {selectedJobDetails?.totalExperience}</p>
              <p><strong>Skills:</strong> {selectedJobDetails?.skills}</p>
            </>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}




    </div>
  );
};

export default AppliedJob;
