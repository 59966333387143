import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../EmployerDashboard.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import { FaEye } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
// import ReactPaginate from 'react-paginate';
import ReactPaginate from 'react-paginate';  // Importing react-paginate
// import '../../Styles/pagination.css'

const JobSeekerTable = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const applicantsPerPage = 10; // Number of applicants per page
  const role = sessionStorage.getItem("role");
// const [open]
  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response.data.data)) {
          setApplicants(response.data.data);
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDeleteClick = async (jobseeker) => {
    const confirmed = window.confirm('Are you sure you want to delete this Job seeker?');
    if (!confirmed) return;

    try {
      const response = await fetch(`${apiUrl}/admin/jobseekers/${jobseeker.userId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        alert('Job deleted successfully.');
      } else {
        alert('Failed to delete the job seeker. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      alert('An error occurred while deleting the job.');
    }
  };

  const handleViewClick = (applicant) => {
    setSelectedApplicant(applicant);
    setShowDetailsModal(true);
  };

  const closePopup = () => setShowDetailsModal(false);

  const indexOfLastApplicant = (currentPage + 1) * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row>
          <EmployerDashboardSideBar />
          <Col md={10} className="main-content">
            <h5 className="mb-3 fw-bold">All Job Seeker</h5>

            <Modal show={showDetailsModal} onHide={closePopup}>
              <Modal.Header closeButton>
                <Modal.Title>Job Seeker Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedApplicant && (
                  <div>
                    <p><strong>Name:</strong> {selectedApplicant.userName} {selectedApplicant.lastName}</p>
                    <p><strong>Email:</strong> {selectedApplicant.emailAddress}</p>
                    <p><strong>Phone:</strong> {selectedApplicant.phoneNumber}</p>
                    <p><strong>Address:</strong> {selectedApplicant.address}</p>
                    <p><strong>Location:</strong> {selectedApplicant.location}</p>
                    <p><strong>Experience:</strong> {selectedApplicant.experience}</p>
                    <p><strong>Status:</strong> {selectedApplicant.status}</p>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="card border-0 rounded-0">
              <div className="card-body table-responsive">
                <Table hover striped variant="light" className="table">
                  <thead>
                    <tr className="bg-light">
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentApplicants.map((applicant) => (
                      <tr key={applicant.id} className="table-row">
                        <td>{applicant.userName} {applicant.lastName}</td>
                        <td>{applicant.emailAddress}</td>
                        <td>{applicant.phoneNumber}</td>
                        <td>
                          <Link onClick={() => handleViewClick(applicant)} className="btn btn-info text-white me-2">
                            View
                          </Link>
                          {role === 'admin' && (
                            <Link onClick={() => handleDeleteClick(applicant)} className="text-danger">
                              <FaTrash size={16} />
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            {/* Pagination */}
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(applicants.length / applicantsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default JobSeekerTable;
