import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import { FaBriefcase, FaMapMarkerAlt, FaEye, FaRupeeSign } from "react-icons/fa";
import { formatDistanceToNow, parseISO } from "date-fns";
import moment from 'moment';
import '../Styles/jobdetails.css'
import EmployerLogin from "../components/EmployerLogin";
import JobSeekerRegistration from "../components/JobseekerLogin";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Links, Typography } from "@mui/material";

const JobDetailPage = () => {

  sessionStorage.setItem("previousJobDetails", window.location.pathname);

  const { jobIds } = useParams(); // Get jobId from URL

  const [jobDetails, setJobDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [joblist, setJoblist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const navigate = useNavigate();
  const [showJobseekerPopup, setShowJobseekerPopup] = useState(false);
  const [selectedjob, setSelectedjob] = useState(null)
  const [jobApplication, setJobApplication] = useState(null);
  const location = useLocation(); // Get the current page path
  const requiredFields = ["firstName", "lastName", "Currentsalary", "expectedSalary", "skills",
    "totalExperience", "phoneNumber", "city", "profileTitle", "companyName", "pincode",
    "qualification",
    "responsibilities", "qualification",];

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      // setIsAuthenticated(true);
      console.log('Token exists:', token);
    } else {
      // setIsAuthenticated(false);
      console.log('Token does not exist');
    }
    // if (token) {
    //   // setIsLoggedIn(true);
    // }
  }, []);

  useEffect(() => {
    const fetchJobApplication = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job/applications/${userId}`);

        // Check if the response contains data and is an array
        if (Array.isArray(response.data.data) && response.data.data.length > 0) {
          const jobIds = response.data.data.map((application) => application?.job?.jobId);
          setJobApplication(jobIds); // Store all job IDs
          console.log("Applied job IDs: ", jobIds);
        } else {
          console.log("No job applications found");
          setJobApplication([]);
        }
      } catch (error) {
        console.error("Error fetching job application:", error);
      }
    };



    fetchJobApplication();
  }, [userId]); // Runs when applicationId changes

  console.log("appp", jobApplication)

  const openJobseekerPopup = () => {
    setShowJobseekerPopup(true);
  };

  const closeJobseekerPopup = () => {
    setShowJobseekerPopup(false);
  };

  const toggleResumePopup = () => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      const missingFields = requiredFields.filter(field => !userDetails?.[field]);
      // console.log("missingFields",missingFields)

      if (missingFields.length > 0) {
        setShowValidationError(true);
      } else {
        sessionStorage.setItem("JobId", id)
        sessionStorage.setItem("JobTitle", jobDetails.jobTitle)
        sessionStorage.setItem("Postedby", jobDetails.postedById)
        navigate("/jobseeker/apply");
      }
    }
    else {
      setShowJobseekerPopup(true);
    }
  };

  const closeValidationErrorPopup = () => setShowValidationError(false);


  const getFormattedTime = (createdAt) => {
    if (!createdAt) {
      return 'Invalid date';
    }

    try {
      const parsedDate = parseISO(createdAt);
      if (isNaN(parsedDate)) {
        return 'Invalid date';
      }
      return formatDistanceToNow(parsedDate, { addSuffix: true });
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid date';
    }
  };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job/getjob/${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setJobDetails(response.data.data);
        setSelectedjob(response.data.data.jobId)
      } catch (error) {
        console.error("Error fetching job data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [id, token, apiUrl]);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });
        setJoblist(response.data.data);
      } catch (error) {
        console.error("Error fetching job data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [token, apiUrl]);

  useEffect(() => {

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setUserDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      fetchUserData();

    }
  }, [userId, token, apiUrl]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center text-danger">
        Error fetching job details.
      </p>
    );
  }

  if (!jobDetails) {
    return <p className="text-center">No job details available.</p>;
  }


  const filteredJobs = joblist?.filter(job => job.jobId !== jobDetails.jobId).slice(0, 5);

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />

      <JobSeekerRegistration
        showJobseekerPopup={showJobseekerPopup}
        hideJobseekerPopup={closeJobseekerPopup}
      />
      <Container fluid className="content">
        <Row className="g-4">
          <Col>
            {token ? <EmployerDashboardSideBar /> : <div></div>}
          </Col>
          <Col md={token ? 10 : 12} className="main-content ">
            <Row>
              <Col md={12} style={{ marginLeft: !token ? '50px' : '0' }}>

                {/* Breadcrumbs */}
                <div role="presentation" className="ms-2 pt-3" >
                  <Breadcrumbs maxItems={2} aria-label="breadcrumb" className="breadcrum">
                    <Links underline="hover" color="inherit" href="/Joblist">
                      All Jobs
                    </Links>

                    <Typography sx={{ color: "#2F2761", fontWeight: 'bold' }}>  Job Details</Typography>
                  </Breadcrumbs>
                </div>

                <h5 className="mb-3 mt-3 fw-bold px-3">Job Details</h5>
                <div className="d-flex container-box">
                  <div className="job-detail-container" style={{ width: "55%", marginRight: "4rem" }}>
                    <div className="job-card p-4 rounded-3 ms-2" style={{ backgroundColor: "#fff" }}>
                      <h5 className="card-title">{jobDetails?.jobTitle}</h5>
                      <div className="d-flex  mt-3 exsaloc" style={{ gap: "4rem" }}>
                        <div className="card-text exp">
                          <FaBriefcase className="me-2 " />
                          {`${jobDetails?.minExperience} - ${jobDetails?.maxExperience} Years`}
                        </div>
                        <div className="card-text sal">
                          <FaRupeeSign className="me-2" />
                          {jobDetails?.salary ? jobDetails?.salary : "Not disclosed"}
                        </div>
                        <div className="card-text loc">
                          <FaMapMarkerAlt className="me-2" />
                          {jobDetails?.location}
                        </div>

                      </div>


                      <div className="d-flex justify-content-between mt-lg-5 align-items-center container-box2">
                        <p className="card-text m-0 posted">
                          Posted: {getFormattedTime(jobDetails?.createdAt)}
                        </p>
                        <div></div>
                        <p className="card-text m-0 expiery">
                          Expiry at: {moment(jobDetails?.expiryDate).format('MMMM Do YYYY')}
                        </p>

                        <div>
                          {userRole !== "employer" && (
                            <>
                              {Array.isArray(jobApplication) && jobApplication.includes(selectedjob) ? (
                                <button
                                  className="apply-button-1"
                                  style={{ background: "#A0A0A0", cursor: "not-allowed" }}
                                  disabled
                                >
                                  Applied
                                </button>
                              ) : (
                                <button
                                  className="apply-button-1"
                                  onClick={toggleResumePopup}
                                  style={{
                                    background: moment().isAfter(moment(jobDetails?.expiryDate)) ? "#A0A0A0" : "#2F2761", // Gray if expired
                                    cursor: moment().isAfter(moment(jobDetails?.expiryDate)) ? "not-allowed" : "pointer",
                                  }}
                                  disabled={moment().isAfter(moment(jobDetails?.expiryDate))}
                                >
                                  Apply
                                </button>
                              )}
                            </>
                          )}

                        </div>
                      </div>
                    </div>

                    {/* <div className="job-description-card mt-4 p-4 ms-2" style={{ boxShadow: "0px 0px 17.6px 10px #ABDDF240" }}>
                      <div>{jobDetails?.description}</div>
                      <div className="mt-3">{jobDetails?.responsibilitiesAndDuties}</div>

                      <div className="d-flex justify-content-between mt-lg-5 align-items-center">
                        <p className="card-text m-0">
                          Total Application:20
                        </p>
                        <p className="card-text m-0">
                          created at: {moment(jobDetails?.createdAt).format('MMMM Do YYYY')}
                        </p>
                        <p className="card-text m-0">
                          Expiry at: {moment(jobDetails?.expiryDate).format('MMMM Do YYYY')}
                        </p>
                      </div>
                    </div> */}

                    <div className="job-description-card mt-4 p-4 ms-2" style={{ boxShadow: "0px 0px 17.6px 10px #ABDDF240", background: 'white' }}>

                      <div className="d-flex justify-content-between lg-5 mb-3 align-items-center">
                        <p className="card-text m-0 applicant">
                          Total Application:20
                        </p>
                        <p className="card-text m-0 creat">
                          Created at: {moment(jobDetails?.createdAt).format('MMMM Do YYYY')}
                        </p>
                        <p className="card-text m-0 exp2">
                          Expiry at: {moment(jobDetails?.expiryDate).format('MMMM Do YYYY')}
                        </p>
                      </div>

                      {/* <div className=""><span style={{fontSize:'16px',fontWeight:'bold'}}>Description:
                      <div>{jobDetails?.description}</div>
                      </span>
                      </div> */}

                      <div className="mb-3 "><span style={{ fontSize: '16px', fontWeight: 'bold', paddingTop: '20px' }}>Responsibilities And Duties:</span>
                        <ul className="" >
                          {jobDetails?.responsibilitiesAndDuties?.map((duty, index) => (
                            <li key={index}>{duty}</li>
                          ))}
                        </ul>
                      </div>
                      <br></br>
                      <div className=""><span style={{ fontSize: '16px', fontWeight: 'bold' }}>Skills:</span>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                          {jobDetails?.skills?.map((duty, index) => (
                            <li key={index}>{duty}</li>
                          ))}
                        </ul>
                      </div>

                    </div>

                  </div>

                  <div className="right-sidebar" style={{ width: "35%" }}>
                    <div className="card-2 p-4 right" style={{ marginBottom: '30px', borderRadius: '10px' }}>
                      <h3 className="card-text" style={{ fontSize: "1.2rem" }}>
                        Jobs you might be interested in
                      </h3>
                      {filteredJobs?.map(job => (
                        <div key={job.id} className="job-item my-3 jobbox" style={{ padding: '10px', background: 'white', borderRadius: '10px', cursor: 'pointer' }}>
                          <Link to={`/viewdetails/${job.jobId}`}>

                            <h4 className="card-title">
                              {job.jobTitle}
                            </h4>
                            <p className="card-text">{job.companyName}</p>
                            <p className="card-text">{job.location}</p>
                            <p className="card-text" style={{ fontSize: "0.9em" }}>Posted {getFormattedTime(job.createdAt)}</p>
                          </Link>

                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {userRole === 'jobseeker' ? (<Modal show={showValidationError} onHide={closeValidationErrorPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Incomplete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Some required fields are missing in your profile. Please complete your profile to apply.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeValidationErrorPopup}>
            Close
          </Button>
          <Button variant="primary" onClick={() => navigate("/jobseekerprofile")}>
            Complete Profile
          </Button>
        </Modal.Footer>
      </Modal>) : <></>}
    </div>
  );
};

export default JobDetailPage;
