import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import { Container, Col, Row, Table, Badge, Nav, Button, Navbar, Form, Modal, Card } from 'react-bootstrap';
import { FaHome, FaBriefcase, FaUser, FaEnvelope, FaClipboardList, FaUsers, FaChartLine } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar"
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';
import { useNavigate } from 'react-router-dom';
import SubscriptionPacks from '.././Employee/Subscription/SubscriptionPacks';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from 'react-hot-toast';
import '../../Styles/jobseekerjoblist.css'
import Select from "react-select";

const CallcenterJobDescriptionForm = () => {

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const navigate = useNavigate();

  const uselocation = useLocation();
  const updateingId = uselocation.state;

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = sessionStorage.getItem('accessToken');
  const [open, setOpen] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [credits, setCredits] = useState()

  const userDetails = sessionStorage.getItem("userDetails")
  console.log("userDetails", userDetails);
  const [newJobCategory, setNewJobCategory] = useState(""); // Store user-entered category
  const [isAddingCategory, setIsAddingCategory] = useState(false); // Track loading state
  const [jobSubcategoriess, setJobSubcategoriess] = useState([]); // List of job subcategories
  const [newJobSubCategory, setNewJobSubCategory] = useState(""); // New subcategory input
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false); // Loading state
  const [employerIds, setEmployerIds] = useState([]);
  const [selectedEmployerId, setSelectedEmployerId] = useState(""); // Separate state


  const fetchEmployerIds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/user`, {
        headers: { Authorization: `${token}` },
      });
      console.log("employer", response.data)

      if (response.data?.success) {
        const ids = response.data.data
          .filter(user => user.role === "employer" && user.employerId && user.employerId.trim() !== "") // Exclude null/empty values
          .map(emp => ({ value: emp.userId, label: `${emp.employerId}` })); // Format for react-select

        setEmployerIds(ids);
      } else {
        console.error("Failed to fetch employer IDs");
      }
    } catch (error) {
      console.error("Error fetching employer IDs:", error);
    }
  };

  useEffect(() => {
    fetchEmployerIds();
  }, [token])



  const handleEmployerSelect = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      employerId: selectedOption ? selectedOption.value : "", // ✅ Store only ObjectId
      createdBy: userId
    }));
    setSelectedEmployerId(selectedOption)
  };

  console.log("employerIds", employerIds)
  console.log("selectedEmployerId", selectedEmployerId)
  const handleOpen = () => {
    // if (subscriptionStatus === 'active') {
    setOpen(true);
    // }
  };

  const handleClose = () => {

    setOpen(false);
  };

  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = async () => {
    try {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        console.warn("No access token found");
        return;
      }

      const response = await axios.get(`${apiUrl}/user/${userId}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.status === 200 && response.data?.data) {
        const userData = response.data.data;
        console.log("User details fetched:", userData);

        // Set subscription status
        setSubscriptionStatus(userData.subscriptionStatus);
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (err) {

      console.error("Error fetching user details:", err);
      toast.error("Failed to fetch user details. Please try again.");
    }
  };

  // important subscription
  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }

    const fetchProfile = async () => {
      try {
        // First, check if subscriptionStatus is already set
        if (subscriptionStatus) {
          console.log("Subscription status already available:", subscriptionStatus);
          return;
        }


        const response = await axios.get(`${apiUrl}/subscription/getEmployerPlan/${userId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.status === 200 || response.status === 201) {
          console.log("Response Status:", response.status);

          if (response.data && response.data.data) {
            const userData = response.data.data;


            // Check if subscriptionStatus is active
            if (userData.subscriptionStatus === 'active') {
              setCredits(userData.jobCredits);
              console.log("Subscription Active:", userData);
            } else {
              console.warn("Subscription is inactive, hiding credits.");
              setCredits(null);
            }
          } else {
            console.error("Invalid data format in response.");
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (err) {
        // if (subscriptionStatus === 'active') {
        // setOpen(true);
        handleOpen();
        // }

        if (err.response) {
          const { status, data } = err.response;
          if (data?.errorMsg === "Employer does not have an active subscription plan") {
            // toast.error("Your subscription is inactive. Please subscribe to a plan.");
          } else if (status === 400) {
            // Handle 400 error
          } else if (status === 401) {
            toast.error("Unauthorized: Please check your authentication token.");
          } else if (status === 404) {
            toast.error("Not Found: Unable to find the requested resource.");
          } else {
            // toast.error(`Error ${status}: ${data?.message || err.message}`);
          }
        } else {
          toast.error("Network error or server not reachable:", err.message);
        }
      }
    };

    fetchProfile();
  }, [subscriptionStatus]);




  console.log("credits", credits)

  useEffect(() => {
    if (updateingId !== null) {
      setFormData(updateingId?.job)
    }

  }, [updateingId])





  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [jobcategories, setJobcategories] = useState([]);
  const [jobSubcategories, setjobSubcategories] = useState([]);

  console.log("updating", updateingId)

  const [formData, setFormData] = useState({
    jobTitle: '',
    totalExperience: null,
    maxExperience: null,
    minExperience: null,
    jobLocation: '',
    salary: '',
    companyName: '',
    jobType: [],
    category_id: '',
    jobCategory_id: '',
    categoryName: '',
    subcategoryName: '',
    jobCategoryName: '',
    subcategory_id: '',
    jobSubCategory_id: '',
    qualifications: '',
    responsibilities: [],
    skills: [],
    minExperience: '',
    maxExperience: '',
    hoursPerWeek: '',
    jobPosition: '',
    miscDetails: '',
    termsAndConditions: '',
    specialRequirements: '',
    noOfPosition: 1,
    submissionDate: '',
    expiryDate: '',
    benefitsDetails: '',
    shift: '',
    workWeek: 'y',

  });

  const win = window.localStorage;


  useEffect(() => {
    if (updateingId !== null) {
      win.clear();
      const formattedSubmissionDate = updateingId?.job?.submissionDate
        ? new Date(updateingId?.job?.submissionDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
        : '';
      setFormData({
        ...updateingId?.job,
        jobLocation: updateingId?.job?.location,
        submissionDate: formattedSubmissionDate,
        expiryDate: updateingId?.job?.expiryDate
          ? new Date(updateingId?.job?.expiryDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
          : '',
        qualifications: updateingId?.job?.qualificationRequirements,
        responsibilities: updateingId?.job?.responsibilitiesAndDuties,
        // jobId: undefined,
      });
      console.log("databind", updateingId?.job);



      console.log("location", updateingId?.job)

    }
    else {    // Load form data from localStorage when the component mounts
      const savedFormData = {
        jobTitle: win.getItem('jobTitle') || '',
        totalExperience: win.getItem('totalExperience') || null,
        jobLocation: win.getItem('jobLocation') || '',
        salary: win.getItem('salary') || '',
        companyName: win.getItem('companyName') || '',
        category_id: win.getItem('category_id') || '',
        industryCategory: win.getItem('industryCategory') || '',
        subcategory_id: win.getItem('subcategory_id') || '674af135e938a95a587969e8',
        jobSubCategory_id: win.getItem('jobSubCategory_id') || '679e532394c108bb56f28704',
        industryId: win.getItem('industryId') || '674b02b57a85d68f70dce160',
        qualifications: win.getItem('qualifications') || '',
        responsibilities: JSON.parse(win.getItem('responsibilities')) || [],
        skills: JSON.parse(win.getItem('skills')) || [],
        hoursPerWeek: win.getItem('hoursPerWeek') || '',
        jobPosition: win.getItem('jobPosition') || '',
        miscDetails: win.getItem('miscDetails') || '',
        termsAndConditions: win.getItem('termsAndConditions') || '',
        specialRequirements: win.getItem('specialRequirements') || '',
        noOfPosition: win.getItem('noOfPosition') || 1,
        submissionDate: win.getItem('submissionDate') || '',
        expiryDate: win.getItem('expiryDate') || '',
        benefitsDetails: win.getItem('benefitsDetails') || '',
        shift: win.getItem('shift') || '',
        workWeek: win.getItem('workWeek') || 'y',
      }
      setFormData(savedFormData);

    };
  }, [updateingId]); // Empty dependency array, runs once when the component mounts

  const handleChange = async (e) => {
    const { name, value } = e.target;


    // Handle salary input - ensure it doesn't exceed 10,000
    if (name === 'salary') {
      if (value > 100000) {
        return; // Prevent updating if the value exceeds 10,000
      }
    }

    // Update form data state
    setFormData((prevData) => ({ ...prevData, [name]: value }));



    const parsedValue = value ? parseInt(value, 10) : '';

    // Check if parsedValue is a valid number (not NaN)
    if (!isNaN(parsedValue)) {
      setFormData({
        ...formData,
        [name]: name === "minExperience" || name === "maxExperience" ? parsedValue : value,  // Handle both minExperience and maxExperience
      });
    }


    // Save the updated value to localStorage
    if (name !== 'category_id' && name !== 'skills' && name !== 'responsibilities') {
      win.setItem(name, value); // Save other values as they are
    }


    // Handle 'skills' array - store as JSON
    if (name === 'skills') {
      const skillsArray = value.split(/,\s*/).filter(skill => skill.trim() !== '');
      win.setItem('skills', JSON.stringify(skillsArray));
    }

    // Handle 'responsibilities' array - store as JSON
    else if (name === 'responsibilities') {
      const responsibilitiesArray = value.split(/,\s*/).filter(resp => resp.trim() !== '');
      win.setItem('responsibilities', JSON.stringify(responsibilitiesArray));
    }

    // Handle other fields normally
    else {
      win.setItem(name, value);
    }


    // Fetch subcategories when a category is selected
    if (name === 'category_id') {
      if (value) {
        fetchSubcategories(value); // Fetch subcategories based on selected category
      } else {
        setSubcategories([]); // Clear subcategories when no category is selected
      }
    }

    if (name === 'jobCategory_id') {
      if (value) {
        fetchJobSubcategories(value); // Fetch subcategories based on selected category
      } else {
        setjobSubcategories([]); // Clear subcategories when no category is selected
      }
    }


  };

  useEffect(() => {
    fetchCategories();
    fetchJobCategories();
    AllSubcategories();
    AllJobSubcategories();

  }, []);
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data.data);

    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchJobCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/job-category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setJobcategories(response.data.data)
      console.log("jobs", response.data)
    }
    catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const AllSubcategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sub-category`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubcategories(response.data.data);
      return response.data.data;  // Assuming `data` holds the subcategories
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      return [];  // Return an empty array if there’s an error
    }
  };

  const AllJobSubcategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/job-subcategory`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setjobSubcategories(response.data.data);
      return response.data.data;  // Assuming `data` holds the subcategories
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      return [];  // Return an empty array if there’s an error
    }
  };


  const fetchSubcategories = async (value) => {
    try {
      const response = await axios.get(`${apiUrl}/sub-category/category/${value}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubcategories(response.data.data);
      return response.data.data;  // Assuming `data` holds the subcategories
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      return [];  // Return an empty array if there’s an error
    }
  };

  const fetchJobSubcategories = async (value) => {
    try {
      const response = await axios.get(`${apiUrl}/job-subcategory/jobsubcategory/${value}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setjobSubcategories(response.data.data);
      return response.data.data;  // Assuming `data` holds the subcategories
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      return [];  // Return an empty array if there’s an error
    }
  };



  useEffect(() => {
    const cat_id = win.getItem("category_id");
    if (cat_id) {
      fetchSubcategories(cat_id)
    }
  }, [])

  useEffect(() => {
    const jobcat_id = win.getItem("jobCategory_id");
    if (jobcat_id) {
      fetchJobSubcategories(jobcat_id)
    }
  }, [])






  const handleJobSubCategoryChange = (e) => {
    const { value } = e.target;

    if (value === "Others") {
      setFormData((prevData) => ({
        ...prevData,
        jobSubCategory_id: "Others",
      }));
    } else {
      const selectedSubCategory = jobSubcategories.find((sub) => sub.jobSubCategory_id === value);
      setFormData((prevData) => ({
        ...prevData,
        jobSubCategory_id: value,
        jobSubCategoryName: selectedSubCategory ? selectedSubCategory.jobSubCategoryName : "",
      }));
    }
  };


  const handleSubcategoryChange = (e) => {
    const { value } = e.target;

    // Find the selected subcategory object
    const selectedSubcategory = subcategories.find(
      (subcategory) => subcategory.subcategory_id === value
    );

    if (selectedSubcategory) {
      // Store the subcategory name in localStorage (if needed)
      localStorage.setItem('industrySubcategory', selectedSubcategory.subcategoryName);

      // Update the formData with subcategory_id and subcategoryName
      setFormData((prevData) => ({
        ...prevData,
        subcategory_id: value, // Store the subcategory ID
        subcategory: selectedSubcategory.subcategoryName, // Store the subcategory name
      }));
    } else {
      // Clear the formData if no valid subcategory is selected
      setFormData((prevData) => ({
        ...prevData,
        subcategory_id: '',
        subcategoryName: '', // Reset subcategory name when no subcategory is selected
      }));
    }
  };






  const handleNextStep = () => {
    if (!validateStep(currentStep)) return;
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const validateStep = (step) => {
    const newErrors = {};

    if (step === 1) {
      if (!formData.jobTitle) newErrors.jobTitle = 'Job title is required';
      if (!formData.jobLocation) newErrors.jobLocation = 'Job location is required';
      if (!formData.salary || isNaN(formData.salary)) newErrors.salary = 'Salary must be a number';
      if (!formData.shift) newErrors.shift = 'Shift is required';
      if (!formData.submissionDate) newErrors.submissionDate = 'Submission date is required';
      if (!formData.expiryDate) newErrors.expiryDate = 'Expiry date is required';
      if (!formData.jobCategory_id || formData.jobCategory_id === "") {
        newErrors.jobCategory_id = 'Jobcategory is required';
      }
      if (!formData.category_id || formData.category_id === "") {
        newErrors.category_id = 'Category is required';
      }
      if (!formData.subcategory_id || formData.subcategory_id === "") {
        newErrors.subcategory_id = 'Subcategory is required';
      }

    }

    if (step === 2) {
      if (!formData.responsibilities) newErrors.responsibilities = 'Responsibilities are required';
      if (!formData.qualifications) newErrors.qualifications = 'Qualifications are required';
      if (!formData.benefitsDetails) newErrors.benefitsDetails = 'Benefit details are required';
      if (!formData.termsAndConditions) newErrors.termsAndConditions = 'Terms and Conditions are required';
      if (!formData.skills) newErrors.skills = 'Skills required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  let formattedDate = "";
  if (isValidDate(formData.submissionDate)) {
    formattedDate = new Date(formData.submissionDate).toISOString();
  } else {
    console.error("Invalid submission date:", formData.submissionDate);
    formattedDate = new Date().toISOString();
  }
  let jobstartDate = "";
  if (isValidDate(formData.submissionDate)) {
    jobstartDate = new Date(formData.submissionDate).toISOString();
  } else {
    console.error("Invalid submission date:", formData.submissionDate);
    jobstartDate = new Date().toISOString();
  }
  let jobEndDate = "";
  if (isValidDate(formData.expiryDate)) {
    jobEndDate = new Date(formData.expiryDate).toISOString();
  } else {
    console.error("Invalid submission date:", formData.expiryDate);
    jobEndDate = new Date().toISOString();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateStep(2)) return;

    const salary = parseInt(formData.salary, 10);

    // Convert Array
    const skillsArray = typeof formData.skills === 'string'
      ? formData.skills.trim().split(',').map(skill => skill.trim())
      : []; // Handle case when skills isn't a string or is empty

    // Convert Array for Responsibilities, ensuring it's a string
    const responsibilitiesAndDutiesArray = typeof formData.responsibilities === 'string'
      ? formData.responsibilities.trim().split(',').map(skill => skill.trim())
      : []; // Handle case when responsibilities isn't a string or is empty

    const selectedIndustryName = formData.categoryName;
    const userId = sessionStorage.getItem('userId');
    const payload = {
      jobTitle: formData.jobTitle || null,  // If it's optional, ensure it's null when not provided
      reportsTo: formData.companyName || null,  // Optional, handle as null if not provided
      location: formData.jobLocation || null,  // Optional, handle as null if not provided
      startDate: jobstartDate ? convertToISOString(jobstartDate) : null,  // Ensure valid DateTime
      expiryDate: jobEndDate ? convertToISOString(jobEndDate) : null,  // Ensure valid DateTime
      responsibilitiesAndDuties: Array.isArray(responsibilitiesAndDutiesArray) ? responsibilitiesAndDutiesArray : [],  // Ensure it's an array
      skills: Array.isArray(skillsArray) ? skillsArray : [],  // Ensure it's an array
      termsAndConditions: formData.termsAndConditions || null,  // Optional, handle as null if not provided
      qualificationRequirements: formData.qualifications || null,  // Optional, handle as null if not provided
      minExperience: formData.minExperience || null,  // Ensure it's an integer or null
      maxExperience: formData.maxExperience || null,  // Ensure it's an integer or null
      salary: salary || null,  // Ensure it's an integer or null
      jobCategory: formData.jobCategoryName || null,  // Optional
      hoursPerWeek: formData.hoursPerWeek || null,  // Optional
      postedById: selectedEmployerId.value || null,  // Ensure valid userId or null
      category: formData.category || null,  // Optional
      subcategory: formData.subcategory || null,  // Optional
      category_id: formData.category_id || null,
      subcategory_id: formData.subcategory_id || null,
      jobCategory_id: formData.jobCategory_id || null,
      jobSubCategory_id: formData.jobSubCategory_id || null,
      jobSubcategory: formData.jobSubCategoryName || null,  // Optional
      isDeleted: false,  // Boolean, should be fine
      createdAt: formattedDate,  // Ensure it's a valid DateTime
      updatedAt: formattedDate,  // Ensure it's a valid DateTime
      jobType: "Full Time",  // Valid string
      subscriptionStatus: "inactive",  // Valid string
      shift: formData.shift || null,
      benefitsDetails: formData.benefitsDetails || null,
      specialRequirements: formData.specialRequirements || null,
      miscDetails: formData.miscDetails || null,
      createdBy: userId || null
    };


    axios.post(`${apiUrl}/job`, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        console.log('Job posted successfully:', response.data);

        Swal.fire({
          icon: 'success',
          title: 'Job posted Successfully!',
          text: 'Your job description has been posted.',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/applicants');
        });

        setFormData({
          jobTitle: '',
          totalExperience: '',
          jobLocation: '',
          salary: '',
          companyName: '',
          jobType: '',
          category_id: '',
          subcategory_id: '',
          jobSubCategory_id: '',
          jobCategory_id: '',
          industryId: '',
          qualifications: '',
          responsibilities: [],
          skills: [],
          totalExperience: '',
          maxExperience: '',
          minExperience: '',
          salary: 0,
          hoursPerWeek: 40,
          jobPosition: '',
          miscDetails: '',
          termsConditions: '',
          noOfPosition: 1,
          submissionDate: '',
          expiryDate: '',
          benefitsDetails: '',
          shift: '',
          workWeek: 'y',
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error)
      });

  };
  const handleCategoryChange = async (e) => {
    const { value } = e.target;

    // Find the selected category object
    const selectedCategory = categories.find(
      (category) => category.category_id === value
    );

    if (selectedCategory) {
      // Store the category name in localStorage
      localStorage.setItem('industryCategory', selectedCategory.categoryName);

      // Update the formData with the categoryName and category_id
      setFormData((prevData) => ({
        ...prevData,
        category_id: value, // Store the ID if needed
        category: selectedCategory.categoryName, // Store the name
      }));
    } else {
      // Clear the formData if no valid category is selected
      setFormData((prevData) => ({
        ...prevData,
        category_id: '',
        categoryName: '', // Reset category name when no category is selected
      }));
    }

    // If category is selected, fetch subcategories
    if (value) {
      try {
        const fetchedSubcategories = await fetchSubcategories(value);
        setSubcategories(fetchedSubcategories);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setSubcategories([]); // Clear subcategories on error
      }
    } else {
      setSubcategories([]); // Clear subcategories if no category is selected
    }
  };

  console.log("formdata", formData)
  useEffect(() => {
    const storedCategoryName = localStorage.getItem('industryCategory');
    if (storedCategoryName) {
      setFormData((prevData) => ({
        ...prevData,
        categoryName: storedCategoryName,
      }));
    }
  }, []);

  const handleJobCategoryChange = async (e) => {
    const { name, value } = e.target;

    // Find the selected category by category_id (value)
    const selectedCategory = jobcategories.find(
      (category) => category.jobCategory_id === value
    );

    if (selectedCategory) {
      // Store the category name and category id in formData
      setFormData((prevData) => ({
        ...prevData,
        jobCategory_id: value, // Store category ID
        jobCategoryName: selectedCategory.jobCategoryName, // Store category name
      }));

      // Store the category name in localStorage or win
      localStorage.setItem('selectedCategoryName', selectedCategory.categoryName);
      win.setItem(name, selectedCategory.categoryName);

      console.log("win", win);
    } else {
      // If no valid category is selected, reset category data in formData
      setFormData((prevData) => ({
        ...prevData,
        jobCategory_id: '',
        jobCategoryName: '',
      }));
      localStorage.removeItem('selectedCategoryName');
      win.removeItem(name);
    }

    // If category is selected, fetch subcategories
    if (value) {
      try {
        const fetchedJobSubcategories = await fetchJobSubcategories(value);
        setjobSubcategories(fetchedJobSubcategories);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setjobSubcategories([]); // Clear subcategories on error
      }
    } else {
      setjobSubcategories([]); // Clear subcategories if no category is selected
    }
  };





  const handleSave = () => {
    // Optionally, store data in localStorage, sessionStorage, or send to a backend
    console.log("Saved Data:", formData);

    // Here, you might want to save it to localStorage, API, or some other place
    localStorage.setItem("savedJobData", JSON.stringify(formData));
  };


  useEffect(() => {
    const savedData = localStorage.getItem("savedJobData");
    if (savedData) {
      setFormData(JSON.parse(savedData));  // Set saved data to formData state
    }
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {

      const salary = parseInt(formData.salary, 10);

      // Convert Array for Skills
      const skillsArray = typeof formData.skills === 'string'
        ? formData.skills.trim().split(',').map(skill => skill.trim())
        : [];

      // Convert Array for Responsibilities
      const responsibilitiesAndDutiesArray = typeof formData.responsibilities === 'string'
        ? formData.responsibilities.trim().split(',').map(responsibility => responsibility.trim())
        : [];

      const userId = sessionStorage.getItem('userId');
      const payload = {
        jobTitle: formData.jobTitle || null,
        reportsTo: formData.companyName || null,
        location: formData.jobLocation || null,
        startDate: jobstartDate ? convertToISOString(jobstartDate) : null,
        expiryDate: jobEndDate ? convertToISOString(jobEndDate) : null,
        responsibilitiesAndDuties: Array.isArray(responsibilitiesAndDutiesArray) ? responsibilitiesAndDutiesArray : [],
        skills: Array.isArray(skillsArray) ? skillsArray : [],
        termsAndConditions: formData.termsAndConditions || null,
        qualificationRequirements: formData.qualifications || null,
        minExperience: formData.minExperience || null,
        maxExperience: formData.maxExperience || null,
        salary: salary || null,
        jobCategory: formData.jobCategoryName || null,
        hoursPerWeek: formData.hoursPerWeek || null,
        postedById: selectedEmployerId.value || null,
        category: formData.category || null,
        subcategory: formData.subcategory || null,
        jobSubcategory: formData.jobSubCategoryName || null,
        shift: formData.shift || null,
        category_id: formData.category_id || null,
        subcategory_id: formData.subcategory_id || null,
        jobCategory_id: formData.jobCategory_id || null,
        jobSubCategory_id: formData.jobSubCategory_id || null,
        benefitsDetails: formData.miscDetails || null,
        specialRequirements: formData.specialRequirements || null,
        createdBy: userId || null,
        isDeleted: false,
        createdAt: formattedDate,
        updatedAt: formattedDate,
        jobType: "Full Time",
      };

      // Make the API request to update the job
      const response = await axios.patch(`${apiUrl}/job/update/${updateingId?.job?.jobId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });

      // Assuming the response returns the updated data
      setFormData(response.data.data);
      console.log("Updated job successfully:", response.data.data);

      Swal.fire({
        icon: 'success',
        title: 'Job Updated Successfully!',
        text: 'Your job details have been updated.',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/applicants');
      });

    } catch (error) {
      console.error("Error updating job:", error);
      toast.error(error?.response?.data?.error || 'Failed to update job');
    }
  };

  function convertToISOString(dateString) {
    const date = new Date(dateString);
    return date.toISOString();
  }

  const handleAddCategory = async () => {
    if (!newJobCategory.trim()) {
      setErrors({ jobCategoryName: "Enter a valid job category" });
      return;
    }

    setIsAddingCategory(true);

    try {
      const response = await axios.post(`${apiUrl}/job-category`, {
        jobCategoryName: newJobCategory,
        jobCategoryCode: newJobCategory, // Using name as code
        jobCategoryImage: "", // Empty image
      });

      const newCategoryData = response.data; // Get new category with ID

      // Update dropdown list with new category
      setJobcategories([...jobcategories, newCategoryData]);
      fetchJobCategories()
      // Automatically select the newly added category
      setFormData({
        jobCategory_id: newCategoryData.jobCategory_id,
        jobCategoryName: newCategoryData.jobCategoryName,
      });

      // Hide input field after adding
      setNewJobCategory("");
      setErrors({});
    } catch (error) {
      console.error("Error adding job category:", error);
    } finally {
      setIsAddingCategory(false);
    }
  };


  const handleAddSubCategory = async () => {
    if (!newJobSubCategory.trim()) {
      alert("Enter a valid job subcategory");
      return;
    }

    setIsAddingSubCategory(true);

    try {
      const response = await axios.post(`${apiUrl}/job-subcategory`, {
        jobSubCategoryName: newJobSubCategory,
        jobCategory_id: formData.jobCategory_id, // Pass selected Job Category ID
        subcategoryImage: "", // Empty image
      });

      const newSubCategoryData = response.data;

      // Update dropdown with new subcategory
      setJobSubcategoriess([...jobSubcategoriess, newSubCategoryData]);

      // Automatically select the new subcategory
      setFormData({
        jobSubCategory_id: newSubCategoryData.jobSubCategory_id,
        jobSubCategoryName: newSubCategoryData.jobSubCategoryName,
      });

      setNewJobSubCategory(""); // Clear input
    } catch (error) {
      console.error("Error adding job subcategory:", error);
    } finally {
      setIsAddingSubCategory(false);
    }
  };

  return (
    <div>

      <div className="dashboard">
        < EmployerDashboardHeader />

        <Container fluid className="content">
          <Row className='g-4'>
            <Col>
              <EmployerDashboardSideBar />
            </Col>

            <Col md={10} className="main-content">
              <Row>
                <Col md={12}>
                  <h5 className='mb-3 fw-bold px-4 py-3 mt-3'>Post Job Description</h5>
                  <div className='card border-0 rounded-0'>
                    <div className='card-body p-4'>
                      <form onSubmit={updateingId ? handleUpdate : handleSubmit}>
                        {currentStep === 1 && (
                          <div>
                            <div className="row g-4">


                              <div className="col-md-4">
                                <label className="form-label">
                                  Employer Id <span className="text-danger">*</span>
                                </label>
                                <Select
                                  name="employerId"
                                  value={employerIds.find((id) => id.value === formData.employerId) || ""}
                                  onChange={handleEmployerSelect}
                                  options={employerIds}
                                  isSearchable
                                  placeholder="Search or Select an Employer"
                                  className={errors.employerId ? "is-invalid" : ""}
                                />
                                {errors.employerId && <div className="invalid-feedback">{errors.employerId}</div>}
                              </div>

                              <div className="col-md-4">
                                <label className="form-label">Job Title <span className='text-danger'>*</span></label>
                                <input
                                  type="text"
                                  name="jobTitle"
                                  value={formData.jobTitle}
                                  onChange={handleChange}
                                  className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                                  disabled={Boolean(updateingId)}
                                />
                                {errors.jobTitle && (
                                  <div className="invalid-feedback">{errors.jobTitle}</div>
                                )}
                              </div>


                              <div className="col-md-4">
                                <label className="form-label">
                                  Job Category <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="jobCategory_id"
                                  value={formData.jobCategory_id}
                                  onChange={handleJobCategoryChange}
                                  className={`form-control ${errors.jobCategory_id ? "is-invalid" : ""}`}
                                >
                                  <option value="">Select a Job Category</option>
                                  {jobcategories.length > 0 ? (
                                    jobcategories.map((category) => (
                                      <option key={category.jobCategory_id} value={category.jobCategory_id}>
                                        {category.jobCategoryName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No industries available</option>
                                  )}
                                </select>

                                {/* Show input field when 'Others' is selected */}
                                {formData.jobCategoryName === "Others" && (
                                  <div className="d-flex mt-2">
                                    <input
                                      type="text"
                                      name="jobCategoryName"
                                      value={newJobCategory}
                                      onChange={(e) => setNewJobCategory(e.target.value)}
                                      placeholder="Enter new category"
                                      className={`form-control ${errors.jobCategoryName ? "is-invalid" : ""}`}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-primary ms-2"
                                      onClick={handleAddCategory}
                                      disabled={isAddingCategory}
                                    >
                                      {isAddingCategory ? "Adding..." : "+"}
                                    </button>
                                  </div>
                                )}
                                {errors.jobCategoryName && <div className="invalid-feedback">{errors.jobCategoryName}</div>}
                              </div>





                              <div className="col-md-4">
                                <label className="form-label">Job Subcategory</label>
                                <select
                                  name="jobSubCategory_id"
                                  value={formData.jobSubCategory_id}
                                  onChange={handleJobSubCategoryChange}
                                  className={`form-control ${errors.jobSubCategory_id ? "is-invalid" : ""}`}
                                >
                                  <option value="">Select an Industry Subcategory</option>
                                  {jobSubcategories.length > 0 ? (
                                    jobSubcategories.map((subcategory) => (
                                      <option key={subcategory.jobSubCategory_id} value={subcategory.jobSubCategory_id}>
                                        {subcategory.jobSubCategoryName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No subcategories available</option>
                                  )}
                                  <option value="Others">Others</option>
                                </select>

                                {/* Show input field only when "Others" is selected */}
                                {formData.jobSubCategory_id === "Others" && (
                                  <div className="d-flex mt-2">
                                    <input
                                      type="text"
                                      name="jobSubCategoryName"
                                      value={newJobSubCategory}
                                      onChange={(e) => setNewJobSubCategory(e.target.value)}
                                      placeholder="Enter new subcategory"
                                      className="form-control"
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-primary ms-2"
                                      onClick={handleAddSubCategory}
                                      disabled={isAddingSubCategory}
                                    >
                                      {/* {isAddingSubCategory ? "Adding..." : "+"} */}
                                      +
                                    </button>
                                  </div>
                                )}

                                {errors.jobSubCategory_id && (
                                  <div className="invalid-feedback">{errors.jobSubCategory_id}</div>
                                )}
                              </div>


                              <div className="col-md-4">
                                <label className="form-label">Industry Category</label>
                                <select
                                  name="category_id"
                                  value={formData.category_id}
                                  onChange={handleCategoryChange}
                                  className={`form-control ${errors.category_id ? 'is-invalid' : ''}`}
                                >
                                  <option value="">Select a Industry</option>
                                  {Array.isArray(categories) && categories.length > 0 ? (
                                    categories.map((category) => (
                                      <option key={category.category_id} value={category.category_id}>
                                        {category.categoryName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No categories available</option>
                                  )}
                                </select>

                                {errors.category_id && (
                                  <div className="invalid-feedback">{errors.category_id}</div>
                                )}
                              </div>

                              <div className="col-md-4">
                                <label className="form-label">Industry Subcategory</label>
                                <select
                                  name="subcategory_id"
                                  value={formData.subcategory_id}
                                  onChange={handleSubcategoryChange}
                                  className={`form-control ${errors.subcategory_id ? 'is-invalid' : ''}`}
                                >
                                  <option value="">Select a Industry subcategory</option>
                                  {subcategories.length > 0 ? (
                                    subcategories.map((subcategory) => (
                                      <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                                        {subcategory.subcategoryName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No subcategories available</option>
                                  )}
                                </select>
                                {errors.subcategory_id && <div className="invalid-feedback">{errors.subcategory_id}</div>}
                              </div>


                              <div className="col-md-4">
                                <label className="form-label">
                                  Total Experience (Years) <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex">
                                  <input
                                    type="number"
                                    name="minExperience"
                                    value={formData.minExperience}
                                    onChange={handleChange}
                                    className={`form-control ${errors.minExperience ? 'is-invalid' : ''}`}
                                    placeholder="Min Experience"
                                  />
                                  <span className="mx-2">to</span>
                                  <input
                                    type="integer"
                                    name="maxExperience"
                                    value={formData.maxExperience}
                                    onChange={handleChange}
                                    className={`form-control ${errors.maxExperience ? 'is-invalid' : ''}`}
                                    placeholder="Max Experience"
                                  />
                                </div>
                                {errors.minExperience && (
                                  <div className="invalid-feedback">{errors.minExperience}</div>
                                )}
                                {errors.maxExperience && (
                                  <div className="invalid-feedback">{errors.maxExperience}</div>
                                )}
                              </div>



                              <div className="col-md-4">
                                <label className="form-label">Job Location <span className='text-danger'>*</span></label>
                                <input type="text" name="jobLocation" value={formData.jobLocation} onChange={handleChange} className={`form-control ${errors.jobLocation ? 'is-invalid' : ''}`}
                                />
                                {errors.jobLocation && (
                                  <div className="invalid-feedback">{errors.jobLocation}</div>
                                )}
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">
                                  Salary/Month (INR)<span className="text-danger">*</span>
                                </label>
                                <input type="number" name="salary" value={formData.salary} onChange={handleChange} className={`form-control ${errors.salary ? 'is-invalid' : ''}`}
                                />
                                {errors.salary && (
                                  <div className="invalid-feedback">{errors.salary}</div>
                                )}
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">Shift Type <span className='text-danger'>*</span></label>
                                <div className="custom-dropdown">
                                  <select style={{ height: '35px' }}
                                    name="shift"
                                    value={formData.shift}
                                    onChange={handleChange}
                                    className={`form-control ${errors.shift ? 'is-invalid' : ''}`}
                                  >
                                    <option value="">Select a Shift</option>
                                    <option value="General shift(9 AM - 6 PM)">General shift (9 AM - 6 PM)</option>
                                    <option value="First shift(6 AM - 3 PM)">First shift (6 AM - 3 PM)</option>
                                    <option value="Second shift(2 PM - 11 PM)">Second shift (2 PM - 11 PM)</option>
                                    <option value="Night shift(10 PM - 7 AM)">Night shift (10 PM - 7 AM)</option>
                                    <option value="Others">Others</option>
                                  </select>
                                  <i className="fa fa-chevron-down custom-dropdown-arrow"></i>
                                </div>
                                {errors.shift && (
                                  <div className="invalid-feedback">{errors.shift}</div>
                                )}
                              </div>

                              <div className="col-md-4">
                                <label className="form-label">Submission Date <span className='text-danger'>*</span></label>
                                <input
                                  type="date"
                                  name="submissionDate"
                                  value={formData.submissionDate}
                                  onChange={handleChange}
                                  className={`form-control ${errors.submissionDate ? 'is-invalid' : ''}`}
                                  min={new Date().toISOString().split('T')[0]} // Today's date as the minimum date
                                />
                                {errors.submissionDate && (
                                  <div className="invalid-feedback">{errors.submissionDate}</div>
                                )}
                              </div>


                              <div className="col-md-4">
                                <label className="form-label">Expiry Date <span className='text-danger'>*</span></label>
                                <input
                                  type="date"
                                  name="expiryDate"
                                  value={formData.expiryDate}
                                  onChange={handleChange}
                                  className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                                  min={formData.submissionDate ? new Date(formData.submissionDate).toISOString().split('T')[0] : ''}
                                />
                                {errors.expiryDate && (
                                  <div className="invalid-feedback">{errors.expiryDate}</div>
                                )}
                              </div>
                              <div className="col-md-12 text-end">
                                <button type="button" className="default-btn border-btn prev" onClick={handleSave}>Save</button>
                                <button type="button" onClick={handleNextStep} className="default-btn ms-2 next">Next</button>
                              </div>
                            </div>
                          </div>
                        )}

                        {currentStep === 2 && (
                          <div>
                            <div className="row g-4">

                              <div className="col-md-6">
                                <label className="form-label">Skills<span className='text-danger'>*</span> <label style={{ fontSize: '12px', color: 'gray' }}> (e.g: Welding,Plumbing,Electrical Repair)</label></label>
                                <textarea name="skills" value={formData.skills} onChange={handleChange} className={`form-control ${errors.skills ? 'is-invalid' : ''}`} />
                                {errors.Skills && (
                                  <div className="invalid-feedback">{errors.Skills}</div>
                                )}
                              </div>




                              <div className="col-md-6">
                                <label className="form-label">Responsibilities<span className='text-danger'>*</span><label style={{ fontSize: '12px', color: 'gray' }}>(e.g: Welding metal structures,Repairing electrical systems)</label></label>
                                <textarea name="responsibilities" value={formData.responsibilities} onChange={handleChange} className={`form-control ${errors.responsibilities ? 'is-invalid' : ''}`} />
                                {errors.responsibilities && (
                                  <div className="invalid-feedback">{errors.responsibilities}</div>
                                )}
                              </div>
                              <div className="col-md-6">
                                <label className="form-label">Qualifications<span className='text-danger'>*</span></label>
                                <textarea name="qualifications" value={formData.qualifications} onChange={handleChange} className={`form-control ${errors.qualifications ? 'is-invalid' : ''}`} />
                                {errors.qualifications && (
                                  <div className="invalid-feedback">{errors.qualifications}</div>
                                )}
                              </div>

                              <div className="col-md-6">
                                <label className="form-label">Benefit Details<span className='text-danger'>*</span></label>
                                <textarea name="benefitsDetails" value={formData.benefitsDetails} onChange={handleChange} className={`form-control ${errors.benefitsDetails ? 'is-invalid' : ''}`} />
                                {errors.benefitsDetails && (
                                  <div className="invalid-feedback">{errors.benefitsDetails}</div>
                                )}
                              </div>


                              <div className="col-md-6">
                                <label className="form-label">Terms Conditions Details<span className='text-danger'>*</span></label>
                                <textarea name="termsAndConditions" value={formData.termsAndConditions} onChange={handleChange} className={`form-control ${errors.termsAndConditions ? 'is-invalid' : ''}`} />
                                {errors.termsAndConditions && (
                                  <div className="invalid-feedback">{errors.termsAndConditions}</div>
                                )}
                              </div>

                              <div className="col-md-6">
                                <label className="form-label">Misc Details</label>
                                <textarea name="miscDetails" value={formData.miscDetails} onChange={handleChange} className={`form-control ${errors.miscDetails ? 'is-invalid' : ''}`} />
                                {errors.miscDetails && (
                                  <div className="invalid-feedback">{errors.miscDetails}</div>
                                )}
                              </div>

                              <div className="col-md-6">
                                <label className="form-label">Special Requirements</label>
                                <textarea name="specialRequirements" value={formData.specialRequirements} onChange={handleChange} className={`form-control ${errors.specialRequirements ? 'is-invalid' : ''}`} />
                                {errors.specialRequirements && (
                                  <div className="invalid-feedback">{errors.specialRequirements}</div>
                                )}
                              </div>

                              <div className="col-md-12 text-end">
                                <button type="button" onClick={handlePreviousStep} className="default-btn border-btn prev">Previous</button>
                                <button
                                  type="submit"
                                  className="default-btn ms-2 submit"
                                >
                                  {updateingId ? 'Update' : 'Submit'}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>

                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {subscriptionStatus === 'active' ? (
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" style={{ borderRadius: "10px" }}>
            <DialogContent style={{ backgroundColor: "#f5f6fa" }}>
              <IconButton onClick={handleClose} size="small"
                style={{
                  position: "absolute",
                  width: "40px",
                  top: "10px",
                  right: "20px",
                  zIndex: 10,
                }}>
                <CloseIcon />
              </IconButton>
              <SubscriptionPacks />
            </DialogContent>
          </Dialog>
        ) : ''}
      </div>
    </div>
  );
};

export default CallcenterJobDescriptionForm;
