import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Container, Col, Row, Table, Form, Spinner, InputGroup, Button } from 'react-bootstrap';
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from '../components/EmployerDashboardHeader';
import { useLocation } from "react-router-dom";
import { toast } from 'react-hot-toast';
import moment from 'moment';
import ReactPaginate from 'react-paginate';  // Importing react-paginate
import Bottom from "./Home/bottom";
import '../Styles/jobseekerjoblist.css'
import '../Styles/Homepage.css'


const ListPage = () => {
  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    jobTitle: "",
    jobCategory: "",

    totalExperience: "",
    salary: "",
    location: ""
  });
  const [allChecked, setAllChecked] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState({});

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0); // react-paginate starts at 0
  const [jobsPerPage, setJobsPerPage] = useState(10); // How many jobs per page
  const location = useLocation();
  const { selectedIds } = location.state || {};
  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const userRole = sessionStorage.getItem("role");
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [jobSeekersData, setJobSeekersData] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/job`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setJobData(response.data.data);
        setFilteredJobData(response.data.data);
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };

    setSelectedUserData(selectedIds);
    fetchJobs();
  }, [token, apiUrl, selectedIds]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };
      filterData(updatedFilters);
      return updatedFilters;
    });
  };

  const filterData = (filters) => {
    let filteredData = jobData;

    if (filters.jobTitle) {
      filteredData = filteredData.filter((job) =>
        job.jobTitle?.toLowerCase().includes(filters.jobTitle.toLowerCase())
      );
    }

    if (filters.jobCategory) {
      filteredData = filteredData.filter((job) =>
        job.jobCategory?.toLowerCase().includes(filters.jobCategory.toLowerCase())
      );
    }

    if (filters.totalExperience) {
      filteredData = filteredData.filter((job) =>
        job.totalExperience >= filters.totalExperience
      );
    }

    if (filters.salary) {
      filteredData = filteredData.filter((job) =>
        job.salary >= filters.salary
      );
    }

    if (filters.location) {
      filteredData = filteredData.filter((job) =>
        job.location?.toLowerCase().includes(filters.location.toLowerCase())
      );
    }

    setFilteredJobData(filteredData);
  };

  // Handle page change using react-paginate
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const fetchProfile = async (job) => {
    try {
      const responses = await Promise.all(
        selectedUserData?.map((id) =>
          axios.get(`${apiUrl}/user/${id}`, {
            headers: { Authorization: `${token}` },
          })
        )
      );

      const jobSeekerApplications = responses.map((response) => {
        const data = response?.data?.data;
        console.log(`data-${data}`);

        return {
          jobId: job.jobId || "default-job-id",
          employerId: job.postedById || "default-employer-id",
          jobSeekerId: data.userId || data.id,
          experience: data.totalExperience || "N/A",
          coverletter: data.coverletter || "",
          skills: data.skills || [],
          responsibility: data.responsibilities || [],
          resume: data.resume || "",
          currentCTC: data.salary || "",
          expectCTC: data.expectedSalary || "",
          status: "APPLIED",
          email: data.email || "",
        };
      });

      setJobSeekersData(jobSeekerApplications);
      return jobSeekerApplications;
    } catch (error) {
      toast.error(error?.response?.data ?? "Error fetching job seeker applications")
      console.error("Error fetching job seeker applications:", error.response?.data || error.message);
      throw error;
    }
  };




  const ApplyJobForSelected = async (job) => {
    // Show SweetAlert confirmation popup
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `Do you want to apply for the job: "${job.jobTitle}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Apply",
      cancelButtonText: "Cancel",
    });

    if (!result.isConfirmed) {
      return; // If the user cancels, do nothing
    }

    try {
      await fetchProfile(job);
      const jobSeekerApplications = await fetchProfile(job);

      if (!selectedUserData?.length) {
        sessionStorage.setItem("JobId", job.jobId);
        sessionStorage.setItem("Postedby", job.postedById);
        navigate("/applyJobAllJobSeeker");
      } else {
        const dataToPost = {
          jobId: job.jobId,
          employerId: job.postedById,
          callCenterAgentId: userId,
          jobSeekerApplications: jobSeekerApplications,
        };

        const response = await axios.post(`${apiUrl}/applications/applymultiple`, dataToPost, {
          headers: { Authorization: `${token}` },
        });

        if (response.data.status) {
          toast.success(response?.data?.message ?? "Applied job successfully.");
        } else {
          toast.error(response?.data?.message ?? "Failed to apply for jobs.");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Error applying for jobs.");
    }
  };

  // Determine the range of jobs to display on the current page
  const indexOfLastJob = (currentPage + 1) * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobData.slice(indexOfFirstJob, indexOfLastJob);

  // Handle individual job selection
  const handleCheckboxChange = (jobId) => {
    setSelectedJobs((prevSelectedJobs) => {
      const newSelectedJobs = { ...prevSelectedJobs, [jobId]: !prevSelectedJobs[jobId] };

      // Check if all jobs are selected
      setAllChecked(Object.keys(newSelectedJobs).length === filteredJobData.length &&
        Object.values(newSelectedJobs).every(val => val));

      return newSelectedJobs;
    });
  };


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />

      <Container fluid className="content" >
        <Row className="g-4">
          <Col className="side">
            {token ? <EmployerDashboardSideBar /> : <div></div>}
          </Col>

          <Col md={token ? 10 : 12} className="">
            <Row>
              <Col md={12} style={{ background: '#F5F6FA', padding: '0px' }}>
                <h5 className="mb-3 fw-bold mt-5 px-4 ">Job Listing</h5>

                <div className="card border-0 rounded-0 mb-3 main-box" style={{ background: '#F5F6FA' }}>


                  <div className="card-body alljobtable ">
                    {/* Filter Toggle Button */}


                    {/* Conditionally Render Filter Rows */}
                    <div >
                      <div >

                        <h6 className="fw-bold " style={{ marginBottom: '30px' }}></h6>
                        <Row className="g-2 " style={{ marginBottom: '20px', padding: '10px', background: 'white' }}>
                          {/* <h4 className="text-center">Filter</h4> */}
                          <Col md={3} className="jobfilter">
                            <InputGroup className="filter-header-input">
                              <InputGroup.Text className="filter-header-highlighter">Job Title</InputGroup.Text>
                              <Form.Control
                                className="filter-header-search"
                                type="text"
                                name="jobTitle"
                                value={filters.jobTitle}
                                onChange={handleFilterChange}
                                placeholder="Search by title"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={3}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">{userRole === 'jobseeker' ? 'Job Company' : 'Job Industry'}</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="jobCategory"
                                value={filters.jobCategory}
                                onChange={handleFilterChange}
                                // placeholder="Search by industry"
                                placeholder={userRole === "jobseeker" ? "Search by Company " : "Search by Industry"}
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Experience</InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="totalExperience"
                                value={filters.totalExperience}
                                onChange={handleFilterChange}
                                placeholder="Min experience"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Salary</InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="salary"
                                value={filters.salary}
                                onChange={handleFilterChange}
                                placeholder="Min Salary"
                              />
                            </InputGroup>
                          </Col>
                          <Col md={2}>
                            <InputGroup>
                              <InputGroup.Text className="filter-header-highlighter">Location</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="location"
                                value={filters.location}
                                onChange={handleFilterChange}
                                placeholder="Location"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                      </div>

                      <div className="container-fluid">
                        <div className="card border-0 rounded-0">
                          <div className="card-body table-responsive alljobtable"> {/* Enables horizontal scrolling */}
                            <Table hover className="table">
                              <thead className="thead-light">
                                <tr className="tablehead">
                                  <th style={{ width: "200px" }}>Job Title</th>
                                  <th style={{ width: "200px" }}>Location</th>
                                  <th>{userRole === "jobseeker" ? "Company Name" : "Industry"}</th>
                                  <th>Experience</th>
                                  <th>₹ Salary/Month</th>
                                  <th>Created Date</th>
                                  <th>Applicants</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentJobs.length > 0 ? (
                                  currentJobs.map((job) => (
                                    <tr key={job.id} className="tbodys">
                                      <td style={{ width: "200px" }}>{job.jobTitle}</td>
                                      <td style={{ width: "200px" }}>{job.location}</td>
                                      <td>{job?.postedBy?.companyName}</td>
                                      <td>{job.minExperience}-{job.maxExperience} years</td>
                                      <td>{job.salary ? `₹ ${job.salary}` : "-"}</td>
                                      <td>{moment(job.createdAt).format("YYYY-MM-DD")}</td>
                                      <td style={{ textAlign: 'center' }}>
                                        <Link to={`/viewdetails/${job.jobId}`} className="btn btn-info btn-sm me-2">
                                          View
                                        </Link>
                                        {(userRole === "admin" || userRole === "callcenter") && (
                                          <Link className="btn btn-success btn-sm" onClick={() => ApplyJobForSelected(job)}>
                                            Apply
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="8" className="text-center">No jobs found</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>

                            {/* Pagination */}
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              pageCount={Math.ceil(filteredJobData.length / jobsPerPage)}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination justify-content-center"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Col className="bottomNavigate">
        <Bottom />
      </Col>
    </div>
  );
};

export default ListPage;
