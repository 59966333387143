import React, { useState, useEffect } from "react";

import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import EmployerDashboardHeader from '../../components/EmployerDashboardHeader';

import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import blogimg1 from "../../assets/images.jpeg"
import blogimg2 from "../../assets/imaes2.jpeg"
import blogimg3 from "../../assets/imaes3.png"
import blogimg4 from "../../assets/improvement-summary-personal-development-workflow.jpg"

import '../../Styles/videoPage.css'
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BlogPage = () => {
  

  const [show, setShow] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const colWidth = isLoggedIn ? 10 : 12;
  const Navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const role = sessionStorage.getItem('role');
    if (token) {
      setIsLoggedIn(true);
      // setSidebar(true)
    }

  }, []);
  // const blogs = [
  //   {
  //     title: "Workaroo.ai | Revolutionizing Blue-Collar Recruitment with AI",
  //     description1: "Workaroo.ai is transforming the blue-collar job market by addressing inefficiencies in hiring, skill development, and language barriers through innovative AI-powered solutions.Our platform bridges the gap between employers and workers with features like precise AI-driven job matching, multilingual interfaces, and real-time skill enhancement",
  //     description2: "Employers benefit from reduced recruitment time and costs, while workers gain access to better opportunities and holistic support, including training, financial aid, and healthcare options. By leveraging AI for data-driven decisions, Workaroo ensures long-term sustainability and growth for both employers and workers.",
  //     description3: "The blue-collar sector is riddled with inefficiencies: Employers struggle to find skilled workers, positions remain unfilled, leading to operational disruptions and productivity losses.Workers face difficulties finding suitable jobs: Despite having the necessary skills, workers often remain unemployed or underemployed due to mismatched opportunities.Attrition and turnover rates are alarmingly high in this sector, which increases recruitment costs for employers. Skill gaps are prevalent, creating barriers to finding and retaining the right talent. Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot.",
  //     description4: "Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot. Our AI engine automates the process of matching workers to jobs based on a deep analysis of skills, experience, and job requirements.",
  //     description5: "By leveraging machine learning algorithms, we go beyond keyword-based searches, analyzing skill sets, experience level, and geographical preferences. This reduces time-to-hire for employers and increases job satisfaction for workers. Workaroo’s AI assesses candidates using AI-powered video interviews and audio profiles to offer richer insights. Multilingual AI interfaces allow workers to interact with the platform in their native languages. AI also recommends upskilling programs tailored to industry needs. By providing real-time recommendations, holistic worker support, and intelligent recruitment workflows, Workaroo ensures a happier, more stable workforce. Employers reduce costs and workers gain better opportunities, revolutionizing the blue-collar job market.",
  //     description6:"2. Creative Use of AI for Interviews and Candidate Insights: Workaroo’s AI doesn’t just match candidates—it assesses them. We use AI powered video interviews and audio profiles to offer a richer understanding of each worker. This is especially important in the blue-collar space, where traditional resumes often do not fully capture a worker’s potential. By analyzing: Voice and language proficiency, Facial expressions and body language during interviews, Technical knowledge through targeted interview questions, our AI provides employers with deeper insights into a worker's capabilities beyond what a typical resume would convey.",
  //     description7:" 3. Breaking Language Barriers with Multilingual AI: Language is a major barrier in the blue-collar hiring process. Many workers are not comfortable with English centric job portals. Workaroo’s multilingual AI interface addresses this problem by allowing workers to interact with the platform in their native languages. This includes innovative ideas like: Audio and video resumes in multiple languages,Audio and video JDs(Job Descriptions) in multiple languages Multilingual job descriptions and communication, making the process more inclusive and effective. This feature significantly increases the pool of available talent for employers, giving them access to workers who were previously underserved by traditional platforms. ",
  //     description8:"4. AI Powered Skill Development and Upskilling: Workaroo goes beyond placement; we empower workers with personalized skill development. AI analyzes market trends and worker profiles to recommend specific upskilling programs tailored to both current job demands and future industry needs. This creates a sustainable talent pipeline, enabling workers to stay competitive and employers to benefit from a continually improving workforce. Workers are groomed before deployment to make sure they understand the company and environment, work procedures and expectations. This helps cementing their relationship with companies and increases the stickiness.  Workers receive real time recommendations for certifications, vocational training, and soft skill enhancement. ",
  //     description9:" 5. AI for Data Driven Decision Making: The intelligence layer of Workaroo’s platform uses AI to continuously analyze data from job placements, industry trends, and user interactions. This allows both employers and the platform itself to: Predict hiring trends and proactively address skill gaps. Optimize job postings based on historical success metrics. Improve employer decision making through real time insights into the workforce, reducing attrition by placing the right candidates in the right roles. This data driven approach helps employers stay ahead of market changes and ensures that workers are always aligned with the most relevant job opportunities. ",
  //     description10:"Creative AI Solutions That Set Workaroo Apart: ",
  //     description11:"1. Holistic Worker Support: Unlike traditional platforms, Workaroo doesn’t just stop at recruitment. Through AI powered solutions, we provide access to broader services such as training/skill upgradation enablement, accommodation assistance, financial aid, and healthcare options, addressing the broader needs of the blue-collar workforce. This ensures a happier, more stable workforce for employers. ",
  //     description12:"2. Focus on Skills Over Resumes: Our platform prioritizes skills assessment, ensuring that workers without formal education or detailed resumes are evaluated based on their hands on expertise. This levels the playing field for blue-collar workers. ",
  //     description13:"3. Intelligent Automation of Recruitment Workflow: From job description creation to resume filtering, Workaroo uses AI to automate repetitive tasks, freeing up HR teams to focus on strategic decisions. This drastically reduces recruitment costs for employers. ",
  //     description14:"Measurable Impact and Results: ",
  //     description15:"Workaroo’s AIpowered platform has already made significant strides: Placement success rate: Over 70% of job vacancies posted on the platform are filled with qualified candidates within two weeks. Reduction in recruitment time: Employers using Workaroo have reduced their recruitment time by over 40%, thanks to automated processes and precise matching. Upskilling initiatives: Workers on the platform receive continuous skill development recommendations, contributing to long term employability and lower turnover. ",
  //     description16:"Our Mission: ",
  //     description17:"Workaroo is not just another recruitment platform—it’s a comprehensive ecosystem built on the backbone of AI. We are empowering workers, solving industry challenges, and shaping the future of blue-collar recruitment. By investing in technology that fosters inclusivity, efficiency, and growth, we are revolutionizing the blue-collar job market, one placement at a time. #AIRecruitment #BlueCollarJobs #AIInnovation #WorkforceSolutions #TalentMatching #SkillDevelopment ",
  //     link: "/blogs/resume-tips",
  //     image: blogimg1
  //   },
  //   {
  //     title: "Bridging the Digital Divide: Making Tech-Driven Job Platforms More Accessible to India’s Blue-Collar Workforce ",
  //     description1: "In the small town of Nagpur, Ramesh, an experienced welder, wakes up every day hoping for work. His skills are sharp, but the local job market is unpredictable, and he relies heavily on contractors who offer inconsistent pay and limited opportunities.",
  //     description2: "If you look at the other side of the spectrum, across the country, companies in cities like Pune or Chennai are struggling to find skilled workers like him, despite the rapid rise of tech-driven job platforms.",
  //     description3: "Why the disconnect? For millions of blue-collar workers like Ramesh, these platforms are difficult to access due to barriers such as language, digital literacy, and access to technology.",
  //     description4: "This situation is not unique to Ramesh. India, with its massive 400m+ blue-collar workforce, is grappling with how to bring these workers into the fold of the digital economy.",
  //     description5: "While job platforms have revolutionized hiring for the white-collar workforce, particularly for IT services, they must adapt to meet the needs of workers on the ground. Let’s explore the challenges and the solutions that can help bridge this gap.",
  //     description6:"In a country as linguistically diverse as India, one of the first obstacles workers encounter on digital job platforms is language. The majority of these platforms operate in English which immediately alienates workers from states like UP, MP, Tamil Nadu, West Bengal, or Maharashtra, where local languages dominate. A daily wage worker from Kanpur who speaks only Hindi, may struggle to understand job descriptions or fill out application forms on an English-language platform.",
  //     description7:"2. Digital Literacy While smartphone penetration is growing across India, many workers are still not familiar with using technology beyond making phone calls or sending WhatsApp messages. Take Rekha, a domestic worker in Bengaluru. She has a smartphone but uses it mainly to communicate with family. When her friend suggested she look for jobs online, Rekha was unsure how to navigate the job portals or upload her information. Many blue-collar workers like Rekha lack the digital literacy needed to fully participate in the online job market. They are overwhelmed by the complexity of job applications, login processes, and resume creation, leading to frustration and disengagement. ",
  //     description8:"3. Access to Technology Though India is fast becoming a digital-first nation, access to technology remains a challenge for many in rural and semi-urban areas. Even those who own smartphones might struggle with unstable internet connections or expensive data plans. For example, in villages across Uttar Pradesh, workers may have to walk miles just to find reliable internet to apply for jobs online. While technology has improved a lot over last 2 decades still there are islands of poor connectivity. We had stories during COVID that students in some villages have to sit on the house roof to get the access to internet as connectivity was poor. The lack of affordable and accessible technology becomes a significant hurdle for workers to find better job opportunities. ",
  //     description9:"Solutions to Bridge the Gap ",
  //     description10:"1. Multilingual Platforms One of the most effective ways to make job platforms accessible is by providing interfaces in regional languages. In India, where over 22 official languages are spoken, job portals must be tailored to include languages such as Tamil, Telugu, Marathi, Bengali, and Kannada. Offering multilingual support can significantly ease the burden on workers. Consider the success of some e-commerce platforms that have integrated regional language options. When workers from rural Karnataka can search for jobs in Kannada or a laborer in Bengal can navigate a portal in Bengali, they are more likely to use these platforms confidently. ",
  //     description11:"2. Simple, User-Friendly Design   Platforms must be designed with the user in mind—especially those who are not tech-savvy. Instead of long forms or complex resume builders, platforms could offer features like audio and video profiles. A plumber from Bihar, for instance, could simply record a short video explaining his work experience rather than struggling to type out a resume. This not only makes the platform easier to use but allows employers to assess a worker’s skills more directly. In today’s world of AI, the video or audio profiles can be translated to text resumes.   Simplified navigation, easy-to-understand icons, and the use of everyday language rather than corporate jargon can also make these platforms more accessible. A construction worker should be able to apply for jobs without needing extensive technical knowledge. ",
  //     description12:"3. Mobile-First Approach In India, the mobile phone is the primary device for accessing the internet, especially in rural areas. Job platforms should be fully optimized for mobile use, ensuring they work efficiently even on low-cost smartphones. They should load quickly, use minimal data, and be accessible in areas with weak internet connections. Additionally, SMS-based job alerts can help bridge the technology gap. Workers can receive job notifications via SMS and respond with a simple message to apply. For Ramesh, the welder from Nagpur, receiving a text message about a job in Hyderabad that matches his skills could be the nudge he needs to access better-paying work. ",
  //     description13:"4. Digital Literacy and Training Programs While simplifying platforms is essential, so too is improving digital literacy. Workers need to be equipped with the basic skills to navigate online platforms confidently. Local governments, NGOs, and private companies can collaborate to set up digital literacy programs in communities. These programs could teach workers how to search for jobs online, create profiles, and apply for positions. There are already promising examples of this approach. In Kerala, the government has rolled out digital literacy programs that have helped thousands of citizens, including blue-collar workers, use technology to improve their employment prospects. Short 2/3 minute “how to do” kind of videos can go great extent to educate the users. There can be online distance learning programs in local languages which will be very productive.  ",
  //     description14:"5. Hybrid Models: Combining Offline and Online Efforts Not all workers are ready for a fully digital experience. This is where offline support can make a difference. By setting up local support centers or kiosks, workers can receive assistance in registering for platforms, building profiles, and applying for jobs. These centers(or Agents) could be run by local agencies or even mobile shops, painter or hardware shops providing a crucial bridge between workers and digital job portals. These agents can play a role in attracting, enabling, educating, grooming the workers.  A real-world example of this model in action comes from Andhra Pradesh, where local employment agencies assist workers in signing up for job portals. Workers who are unfamiliar with online systems are guided through the process and receive updates on job opportunities via SMS. ",
  //     description15:"A Win-Win for Workers and Employers ",
  //     description16:" The benefits of addressing these challenges are enormous—not just for workers, but for employers too. Indian companies often struggle to find skilled blue-collar workers. By making digital platforms more inclusive, they can tap into a vast, underserved talent pool. For example, a manufacturing company in Pune might find it easier to recruit electricians and welders from nearby villages through a platform that supports local languages and mobile-friendly applications. The recruitment process also becomes more streamlined. Features such as video resumes allow employers to assess candidates more thoroughly, reducing mismatches in hiring. The process becomes faster, more efficient, and ultimately more reliable for both workers and businesses. ",
  //     description17:" Conclusion ",
  //     description18:"As India races towards becoming a digital economy, we cannot afford to leave millions of blue-collar workers behind. By addressing language barriers, improving digital literacy, and ensuring broader access to technology, we can bridge the digital divide and open doors for workers like Ramesh and Rekha. This transition will not only improve the livelihoods of blue-collar workers across the country but also help businesses find the skilled workforce they need. In a country where opportunity and talent abound, it’s time to ensure that technology works for everyone. #DigitalDivide #InclusiveDigital #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #Multilingual  4th Aug 2024  Ravi Annadurai https://www.linkedin.com/in/arc1507/ ",
  //     link: "/blogs/interview-tips",
  //     image: blogimg2,
  //   },
  //   {
  //     title: "Upskilling the Blue-Collar Workforce: Industry’s Urgent and Critical need for Skill Development ",
  //     description1: "In the heart of India’s industrial belt, stories like that of Mohit, a seasoned welder from Ludhiana, are becoming increasingly common. Despite decades of experience, Mohit now finds himself at a crossroads. The machines in his factory have become more advanced, and the younger, tech-savvy workers are increasingly taking over tasks that once defined his career.",
  //     description2: "Mohit knows he needs new skills, but where can he learn them? And how will he afford it?",
  //     description3: "This scenario highlights the reality faced by millions of India’s blue-collar workers.",
  //     description4: "As technological advancements transform industries, the skills gap among these workers presents a critical challenge, threatening livelihoods, business profitability, and the overall health of the nation's economy.",
  //     description5: "The struggle to stay relevant in the changing job market is not just a personal issue for Mohit but a larger problem affecting millions of skilled workers across the country.",
  //     description6:"The Blue-Collar Skills Crisis in India ",
  //     description7:"India is home to one of the largest blue-collar workforces in the world. Estimates suggest that blue-collar workers account for nearly 90% of the country’s workforce. These workers are the backbone of industries such as construction, automotive manufacturing, logistics, and retail. Yet, according to reports, over 60% of India’s blue-collar workers lack formal training, with many relying solely on practical, hands-on experience. ",
  //     description8:" 1. Skill Gaps and Obsolete Knowledge Blue-collar workers often acquire their skills through informal channels, such as on-the-job learning or apprenticeships. While this form of learning has sufficed for years, it no longer matches the demands of today’s digital and automated industrial landscape. Take the manufacturing sector, for example. The transition to automated processes, including robotics and machine learning systems, has created a high demand for workers who understand and can operate advanced technologies. In contrast, a majority of the existing workforce is still equipped with outdated skills that were relevant decades ago. A welder might have mastered manual welding techniques but has no experience operating CNC (Computer Numerical Control) machines. Similarly, factory workers trained to operate mechanical tools are struggling to adapt to digitized production lines where everything is automated and controlled by software. ",
  //     description9:"2. Impact on Livelihoods of Workers The most immediate impact of this skills gap is on the workers themselves. The lack of relevant skills reduces their employability, making it difficult for them to secure stable, high-paying jobs. As industries automate and modernize, those without the necessary skills are either forced into low-paying, menial jobs or, worse, pushed out of the workforce entirely. Workers like Mohit, despite their years of experience, risk becoming irrelevant if they cannot upskill and adapt. The economic consequences of this are severe. For millions of workers, this skill gap translates to lower wages, job insecurity, and reduced opportunities for career growth. A factory worker who once earned a respectable wage may find his earnings dropping as companies increasingly value new skills over old experience. The cumulative effect is devastating for households, leading to a cycle of poverty and reduced social mobility. ",
  //     description10:"3. Adverse Effects on Businesses and Industry Health For businesses, the skill gap creates major operational challenges. Indian industries are heavily dependent on blue-collar labor, and the inability to find workers with the necessary skills leads to reduced productivity, higher operational costs, and longer production cycles. For example, a manufacturing company that has invested in automated machinery may find its operations stalled because it cannot find workers who can operate the new equipment efficiently. Furthermore, the lack of skilled workers reduces the competitiveness of entire industries. When companies are unable to keep up with technological advancements due to skill shortages, they lose out on contracts, miss deadlines, and face increased downtime.  Recently during my conversation with one Industrialist in Bangalore in the manufacturing space , he said that “the business can grow 70-80% year given the business inflow”. However he is able to grow the business only 25% due to lack of quality  manpower. That is an alarming extent of skills shortage problem. This in turn, affects profitability and limits the potential for growth. India’s automotive and manufacturing sectors, which are integral to its economy, are particularly vulnerable to these challenges. ",
  //     description11:" The Way Forward: Solutions to the Skills Problem ",
  //     description12:"To address the blue-collar skills crisis, a multi-pronged approach is necessary—one that involves not only workers but also businesses, educational institutions, NGOs and the government. Indian Govt has launched several initiatives and agencies to address the skill development namely National Skill development corporation, Skill India Digital and Pradhan Mantri Kaushal Vikas Yojana(launched in 2015) to encourage and promote skill development in the country. Towards finding innovative and deeper solutions to skill development, we have explored the following ideas. These solutions highlight how technology, innovative learning methods, and collaboration between stakeholders can help bridge the skills gap. ",
  //     description13:"1. Online Learning Platforms and E-Learning Tools In a country as vast as India, traditional vocational training programs are often limited by geography, accessibility, and cost. Online learning platforms are changing this. By offering courses that workers can access from their smartphones, these platforms are removing barriers to learning. Platforms such as Skill India, Coursera, and Udemy are providing affordable and relevant courses in fields like machine operation, plumbing, and automotive repair, making skill development more accessible to blue-collar workers across India. For example, a logistics worker in Uttar Pradesh who wants to learn about supply chain management can now do so through his phone, at his own pace. E-learning allows workers to upskill without having to leave their jobs, giving them the flexibility to learn new skills while still earning an income. ",
  //     description14:"2. Flexible Learning and Continuous Development A rigid curriculum no longer works in a rapidly changing world. Learning must be continuous and flexible, allowing workers to acquire new skills as industries evolve. For example, a CNC machine operator who has already completed basic training may need to return for refresher courses or learn additional skills as technology advances.  Flexible curriculums, where workers HOP in and HOP out of courses/learning modules based on their needs, will ensure that the workforce remains adaptable and ready for future changes. These learning pathways could be supported by micro-credentials and certificates, which workers can collect as they progress through different stages of learning. ",
  //     description15:"3. Industry’s Aggressive Role in Upskilling Industries cannot wait for workers to catch up with their technological advancements. Businesses must take an aggressive role in upskilling their employees. Many Indian companies are already partnering with training platforms to offer in-house training programs that prepare workers for the specific technologies and systems used in their operations. For example, large automotive manufacturers are providing training on how to operate and maintain robotic equipment, while logistics companies are teaching their workers how to use warehouse management software. This model not only fills immediate skill gaps but also invests in the long-term development of the workforce, creating a more capable and adaptable pool of workers. Training programs by Industries can help them to fulfil their requirements as well as improve their branding in terms of workers trained/certified by Company’s Academy.  Infact companies which hire blue collar workforce can take a cue from IT industry which spent lot of time and effort in training the white collar resources which ultimately created huge base of IT resources. Today it is helping newer Tech industries like GCC(Global Capability centers) and new age startups.  ",
  //     description16:"4. AI-Driven Skill Assessments One of the most exciting developments in the upskilling of blue-collar workers is the use of AI-driven skill assessments. AI can analyze a worker’s current skill set, identify gaps, and recommend personalized learning paths based on the individual’s experience and aspirations. For instance, a technician with basic electrical skills can take an AI-driven test that evaluates his readiness to handle more advanced electrical systems and suggests specific courses to close those gaps. For businesses, AI tools can also be used to assess their workforce’s strengths and weaknesses, enabling employers to provide targeted training programs. This ensures that workers are trained efficiently, saving companies time and resources while upskilling their workforce in areas critical to operations. ",
  //     description17:"5. Innovative Skill Development funding: Skill Development Vouchers  Skill development vouchers are an innovative approach to financing vocational training and skill development programs, aimed at increasing access to quality education and empowering individuals to choose the training programs best suited to their needs. These vouchers can be seen as a market-driven mechanism to enhance skills, often funded by the government or other agencies. Vouchers as Financial Aid: Individuals receive vouchers that cover part or all of the cost of vocational training or skill development courses. These vouchers are typically redeemable at approved training institutions. Choice and Flexibility: Vouchers give individuals the flexibility to choose from a variety of training providers, encouraging competition among institutions to offer high-quality training programs. Targeted Groups: These vouchers are often aimed at economically disadvantaged individuals or those in need of specific skill development, such as blue-collar workers, youth, or workers in the informal sector.",
  //     description18:"Conclusion: A Collective Responsibility ",
  //     description19:"The skill crisis in India’s blue-collar workforce is a multi-faceted problem that affects the livelihoods of millions of workers, threatens the profitability of businesses, and impacts the health of industries. However, the solution is not beyond reach. By leveraging technology, embracing AI-driven initiatives, reforming vocational training, and encouraging industries to play an active role in skill development, India can build a workforce that is future-ready. For workers like Mohit and the millions of others who rely on their skills for a living, upskilling is not just an option—it’s a necessity. With the right tools and support, India’s blue-collar workforce can adapt to the changing demands of the 21st century and continue to power the country’s economic growth. #IndustryDrivenTrainings #LifeLongSkillDevelopment #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #VocationalTrainings 1st Sep 2024  Ravi Annadurai ",
  //     link: "/blogs/remote-jobs-2025",
  //     image: blogimg3,
  //   },
  // ];

  const blogs = [
    {
      title: " Workaroo.ai | Revolutionizing Blue-Collar Recruitment with AI",
      description1: " ➤ Workaroo.ai is transforming the blue-collar job market by addressing inefficiencies in hiring, skill development, and language barriers through innovative AI-powered solutions.Our platform bridges the gap between employers and workers with features like precise AI-driven job matching, multilingual interfaces, and real-time skill enhancement",
      description2: " ➤ Employers benefit from reduced recruitment time and costs, while workers gain access to better opportunities and holistic support, including training, financial aid, and healthcare options. By leveraging AI for data-driven decisions, Workaroo ensures long-term sustainability and growth for both employers and workers.",
      description3: " ➤ The blue-collar sector is riddled with inefficiencies: Employers struggle to find skilled workers, positions remain unfilled, leading to operational disruptions and productivity losses.Workers face difficulties finding suitable jobs: Despite having the necessary skills, workers often remain unemployed or underemployed due to mismatched opportunities.Attrition and turnover rates are alarmingly high in this sector, which increases recruitment costs for employers. Skill gaps are prevalent, creating barriers to finding and retaining the right talent. Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot.",
      description4: " ➤ Traditional job boards and hiring processes have failed to address these challenges, particularly for the blue-collar workforce. These platforms are often not user-friendly for workers who lack formal education, technical skills, computer skills, or English proficiency. At Workaroo, AI serves as the backbone of our platform, addressing these challenges in ways that traditional methods cannot. Our AI engine automates the process of matching workers to jobs based on a deep analysis of skills, experience, and job requirements.",
      description5: " ➤ By leveraging machine learning algorithms, we go beyond keyword-based searches, analyzing skill sets, experience level, and geographical preferences. This reduces time-to-hire for employers and increases job satisfaction for workers. Workaroo’s AI assesses candidates using AI-powered video interviews and audio profiles to offer richer insights. Multilingual AI interfaces allow workers to interact with the platform in their native languages. AI also recommends upskilling programs tailored to industry needs. By providing real-time recommendations, holistic worker support, and intelligent recruitment workflows, Workaroo ensures a happier, more stable workforce. Employers reduce costs and workers gain better opportunities, revolutionizing the blue-collar job market.",
      description6: " ➤ Creative Use of AI for Interviews and Candidate Insights: Workaroo’s AI doesn’t just match candidates—it assesses them. We use AI powered video interviews and audio profiles to offer a richer understanding of each worker. This is especially important in the blue-collar space, where traditional resumes often do not fully capture a worker’s potential. By analyzing: Voice and language proficiency, Facial expressions and body language during interviews, Technical knowledge through targeted interview questions, our AI provides employers with deeper insights into a worker's capabilities beyond what a typical resume would convey.",
      description7: " ➤  Breaking Language Barriers with Multilingual AI: Language is a major barrier in the blue-collar hiring process. Many workers are not comfortable with English centric job portals. Workaroo’s multilingual AI interface addresses this problem by allowing workers to interact with the platform in their native languages. This includes innovative ideas like: Audio and video resumes in multiple languages,Audio and video JDs(Job Descriptions) in multiple languages Multilingual job descriptions and communication, making the process more inclusive and effective. This feature significantly increases the pool of available talent for employers, giving them access to workers who were previously underserved by traditional platforms. ",
      description8: " ➤ AI Powered Skill Development and Upskilling: Workaroo goes beyond placement; we empower workers with personalized skill development. AI analyzes market trends and worker profiles to recommend specific upskilling programs tailored to both current job demands and future industry needs. This creates a sustainable talent pipeline, enabling workers to stay competitive and employers to benefit from a continually improving workforce. Workers are groomed before deployment to make sure they understand the company and environment, work procedures and expectations. This helps cementing their relationship with companies and increases the stickiness.  Workers receive real time recommendations for certifications, vocational training, and soft skill enhancement. ",
      description9: "  ➤ AI for Data Driven Decision Making: The intelligence layer of Workaroo’s platform uses AI to continuously analyze data from job placements, industry trends, and user interactions. This allows both employers and the platform itself to: Predict hiring trends and proactively address skill gaps. Optimize job postings based on historical success metrics. Improve employer decision making through real time insights into the workforce, reducing attrition by placing the right candidates in the right roles. This data driven approach helps employers stay ahead of market changes and ensures that workers are always aligned with the most relevant job opportunities. ",
      description10: "Creative AI Solutions That Set Workaroo Apart: ",
      description11: " ➤  Holistic Worker Support: Unlike traditional platforms, Workaroo doesn’t just stop at recruitment. Through AI powered solutions, we provide access to broader services such as training/skill upgradation enablement, accommodation assistance, financial aid, and healthcare options, addressing the broader needs of the blue-collar workforce. This ensures a happier, more stable workforce for employers. ",
      description12: " ➤ Focus on Skills Over Resumes: Our platform prioritizes skills assessment, ensuring that workers without formal education or detailed resumes are evaluated based on their hands on expertise. This levels the playing field for blue-collar workers. ",
      description13: " ➤ Intelligent Automation of Recruitment Workflow: From job description creation to resume filtering, Workaroo uses AI to automate repetitive tasks, freeing up HR teams to focus on strategic decisions. This drastically reduces recruitment costs for employers. ",
      description14: " ➤ Measurable Impact and Results: ",
      description15: " ➤ Workaroo’s AIpowered platform has already made significant strides: Placement success rate: Over 70% of job vacancies posted on the platform are filled with qualified candidates within two weeks. Reduction in recruitment time: Employers using Workaroo have reduced their recruitment time by over 40%, thanks to automated processes and precise matching. Upskilling initiatives: Workers on the platform receive continuous skill development recommendations, contributing to long term employability and lower turnover. ",
      description16: "Our Mission: ",
      description17: " ➤ Workaroo is not just another recruitment platform—it’s a comprehensive ecosystem built on the backbone of AI. We are empowering workers, solving industry challenges, and shaping the future of blue-collar recruitment. By investing in technology that fosters inclusivity, efficiency, and growth, we are revolutionizing the blue-collar job market, one placement at a time. #AIRecruitment #BlueCollarJobs #AIInnovation #WorkforceSolutions #TalentMatching #SkillDevelopment ",
      discription25:"",
      link: "/blogs/resume-tips",
      image: blogimg1
    }, 
    // {
    //   title: "Job Fulfillment Model ",
    //   description1: " ➤ Workaroo takes full ownership of the sourcing process—understanding the Job Description (JD), sourcing and screening candidates, evaluating fitment, and presenting a curated list of high-quality candidates. By handling the end-to-end hiring process, Workaroo ensures businesses acquire top-tier talent while saving time and resources.",
    //   description2: " ➤ The Job Fulfillment Model is designed to help employers efficiently connect with top-quality candidates. Workaroo takes full ownership of the sourcing process—understanding the Job Description (JD), sourcing and screening candidates, evaluating fitment, and presenting a curated list of top professionals to employers for hiring decisions.",

    //   description3: " ➤ This model streamlines the recruitment process, reducing hiring timelines and ensuring that businesses secure the right talent. By leveraging both human expertise and AI-driven recruitment tools, Workaroo enhances efficiency and eliminates the complexities of traditional hiring methods.",

    //   description4: " ➤ Platform Enrolment Charges: To ensure an effective hiring process, Workaroo charges an enrolment fee that covers comprehensive employer support, including job description (JD) creation, advanced candidate sourcing, and seamless account setup.",

    //   description5: " ➤ Account Setup & JD Documentation: Our team works closely with employers to craft a detailed job description (JD) that aligns with their hiring needs and business goals, ensuring the right candidates are targeted from the outset.",

    //   description6: " ➤ Strategic Job Promotion: We enhance job visibility through a robust multi-channel marketing approach. This includes targeted campaigns across video job descriptions, Workaroo’s website & mobile apps, as well as digital and traditional media outlets.",

    //   description7: " ➤ Comprehensive Candidate Database Access: Employers can tap into a vast talent pool of pre-vetted, skilled workers, ensuring they connect with professionals who meet their criteria and are ready for employment.",

    //   description8: " ➤ AI-Powered Recruitment System: Workaroo leverages artificial intelligence and data-driven insights to match employers with the best-fit candidates, streamlining the hiring process and increasing efficiency.",

    //   description9: " ➤ Hiring Charges: In addition to the enrolment fee, Workaroo provides AI-powered recruitment combined with human screening processes to find, evaluate, and match the most suitable candidates for your job openings.",

    //   description10: " ➤ Expert Recruitment Support: A dedicated recruitment specialist is assigned to guide employers throughout the hiring journey, ensuring personalized and effective hiring decisions.",

    //   description11: " ➤ Precision Candidate Matching: Advanced AI-driven screening ensures that only pre-screened and highly suitable candidates are presented to employers, saving time and improving the quality of new hires.",

    //   description12: " ➤ Ongoing Employer Support: Workaroo’s dedicated customer service team is available to assist throughout the hiring process, providing necessary guidance and troubleshooting any challenges that may arise.",
      
    //   discription25: `${<button>clickME</button>}`,
    //   link: "/blogs/remote-jobs-2025",
    //   image: blogimg4,
    // },
    // {
    //   "title": "Subscription Model ",
    //   "description1": "➤ Workaroo Job Marketplace introduces a subscription model, providing employers access to an extensive resource database of jobseeker profiles and direct contacts.",
    //   "description2": "➤ Designed for large recruitment teams or independent hiring groups, enabling a streamlined hiring process from sourcing to final selection.",
    //   "description3": "➤ Four flexible subscription plans—Basic, Silver, Silver Plus, and Gold—offering different JD posting limits, profile unlocks, and validity durations to match client needs.",
    //   "description4": "➤ Subscription Benefits: Employers can unlock job seeker contacts and post JDs within their chosen plan's limit, ensuring efficient hiring.",
    //   "description5": "➤ Cost-effective hiring model: Unlike traditional pay-per-job models, Workaroo’s subscription plan optimizes recruitment costs for businesses of all sizes.",
    //   "description6": "➤ Basic Plan: 2 job postings, 50 profile unlocks, 1-month validity at ₹2450—ideal for small-scale hiring or startups.",
    //   "description7": "➤ Silver Plan: 4 job postings, 150 profile unlocks, 3-month validity at ₹4900—suitable for mid-sized hiring needs.",
    //   "description8": "➤ Silver Plus Plan: 7 job postings, 300 profile unlocks, 6-month validity at ₹9800—designed for high-volume recruitment.",
    //   "description9": "➤ Gold Plan: 15 job postings, 750 profile unlocks, 12-month validity at ₹12,600—perfect for companies hiring in bulk.",
    //   "description10": "➤ Efficient candidate selection: AI-powered profile matching ensures recruiters find the most relevant candidates quickly.",
    //   "description11": "➤ Dedicated customer support to assist with subscription management, profile unlocking, and hiring strategy guidance.",
    //   "description12": "➤ Seamless integration with existing HR systems and applicant tracking tools for an optimized hiring experience.",
    //   "description13": "➤ Scalable for growing businesses: Adaptable subscription plans to accommodate changing hiring needs.",
    //   "description14": "➤ AI-driven precision hiring: Workaroo uses machine learning to match job descriptions with top candidates for better hiring decisions.",
    //   "description15": "➤ Transforming recruitment: Workaroo’s subscription-driven model makes hiring data-driven, cost-efficient, and employer-centric.",
    //   link: "/blogs/remote-jobs-2025",
    //   image: blogimg5,
    // },
    {
      title: "Bridging the Digital Divide: Making Tech-Driven Job Platforms More Accessible to India’s Blue-Collar Workforce ",
      description1: " ➤ In the small town of Nagpur, Ramesh, an experienced welder, wakes up every day hoping for work. His skills are sharp, but the local job market is unpredictable, and he relies heavily on contractors who offer inconsistent pay and limited opportunities.",
      description2: " ➤ If you look at the other side of the spectrum, across the country, companies in cities like Pune or Chennai are struggling to find skilled workers like him, despite the rapid rise of tech-driven job platforms.",
      description3: " ➤ Why the disconnect? For millions of blue-collar workers like Ramesh, these platforms are difficult to access due to barriers such as language, digital literacy, and access to technology.",
      description4: " ➤ This situation is not unique to Ramesh. India, with its massive 400m+ blue-collar workforce, is grappling with how to bring these workers into the fold of the digital economy.",
      description5: " ➤ While job platforms have revolutionized hiring for the white-collar workforce, particularly for IT services, they must adapt to meet the needs of workers on the ground. Let’s explore the challenges and the solutions that can help bridge this gap.",
      description6: " ➤ In a country as linguistically diverse as India, one of the first obstacles workers encounter on digital job platforms is language. The majority of these platforms operate in English which immediately alienates workers from states like UP, MP, Tamil Nadu, West Bengal, or Maharashtra, where local languages dominate. A daily wage worker from Kanpur who speaks only Hindi, may struggle to understand job descriptions or fill out application forms on an English-language platform.",
      description7: " ➤ Digital Literacy While smartphone penetration is growing across India, many workers are still not familiar with using technology beyond making phone calls or sending WhatsApp messages. Take Rekha, a domestic worker in Bengaluru. She has a smartphone but uses it mainly to communicate with family. When her friend suggested she look for jobs online, Rekha was unsure how to navigate the job portals or upload her information. Many blue-collar workers like Rekha lack the digital literacy needed to fully participate in the online job market. They are overwhelmed by the complexity of job applications, login processes, and resume creation, leading to frustration and disengagement. ",
      description8: " ➤ Access to Technology Though India is fast becoming a digital-first nation, access to technology remains a challenge for many in rural and semi-urban areas. Even those who own smartphones might struggle with unstable internet connections or expensive data plans. For example, in villages across Uttar Pradesh, workers may have to walk miles just to find reliable internet to apply for jobs online. While technology has improved a lot over last 2 decades still there are islands of poor connectivity. We had stories during COVID that students in some villages have to sit on the house roof to get the access to internet as connectivity was poor. The lack of affordable and accessible technology becomes a significant hurdle for workers to find better job opportunities. ",
      description9: "Solutions to Bridge the Gap ",
      description10: "1. Multilingual Platforms One of the most effective ways to make job platforms accessible is by providing interfaces in regional languages. In India, where over 22 official languages are spoken, job portals must be tailored to include languages such as Tamil, Telugu, Marathi, Bengali, and Kannada. Offering multilingual support can significantly ease the burden on workers. Consider the success of some e-commerce platforms that have integrated regional language options. When workers from rural Karnataka can search for jobs in Kannada or a laborer in Bengal can navigate a portal in Bengali, they are more likely to use these platforms confidently. ",
      description11: "2. Simple, User-Friendly Design   Platforms must be designed with the user in mind—especially those who are not tech-savvy. Instead of long forms or complex resume builders, platforms could offer features like audio and video profiles. A plumber from Bihar, for instance, could simply record a short video explaining his work experience rather than struggling to type out a resume. This not only makes the platform easier to use but allows employers to assess a worker’s skills more directly. In today’s world of AI, the video or audio profiles can be translated to text resumes.   Simplified navigation, easy-to-understand icons, and the use of everyday language rather than corporate jargon can also make these platforms more accessible. A construction worker should be able to apply for jobs without needing extensive technical knowledge. ",
      description12: "3. Mobile-First Approach In India, the mobile phone is the primary device for accessing the internet, especially in rural areas. Job platforms should be fully optimized for mobile use, ensuring they work efficiently even on low-cost smartphones. They should load quickly, use minimal data, and be accessible in areas with weak internet connections. Additionally, SMS-based job alerts can help bridge the technology gap. Workers can receive job notifications via SMS and respond with a simple message to apply. For Ramesh, the welder from Nagpur, receiving a text message about a job in Hyderabad that matches his skills could be the nudge he needs to access better-paying work. ",
      description13: "4. Digital Literacy and Training Programs While simplifying platforms is essential, so too is improving digital literacy. Workers need to be equipped with the basic skills to navigate online platforms confidently. Local governments, NGOs, and private companies can collaborate to set up digital literacy programs in communities. These programs could teach workers how to search for jobs online, create profiles, and apply for positions. There are already promising examples of this approach. In Kerala, the government has rolled out digital literacy programs that have helped thousands of citizens, including blue-collar workers, use technology to improve their employment prospects. Short 2/3 minute “how to do” kind of videos can go great extent to educate the users. There can be online distance learning programs in local languages which will be very productive.  ",
      description14: "5. Hybrid Models: Combining Offline and Online Efforts Not all workers are ready for a fully digital experience. This is where offline support can make a difference. By setting up local support centers or kiosks, workers can receive assistance in registering for platforms, building profiles, and applying for jobs. These centers(or Agents) could be run by local agencies or even mobile shops, painter or hardware shops providing a crucial bridge between workers and digital job portals. These agents can play a role in attracting, enabling, educating, grooming the workers.  A real-world example of this model in action comes from Andhra Pradesh, where local employment agencies assist workers in signing up for job portals. Workers who are unfamiliar with online systems are guided through the process and receive updates on job opportunities via SMS. ",
      description15: "A Win-Win for Workers and Employers ",
      description16: " ➤ The benefits of addressing these challenges are enormous—not just for workers, but for employers too. Indian companies often struggle to find skilled blue-collar workers. By making digital platforms more inclusive, they can tap into a vast, underserved talent pool. For example, a manufacturing company in Pune might find it easier to recruit electricians and welders from nearby villages through a platform that supports local languages and mobile-friendly applications. The recruitment process also becomes more streamlined. Features such as video resumes allow employers to assess candidates more thoroughly, reducing mismatches in hiring. The process becomes faster, more efficient, and ultimately more reliable for both workers and businesses. ",
      description17: " Conclusion ",
      description18: " ➤ As India races towards becoming a digital economy, we cannot afford to leave millions of blue-collar workers behind. By addressing language barriers, improving digital literacy, and ensuring broader access to technology, we can bridge the digital divide and open doors for workers like Ramesh and Rekha. This transition will not only improve the livelihoods of blue-collar workers across the country but also help businesses find the skilled workforce they need. In a country where opportunity and talent abound, it’s time to ensure that technology works for everyone. #DigitalDivide #InclusiveDigital #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #Multilingual  4th Aug 2024  Ravi Annadurai https://www.linkedin.com/in/arc1507/ ",
      discription25:"",
      link: "/blogs/interview-tips",
      image: blogimg2,
    },
    {
      title: "Upskilling the Blue-Collar Workforce: Industry’s Urgent and Critical need for Skill Development ",
      description1: " ➤ In the heart of India’s industrial belt, stories like that of Mohit, a seasoned welder from Ludhiana, are becoming increasingly common. Despite decades of experience, Mohit now finds himself at a crossroads. The machines in his factory have become more advanced, and the younger, tech-savvy workers are increasingly taking over tasks that once defined his career.",
      description2: " ➤ Mohit knows he needs new skills, but where can he learn them? And how will he afford it?",
      description3: " ➤ This scenario highlights the reality faced by millions of India’s blue-collar workers.",
      description4: " ➤ As technological advancements transform industries, the skills gap among these workers presents a critical challenge, threatening livelihoods, business profitability, and the overall health of the nation's economy.",
      description5: " ➤ The struggle to stay relevant in the changing job market is not just a personal issue for Mohit but a larger problem affecting millions of skilled workers across the country.",
      description6: " ➤ The Blue-Collar Skills Crisis in India ",
      description7: " ➤ India is home to one of the largest blue-collar workforces in the world. Estimates suggest that blue-collar workers account for nearly 90% of the country’s workforce. These workers are the backbone of industries such as construction, automotive manufacturing, logistics, and retail. Yet, according to reports, over 60% of India’s blue-collar workers lack formal training, with many relying solely on practical, hands-on experience. ",
      description8: " 1. Skill Gaps and Obsolete Knowledge Blue-collar workers often acquire their skills through informal channels, such as on-the-job learning or apprenticeships. While this form of learning has sufficed for years, it no longer matches the demands of today’s digital and automated industrial landscape. Take the manufacturing sector, for example. The transition to automated processes, including robotics and machine learning systems, has created a high demand for workers who understand and can operate advanced technologies. In contrast, a majority of the existing workforce is still equipped with outdated skills that were relevant decades ago. A welder might have mastered manual welding techniques but has no experience operating CNC (Computer Numerical Control) machines. Similarly, factory workers trained to operate mechanical tools are struggling to adapt to digitized production lines where everything is automated and controlled by software. ",
      description9: "2. Impact on Livelihoods of Workers The most immediate impact of this skills gap is on the workers themselves. The lack of relevant skills reduces their employability, making it difficult for them to secure stable, high-paying jobs. As industries automate and modernize, those without the necessary skills are either forced into low-paying, menial jobs or, worse, pushed out of the workforce entirely. Workers like Mohit, despite their years of experience, risk becoming irrelevant if they cannot upskill and adapt. The economic consequences of this are severe. For millions of workers, this skill gap translates to lower wages, job insecurity, and reduced opportunities for career growth. A factory worker who once earned a respectable wage may find his earnings dropping as companies increasingly value new skills over old experience. The cumulative effect is devastating for households, leading to a cycle of poverty and reduced social mobility. ",
      description10: "3. Adverse Effects on Businesses and Industry Health For businesses, the skill gap creates major operational challenges. Indian industries are heavily dependent on blue-collar labor, and the inability to find workers with the necessary skills leads to reduced productivity, higher operational costs, and longer production cycles. For example, a manufacturing company that has invested in automated machinery may find its operations stalled because it cannot find workers who can operate the new equipment efficiently. Furthermore, the lack of skilled workers reduces the competitiveness of entire industries. When companies are unable to keep up with technological advancements due to skill shortages, they lose out on contracts, miss deadlines, and face increased downtime.  Recently during my conversation with one Industrialist in Bangalore in the manufacturing space , he said that “the business can grow 70-80% year given the business inflow”. However he is able to grow the business only 25% due to lack of quality  manpower. That is an alarming extent of skills shortage problem. This in turn, affects profitability and limits the potential for growth. India’s automotive and manufacturing sectors, which are integral to its economy, are particularly vulnerable to these challenges. ",
      description11: " ➤  The Way Forward: Solutions to the Skills Problem ",
      description12: " ➤ To address the blue-collar skills crisis, a multi-pronged approach is necessary—one that involves not only workers but also businesses, educational institutions, NGOs and the government. Indian Govt has launched several initiatives and agencies to address the skill development namely National Skill development corporation, Skill India Digital and Pradhan Mantri Kaushal Vikas Yojana(launched in 2015) to encourage and promote skill development in the country. Towards finding innovative and deeper solutions to skill development, we have explored the following ideas. These solutions highlight how technology, innovative learning methods, and collaboration between stakeholders can help bridge the skills gap. ",
      description13: "1. Online Learning Platforms and E-Learning Tools In a country as vast as India, traditional vocational training programs are often limited by geography, accessibility, and cost. Online learning platforms are changing this. By offering courses that workers can access from their smartphones, these platforms are removing barriers to learning. Platforms such as Skill India, Coursera, and Udemy are providing affordable and relevant courses in fields like machine operation, plumbing, and automotive repair, making skill development more accessible to blue-collar workers across India. For example, a logistics worker in Uttar Pradesh who wants to learn about supply chain management can now do so through his phone, at his own pace. E-learning allows workers to upskill without having to leave their jobs, giving them the flexibility to learn new skills while still earning an income. ",
      description14: "2. Flexible Learning and Continuous Development A rigid curriculum no longer works in a rapidly changing world. Learning must be continuous and flexible, allowing workers to acquire new skills as industries evolve. For example, a CNC machine operator who has already completed basic training may need to return for refresher courses or learn additional skills as technology advances.  Flexible curriculums, where workers HOP in and HOP out of courses/learning modules based on their needs, will ensure that the workforce remains adaptable and ready for future changes. These learning pathways could be supported by micro-credentials and certificates, which workers can collect as they progress through different stages of learning. ",
      description15: "3. Industry’s Aggressive Role in Upskilling Industries cannot wait for workers to catch up with their technological advancements. Businesses must take an aggressive role in upskilling their employees. Many Indian companies are already partnering with training platforms to offer in-house training programs that prepare workers for the specific technologies and systems used in their operations. For example, large automotive manufacturers are providing training on how to operate and maintain robotic equipment, while logistics companies are teaching their workers how to use warehouse management software. This model not only fills immediate skill gaps but also invests in the long-term development of the workforce, creating a more capable and adaptable pool of workers. Training programs by Industries can help them to fulfil their requirements as well as improve their branding in terms of workers trained/certified by Company’s Academy.  Infact companies which hire blue collar workforce can take a cue from IT industry which spent lot of time and effort in training the white collar resources which ultimately created huge base of IT resources. Today it is helping newer Tech industries like GCC(Global Capability centers) and new age startups.  ",
      description16: "4. AI-Driven Skill Assessments One of the most exciting developments in the upskilling of blue-collar workers is the use of AI-driven skill assessments. AI can analyze a worker’s current skill set, identify gaps, and recommend personalized learning paths based on the individual’s experience and aspirations. For instance, a technician with basic electrical skills can take an AI-driven test that evaluates his readiness to handle more advanced electrical systems and suggests specific courses to close those gaps. For businesses, AI tools can also be used to assess their workforce’s strengths and weaknesses, enabling employers to provide targeted training programs. This ensures that workers are trained efficiently, saving companies time and resources while upskilling their workforce in areas critical to operations. ",
      description17: "5. Innovative Skill Development funding: Skill Development Vouchers  Skill development vouchers are an innovative approach to financing vocational training and skill development programs, aimed at increasing access to quality education and empowering individuals to choose the training programs best suited to their needs. These vouchers can be seen as a market-driven mechanism to enhance skills, often funded by the government or other agencies. Vouchers as Financial Aid: Individuals receive vouchers that cover part or all of the cost of vocational training or skill development courses. These vouchers are typically redeemable at approved training institutions. Choice and Flexibility: Vouchers give individuals the flexibility to choose from a variety of training providers, encouraging competition among institutions to offer high-quality training programs. Targeted Groups: These vouchers are often aimed at economically disadvantaged individuals or those in need of specific skill development, such as blue-collar workers, youth, or workers in the informal sector.",
      description18: " ➤ Conclusion: A Collective Responsibility ",
      description19: " ➤ The skill crisis in India’s blue-collar workforce is a multi-faceted problem that affects the livelihoods of millions of workers, threatens the profitability of businesses, and impacts the health of industries. However, the solution is not beyond reach. By leveraging technology, embracing AI-driven initiatives, reforming vocational training, and encouraging industries to play an active role in skill development, India can build a workforce that is future-ready. For workers like Mohit and the millions of others who rely on their skills for a living, upskilling is not just an option—it’s a necessity. With the right tools and support, India’s blue-collar workforce can adapt to the changing demands of the 21st century and continue to power the country’s economic growth. #IndustryDrivenTrainings #LifeLongSkillDevelopment #BlueCollarJobs #AIInnovation #WorkforceSolutions #SkillDevelopment #EnableBlueCollar #VocationalTrainings 1st Sep 2024  Ravi Annadurai ",
      discription25:"",
      link: "/blogs/remote-jobs-2025",
      image: blogimg3,
    },
    ,
    
   
  ];

  const handleShow = (blog) => {
    setCurrentBlog(blog);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentBlog(null);
  };

  return (
    <>
      <EmployerDashboardHeader />
      <div className="dashboard">
        <Container fluid className="content">
          <Row className="g-4">
            <Col >
            {isLoggedIn ? <EmployerDashboardSideBar /> :
              <></>
            }
            </Col>
            <Col md={colWidth} className="main-content">
              <section
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "40px 0 10px",
                  textAlign: "center",
                }}
              >
                <Container>
                  <h1 style={{ fontWeight: "bold", marginBottom: '0px', color: '#170F49' }}> Blog</h1>

                </Container>
              </section>

              {/* Blog Section */}
              <Container style={{ padding: "30px 0" }}>
                <Row className="g-4" style={{ display: 'flex', flexWrap:'wrap', alignItems: 'stretch' }}>
                  {blogs.map((blog, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={6}
                      sm={12}
                      className="mb-4 d-flex"
                      style={{ display: 'flex' }} // Ensures each column stretches
                    >
                      <Card
                        className="blogcard"
                        style={{
                          border: "none",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          flexDirection: "column", // Ensures button stays at the bottom
                          flex: 1, // Makes all cards equal in height
                          position: 'relative',
                        }}
                      >
                        <Card.Img variant="top" src={blog.image} alt={blog.title} style={{ width: '100%',marginLeft:'0px', height: '200px', border: 'none', borderRadius: '10px 10px 0px 0px', padding: '0px' }} />
                        <Card.Body style={{ flex: 1, padding: '15px', paddingBottom: '0px', }}>
                          <Card.Title style={{ fontWeight: '1200px' }} className="cardtitle">{blog.title}</Card.Title>
                          <Card.Text
                            style={{
                              // background:'#000',
                              height: '120px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                              color: '#555'
                            }}
                          >
                            {blog.description1.split(' ').slice(0, 18).join(' ')}
                            {blog.description1.split(' ').length > 18 && (
                              <span style={{ color: '#333', fontWeight: 'bold' }}>...</span>
                            )}
                          </Card.Text>
                        </Card.Body>
                        <a
                          className="readmore"
                          onClick={() => handleShow(blog)}
                          style={{ width: '100px', marginTop: '0px', position: 'absolute', bottom: '20px', left: '10px', fontWeight: 'bold' }} // Keeps the button at the bottom
                        >
                          <FaArrowRight className="readarrow" />Read More
                        </a>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
              {/* Modal Popup */}
              {currentBlog && (
                <Modal
                  show={show}
                  onHide={handleClose}
                  centered={true} // Vertically center the modal
                  size="xl" // Ensures initial large width
                  dialogClassName="custom-modal-dialog"
                >
                  <Modal.Body
                    style={{
                      display: "flex",
                      // background:'#000',
                      borderRadius: "15px",
                      overflow: "hidden",
                      width: "90%", // Increased width to 90% of screen
                      height: "80vh", // Increased height to 95% of viewport height
                      margin: "auto", // Center the modal horizontally and vertically
                    }}
                  >
                    {/* Left-side Image */}
                    <div style={{ flex: "1 1 50%", maxHeight: "100%", overflow: "hidden", background: '#000' }}>
                      <img
                        src={currentBlog.image}
                        alt={currentBlog.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Ensures the image scales proportionally
                        }}
                      />
                    </div>

                    {/* Right-side Content */}
                    <div
                      style={{
                        flex: "1 1 50%",
                        padding: "20px",
                        overflowY: "auto", // Ensures scrollability for content
                      }}
                    >
                      <h2>{currentBlog.title}</h2>


                      {/* Render description with conditional <br /> based on word length before period */}
                      <div style={{ color: '#555', fontSize: '14px', marginTop: '10px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: '400px' }}>

                        {currentBlog.description1}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description2}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description3}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description4}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description5}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description6}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description7}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description8}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description9}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description10}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description11}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description12}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description13}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description14}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description15}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description16}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description17}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description18}
                        <br /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>{currentBlog.description19}
                      </div>

                      {/* <Button
                        variant="secondary"
                        className="mt-3"
                        onClick={handleClose}
                        style={{
                          borderRadius: "8px",
                          padding: "10px 20px",
                          fontWeight: "bold",
                        }}
                      >
                        Close
                      </Button> */}
                    <div className='BlogDiv'>
                      {currentBlog.image.includes("improvement-summary-personal-development-workflow.a670643e265f49147d3c.jpg") && (
                          <Button
                            style={{position:'relative',top:'16rem!important'}}
                            onClick={() => Navigate('/employersub')}
                          >
                            Apply
                          </Button>
                        )}
                        <Button

                          variant="secondary"
                          className=" blog-btn"
                          onClick={handleClose}
                          style={{
                            borderRadius: "8px",
                            padding: "10px 20px",
                            fontWeight: "bold",
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    
                    </div>
                  </Modal.Body>

                </Modal>

              )}

            </Col>
          </Row>
        </Container>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default BlogPage;
