import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import EmployerDashboardSideBar from '../components/EmployerDashboardSideBar';
import axios from 'axios';
import { Card, Col, Container, Form, InputGroup, Row, Stack } from 'react-bootstrap';
import { Chip } from '@mui/material';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Links, Typography } from "@mui/material";
import '../Styles/jobdetails.css'

export const JobseekerDetails = () => {


  const location = useLocation();
  const { id } = location.state || {}; // Destructure to get 'id' from location.state
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem('accessToken');

  const [applicantData, setApplicantData] = useState(null); // State to store applicant details
  const [loading, setLoading] = useState(true); // To track loading state
  const [error, setError] = useState(null); // To track error state
  const [plane, setPlane] = useState(null);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // const token = sessionStorage.getItem("accessToken");
        // const response = await axios.get(`${apiUrl}/user/${userId}`,{
        //     headers: {
        //         'Authorization': `${token}`,
        //         'Content-Type': 'application/json',
        //     });

        const response = await fetch(`${apiUrl}/user/${userId}`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('response', response);


        if (response.data) {
          setPlane(response.data.data.activePlanId);
          // alert('enters')
          console.log("Fetched Plan ID:", response.data.activePlanId);
        } else {
          console.warn("Unexpected response:", response);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    fetchProfile();
  }, [userId, apiUrl, token]);

  console.log("setPlane", plane);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = sessionStorage.getItem("accessToken");
        const userId = sessionStorage.getItem("userId");

        if (!userId || !token) {
          console.warn("Missing userId or token, skipping API call.");
          return;
        }

        const response = await fetch(`${apiUrl}/user/${userId}`, {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });

        // Convert response to JSON
        const data = await response.json();
        console.log("API Response:", data);

        // Check if response contains 'data' field
        if (data && data.data) {
          setPlane(data.data.activePlanId);
          console.log("Fetched Plan ID:", data.data.activePlanId);
        } else {
          console.warn("Unexpected response format:", data);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);



  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          console.log("Fetching data for ID:", id);
          console.log("API URL:", `${apiUrl}/user/${id}`);
          console.log("Authorization Token:", token);

          const response = await fetch(`${apiUrl}/user/${id}`, {
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json',
            },
          });

          console.log("Response Status:", response.status);

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error fetching applicant data: ${errorData.message || 'Unknown error'}`);
          }

          const data = await response.json();
          console.log("Fetched data:", data);
          setApplicantData(data?.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setError(error.message);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id, apiUrl, token]); // Trigger fetch whenever 'id' or 'apiUrl' or 'token' changes


  // Show loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show error if there was a problem fetching data
  if (error) {
    return <div>Error: {error}</div>;
  }


  const formattedApplicantData = {
    personalDetails: {
      jobseekerId: applicantData.jobseekerId,
      firstName: applicantData.firstName,
      lastName: applicantData.lastName,
      phoneNumber: applicantData.phoneNumber,
      email: applicantData.email,
      dob: applicantData.dob,
      address: applicantData.address,
      city: applicantData.city,
      state: applicantData.state,
      pincode: applicantData.pincode,
    },
    kycDetails: {
      aadharNo: applicantData.aadharNo,
      panNo: applicantData.panNumber,
      voterId: applicantData.voterId,
      drivingLicenceNumber: applicantData.drivingLicenceNumber,
    },
    professionalDetails: {
      profileTitle: applicantData.profileTitle,
      experience: applicantData.experience,
      currentJob: applicantData.CurrentJob,
      currentSalary: applicantData.CurrentSalary,
      expectedSalary: applicantData.ExpectedSalary,
      ReasonsforChange: applicantData.ReasonsforChange,
      skills: applicantData.skills,
      responsibilities: applicantData.responsibilities,
      NativeLocation: applicantData.NativeLocation,
      LanguagesKnown: applicantData.LanguagesKnown,
      NoticePeriod: applicantData.NoticePeriod,
      Relocation: applicantData.Relocation,
      InterestedJobField: applicantData.InterestedJobField,
      BatchCode: applicantData.WRKBatchCODE,
      companyName: applicantData.companyName,
      JobseekerFeedback: applicantData.JobseekerFeedback,
      qualification: applicantData.Qualification,
      miscDetails: applicantData.miscDetails,
      Remarks: applicantData.Remarks,
      CSE: applicantData.CSE,
    }
  }

  // Display the applicant data if fetched successfully
  return (
    <div>
      <EmployerDashboardHeader />
      <div style={{ display: 'flex' }}>
        <Row>
          <Col><EmployerDashboardSideBar /></Col>

          {/* Render applicant details here */}
          <Col md={10}>

            {applicantData ? (

              <Container style={{ background: '#F5F6FA' }}>
                <Row>

                  {/* Breadcrumbs */}
                  <div role="presentation" className="ms-2 py-3" >
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb" className='breadcrum'>


                      <Links
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/applyJobAllJobSeeker')} // Navigate to previous Job Details page
                        style={{ cursor: "pointer" }}
                      >
                        All Jobseekers
                      </Links>

                      <Typography sx={{ color: "#2F2761", fontWeight: 'bold' }}>Jobseekers Details</Typography>
                    </Breadcrumbs>
                  </div>
                  <Col>
                    {/* Personal Details */}
                    <Card className="mb-4">
                      <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                        Personal Details
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-3">
                          {Object.entries(formattedApplicantData.personalDetails || {}).map(([key, value]) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>
                                  {key === "phoneNumber" ? "MOBILE NUMBER" : key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                </InputGroup.Text>
                                {key.toLowerCase() === "address" ? (
                                  <Form.Control as="textarea" value={value} readOnly rows={3} />
                                ) : (
                                  <Form.Control
                                    type="text"
                                    value={key === "phoneNumber" && (!plane && role === "employer") ? "**********" : value}
                                    readOnly
                                    style={key === "phoneNumber" && (!plane && role === "employer") ? { filter: "blur(5px)" } : {}}
                                  />
                                )}
                              </InputGroup>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>


                    </Card>

                    {/* KYC Details */}
                    <Card className="mb-4">
                      <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                        KYC Details
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-3">
                          {Object.entries(formattedApplicantData.kycDetails || {}).map(([key, value]) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</InputGroup.Text>
                                <Form.Control type="text" value={value} readOnly />
                              </InputGroup>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>

                    {/* Professional Details */}
                    <Card className="mb-4">
                      <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white" }}>
                        Professional Details
                      </Card.Header>
                      <Card.Body>
                        

                        <Row className="g-3">
                          {Object.entries(formattedApplicantData.professionalDetails || {}).map(([key, value]) => (
                            <Col md={6} key={key}>
                              <InputGroup>
                                <InputGroup.Text>
                                  {key === "profileTitle" ? "JOB TITLE (Current) "
                                    : key === "currentSalary" ? "CURRENT SALARY (Month) "
                                      : key === "expectedSalary" ? "EXPECTED SALARY (Month) "
                                        : key === "responsibilities" ? "JOB RESPONSIBILITIES "
                                          : key === "WRKBatchCODE" ? "WRK-BATCH CODE"
                                            : key === "companyName" ? "COMPANY NAME"
                                              : key === "JobseekerFeedback" ? "JOBSEEKER STATUS"
                                                : key === "Relocation" ? "READY FOR RELOCATION (Y/N)"
                                                  : key === "Addl" ? "DL DETAILS"
                                                    : key === "Addl2" ? "ADDL DETAILS"
                                                      : key === "ReasonsforChange" ? "REASONS FOR CHANGE"
                                                        : key.replace(/([A-Z])/g, " $1").toUpperCase()}
                                </InputGroup.Text>

                                {Array.isArray(value) ? (
                                  <Stack direction="row" spacing={1} className="flex-wrap" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '3px' }}>
                                    {value.map((item, index) => (
                                      <Chip key={index} label={item} />
                                    ))}
                                  </Stack>
                                ) : key === "Remarks" || key === "qualification" || key === "miscDetails" ? (
                                  <Form.Control as="textarea" value={value} readOnly rows={3} />
                                ) : (
                                  <Form.Control type="text" value={value} readOnly />
                                )}
                              </InputGroup>
                            </Col>
                          ))}

                        </Row>

                      </Card.Body>
                    </Card>

                   
                  </Col>
                </Row>
              </Container>

            ) : (
              <p>No details available for this applicant.</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

