import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Table, Button, Modal, Card, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../EmployerDashboard.css';
import axios from 'axios';
import { FaEye } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import '../Styles/jobseekerjoblist.css';
import moment from 'moment';


const AllCandidates = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [applicants, setApplicants] = useState([]);
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [applied, setApplied] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [planRestrict, setPlanRestrict] = useState(true);
  const userId = sessionStorage.getItem("userId");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);

  const [filter, setFilter] = useState({
    name: '',
    location: '',
  });

  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const [itemsPerPage, setItemsPerPage] = useState(20); // Default number of items per page

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        if (!token) {
          throw new Error('Authentication token is missing');
        }

        const response = await axios.get(`${apiUrl}/user/roles/jobseeker`, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (Array.isArray(response?.data?.data)) {
          setApplicants(response.data.data);
          setFilteredApplicants(response.data.data); // Initialize filtered applicants with all data
        } else {
          throw new Error('Expected data to be an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [apiUrl]);

  const applyFilters = () => {
    const filtered = applicants.filter((applicant) => {
      return (
        (filter.name ? (applicant.userName && applicant.userName.toLowerCase().includes(filter.name.toLowerCase())) : true) &&
        (filter.location ? (applicant.city && applicant.city.toLowerCase().includes(filter.location.toLowerCase())) : true)
      );
    });
    setFilteredApplicants(filtered); // Update filteredApplicants with filtered results
    setCurrentPage(0); // Reset to the first page after applying filters
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  // Function to handle page change
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage); // Update the current page
  };

  const token = sessionStorage.getItem('accessToken');

  const handleViewClick = async (applicant) => {
    setShowDetailsModal(true)
    // Fetch applicant details
    try {
      // handle applicant details view
      const response = await axios.get(`${apiUrl}/user/${applicant.userId}`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log("re", applicant);
      setApplied(response.data.data)
      console.log("app", applied)
    } catch (error) {
      console.error("Error fetching applicant details:", error);
    }
  };

  useEffect(() => {
    if (applied) {
      console.log("Updated applied state:", applied);
    }
  }, [applied]);

  const handleLimitChange = (e) => {
    setItemsPerPage(Number(e.target.value)); // Set the items per page based on user selection
    setCurrentPage(0); // Reset to the first page after changing the limit
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Get the current applicants to display based on pagination
  const currentApplicants = filteredApplicants.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );


  return (
    <div className="dashboard">
      <Container fluid className="content">
        <Row className="mt-4">
          <Col md={12}>
            <h5 className="mb-3 fw-bold">All Jobseekers</h5>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Card className="mb-4 shadow-sm" style={{ flex: '18%' }}>
                <Card.Body>
                  <Form>
                    <Row className="justify-content-between">
                      <Col sm={12}>
                        <Form.Group controlId="jobTitle">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={filter?.name}
                            onChange={handleFilterChange}
                            placeholder="Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group controlId="location" className='mt-3'>
                          <Form.Label >Location</Form.Label>
                          <Form.Control
                            type="text"
                            name="location"
                            value={filter?.location}
                            onChange={handleFilterChange}
                            placeholder="Location"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} className="d-flex align-items-end" style={{ marginTop: "27px" }}>
                        <Button style={{ backgroundColor: " rgb(47 39 97)" }}
                          onClick={applyFilters}
                          className="btn w-100">
                          Filters
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              <div className="card border-0 rounded-0" style={{ flex: '82%' }}>

                <div  >
                  <Form.Group className="limit">
                    <Form.Label>Limit: </Form.Label>
                    <Form.Control as="select" value={itemsPerPage} onChange={handleLimitChange} style={{ height: '30px' }} className="limit-select">
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="card-body table-responsive">
                  <Table hover className="table">
                    <thead className="theads">
                      <tr className="tablehead">
                        {/* <th>Select</th> */}
                        <th>FirstName</th>
                        <th style={{ width: '60px' }}>LastName</th>
                        <th>Profile Title</th>
                        <th>Total Experience</th>
                        <th>₹ Salary/Month</th>
                        <th>Skills</th>
                        <th>Location</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="tbodys">
                      {currentApplicants.length === 0 && !loading ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No matching jobs found
                          </td>
                        </tr>
                      ) : (
                        currentApplicants.map((applicant) => (
                          <tr key={applicant.id} className="table-row">


                            <td style={{ fontWeight: 'bold' }}>{applicant.firstName} </td>
                            <td style={{ fontWeight: 'bold' }}>{applicant.lastName} </td>
                            <td>{applicant.profileTitle}</td>
                            <td>{applicant.experience ? applicant.experience.match(/\d+/)?.[0] : "-"}</td>
                            <td>{applicant.salary ? applicant.salary.split(/[-/]/)[0].trim().match(/(\d{1,3}(?:,\d{3})*)(k?)/)?.[0] : "-"}</td>
                            <td>{applicant.skills || "-"}</td>
                            <td>{applicant.city || "-"}</td>
                            <td>
                              <Link onClick={() => handleViewClick(applicant)} className="btn btn-info" style={{ background: '#2F2761', border: 'none', color: 'white' }}>
                                {/* <FaEye style={{ color: '#170F49' }} /> */}
                                view
                              </Link>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(filteredApplicants.length / itemsPerPage)} // Total pages based on filtered data
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName="active"
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                  />
                </div>
              </div>

            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Applicant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {applied ? (
            <div>
              <p><strong>Name:</strong> {applied.firstName} {applied.lastName}</p>
              <p><strong>Location:</strong> {applied.city}</p>

              <p><strong>createdAt: </strong>{moment(applied?.createdAt).format('MMMM Do YYYY')}</p>
              <p><strong>updatedAt: </strong> {moment(applied?.updatedAt).format('MMMM Do YYYY')}</p>
            </div>
          ) : (
            <p>Loading applicant details...</p>
          )}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllCandidates;