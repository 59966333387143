// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import img from "../assets/rb_802.jpg";
// import { apiCall } from "../utils/api";
// import AdminDashboard from '.././components/AdminDashboard';
// import { Container, Row} from 'react-bootstrap';
// import Header from '.././pages/admin/Header';

// const CreateProfile = () => {
//   const navigate = useNavigate();
//   // const id = sessionStorage.getItem("userId");

//   const [formData, setFormData] = useState({
//     firstName: "",
//     surName: "",
//     phoneNumber: "",
//     email: "",
//     department: "",
//     designation: "",
//     companyName: "",
//     cinNumber: "",
//     panNumber: "",
//     gstIn: "",
//     industryCategory: "",
//     industrySubCategory: "",
//     communicationAdress: "",
//     branch: "",
//     city: "",
//     state: "",
//     country: "",
//     pincode: "",
//     password: "",
//     role: "employer",
//     isDeleted: false,
//     createdAt: new Date().toISOString(),
//     updatedAt: new Date().toISOString(),
//     image: "",
//   });

//   const [imagePreview, setImagePreview] = useState(img);
//   const [uploadFile, setUploadFile] = useState(null);
//   const [errors, setErrors] = useState({});
//   const [showCompanyDetails, setShowCompanyDetails] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setImagePreview(URL.createObjectURL(file));
//       setUploadFile(file);
//     }
//   };

//   const validatePersonalDetails = () => {
//     const requiredFields = ["firstName", "surName", "phoneNumber", "email"];
//     const newErrors = {};

//     requiredFields.forEach((field) => {
//       if (!formData[field]) {
//         newErrors[field] = "This field is required";
//       }
//     });

//     if (
//       formData.phoneNumber &&
//       !/^\d{10}$/.test(formData.phoneNumber)
//     ) {
//       newErrors.phoneNumber = "Phone number must be 10 digits";
//     }
//     if (
//       formData.email &&
//       !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
//     ) {
//       newErrors.email = "Email is invalid";
//     }

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const validateCompanyDetails = () => {
//     const requiredFields = [
//       "companyName",
//       "gstIn",
//       "industryCategory",
//       "industrySubCategory",
//       "communicationAdress",
//     ];
//     const newErrors = {};

//     requiredFields.forEach((field) => {
//       if (!formData[field]) {
//         newErrors[field] = "This field is required";
//       }
//     });

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleNext = () => {
//     if (validatePersonalDetails()) {
//       setShowCompanyDetails(true);
//     }
//   };

//   const handlePrevious = () => {
//     setShowCompanyDetails(false);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateCompanyDetails()) {
//       return;
//     }

//     const id = sessionStorage.getItem("userId");
//     const userId = id;
//     const payload = {
//       userId,
//       ...formData,
//       image: uploadFile ? uploadFile.name : "",
//     };

//     try {
//       const response = await apiCall(`user/employerProfile/${id}`, "PATCH", payload);
//       if (response?.data) {
//         navigate("/employerProfileView");
//       }
//     } catch (error) {
//       console.error("Failed to create employer profile:", error);
//     }
//   };

//   return (

//         // <div className="col-md-7 p-4 d-flex align-items-center justify-content-center">
//         //   <form onSubmit={handleSubmit} className="w-100 " style={{height:'100vh'}}>
//         //     <div className="card p-4 w-100" style={{ objectFit: "cover" }}>
//         //       <h2 className="text-center mb-2" style={{ color: "#3498db" }}>
//         //         Employer Details
//         //       </h2>

//         //       {!showCompanyDetails && (
//         //         <div style={{height:'67vh'}}>
//         //           <h4 className="text mb-2" style={{ color: "#014C9" }}>
//         //             Personal Details
//         //           </h4>
//         //           <div className="row">
//         //             {[
//         //               { label: "First Name", name: "firstName", type: "text" },
//         //               { label: "SurName", name: "surName", type: "text" },
//         //               { label: "Phone Number", name: "phoneNumber", type: "text" },
//         //               { label: "Email", name: "email", type: "email" },
//         //               { label: "Designation", name: "designation", type: "text" },
//         //               { label: "Department", name: "department", type: "text" },
//         //             ].map((field, index) => (
//         //               <div className="col-md-6 mb-3" key={index}>
//         //                  {field.label}
//         //                 <input
//         //                   type={field.type}
//         //                   name={field.name}
//         //                   value={formData[field.name]}
//         //                   onChange={handleInputChange}
//         //                   className={`form-control ${
//         //                     errors[field.name] ? "is-invalid" : ""
//         //                   }`}
//         //                   // placeholder={` Enter your ${field.label} `}
//         //                 />
//         //                 {errors[field.name] && (
//         //                   <div className="invalid-feedback">
//         //                     {errors[field.name]}
//         //                   </div>
//         //                 )}
//         //               </div>
//         //             ))}
//         //           </div>
//         //           <div className="text-center">
//         //             <button
//         //               type="button"
//         //               className="default-btn"
//         //               onClick={handleNext}
//         //             >
//         //               Next
//         //             </button>
//         //           </div>
//         //         </div>
//         //       )}

//         //       {showCompanyDetails && (
//         //         <div style={{}}>
//         //           <h4 className="text mb-3" style={{ color: "#014C9" }}>
//         //             Company Details
//         //           </h4>
//         //           <div className="row">
//         //             {[
//         //               { label: "Company Name", name: "companyName", type: "text" },
//         //               { label: "CIN Number", name: "cinNumber", type: "text" },
//         //               { label: "PAN Number", name: "panNumber", type: "text" },
//         //               { label: "GSTIN", name: "gstIn", type: "text" },
//         //               { label: "Industry Category", name: "industryCategory", type: "text" },
//         //               { label: "Industry Sub Category", name: "industrySubCategory", type: "text" },
//         //               { label: "Communication Address", name: "communicationAdress", type: "text" },
//         //               { label: "Pin Code", name: "pincode", type: "text" },
//         //               { label: "City", name: "city", type: "text" },
//         //               { label: "State", name: "state", type: "text" },
//         //             ].map((field, index) => (
//         //               <div className="col-md-6 mb-3" key={index}>
//         //                  {field.label}
//         //                 <input
//         //                   type={field.type}
//         //                   name={field.name}
//         //                   value={formData[field.name]}
//         //                   onChange={handleInputChange}
//         //                   className={`form-control ${
//         //                     errors[field.name] ? "is-invalid" : ""
//         //                   }`}
//         //                   // placeholder={`Enter your ${field.label} `}
//         //                 />
//         //                 {errors[field.name] && (
//         //                   <div className="invalid-feedback">
//         //                     {errors[field.name]}
//         //                   </div>
//         //                 )}
//         //               </div>
//         //             ))}
//         //           </div>
//         //           <div className="d-flex justify-content-between mt-0">
//         //             <button
//         //               type="button"
//         //               className="default-btn"
//         //               onClick={handlePrevious}
//         //             >
//         //               Previous
//         //             </button>
//         //             <button type="submit" className="default-btn">
//         //               Submit
//         //             </button>
//         //           </div>
//         //         </div>
//         //       )}
//         //     </div>
//         //   </form>
//         // </div>

//           <div className="dashboard">
//                 <Header />
//               {/* < EmployerDashboardHeader /> */}
//               {/* <Header /> */}
            
//               <Container fluid className="content">
//                 <Row className='g-4'>
//                   <AdminDashboard />

//         <div className="col-md-7 p-4 d-flex  align-items-center justify-content-center" style={{margin:"50px auto"}}>
//   <form onSubmit={handleSubmit} className="w-100" style={{ height: '100vh' }}>
//     <div className="card p-4 w-100" style={{ objectFit: "cover" }}>
//       <h2 className="text-center mb-3" style={{ color: "#3498db", fontWeight: 'bold' }}>
//         Employer Details
//       </h2>

//       {!showCompanyDetails && (
//         <div style={{ height: '67vh' }}>
//           <h4 className="text mb-3" style={{ color: "#014C9", fontWeight: '500' }}>
//             Personal Details
//           </h4>
//           <div className="row">
//             {[
//               { label: "First Name", name: "firstName", type: "text" },
//               { label: "SurName", name: "surName", type: "text" },
//               { label: "Phone Number", name: "phoneNumber", type: "text" },
//               { label: "Email", name: "email", type: "email" },
//               { label: "Designation", name: "designation", type: "text" },
//               { label: "Department", name: "department", type: "text" },
//             ].map((field, index) => (
//               <div className="col-md-6 mb-3" key={index}>
//                 <label htmlFor={field.name} className="form-label">{field.label}</label>
//                 <input
//                   id={field.name}
//                   type={field.type}
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleInputChange}
//                   className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
//                   aria-label={`Enter your ${field.label}`}
//                 />
//                 {errors[field.name] && (
//                   <div className="invalid-feedback">{errors[field.name]}</div>
//                 )}
//               </div>
//             ))}
//           </div>
//           <div className="text-center">
//             <button
//               type="button"
//               className="default-btn"
//               onClick={handleNext}
//               style={{ backgroundColor: "#3498db", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       )}

//       {showCompanyDetails && (
//         <div style={{}}>
//           <h4 className="text mb-3" style={{ color: "#014C9", fontWeight: '500' }}>
//             Company Details
//           </h4>
//           <div className="row">
//             {[
//               { label: "Company Name", name: "companyName", type: "text" },
//               { label: "CIN Number", name: "cinNumber", type: "text" },
//               { label: "PAN Number", name: "panNumber", type: "text" },
//               { label: "GSTIN", name: "gstIn", type: "text" },
//               { label: "Industry Category", name: "industryCategory", type: "text" },
//               { label: "Industry Sub Category", name: "industrySubCategory", type: "text" },
//               { label: "Communication Address", name: "communicationAdress", type: "text" },
//               { label: "Pin Code", name: "pincode", type: "text" },
//               { label: "City", name: "city", type: "text" },
//               { label: "State", name: "state", type: "text" },
//             ].map((field, index) => (
//               <div className="col-md-6 mb-3" key={index}>
//                 <label htmlFor={field.name} className="form-label">{field.label}</label>
//                 <input
//                   id={field.name}
//                   type={field.type}
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleInputChange}
//                   className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
//                   aria-label={`Enter your ${field.label}`}
//                 />
//                 {errors[field.name] && (
//                   <div className="invalid-feedback">{errors[field.name]}</div>
//                 )}
//               </div>
//             ))}
//           </div>
//           <div className="d-flex justify-content-between mt-3">
//             <button
//               type="button"
//               className="default-btn"
//               onClick={handlePrevious}
//               style={{ backgroundColor: "#e74c3c", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
//             >
//               Previous
//             </button>
//             <button
//               type="submit"
//               className="default-btn"
//               style={{ backgroundColor: "#3498db", color: "#fff", borderRadius: '5px', padding: '10px 20px' }}
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   </form>
// </div>
//   </Row>
//        </Container>
//      </div>

//   );
// };

// export default CreateProfile;


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Form,
  Spinner,
  Button,
  InputGroup,
  Image,
  Modal,
  Card,
} from "react-bootstrap";
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import { toast } from "react-hot-toast";
import profile from "../../assets/profieimg.png";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";


const EnrollmentCreate = () => {
  const navigate = useNavigate();
  const Auth = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const [categoryName, setCategoryName] = useState(""); // New state
  const location = useLocation();
  const applicant = location.state;
  console.log("applicant",applicant)


  useEffect(() => {
    if (!Auth) {
      navigate("/");
    }
  }, [Auth, navigate]);

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [employerPackageData, setEmployerPackageData] = useState([]);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    industryCategory: "",
    industryCategoryId: "",
    industrySubCategory: "",
    industrySubCategoryId: "",
    branch: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: [],
    qualification: [],
    responsibilities: [],
    dob: "",
    resume: "",
  });

  useEffect(() => {
    sessionStorage.setItem("userName", formData.userName);
  }, [formData.userName]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    

    fetchProfile();
  }, [token, apiUrl]);
  
  const fetchProfile = async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("userId");

      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data && response.data.data) {
        setUserDetails(response.data.data);
        // setFormData(response.data.data);

        console.log("categ", response.data.data);
      } else {
        setError(response.data.message || "Failed to fetch profile.");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
      setError("An error occurred while fetching the profile.");
    } finally {
      setLoading(false);
    }
  };

  // console.log("setforms", formData);
  // useEffect(() => {
  //   const token = sessionStorage.getItem("accessToken");
  //   if (token) {
  //     setIsLoggedIn(true);
  //   }
  //   const fetchProfile = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${apiUrl}/subscription/getEmployerPlan/${userId}`,
  //         {
  //           headers: {
  //             Authorization: `${token}`,
  //           },
  //         }
  //       );
  //       console.log(response);
  //       if (response.status) {
  //         console.log("Response Status:", response.status);
  //         if (response.data && response.data.data) {
  //           const userData = response.data.data;
  //           setEmployerPackageData(userData);
  //           console.log(userData);
  //         } else {
  //           console.error("Invalid data format in response.");
  //         }
  //       } else {
  //         console.error(`Unexpected status code: ${response.status}`);
  //       }
  //     } catch (err) {
  //       //  handleOpen()

  //       if (err.response) {
  //         const { status } = err.response;
  //         if (status === 400) {
  //           // toast.error("Bad Request: Please check the input data.");
  //           // alert(status?.data?.errorMsg)
  //           // toast.error(err?.response?.data?.errorMsg)
  //         } else if (status === 401) {
  //           toast.error(
  //             "Unauthorized: Please check your authentication token."
  //           );
  //         } else if (status === 404) {
  //           toast.error("Not Found: Unable to find the requested resource.");
  //         } else {
  //           toast.error(
  //             `Error ${status}:`,
  //             err.response.data.message || err.message
  //           );
  //         }
  //       } else {
  //         toast.error("Network error or server not reachable:", err.message);
  //       }
  //     }
  //   };

  //   fetchProfile();
  // }, []);

  const [industries, setIndustries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/category`, {
          headers: {
            Authorization: ` ${token}`,
          },
        });
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchAllSubCategories();
    fetchCategories();
  }, []);

    useEffect(() => {
      console.log("applicant-initial",applicant);

      if (applicant) {
        // win.clear();
        const formattedSubmissionDate = applicant?.submissionDate
          ? new Date(applicant?.submissionDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
          : '';
        setFormData({
          ...applicant,
          jobLocation: applicant?.location,
          submissionDate: formattedSubmissionDate,
          expiryDate: applicant?.expiryDate
            ? new Date(applicant?.expiryDate).toISOString().split('T')[0] // Converts to YYYY-MM-DD
            : '',
          qualifications: applicant?.qualificationRequirements,
          responsibilities: applicant?.responsibilitiesAndDuties,
          city:applicant?.city,
          // jobId: undefined,
        });
        console.log("applicant",applicant);
        
console.log("form",formData);

  
      }
      else {    // Load form data from localStorage when the component mounts
        const savedFormData = {
          jobTitle: win.getItem('jobTitle') || '',
          totalExperience: win.getItem('totalExperience') || null,
          jobLocation: win.getItem('jobLocation') || '',
          salary: win.getItem('salary') || '',
          companyName: win.getItem('companyName') || '',
          category_id: win.getItem('category_id') || '',
          industryCategory: win.getItem('industryCategory') || '',
          subcategory_id: win.getItem('subcategory_id') || '674af135e938a95a587969e8',
          jobSubCategory_id: win.getItem('jobSubCategory_id') || '679e532394c108bb56f28704',
          industryId: win.getItem('industryId') || '674b02b57a85d68f70dce160',
          qualifications: win.getItem('qualifications') || '',
          responsibilities: JSON.parse(win.getItem('responsibilities')) || [],
          skills: JSON.parse(win.getItem('skills')) || [],
          hoursPerWeek: win.getItem('hoursPerWeek') || '',
          jobPosition: win.getItem('jobPosition') || '',
          miscDetails: win.getItem('miscDetails') || '',
          termsAndConditions: win.getItem('termsAndConditions') || '',
          specialRequirements: win.getItem('specialRequirements') || '',
          noOfPosition: win.getItem('noOfPosition') || 1,
          submissionDate: win.getItem('submissionDate') || '',
          expiryDate: win.getItem('expiryDate') || '',
          benefitsDetails: win.getItem('benefitsDetails') || '',
          shift: win.getItem('shift') || '',
          workWeek: win.getItem('workWeek') || 'y',
        }
        setFormData("");
  
      };
    }, []); // Empty dependency array, runs once when the component mounts
  

  const fetchAllSubCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sub-category`, {
        headers: { Authorization: ` ${token}` },
      });
      setSubcategories(response.data.data);
      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/sub-category/category/${categoryId}`,
        {
          headers: { Authorization: ` ${token}` },
        }
      );
      // setSubcategories(response.data.data)
      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleCategoryChange = async (e) => {
    const selectedCategoryId = e.target.value;

    // Find the selected category object based on the category_id
    const selectedCategory = categories.find(
      (category) => category.category_id === selectedCategoryId
    );

    // Update formData with selected category and reset subcategory
    setFormData((prev) => ({
      ...prev,
      // Set the full category object (industryCategory) with category_id and categoryName
      industryCategory: selectedCategory || {
        category_id: null,
        categoryName: "",
      },
      // Set just the category_id (industryCategoryId)
      industryCategoryId: selectedCategoryId
        ? selectedCategory.category_id
        : "",
      // Reset subcategory to an empty object (industrySubCategory) with subcategory_id and subcategoryName
      industrySubCategory: { subcategory_id: null, subcategoryName: "" },
      // Reset subcategory ID (industrySubCategoryId) to null
      industrySubCategoryId: null,
    }));

    // Store the selected category in localStorage
    // localStorage.setItem("industryCategory", selectedCategory ? selectedCategory.categoryName : "");
    // localStorage.setItem("industryCategoryId", selectedCategory ? selectedCategory.category_id : "");

    // Fetch subcategories if a category is selected
    if (selectedCategoryId) {
      try {
        const fetchedSubcategories = await fetchSubcategories(
          selectedCategoryId
        );
        setSubcategories(fetchedSubcategories);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setSubcategories([]);
      }
    } else {
      setSubcategories([]); // Reset subcategories if no category is selected
    }
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategoryId = e.target.value;
    const selectedValue = e.target.value; // Declare selectedValue

    // Find the selected subcategory object based on subcategory_id
    const selectedSubcategory = subcategories.find(
      (subcategory) => subcategory.subcategory_id === selectedSubcategoryId
    );

    // Update the form data with the full subcategory object (subcategory_id and subcategoryName)
    setFormData((prev) => ({
      ...prev,
      industrySubCategory:
        selectedSubcategory || //   subcategory_id: selectedSubcategory.subcategory_id, // ? {
        //   subcategoryName: selectedSubcategory.subcategoryName,
        // }
        { subcategory_id: null, subcategoryName: "" }, // If no subcategory selected, reset to empty object

      industrySubCategoryId: selectedSubcategoryId
        ? selectedSubcategory.subcategory_id
        : "",
    }));

    setFormData((prev) => ({
      ...prev,
      industrySubCategoryId: selectedSubcategory.subcategory_id ? selectedSubcategory.subcategory_id
        // ? {
        //   // subcategory_id: selectedSubcategory.subcategory_id,
        //   subcategoryName: selectedSubcategory.subcategoryName,
        // }
        : { subcategory_id: null }, // If no subcategory selected, reset to empty object
    }));

    setFormData((prev) => ({
      ...prev,
      industrySubCategory: selectedSubcategory
        ? {
            subcategory_id: selectedSubcategory.subcategory_id,
            subcategoryName: selectedSubcategory.subcategoryName,
          }
        : { subcategory_id: null, subcategoryName: "" }, // If no subcategory selected, reset to empty object
      industrySubCategoryId: selectedSubcategory ? selectedSubcategory.subcategory_id : null, // Store only the subcategory ID
    }));
    localStorage.setItem('industrySubCategory', selectedValue);
    // Optionally store in localStorage (if needed)
    localStorage.setItem(
      "industrySubCategory",
      selectedSubcategory ? JSON.stringify(selectedSubcategory) : JSON.stringify({ subcategory_id: null, subcategoryName: "" })
    );
  };

  // useEffect(() => {
  //   // On component mount, check if a value is stored in localStorage
  //   const storedSubCategory = localStorage.getItem("industryCategory");
  //   if (storedSubCategory) {
  //     // Set formData.industrySubCategory with the stored value
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       industrySubCategory: {
  //         subcategory_id: storedSubCategory,
  //       },
  //     }));
  //   }
  // }, []); // Runs once on component mount

  // useEffect(() => {
  //   const savedCategory = localStorage.getItem("industryCategory");
  //   const savedSubcategory = localStorage.getItem("industrySubCategory");
  //   const savedCategoryId = localStorage.getItem("industryCategoryId");
  //   const savedSubcategoryId = localStorage.getItem("industrySubCategoryId");

  //   if (
  //     savedCategory &&
  //     savedSubcategory &&
  //     savedCategoryId &&
  //     savedSubcategoryId
  //   ) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       industryCategory: {
  //         category_id: savedCategoryId,
  //         categoryName: savedCategory,
  //       },
  //       industrySubCategory: {
  //         subcategory_id: savedSubcategoryId,
  //         subcategoryName: savedSubcategory,
  //       },
  //     }));
  //   }
  // }, []);

  // useEffect(() => {
  //   const savedCategory = localStorage.getItem("industryCategory");
  //   const savedSubcategory = localStorage.getItem("industrySubCategory");

  //   if (savedCategory && savedSubcategory) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       industryCategory: { categoryName: savedCategory },
  //       industrySubCategory: { subcategoryName: savedSubcategory },
  //     }));
  //   }
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
  
      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPEG, PNG, JPG, WEBP) are allowed.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const win = window.localStorage;
  useEffect(() => {
    const cat_id = win.getItem("category_id");

    fetchSubcategories(cat_id);
  }, []);

    const handleImageClick = () => {
      document.getElementById("imageInput").click();
    };

    const handleImageRemove = async () => {
      setFormData((prevData) => ({ ...prevData, image: null })); // Update state
    
      try {
        const payload = {
          ...formData,
          userName:formData.userName,
          image: null, // Explicitly set image to null
          industryCategory: formData.industryCategory?.categoryName,
          industryCategoryId: formData.industryCategory?.category_id,
          industrySubCategory: formData.industrySubCategory?.subcategoryName,
          industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
        };
    
        console.log("Final Payload After Image Removal:", payload); // Debugging
    
        const response = await axios.patch(`${apiUrl}/user/enrollment/${userId}`, payload, {
          headers: { Authorization: `${token}` },
        });
    
        toast.success("Profile image removed successfully.");
        fetchProfile(); // Refresh profile after removal
      } catch (error) {
        console.error("Error removing image:", error);
        toast.error("Failed to remove image.");
      }
    };
    

  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const mandatoryFields = [
    "userName",
    "email",
    "phoneNumber",
    "firstName",
    "lastName",
    "pincode",
    "city",
    "companyName"
  ];

  const fieldsToRender = [
    { label: "User Name", key: "userName", mandatory: true },
    { label: "First Name", key: "firstName", mandatory: true },
    { label: "Last Name", key: "lastName", mandatory: true },
    { label: "Mobile Number", key: "phoneNumber", mandatory: true },
    { label: "Email", key: "email", mandatory: true },
    // { label: "Role", key: "role" },
    { label: "Designation", key: "designation" },
    { label: "Department", key: "department" },
    { label: "Branch", key: "branch" },
    { label: "Company Name", key: "companyName" , mandatory:true },
    { label: "CIN Number", key: "cinNumber" },
    { label: "GST In", key: "gstIn" },
    { label: "PAN Number", key: "panNumber" },
    // { label: "Industry Category", key: "industryCategory" },s
    { label: "Company Address", key: "communicationAdress" },
    { label: "City", key: "city", mandatory: true },
    { label: "State", key: "state" },
    { label: "Pincode", key: "pincode", mandatory: true },
    // { label: "Landmark", key: "landmark" },
    // { label: "Country", key: "country"},
    // { label: "DOB", key: "dob" },
    // { label: "Aadhar No", key: "aadharNo"},
    // { label: "Voter ID", key: "voterId" },
    // { label: "Driving Licence No", key: "drivingLicenceNumber" },
    // { label: "Profile Title", key: "profileTitle" },
    // { label: "Total Experience", key: "totalExperience"},
    // { label: "Current Salary", key: "salary"},
    // { label: "Expected Salary", key: "expectedSalary" },
    // { label: "Misc Details", key: "miscDetails"},
  ];

  const handleSaveClick = async () => {
    const token = sessionStorage.getItem("token");
  
    if (!formData.industryCategory || !formData.industrySubCategory) {
      toast.error("Please select both an industry category and subcategory.");
      return;
    }
  
    // Prepare the payload
    const payload = {
      ...formData,
      industryCategory: formData.industryCategory?.categoryName,
      industryCategoryId: formData.industryCategory?.category_id,
      industrySubCategory: formData.industrySubCategory?.subcategoryName,
      industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      createdBy: userId, // Pass userId as createdBy
    };
  
    console.log("Final Payload:", payload);
  
    try {
      const response = await axios.post(`${apiUrl}/callcenter/createemployer`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      toast.success("Employer created successfully!");
      navigate('applyJobAllJobSeeker')
  
      // ✅ Clear localStorage after saving
      localStorage.removeItem("industryCategory");
      localStorage.removeItem("industrySubCategory");
      localStorage.removeItem("industryCategoryId");
      localStorage.removeItem("industrySubCategoryId");
  
      console.log("API Response:", response.data);
  
      setShowSuccessModal(true); // Show success modal
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        toast.error(`Failed to create employer. ${error.response.data.message || error.response.statusText}`);
      } else {
        console.error("Error:", error);
        toast.error("Failed to create employer.");
      }
    }
  };

  const handleUpdateClick = async () => {
    const token = sessionStorage.getItem("accessToken");
  
    if (!applicant || !applicant.userId) {
      toast.error("Employer ID is missing. Cannot update.");
      return;
    }
  
    // Prepare the payload
    const payload = {
      ...formData,
      userName:formData.userName,
      industryCategory: formData.industryCategory?.categoryName,
      industryCategoryId: formData.industryCategory?.category_id,
      industrySubCategory: formData.industrySubCategory?.subcategoryName,
      industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
    };
  
    console.log("Final Update Payload:", payload);
  
    try {
      const response = await axios.patch(`${apiUrl}/callcenter/editenrollment/${applicant.userId}`, payload, {
        headers: { Authorization: `${token}` },
      });
  
      toast.success("Enrollment Employer updated successfully!");
  
      console.log("API Response:", response.data);
      navigate('/enrollment')
      setShowSuccessModal(true); // Show success modal
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        toast.error(`Failed to update employer. ${error.response.data.message || error.response.statusText}`);
      } else {
        console.error("Error:", error);
        toast.error("Failed to update employer.");
      }
    }
  };
  

  console.log("formData before sending:", formData);

  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <Col className="">
          <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-content">
            <Card className="rounded-2 p-4">
              <Card.Body>
                <h3
                  className="text-center"
                  style={{
                    color: "white",
                    color: "#170F49",
                    marginRight: "80px",

                  }}
                >
                  Profile Details
                </h3>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <div className="text-center mb-4" style={{position:'relative'}}>
                  <div
                    className="profile-image-wrapper mb-3"
                    onClick={handleImageClick}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "80px",
                      marginBottom: "10px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={formData.image || profile}
                      alt="Profile Picture"
                      roundedCircle
                      className="shadow-lg"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        // background:'#000',
                        border: "4px solid #ECF6FF",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                    
                  </div>
                  {formData.image && (
        <h4
          onClick={handleImageRemove}
          style={{
            position: "absolute",
            top: "120px",
            left: "53%",
            color: "red",
            cursor: "pointer",
            transform: "translateX(-50%)",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          <MdDelete />
        </h4>
      )}
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>

            

                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header
                        style={{
                          backgroundColor: "rgb(47 39 97)",
                          color: "white",
                          padding: "15px",
                        }}
                      >
                        Employer Details
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender
                            .slice(0, 9)
                            .map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label}{" "}
                                    {mandatory && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </Form.Label>

                                  <Form.Control
                                    type={
                                      key === "dob"
                                        ? "date"
                                        : key === "phoneNumber"
                                          ? "tel"
                                          : key === "email"
                                            ? "email"
                                            : "text"
                                    }
                                    name={key}
                                    value={formData[key]}
                                    onChange={(e) => {
                                      let value = e.target.value;

                                      // Phone Number Validation
                                      if (key === "phoneNumber") {
                                        value = value.replace(/\D/g, ""); // Remove non-numeric characters
                                        if (value.length > 10) return; // Prevent entering more than 10 digits
                                      }

                                      handleChange({ target: { name: key, value } }); // Update state
                                    }}
                                    required={mandatory}
                                    className="rounded-3"
                                    maxLength={key === "phoneNumber" ? 10 : undefined}
                                    pattern={
                                      key === "phoneNumber"
                                        ? "[0-9]{10}"
                                        : key === "email"
                                          ? "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
                                          : undefined
                                    }
                                    // inputMode={key === "phoneNumber" ? "numeric" : undefined}
                                    inputMode={key === "phoneNumber" && formData.phoneNumber ? "numeric" : "text"}
                                  />

                                  {/* Phone Number Error Message */}
                                  {key === "phoneNumber" &&
                                    formData?.phoneNumber?.length > 0 &&
                                    formData?.phoneNumber?.length < 10 && (
                                      <p style={{ color: "red", fontSize: "12px" }}>
                                        Phone number must be exactly 10 digits
                                      </p>
                                    )}

                                  {/* Email Validation Error */}
                                  {key === "email" &&
                                    formData.email &&
                                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                      formData.email
                                    ) && (
                                      <p style={{ color: "red", fontSize: "12px" }}>
                                        Enter a valid email address
                                      </p>
                                    )}
                                </Form.Group>
                              </Col>
                            ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="mb-4">
                      <Card.Header
                        style={{
                          backgroundColor: "rgb(47 39 97)",
                          color: "white",
                          padding: "15px",
                        }}
                      >
                        Company Details
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          {fieldsToRender
                            .slice(10)
                            .map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label}{" "}
                                    {mandatory && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </Form.Label>

                                  {label === "Company Address" ? (
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      name={key}
                                      value={formData[key]}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className="rounded-3"
                                      maxLength={350} //  this line to limit characters to 350
                                    />
                                  ) : (
                                    <Form.Control
                                      type={
                                        label === "Pincode" ? "number" : "text"
                                      }
                                      name={key}
                                      value={formData[key]}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className="rounded-3"
                                    />
                                  )}
                                </Form.Group>
                              </Col>
                            ))}

                          <Col>
                            <Form.Group>
                              <Form.Label>Industry Category</Form.Label>
                              <Form.Control
                                as="select"
                                name="industryCategory"
                                value={formData.industryCategoryId || ""} // This will show the saved value
                                onChange={handleCategoryChange}
                              >
                                <option value="">Select an Industry</option>
                                {categories.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category_id}
                                  >
                                    {category.categoryName}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label>Industry Sub Category</Form.Label>
                              <Form.Control
                                as="select"
                                name="industrySubCategory"
                                value={formData.industrySubCategoryId || ""}
                                onChange={handleSubcategoryChange}
                              >
                                <option value="">
                                  Select a Industry Subcategory
                                </option>
                                {subcategories.length > 0 ? (
                                  subcategories.map((subcategory) => (
                                    <option
                                      key={subcategory.subcategory_id}
                                      value={subcategory.subcategory_id}
                                    >
                                      {subcategory.subcategoryName}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">
                                    No subcategories available
                                  </option>
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button
                  onClick={() =>
                    applicant ? handleUpdateClick() : handleSaveClick()
                  }                
                    className="default-btn px-5 py-2 "
                    disabled={mandatoryFields.some((field) => !formData[field])}
                    style={{ cursor: "pointer" }}
                  >
                   {applicant !== null && applicant !== undefined ? "Update" : "Save"}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    {!applicant ?( <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Created</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully Created!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>):''}




    </div>
  );
};

export default EnrollmentCreate;
