import React, {useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Form,
  Spinner,
  Button,
  Image,
  Modal,
  Card,
} from "react-bootstrap";
import EmployerDashboardSideBar from "../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../components/EmployerDashboardHeader";
import { toast } from "react-hot-toast";
import profile from "../assets/profieimg.png";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import '../Styles/Homepage.css'
import { Autocomplete } from "@mui/material";
import { City, State } from "country-state-city";

const EmployerProfileView = () => {
  const navigate = useNavigate();
  const Auth = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("userId");
  const [page, setPage] = useState(1);


  useEffect(() => {
    if (!Auth) {
      navigate("/");
    }
  }, [Auth, navigate]);

  const [, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setIsLoggedIn] = useState(false);
  const [employerPackageData, setEmployerPackageData] = useState([]);
  const [industryCategories, setIndustryCategories] = useState([]); // List of industry categories
  const [newIndustryCategory, setNewIndustryCategory] = useState(""); // Input for new category
  const [showOtherIndustryInput, setShowOtherIndustryInput] = useState(false); // Toggle input field
  const [isAddingIndustry, setIsAddingIndustry] = useState(false); // Disable button while adding
  const [errors, setErrors] = useState({}); // Validation errors


  const [industrySubcategories, setIndustrySubcategories] = useState([]); // List of industry subcategories
  const [newIndustrySubcategory, setNewIndustrySubcategory] = useState(""); // Input for new subcategory
  const [showOtherSubcategoryInput, setShowOtherSubcategoryInput] = useState(false); // Toggle input field
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false); // Disable button while adding

  //extra adding in state city dropdown

  const [stateOptions, setStateOptions] = useState([]); // 🔹 State List
  const [cityOptions, setCityOptions] = useState([]);   // 🔹 City List


  const fetchStates = (inputValue = "", countryCode = "IN") => {
    const states = State.getStatesOfCountry(countryCode);
    if (states) {
      const filteredStates = states
        .map((state) => state.name)
        .filter((state) => state.toLowerCase().includes(inputValue.toLowerCase()));

      setStateOptions(filteredStates);
    }
  };

  // Function to fetch cities dynamically
  const fetchCities = (stateName = "", countryCode = "IN") => {
    const stateObj = State.getStatesOfCountry(countryCode).find(
      (state) => state.name === stateName
    );
    if (stateObj) {
      const cities = City.getCitiesOfState(countryCode, stateObj.isoCode);
      if (cities) {
        const cityNames = cities.map((city) => city.name);
        setCityOptions(cityNames);
        // setNativeLocationOptions(cityNames); // Native locations same as city
      }
    }
  }

  useEffect(() => {
    fetchCities();
    // fetchAllCities();
    fetchStates();
  }, []);


  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    role: "",
    city: "",
    state: "",
    firstName: "",
    lastName: "",
    surName: "",
    designation: "",
    department: "",
    cinNumber: "",
    gstIn: "",
    panNumber: "",
    industryCategory: "",
    industryCategoryId: "",
    industrySubCategory: "",
    industrySubCategoryId: "",
    branch: "",
    landmark: "",
    pincode: "",
    communicationAdress: "",
    country: "",
    image: "",
    otpCode: "",
    otpExpiryTime: "",
    emailAddress: "",
    address: "",
    pinCode: "",
    aadharNo: "",
    panNo: "",
    voterId: "",
    drivingLicenceNumber: "",
    profileTitle: "",
    totalExperience: "",
    salary: "",
    expectedSalary: "",
    miscDetails: "",
    skills: [],
    qualification: [],
    responsibilities: [],
    dob: "",
    resume: "",
  });

  useEffect(() => {
    sessionStorage.setItem("userName", formData.userName);
  }, [formData.userName]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const token = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_BASE_URL;



  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("userId");

      const response = await axios.get(`${apiUrl}/user/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data && response.data.data) {
        setUserDetails(response.data.data);
        setFormData(response.data.data);

        console.log("categ", response.data.data);
      } else {
        setError(response.data.message || "Failed to fetch profile.");
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
      setError("An error occurred while fetching the profile.");
    } finally {
      setLoading(false);
    }
  },[apiUrl, token]);

  useEffect(() => {


    fetchProfile();
  }, [fetchProfile]);


  console.log("setforms", formData);
  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setIsLoggedIn(true);
    }
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/subscription/getEmployerPlan/${userId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        console.log("response plan:", response);
        if (response.status) {
          console.log("Response Status:", response.status);
          if (response.data && response.data.data) {
            const userData = response.data.data;
            setEmployerPackageData(userData);
            console.log(userData);
          } else {
            console.error("Invalid data format in response.");
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (err) {
        //  handleOpen()

        if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            // toast.error("Bad Request: Please check the input data.");
            // alert(status?.data?.errorMsg)
            // toast.error(err?.response?.data?.errorMsg)
          } else if (status === 401) {
            toast.error(
              "Unauthorized: Please check your authentication token."
            );
          } else if (status === 404) {
            toast.error("Not Found: Unable to find the requested resource.");
          } else {
            toast.error(
              `Error ${status}:`,
              err.response.data.message || err.message
            );
          }
        } else {
          toast.error("Network error or server not reachable:", err.message);
        }
      }
    };

    fetchProfile();
  }, [apiUrl, userId]);

  const [, setCategories] = useState([]);
  const [, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/category`, {
          headers: {
            Authorization: ` ${token}`,
          },
        });
        setCategories(response.data.data);
        setIndustryCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchAllSubCategories();
    fetchCategories();
  }, [apiUrl]);

  const fetchAllSubCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sub-category`, {
        headers: { Authorization: ` ${token}` },
      });
      setSubcategories(response.data.data);
      setIndustrySubcategories(response.data.data);

      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/sub-category/category/${categoryId}`,
        {
          headers: { Authorization: ` ${token}` },
        }
      );
      // setSubcategories(response.data.data)
      return response.data.data; // Assuming response contains subcategory list
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return [];
    }
  };

  const handleIndustryCategoryChange = async (e) => {
    const { value } = e.target;

    if (value === "Others") {
      setShowOtherIndustryInput(true);
      setNewIndustryCategory(""); // Reset input field
      return;
    } else {
      setShowOtherIndustryInput(false);
    }

    // Find the selected industry category object
    const selectedIndustry = industryCategories.find(
      (category) => category.category_id === value
    );

    if (selectedIndustry) {
      setFormData((prevData) => ({
        ...prevData,
        industryCategoryId: value,
        industryCategoryName: selectedIndustry.categoryName,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        industryCategoryId: "",
        industryCategoryName: "",
      }));
    }

    // Fetch subcategories if a category is selected
    if (selectedIndustry) {
      try {
        const fetchedSubcategories = await fetchSubcategories(
          selectedIndustry.category_id
        );
        setIndustrySubcategories(fetchedSubcategories);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setIndustrySubcategories([]);
      }
    } else {
      setIndustrySubcategories([]); // Reset subcategories if no category is selected
    }
  };

  const handleAddIndustryCategory = async () => {
    if (!newIndustryCategory.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industryCategoryId: "Enter a valid industry category",
      }));
      return;
    }

    setIsAddingIndustry(true);

    try {
      const response = await axios.post(`${apiUrl}/category`, {
        categoryName: newIndustryCategory,
        categoryCode: newIndustryCategory,
        categoryImage: ""
      });

      if (response.status) {
        const newCategory = response.data?.data;
        setIndustryCategories((prevCategories) => [...prevCategories, newCategory]);

        // Automatically select the newly added category
        setFormData((prevData) => ({
          ...prevData,
          industryCategoryId: newCategory?.category_id,
          industryCategoryName: newCategory?.categoryName,
        }));

        setShowOtherIndustryInput(false);
        setNewIndustryCategory("");
      }
    } catch (error) {
      console.error("Error adding industry category:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        industryCategoryId: "Failed to add category. Try again.",
      }));
    } finally {
      setIsAddingIndustry(false);
    }
  };


  const handleIndustrySubcategoryChange = (e) => {
    const { value } = e.target;

    if (value === "Others") {
      setShowOtherSubcategoryInput(true);
      setNewIndustrySubcategory(""); // Reset input field
      return;
    } else {
      setShowOtherSubcategoryInput(false);
    }

    // Find the selected subcategory object
    const selectedSubcategory = industrySubcategories.find(
      (subcategory) => subcategory.subcategory_id === value
    );

    if (selectedSubcategory) {
      setFormData((prevData) => ({
        ...prevData,
        industrySubCategoryId: value,
        industrySubCategoryName: selectedSubcategory.subcategoryName,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        industrySubCategoryId: "",
        industrySubCategoryName: "",
      }));
    }
  };

  const handleAddIndustrySubcategory = async () => {
    if (!newIndustrySubcategory.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industrySubCategoryId: "Enter a valid industry subcategory",
      }));
      return;
    }

    setIsAddingSubcategory(true);

    try {
      const response = await axios.post(`${apiUrl}/sub-category`, {
        subcategoryName: newIndustrySubcategory,
        category_id: formData.industryCategoryId, // Link to the selected Industry Category
        // category_id: formData.category_id, // Ensure it is linked to the selected category
        subcategoryImage: ''
      });

      if (response.status) {
        const newSubcategory = response.data?.data;
        setIndustrySubcategories((prevSubcategories) => [...prevSubcategories, newSubcategory]);

        // Automatically select the newly added subcategory
        setFormData((prevData) => ({
          ...prevData,
          industrySubCategoryId: newSubcategory?.subcategory_id,
          industrySubCategoryName: newSubcategory?.subcategoryName,
        }));

        setShowOtherSubcategoryInput(false);
        setNewIndustrySubcategory("");
      }
    } catch (error) {
      console.error("Error adding industry subcategory:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        industrySubCategoryId: "Failed to add subcategory. Try again.",
      }));
    } finally {
      setIsAddingSubcategory(false);
    }
  };
  console.log("industrySubcategories", industrySubcategories)

  useEffect(() => {
    // On component mount, check if a value is stored in localStorage
    const storedSubCategory = localStorage.getItem("industryCategory");
    if (storedSubCategory) {
      // Set formData.industrySubCategory with the stored value
      setFormData((prevData) => ({
        ...prevData,
        industrySubCategory: {
          subcategory_id: storedSubCategory,
        },
      }));
    }
  }, []); // Runs once on component mount

  useEffect(() => {
    const savedCategory = localStorage.getItem("industryCategory");
    const savedSubcategory = localStorage.getItem("industrySubCategory");
    const savedCategoryId = localStorage.getItem("industryCategoryId");
    const savedSubcategoryId = localStorage.getItem("industrySubCategoryId");

    if (
      savedCategory &&
      savedSubcategory &&
      savedCategoryId &&
      savedSubcategoryId
    ) {
      setFormData((prev) => ({
        ...prev,
        industryCategory: {
          category_id: savedCategoryId,
          categoryName: savedCategory,
        },
        industrySubCategory: {
          subcategory_id: savedSubcategoryId,
          subcategoryName: savedSubcategory,
        },
      }));
    }
  }, []);

  useEffect(() => {
    const savedCategory = localStorage.getItem("industryCategory");
    const savedSubcategory = localStorage.getItem("industrySubCategory");

    if (savedCategory && savedSubcategory) {
      setFormData((prev) => ({
        ...prev,
        industryCategory: { categoryName: savedCategory },
        industrySubCategory: { subcategoryName: savedSubcategory },
      }));
    }
  }, []);


  useEffect(() => {
    localStorage.setItem("savedFormData", JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Validate the field
    validateField(name, value);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPEG, PNG, JPG, WEBP) are allowed.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({ ...prevData, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const win = window.localStorage;
  useEffect(() => {
    const cat_id = win.getItem("category_id");

    fetchSubcategories(cat_id);
  }, []);

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleImageRemove = async () => {
    setFormData((prevData) => ({ ...prevData, image: null })); // Update state

    try {
      const payload = {
        ...formData,
        image: null, // Explicitly set image to null
        industryCategory: formData.industryCategory?.categoryName,
        industryCategoryId: formData.industryCategory?.category_id,
        industrySubCategory: formData.industrySubCategory?.subcategoryName,
        industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      };

      console.log("Final Payload After Image Removal:", payload); // Debugging

      const response = await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: `${token}` },
      });

      toast.success("Profile image removed successfully.");
      fetchProfile(); // Refresh profile after removal
    } catch (error) {
      console.error("Error removing image:", error);
      toast.error("Failed to remove image.");
    }
  };


  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }




  const handleSaveClick = async () => {
    const userId = sessionStorage.getItem("userId");
    if (!userId) {
      setError("User ID is missing. Please log in again.");
      return;
    }


    if (!handleValidationPage2()) {
      toast.error("Please fill all required fields before submitting.");
      return;
    }


    // Prepare the payload to send to the API
    const payload = {
      ...formData,
      // industryCategory: formData.industryCategory?.categoryName,
      industryCategory: formData.industryCategoryName,
      // industryCategoryId: formData.industryCategory?.category_id,
      industryCategoryId: formData.industryCategoryId,
      // industrySubCategory: formData.industrySubCategory?.subcategoryName,
      industrySubCategory: formData.industrySubCategoryName,
      // industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      industrySubCategoryId: formData.industrySubCategoryId,
    };
    console.log("Final Payload:", payload); // Debugging the payload

    try {
      // Send the updated data to the API
      const response = await axios.patch(`${apiUrl}/user/${userId}`, payload, {
        headers: { Authorization: `${token}` },
      });

      setShowSuccessModal(true); // Show success modal after successful update

      // Update the formData with the selected category and subcategory
      setFormData((prev) => ({
        ...prev,
        industryCategory: formData.industryCategory?.categoryName,
        industryCategoryId: formData.industryCategory?.category_id,
        industrySubCategory: formData.industrySubCategory?.subcategoryName,
        industrySubCategoryId: formData.industrySubCategory?.subcategory_id,
      }));
      console.log("API Response:", response.data); // Check if the response includes the fields

      console.log("Updated formData:", formData);

      // Persist the selected category and subcategory in localStorage (optional)
      localStorage.setItem(
        "industryCategory",
        formData.industryCategory?.categoryName
      );
      localStorage.setItem(
        "industrySubCategory",
        formData.industrySubCategory?.subcategoryName
      );
      localStorage.setItem(
        "industryCategoryId",
        formData.industryCategory?.category_id
      );
      localStorage.setItem(
        "industrySubCategoryId",
        formData.industrySubCategory?.subcategory_id
      );
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        toast.error(
          `Failed to update profile. ${error.response.data.message || error.response.statusText
          }`
        );
      } else {
        console.error("Error:", error);
        toast.error("Failed to update profile.");
      }
    }
  };

  const validateField = (name, value) => {
    let error = "";

    if (name === "gstIn") {
      const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
      if (!gstRegex.test(value)) {
        error = "Invalid GSTIN format. It should be 15 characters (e.g., 07ABCDE1234F1Z5).";
      }
    }

    if (name === "panNumber") {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!panRegex.test(value)) {
        error = "Invalid PAN format. It should be 10 characters (e.g., ABCDE1234F).";
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  console.log("formData before sending:", formData);


  // Handle Save (send data to API)
  const handleSave = async () => {
    try {
      await axios.patch(`${apiUrl}/user/${userId}`, formData, {
        headers: { Authorization: `${token}` },
      });
      localStorage.setItem("savedFormData", JSON.stringify(formData));
      toast.success("Data saved successfully!");
    } catch (error) {
      toast.error("Failed to save form data.");
    }
  };


  const handleValidation = () => {
    let newErrors = {};

    // Required Fields
    [
      { key: "userName", label: "User Name", required: true },
      { key: "firstName", label: "First Name", required: true },
      { key: "lastName", label: "Last Name", required: true },
      { key: "phoneNumber", label: "Mobile Number", required: true },
      { key: "email", label: "Email", required: true },
    ].forEach(({ key, label, required }) => {
      if (required && !formData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });



    // Email Validation
    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid Email format";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleNext = () => {
    if (handleValidation()) {
      setPage(2);
    }
  };

  const handleValidationPage2 = () => {
    let newErrors = {};

    // Required Fields
    [
      { key: "communicationAdress", label: "Company Address" },
      { key: "pincode", label: "Pincode" },
      { key: "industryCategoryId", label: "Industry Category" },
      { key: "industrySubCategoryId", label: "Industry Subcategory" },
    ].forEach(({ key, label }) => {
      if (!formData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    // Pincode Validation (6 digits)
    if (formData.pincode && !/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Pincode must be exactly 6 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };



  return (
    <div className="dashboard">
      <EmployerDashboardHeader />
      <Container fluid className="content">
        <Row className="g-4">
          <Col className="side">
            <EmployerDashboardSideBar />
          </Col>

          <Col md={10} className="main-content">
            <Card className="rounded-2 p-4 profile-box">
              <Card.Body>
                <h3
                  className="text-center"
                  style={{
                    color: "white",
                    marginRight: "80px",

                  }}
                >
                  Profile Details
                </h3>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <div className="text-center mb-4" style={{ position: 'relative' }}>
                  <div
                    className="profile-image-wrapper mb-3"
                    onClick={handleImageClick}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "80px",
                      marginBottom: "10px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={formData.image || profile}
                      alt="Profile Picture"
                      roundedCircle
                      className="shadow-lg"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        // background:'#000',
                        border: "4px solid #ECF6FF",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />

                  </div>
                  {formData.image && (
                    <h4
                      onClick={handleImageRemove}
                      style={{
                        position: "absolute",
                        top: "120px",
                        left: "53%",
                        color: "red",
                        cursor: "pointer",
                        transform: "translateX(-50%)",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <MdDelete />
                    </h4>
                  )}
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>

                {/* Subscription Plan Details */}
                <div
                  style={{
                    backgroundColor: "#ECF6FF",
                    padding: "10px",
                    marginBottom: "15px",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  {employerPackageData &&
                    employerPackageData.planEndDate &&
                    new Date(employerPackageData.planEndDate) > new Date() ? (
                    <>
                      <p className="fs-6 ms-2" style={{ color: "#170F49" }}>
                        Plan Name: {employerPackageData.planName}
                      </p>
                      <p className="fs-6 ms-2" style={{ color: "#170F49" }}>
                        Plan Start Date:{" "}
                        {moment(employerPackageData?.planStartDate).format(
                          "MMMM Do YYYY"
                        )}
                      </p>
                      <p className="fs-6 ms-2" style={{ color: "#170F49" }}>
                        Plan End Date:{" "}
                        {moment(employerPackageData?.planEndDate).format(
                          "MMMM Do YYYY"
                        )}
                      </p>
                      <p className="fs-6 ms-2" style={{ color: "#170F49" }}>
                        Price: {employerPackageData.price}
                      </p>
                      <p className="fs-6 ms-2" style={{ color: "#170F49" }}>
                        Validity in Days: {employerPackageData.validityInDays}
                      </p>
                    </>
                  ) : (
                    <p className="fs-6 ms-2 mt-3" style={{ color: "#FF0000" }}>
                      No active plan available.
                    </p>
                  )}
                </div>

                <Row>
                  <Col md={12} style={{ padding: '0px' }} >
                    {page === 1 && (
                      <Card className="mb-4">
                        <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white", padding: "15px" }}>
                          Employer Details
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            {[
                              { label: "User Name", key: "userName", mandatory: true },
                              { label: "First Name", key: "firstName", mandatory: true },
                              { label: "Last Name", key: "lastName", mandatory: true },
                              { label: "Mobile Number", key: "phoneNumber", mandatory: true },
                              { label: "Email", key: "email", mandatory: true },
                              { label: "Designation", key: "designation" },
                              { label: "Department", key: "department" },
                              { label: "Branch", key: "branch" },
                              { label: "Company Name", key: "companyName" },
                            ].map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label} {mandatory && <span className="text-danger">*</span>}
                                  </Form.Label>

                                  {/* Department Dropdown */}
                                  {key === "department" ? (
                                    <Form.Select name={key} value={formData[key]} onChange={handleChange} required>
                                      <option value="">Select Department</option>
                                      {["Administration", "HR", "Recruitment", "Production", "Finance", "R&D", "Sales", "Marketing", "Facilities", "Security", "Other"].map((dept) => (
                                        <option key={dept} value={dept}>
                                          {dept}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  ) : key === "branch" ? (
                                    /* Branch Dropdown */
                                    <Form.Select name={key} value={formData[key]} onChange={handleChange} required>
                                      <option value="">Select Branch</option>
                                      {["Head Office", "Branch Office", "Factory", "Warehouse", "Satellite Office", "Other"].map((branch) => (
                                        <option key={branch} value={branch}>
                                          {branch}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  ) : (
                                    /* Default Input Fields */
                                    <Form.Control
                                      type={key === "phoneNumber" ? "tel" : "text"}
                                      name={key}
                                      value={formData[key]}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className="rounded-3"
                                      maxLength={key === "phoneNumber" ? 10 : undefined}
                                      isInvalid={!!errors[key]}
                                    />
                                  )}

                                  {/* Validation Error Messages */}
                                  {errors[key] && <Form.Control.Feedback type="invalid">{errors[key]}</Form.Control.Feedback>}
                                </Form.Group>
                              </Col>
                            ))}
                          </Row>

                          {/* Buttons */}
                          <div className="employer-profile-btn">
                            <Button onClick={handleSave} className="btn btn-success mt-2 me-2 emplyer-save">
                              Save
                            </Button>
                            <Button onClick={handleNext} className="btn btn-primary mt-2 emplyer-next">
                              Next
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    {page === 2 && (
                      <Card className="mb-4">
                        <Card.Header style={{ backgroundColor: "rgb(47 39 97)", color: "white", padding: "15px" }}>
                          Company Details
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            {[
                              { label: "GST In", key: "gstIn" },
                              { label: "PAN Number", key: "panNumber" },
                              { label: "Company Address", key: "communicationAdress", mandatory: true },
                              { label: "State", key: "state" },
                              { label: "City", key: "city" },
                              { label: "Pincode", key: "pincode", mandatory: true },
                            ].map(({ label, key, mandatory }) => (
                              <Col md={6} key={key}>
                                <Form.Group controlId={key} className="mb-3">
                                  <Form.Label>
                                    {label} {mandatory && <span className="text-danger">*</span>}
                                  </Form.Label>

                                  {key === "state" || key === "city" ? (
                                    <Autocomplete
                                      options={key === "state" ? stateOptions : cityOptions}
                                      getOptionLabel={(option) => option}
                                      value={formData[key] || ""}
                                      onInputChange={(event, newInputValue) => {
                                        if (key === "state") {
                                          fetchStates(newInputValue);
                                        } else if (key === "city") {
                                          fetchCities(formData.state);
                                        }
                                      }}
                                      onChange={(event, newValue) => {
                                        handleChange({ target: { name: key, value: newValue } });
                                        if (key === "state") {
                                          fetchCities(newValue);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                          <input
                                            style={{ width: "100%" }}
                                            type="text"
                                            {...params.inputProps}
                                            className={`form-control ${errors[key] ? "is-invalid" : ""}`}
                                            placeholder={`Type ${key === "state" ? "State" : "City"} name...`}
                                          />
                                        </div>
                                      )}
                                    />
                                  ) : (
                                    <Form.Control
                                      type={label === "Pincode" ? "number" : "text"}
                                      name={key}
                                      value={formData[key] || ""}
                                      onChange={handleChange}
                                      required={mandatory}
                                      className={`rounded-3 ${errors[key] ? "is-invalid" : ""}`}
                                    />
                                  )}

                                  {errors[key] && <div className="invalid-feedback">{errors[key]}</div>}
                                </Form.Group>
                              </Col>
                            ))}

                            {/* Industry Category */}
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Industry Category <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                  as="select"
                                  name="industryCategoryId"
                                  value={formData.industryCategoryId || ""}
                                  onChange={handleIndustryCategoryChange}
                                  className={`form-control ${errors.industryCategoryId ? "is-invalid" : ""}`}
                                >
                                  <option value="">Select an Industry</option>
                                  {industryCategories.map((category) => (
                                    <option key={category.category_id} value={category.category_id}>
                                      {category.categoryName}
                                    </option>
                                  ))}
                                  <option value="Others">Others</option>
                                </Form.Control>

                                {showOtherIndustryInput && (
                                  <div className="d-flex mt-2">
                                    <input
                                      type="text"
                                      name="newIndustryCategory"
                                      value={newIndustryCategory}
                                      onChange={(e) => setNewIndustryCategory(e.target.value)}
                                      placeholder="Enter new industry category"
                                      className="form-control"
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-primary ms-2"
                                      onClick={handleAddIndustryCategory}
                                      disabled={isAddingIndustry}
                                    >
                                      {isAddingIndustry ? "Adding..." : "+"}
                                    </button>
                                  </div>
                                )}
                                {errors.industryCategoryId && <div className="invalid-feedback">{errors.industryCategoryId}</div>}
                              </Form.Group>
                            </Col>

                            {/* Industry Subcategory */}
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Industry Subcategory <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                  as="select"
                                  name="industrySubCategoryId"
                                  value={formData.industrySubCategoryId || ""}
                                  onChange={handleIndustrySubcategoryChange}
                                  className={`form-control ${errors.industrySubCategoryId ? "is-invalid" : ""}`}
                                >
                                  <option value="">Select an Industry Subcategory</option>
                                  {industrySubcategories.map((subcategory) => (
                                    <option key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                                      {subcategory.subcategoryName}
                                    </option>
                                  ))}
                                  <option value="Others">Others</option>
                                </Form.Control>

                                {showOtherSubcategoryInput && (
                                  <div className="d-flex mt-2">
                                    <input
                                      type="text"
                                      name="newIndustrySubcategory"
                                      value={newIndustrySubcategory}
                                      onChange={(e) => setNewIndustrySubcategory(e.target.value)}
                                      placeholder="Enter new subcategory"
                                      className="form-control"
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-primary ms-2"
                                      onClick={handleAddIndustrySubcategory}
                                      disabled={isAddingSubcategory}
                                    >
                                      {isAddingSubcategory ? "Adding..." : "+"}
                                    </button>
                                  </div>
                                )}
                                {errors.industrySubCategoryId && <div className="invalid-feedback">{errors.industrySubCategoryId}</div>}
                              </Form.Group>
                            </Col>
                          </Row>

                          {/* Buttons */}
                          <div className="employer-profile-btn">
                            <Button onClick={() => setPage(1)} className="btn btn-secondary me-2 mt-3 employer-prev">
                              Previous
                            </Button>
                            <Button onClick={handleSave} className="btn btn-success mt-3 me-2 emplyer-save">
                              Save
                            </Button>
                            <Button onClick={handleSaveClick} className="btn btn-success mt-3 employer-submit">
                              Submit
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    )}


                  </Col>
                </Row>



              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile has been successfully updated!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default EmployerProfileView;
