import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-hot-toast";
import "../../Styles/TableStyles.css";
import { useLocation } from "react-router-dom";
import ApplyJobPopup from "../Employee/ApplyJobPopup";
import { Container, Col, Row, Table, Modal, Card, Form, Spinner } from 'react-bootstrap';
import EmployerDashboardSideBar from "../../components/EmployerDashboardSideBar";
import EmployerDashboardHeader from "../../components/EmployerDashboardHeader";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";


import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaBorderAll } from "react-icons/fa6";
import { FaRegPlusSquare } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { IoIosHome } from "react-icons/io";

const statusColors = {
  APPLIED: "primary",
  INTERVIEW_SCHEDULED: "secondary",
  SHORTLISTED: "warning",
  REJECTED: "error",
  HIRED: "success",
};

export default function Bottom() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const location = useLocation();
  const [applied, setApplied] = useState([])
  const applicantFromState = location.state?.applicant;
  const employerId = sessionStorage.getItem("userId");
  const userId = sessionStorage.getItem("userId");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const token = sessionStorage.getItem('accessToken');
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [jobSeekersData, setJobSeekersData] = useState([]);
  const [planId, setPlanId] = useState(null);
  const[postedJobs, setPostedJobs] =useState([]);
    const [error, setError] = useState(null);
    const employerid = sessionStorage.getItem("userId");
    const hasShownToastRef = useRef(false);

  const [value, setValue] = React.useState(0);
  const role = sessionStorage.getItem("role");

 

  const getValueFromPath = () => {
    if (location.pathname === "/alljobseekertable") return 0;
    if (location.pathname === "/jobDescriptionForm" && role === "employer") return 2;
    if (location.pathname === "/Joblist" && role === "jobseeker") return 2;
    if (location.pathname === "/employerProfileView" && role === "employer") return 1;
    if (location.pathname === "/jobseekerprofile" && role === "jobseeker") return 1;
    if (location.pathname === "/dashboard" && role === "employer" ) return 3;
    if (location.pathname === "/jobseekerDashboard" && role === "jobseeker" ) return 3;


    return 0; // Default to first tab
  };

  useEffect(() => {
    setValue(getValueFromPath()); // Update value on route change
  }, [location]);

  


  return (
    <div className="" style={{ height: "auto", width: "100%" }}>

      <Box sx={{ width: "100%", position: "fixed", bottom: 0, left: 0, bgcolor: "background.paper", zIndex: 1000 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        
          // Navigation logic
          if (newValue === 0) {
            navigate("/alljobseekertable"); // Redirects to AllJobSeekerTable
          }

          if (newValue === 0) {
            if (role === "employer") {
              navigate("/dashboard");
            } else if (role === "jobseeker") { // Fix: Use else if instead of else
              navigate("/");
            }
          }

          // if (newValue === 1) {
          //   navigate("/jobDescriptionForm"); // Redirects to AllJobSeekerTable
          // }

          if (newValue === 2) {
            if (role === "employer") {
              navigate("/jobDescriptionForm");
            } else if (role === "jobseeker") { // Fix: Use else if instead of else
              navigate("/Joblist");
            }
          }
        //   if (newValue === 3) {
        //     navigate("/dashboard"); // Redirects to AllJobSeekerTable
        //   }
        
          if (newValue === 1) {
            if (role === "employer") {
              navigate("/employerProfileView");
            } else if (role === "jobseeker") { // Fix: Use else if instead of else
              navigate("/jobseekerprofile");
            }
          }

          if (newValue === 3) {
            if (role === "employer") {
              navigate("/dashboard");
            } else if (role === "jobseeker") { // Fix: Use else if instead of else
              navigate("/jobseekerDashboard");
            }
          }

        }}
        
      >
        <BottomNavigationAction label="Home" icon={<IoIosHome  className="fs-4"/>} />
        <BottomNavigationAction label={role === "jobseeker" ? "Jobseeker" : "Employer"}  icon={<IoPersonCircleOutline className="fs-4" />} />
        <BottomNavigationAction  label={role === "jobseeker" ? "All Jobs" : "Post Job"}  icon={<FaRegPlusSquare  className="fs-4"/>} />
        <BottomNavigationAction label="Dashboard" icon={<MdDashboard  className="fs-4" />} />
      </BottomNavigation>
    </Box>
    </div>
  );
}




